import React from 'react';
import ModalConfirm from '../Modal/ModalConfirm';

type ButtonConfirmProps = {
    onClick?: () => void,
    onConfirm?: () => void,
    onCancel?: () => void,
    title: string,
    subtitle?: string,
    confirmText: string,
    cancelText: string,
    children: React.ReactElement
}

type ButtonConfirmState = {
    openConfirmModal: boolean
}

class ButtonConfirm extends React.Component<ButtonConfirmProps, ButtonConfirmState> {
    constructor(props: ButtonConfirmProps) {
        super(props);
        this.state = {
            openConfirmModal: false,
        };
    }
    handleClick = (): void => {
        this.setState({ openConfirmModal: true });
        if (this.props.onClick) this.props.onClick();
    }
    handleCancel = (): void => {
        this.setState({ openConfirmModal: false });
        if (this.props.onCancel) this.props.onCancel();
    }
    handleConfirm = (): void => {
        this.setState({ openConfirmModal: false });
        if (this.props.onConfirm) this.props.onConfirm();
        const children = this.props.children;
        children.props.onClick();
    }
    render() {
        const children = this.props.children;
        return (
            <React.Fragment>
                {React.cloneElement(children, { ...children.props, onClick: this.handleClick })}
                <ModalConfirm
                    isOpen={this.state.openConfirmModal}
                    title={this.props.title}
                    subtitle={this.props.subtitle}
                    onConfirm={this.handleConfirm}
                    confirmText={this.props.confirmText}
                    onCancel={this.handleCancel}
                    cancelText={this.props.cancelText}
                />
            </React.Fragment>
        );
    }
}
export default ButtonConfirm;