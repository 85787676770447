import styled from 'styled-components/macro';
import { rem, rgba } from 'polished';
import { Level } from './types';
import { defaultVillageUiTheme as theme } from '@village/ui';

export const NetworkInfo = styled.div`
    position: absolute;
    top: calc(50% - 142px);
    left: 12px;
    z-index: 1;
    display: flex;
    flex-direction: column !important;
    gap: 8px;
`;

export const PeerMetrics = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    padding: 8px;
`;

export const Name = styled.div`
    color: ${theme.vui.colors.text.emphasis};
    font-size: ${rem(12)};
    text-align: center;
`;

export const InfoItem = styled.div<{ level: Level }>`
    
    color: ${({ level }) =>
        level === "very_good" ? theme.vui.colors.text.success
            : level === "good" ? theme.vui.colors.text.attention
                : level === "bad" ? theme.vui.colors.text.error
                    : theme.vui.colors.text.emphasis };
    background-color: ${rgba(theme.vui.colors.decorative.neutral.gray80, 0.96)};
    padding: 5px;
    width: 50px;
    border-radius: 8px;
    opacity: 1;
    transition: opacity 1s linear;
    text-align: center;
    font-size: ${rem(10)};

`;

export const ItemLabel = styled.div`
    color: ${theme.vui.colors.text.disabled};
    font-size: ${rem(8)};
    text-align: center;
`;