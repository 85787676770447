import React from 'react';
import { calculateElementsFitSize, Size } from 'utils/Basics';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import Loading from 'shared/Loading';
import VideoContainer from 'pages/CallScreen/VideoContainer/VideoContainer';
import { FacingMode } from '../../../CallSession/types';
import { OpentokPublisher } from '../../../opentok/types';
import * as Styled from './styles'
import { Icon } from 'icon';


type TestVideoStreamProps = {
    isVideoOn: boolean,
    mediaStream: any,
    facingMode: FacingMode,
    onVideoResize: (videoSize: Size) => void,
}
type TestVideoStreamState = {}

class TestVideoStream extends React.Component<TestVideoStreamProps, TestVideoStreamState> {
    constructor(props: TestVideoStreamProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.setVideoStream();
        window.addEventListener('resize', this.resizeVideoPreview);
    }
    componentDidUpdate(prevProps: TestVideoStreamProps) {
        if (prevProps?.mediaStream?.id !== this.props?.mediaStream?.id) {
            this.setVideoStream();
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeVideoPreview);
    }
    setVideoStream = () => {
        if (!this.props.mediaStream) return;

        const testVideo = document.getElementById('test-publisher') as HTMLVideoElement;
        testVideo.srcObject = this.props.mediaStream;
        setTimeout(() => this.resizeVideoPreview(), 200);
    }
    resizeVideoPreview = (): void => {
        const videoElement = document.getElementById('test-publisher') as HTMLVideoElement;
        const videoPreview = document.getElementById('video-container-test-preview')?.parentNode as HTMLElement;
        const fitSize = calculateElementsFitSize(videoElement, videoPreview);
        if (!fitSize) return;

        if (!isNaN(fitSize.width) && !isNaN(fitSize.height)) {
            this.props.onVideoResize(fitSize);
        }
    }
    render() {

        const { mediaStream, facingMode } = this.props;

        return (
            <VideoContainer error={!mediaStream}>
                <Styled.TestVideoContainer className={facingMode === FacingMode.user ? 'mirrored' : ''} >
                    <div className="OT_publisher OT_fit-mode-cover" >
                        <video id="test-publisher" className="OT_video-element" playsInline autoPlay />
                    </div>
                </Styled.TestVideoContainer>
                {!this.props.isVideoOn &&
                    <Styled.VideoDisabled>
                        <Icon name="info_white" size={1.25} />&nbsp;This is a preview. Your camera is disabled.
                    </Styled.VideoDisabled>
                }
            </VideoContainer>
        );
    }
}

type TestVideoPreviewProps = {
    videoSource?: string,
    isVideoOn: boolean,
    isBluring: boolean,
    publisher: OpentokPublisher,
    settingsPublisher: OpentokPublisher,
    facingMode: FacingMode
}
type TestVideoPreviewState = {
    isReady: boolean,
    mediaStream: any,
    videoSize: Size | {}
}

class TestVideoPreview extends React.Component<TestVideoPreviewProps, TestVideoPreviewState> {

    timeout?: ReturnType<typeof setTimeout>;
    getPublisherAttempts: number;

    constructor(props: TestVideoPreviewProps) {
        super(props);
        this.state = {
            isReady: false,
            mediaStream: null,
            videoSize: {},
        }
        this.getPublisherAttempts = 0;
    }
    componentDidMount() {
        this.setMediaStream();
    }
    componentDidUpdate(prevProps: TestVideoPreviewProps) {
        if (
            prevProps.videoSource !== this.props.videoSource
            || prevProps.isVideoOn !== this.props.isVideoOn
            || prevProps.isBluring !== this.props.isBluring
        ) {
            this.setMediaStream();
        }
    }
    setMediaStream = async (): Promise<void> => {
        this.setState({ isReady: false });
        try {
            const publisher = await this.getPublisher();
            const stream = await this.getMediaStream(publisher);
            this.setState({ mediaStream: stream });

        } catch (error: any) {
            countlyAddEvent(countlyEvents.error, {
                errorCode: error.name,
                errorDescription: error.message,
            })
        } finally {
            this.setState({ isReady: true });
        }
    }
    isNewStreamTrack = (videoTrack: MediaStreamTrack) => {
        const currentMediaTrack = this.state.mediaStream?.getVideoTracks()[0];
        return !currentMediaTrack || videoTrack.id !== currentMediaTrack.id;
    }
    getMediaStream = (publisher: OpentokPublisher): Promise<MediaStream> => {
        return (new Promise((resolve, reject) => {
            let stream = new MediaStream();
            const videoSource = publisher.getVideoSource();
            const mediaTrack = videoSource.track;
            if (!mediaTrack) {
                return reject("MediaTrack undefined");
            }

            if (mediaTrack.readyState === "live" && this.isNewStreamTrack(mediaTrack)) {
                stream.addTrack(mediaTrack);
                resolve(stream);

            } else {
                setTimeout(() => {
                    this.getMediaStream(publisher)
                        .then((stream) => resolve(stream))
                        .catch((error) => reject(error));
                }, 500);
            }

        }));
    }
    getPublisher = (): Promise<OpentokPublisher> => {
        return (new Promise((resolve, reject) => {
            if (this.getPublisherAttempts > 10) {
                this.getPublisherAttempts = 0;
                reject("Can't get Publisher");
            }
            if (!this.getPublisherAttempts) this.getPublisherAttempts = 0;
            this.getPublisherAttempts++;

            let publisher = this.props.isVideoOn ? this.props.publisher : this.props.settingsPublisher;
            if (publisher) {
                this.getPublisherAttempts = 0;
                resolve(publisher);
            } else {
                setTimeout(() => {
                    this.getPublisher()
                        .then((publisher) => resolve(publisher))
                        .catch((error) => reject(error));
                }, 500);
            }
        }));
    }
    handleVideoResize = (videoSize: Size) => {
        this.setState({ videoSize });
    }
    render() {

        const { isVideoOn, facingMode } = this.props;
        const { isReady, mediaStream, videoSize} = this.state;

        return (
            <Styled.TestVideoPreview id="video-container-test-preview" style={videoSize}>
                {isReady ?
                    <TestVideoStream
                        isVideoOn={isVideoOn}
                        facingMode={facingMode}
                        mediaStream={mediaStream}
                        onVideoResize={this.handleVideoResize}
                    />
                    : <Loading type="mui-spinner" color="#0080a3" />
                }
            </Styled.TestVideoPreview>
        );
    }
}
export default TestVideoPreview;