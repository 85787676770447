import React from 'react';
import CallSessionContext from './CallSessionContext';


function withCallSession(WrappedComponent: ReactComponent){
    class withCallSession extends React.Component<any, {}> {
        displayName: string = 'withCallSession';
        static WrappedComponent: ReactComponent;

        constructor(props: any){
            super(props);
            this.state = {};
        }
        render(){
            
            return (
                <CallSessionContext.Consumer>
                    {(context) => 
                        <WrappedComponent callSession={context} {...this.props} />
                    }
                </CallSessionContext.Consumer>
            );
        }
    };
    withCallSession.WrappedComponent = WrappedComponent;
    return withCallSession;
}
export default withCallSession;