import React from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import AppContext from "./AppContext";
import SSOAuth from 'utils/SSO/SSOAuth';
import User from "utils/SSO/User";
import ConfigFactory from 'ConfigFactory';
import ServerTime from "utils/ServerTime";
import HttpClient from 'utils/HttpClient';
import { countlyAddEvent } from "countly";
import countlyEvents from "countly/events";
import type { IUser, SSOUserData, AuthData, AuthUser } from 'shared/types';
dayjs.extend(utc);

type AppProps = {
    children: JSXElement
}
type AppState = {
    isLoggedIn: boolean,
    authUser: IUser | null,
    checkingAuthentication: boolean,
    isOpenInactivityAlert: boolean,
}

class AppProvider extends React.Component<AppProps, AppState> {

    constructor(props: AppProps) {
        super(props);
        this.state = {
            isLoggedIn: false,
            authUser: null,
            checkingAuthentication: true,
            isOpenInactivityAlert: false,
        };
    }
    getServerTime = async () => {
        let apiName = 'telehealthApi';
        let path = '/time';
        let serverTime: number;
        try {
            serverTime = await HttpClient().get(apiName, path);
            // calculate the diff between the local time and the server time.
            const serverTimeStamp = dayjs.utc(serverTime).unix();
            ServerTime.setTimeOffset(serverTimeStamp);
        } catch (error: any) {
            countlyAddEvent(countlyEvents.error, {
                errorCode: error.name,
                errorDescription: error.message,
                comment: "Can't get serverTime",
                response: JSON.stringify(error.response)
            })
        }
    }
    checkCurrentUser = async () => {
        await this.getServerTime();
        this.checkCurrentUserSSO();
    }
    checkCurrentUserSSO = () => {
        this.setState({ checkingAuthentication: true });
        if (SSOAuth.isValidAuthData()) {
            const authData: AuthData | null = SSOAuth.getAuthData();
            if (authData) {
                this.login(authData.userData);
            } else {
                this.setState({
                    isLoggedIn: false,
                    checkingAuthentication: false
                });
            }
        } else {
            this.setState({
                isLoggedIn: false,
                checkingAuthentication: false
            });
        }
    }
    login = (user: SSOUserData) => {
        let authUser = new User(user);
        this.setState({
            isLoggedIn: true,
            authUser: authUser,
            checkingAuthentication: false
        });
    }
    updateAuthUser = (data: any): AuthUser => {
        const authUser = this.state.authUser;
        for (const key in data) {
            authUser?.setAttribute(key, data[key]);
        }
        this.setState({ authUser: authUser });

        return authUser;
    }
    logout = () => {
        const SSO_CONFIG = ConfigFactory.getSSOConfig(SSOAuth.getIdP())
        SSOAuth.signOut();
        window.location.assign(SSO_CONFIG.HOST);
    }
    toggleInactivityAlert = () => {
        this.setState({
            isOpenInactivityAlert: !this.state.isOpenInactivityAlert
        });
    }
    render() {
        return (
            <AppContext.Provider
                value={{
                    isLoggedIn: this.state.isLoggedIn,
                    authUser: this.state.authUser,
                    checkingAuthentication: this.state.checkingAuthentication,
                    updateAuthUser: this.updateAuthUser,
                    login: this.login,
                    logout: this.logout,
                    checkCurrentUser: this.checkCurrentUser,
                }}
            >
                {this.props.children}
            </AppContext.Provider>
        );
    }
}
export default AppProvider;