import dayjs from 'dayjs';
import React from 'react';
import { Column, Row } from 'shared/Flex';
import DatePicker from 'shared/inputs/DatePicker';
import { debounce } from 'utils/Basics';
import HttpClient from 'utils/HttpClient';

import { Card, Text } from '@village/ui';

import BarChart from './components/BarChart';
import type { DataRow } from './types';

type ReportsPageProps = {}
type ReportsPageState = {
    [x: string]: any,
    loading?: boolean,
    startDate: any,
    endDate: any,
    report?: DataRow[]
}
class ReportsPage extends React.Component<ReportsPageProps, ReportsPageState> {
    constructor(props: ReportsPageProps) {
        super(props);

        let today = dayjs(new Date());
        let before7Days = today.subtract(7, 'day')

        this.state = {
            startDate: before7Days,
            endDate: today,
        };
    }
    componentDidMount() {
        this.getReportVisits(this.state.startDate, this.state.endDate);
    }
    getReportVisits(startDate: any, endDate: any): void {

        let apiName = 'telehealthApi';
        let path = '/reports/visits';
        // Date range should be keept into query string
        let queryParams = {
            start_date: startDate?.format(),
            end_date: endDate?.format()
        };

        this.setState({
            loading: true,
        });

        HttpClient().get(apiName, path, queryParams)
            .then((data) => {
                this.setState({
                    loading: false,
                    report: data,
                });
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            });
    }
    handleChange = (name: string) => (value: any): void => {
        this.setState({ [name]: value ? dayjs(value) : null }, () => {
            debounce(() => this.getReportVisits(this.state.startDate, this.state.endDate), 500);
        });
    }
    getCategories(): string[] {
        let range = [];
        const { startDate, endDate } = this.state;
        let currentDate = startDate;
        let lastDate = endDate;
        if (!lastDate) lastDate = dayjs(new Date());

        while (currentDate.isBefore(lastDate) || currentDate.isSame(lastDate)) {
            range.push(currentDate.format("YYYY-MM-DD"));
            currentDate = currentDate.add(1, 'day');
        }

        return range;
    }
    render() {

        const { startDate, endDate, report } = this.state;

        return (
            <Column grow scrollable>
                <Text type='h4'>Call report</Text>
                <Text type='body2'>
                    This report shows virtual visits made by patients thru Web or Mobile devices
                </Text>
                <Row gap={20}>
                    <DatePicker
                        clearIcon={null}
                        onChange={this.handleChange('startDate')}
                        value={startDate?.toDate()}
                    />
                    <DatePicker
                        onChange={this.handleChange('endDate')}
                        value={endDate?.toDate()}
                    />
                </Row>
                <Card>
                    <BarChart categories={this.getCategories()} data={report} />
                </Card>
            </Column>
        );
    }
}
export default ReportsPage;