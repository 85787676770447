import React from 'react';
import TimelineItem from './TimelineItem';
import { TimelineItem as TimelineItemType } from './types';
import * as Styled from './styles';


type Props = {
    items: TimelineItemType[]
}
const Timeline = ({ items }: Props) => {
    return (
        <Styled.Timeline>
            {items.map(( item ) => <TimelineItem {...item} />)}
        </Styled.Timeline>
    );
}
export default Timeline;