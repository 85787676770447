import type { ReactElement } from 'react';
import React, { useMemo } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import * as Styled from './styles';
import type { OptionBase, OptionGroupBase, SelectAsyncProps } from './types';
import { components as defaultComponents } from './components';

const { ClearIndicator, NoOptionsMessage, Option } = defaultComponents;

export const Autocomplete = <
    Option extends OptionBase = OptionBase,
    OptionGroup extends OptionGroupBase<Option> = OptionGroupBase<Option>
>({
    id,
    components,
    label,
    helperText,
    error,
    loadOptions,
    ...props
}: (SelectAsyncProps<Option, OptionGroup>)): ReactElement => {
    const hasError = Boolean(error);
    const componentsMerged = useMemo<SelectAsyncProps<Option, OptionGroup>['components']>(
        () => ({
            ClearIndicator,
            DropdownIndicator: undefined,
            IndicatorSeparator: undefined,
            NoOptionsMessage,
            Option,
            ...components,
        }),
        [components]
    );

    return (
        <Styled.InputContainer>
            {label ? <Styled.Label type="caption1">{label}</Styled.Label> : null}
            <Styled.Container $error={hasError}>
                {loadOptions ?
                    <AsyncSelect
                        id={id}
                        components={componentsMerged}
                        className="vui-autocomplete-container"
                        classNamePrefix="vui-autocomplete"
                        cacheOptions
                        loadOptions={loadOptions}
                        {...props}
                    />
                    : <Select
                        id={id}
                        components={componentsMerged}
                        className="vui-autocomplete-container"
                        classNamePrefix="vui-autocomplete"
                        {...props}
                    />}
            </Styled.Container>
            {helperText || hasError ? (
                <Styled.HelperText $error={hasError} type="caption1">{error ?? helperText}</Styled.HelperText>
            ) : null}
        </Styled.InputContainer>
    );
};
