export enum LOG_ACTION_BASE {
    create = "create",
    update = "update",
    delete = "delete",
}

export enum LOG_ACTION_SPECIFIC {
    clinic__assign_provider = "clinic.assign_provider",
    clinic__unassign_provider = "clinic.unassign_provider",
    provider__assign_role = "provider.assign_role",
    provider__unassign_role = "provider.unassign_role",
    waiting_room__assign_provider = "waiting_room.assign_provider",
    waiting_room__unassign_provider = "waiting_room.unassign_provider",
}

enum LOG_ACTION {
    create = "create",
    update = "update",
    delete = "delete",
    clinic__assign_provider = "clinic.assign_provider",
    clinic__unassign_provider = "clinic.unassign_provider",
    provider__assign_role = "provider.assign_role",
    provider__unassign_role = "provider.unassign_role",
    waiting_room__assign_provider = "waiting_room.assign_provider",
    waiting_room__unassign_provider = "waiting_room.unassign_provider",
}
export default LOG_ACTION;