import { rem } from 'polished';
import styled from 'styled-components/macro';

export const MicMuted = styled.div`
    color: #373d45;
    font-size: ${rem(14)};
    padding: ${rem(5)} ${rem(3)};
    display: flex;
    align-items: center;
`;
export const AudioSlot = styled.div`
    height: ${rem(22)};
    width: ${rem(8)};
    background-color: #c6cbd1;
    border-radius: ${rem(5)};
    display: inline-block;
    margin-right: ${rem(10)};

    &.green {
        background-color: #31ad6d;
    }
`;
export const MicTest = styled.div`
    width: 100%;
    height: ${rem(22)};
`;
export const MicTestContainer = styled.div`
    position: relative;
    padding-left: ${rem(5)};
`;