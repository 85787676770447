import { SelectOption } from "../../../shared/types"

enum VisitSessionStatus { // TODO move to constants
    inactive = 0,
    active = 1
}
enum FacingMode { // TODO move to constants
    user = "user",
    environment = "environment"
}
type VisitSession = {
    external_session_id: string,
    external_token: string,
    patient_id: number,
    status?: VisitSessionStatus,
}

type VideoQuality = {
    label: string,
    constraints: MediaTrackConstraints | null
}

interface Devices {
    cameraDevices: SelectOption[],
    microphoneDevices: SelectOption[],
    speakerDevices: SelectOption[],
}
interface DeviceSources {
    audioSource?: string,
    videoSource?: string,
    speaker?: string,
}
type StreamState = {
    has_audio: boolean, 
    has_video: boolean 
}
export { VisitSessionStatus, FacingMode }
export type { VisitSession, Devices, DeviceSources, StreamState, VideoQuality }