import styled from 'styled-components/macro';
import { rem } from 'polished';
import { ControlBtnTheme } from './ControlBtn-theme';
import type { Variant } from './types';

export const CtrBtn = styled.div<{ sm?: boolean, variant: Variant}>`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: ${rem(60)};
    height: ${rem(60)};
    background: ${({ variant }) => ControlBtnTheme.color[variant].background};
    color: #fff;

    border-radius: ${rem(16)};
    margin: auto;

    &:hover {
        background-color: ${({ variant }) => ControlBtnTheme.color[variant].hoverBackground};
    }

    &:active {
        background-color: ${({ variant }) => ControlBtnTheme.color[variant].activeBackground};
    }

    ${({ sm }) => sm && `
        width: ${rem(40)};
        height: ${rem(40)};
        border-radius: ${rem(8)};
    `}
`;

export const CtrLabel = styled.div`
    font-size: ${rem(12)};
    font-style: normal;
    font-weight: 500;
    line-height: ${rem(14)};
    text-align: center;
    margin-top: ${rem(6)};
    margin-bottom: ${rem(6)};
    margin-left: ${rem(-12)};
    margin-right: ${rem(-12)};
    color: rgba(255, 255, 255, 0.9);
`;

export const CallControlBtn = styled.div<{active?: boolean, sm?: boolean}>`
    width: ${rem(68)};
    height: ${rem(80)};

    ${({ sm }) => sm && `
        width: ${rem(40)};
        height: ${rem(40)};

        ${CtrBtn} {
            width: ${rem(40)};
            height: ${rem(40)};
            border-radius: ${rem(8)};
        }
    `}

    ${({ active }) => !active && `
        opacity: 0.5;
        pointer-events: none;

        ${CtrBtn} {
            cursor: unset;

            &:hover {
                background: ${ControlBtnTheme.color.light.background};
            }
        }
    `}

    @media screen and (min-width: 768px) {
        ${({ sm }) => sm && `
            width: ${rem(50)};
            height: ${rem(50)};

            ${CtrBtn} {
                width: ${rem(50)};
                height: ${rem(50)};
            }
        `}
    }
`;