import React from 'react';
import WaitScreen from './WaitScreen';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import { Provider, WaitingRoom } from 'shared/types';

type Props = {
    provider: Provider,
    waiting_room: WaitingRoom,
}
class HoldRoom extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        countlyAddEvent(countlyEvents.pageHoldCall);
    }
    render() {

        return (
            <WaitScreen
                title={`You are on hold`}
                subtitle="Please don't go anywhere"
                pill={{
                    text: "We will be with you shortly",
                    color: "green",
                }}
                provider={this.props.provider}
                waiting_room={this.props.waiting_room}
            />
        );
    }
}
export default HoldRoom;