import React from 'react';
import images from 'assets/images';


const TroubleshootSafari = () => {
    return (
        <div>
            <h4 className="gray-90 text-h5-to-h4-medium mb-4">
                Safari on MacOS or iOS
            </h4>
            <p className="is-size-7 has-text-weight-medium">
                Follow these steps to fix the issue:
            </p>
            <div>
                <p>1. Click on the link your provider emailed or texted to you and follow the instructions to join the virtual visit.</p>
                <img src={images.text_message} alt="Text Message" /> 
            </div>
            <hr/>
            <div>
                <p>2. When prompted to allow access to the camera and microphone, tap Allow.</p>
                <img src={images.ios_chrome_prompt} alt="iOS/Chrome Prompt" /> 
            </div>
        </div>
    );
};
export default TroubleshootSafari;