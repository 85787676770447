import styled from 'styled-components/macro';

export const CameraDeniedText = styled.span`
    color: #fff;
    text-align: center;
`;
export const CameraDenied = styled.div`
    font-size: 0.875rem;
    background-color: #24282e;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .icon-container {
        width: 48px;
        height: 48px;
        margin: 6px;
    }
`;
export const VideoContainer = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    
    .video {
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .force-fitMode-contain .OT_video-element {
        object-fit: contain;
    }

    .force-fitMode-cover .OT_video-element {
        object-fit: cover;
    }

    .mirrored .OT_video-element {
        right: -100%;
        transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
    }
`;