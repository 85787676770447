import React from 'react';
import ControlBtn from './ControlBtn';
import countlyEvents from 'countly/events';
import { TRYING_TO_CONNECT } from 'utils/Network/constants';
import { Icon } from 'icon';

type PauseBtnProps = {
    callSession: {
        networkQuality: number,
        isPaused: boolean
    },
    onClick: () => void,
}
const PauseBtn = ({callSession, onClick}: PauseBtnProps) => {
    return (
        <ControlBtn
            id="ctrl-btn-pause"
            active={callSession.networkQuality > TRYING_TO_CONNECT}
            onClick={onClick}
            className="disable-blur"
            label={callSession.isPaused ? 'Resume' : 'Put on hold'}
            eventCountly={countlyEvents.clickPauseButton}
        >
            <Icon name="pause" size={2} />
        </ControlBtn>
    );
}
export default PauseBtn;