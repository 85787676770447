import { rem } from 'polished';
import { Text } from '@village/ui';
import styled from 'styled-components/macro';
import { LeaveBtn } from '../../controls/LeaveBtn/styles';

export const WaitingParticipant = styled.div`
    flex: 1 1 auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;  
`;

export const Content = styled.div`
    text-align: center;
`;

export const Title = styled(Text)`
    color: #FFFFFF;
    margin-bottom: ${rem(6)};
`;

export const Subtitle = styled(Text)`
    color: #FFFFFF;
    opacity: 0.8;
    margin-bottom: ${rem(22)};
`;

export const ButtonEnableAudioVideo = styled(LeaveBtn)`
    height: ${rem(40)};
`;