import { OpentokStream } from "pages/CallScreen/opentok/types";
import { NetworkAssessment } from './types';
import type {
    NetworkQualityState,
    OpentokPeeObject,
    OpentokPeerType,
    OpentokSubscriberStats,
    OpentokPublisherStats,
    PeerStats,
    NetworkMetrics,
    NetworkQuality,
    NetworkStats,
    NetworkStatsHandler,
    OnlineStatusHandler,
} from './types';
import {
    AUDIO_BW_THRESHOLD,
    AUDIO_PL,
    VIDEO_BW_THRESHOLD,
    VIDEO_PL,
    BAD_BITRATE_THRESHOLD,
    BAD_PACKETLOSS_THRESHOLD,
    GOOD_BITRATE_THRESHOLD,
    GOOD_PACKETLOSS_THRESHOLD,
    AUDIO_QUALITY_BANDWIDTH_TOO_LOW,
    AUDIO_VIDEO_SUPPORTED,
    VIDEO_QUALITY_AUDIO_ONLY,
    NO_CONNECTION,
    TRYING_TO_CONNECT,
    CHECK_CONNECTION_INTERVAL_TIMEOUT,
    RUN_INTERVAL_TIMEOUT,
    MAX_SAMPLES,
    SAMPLE_WEIGHTS,
} from "./constants";


class NetworkTest {

    opentokPeerObject: OpentokPeeObject;
    opentokPeerType: OpentokPeerType;
    intervalJob?: ReturnType<typeof setInterval>;
    intervalJobCheckOnlineStatus?: ReturnType<typeof setInterval>;
    isOnline: boolean = true;
    biConnected: boolean = false;
    statsSamples: PeerStats[] = [];

    constructor(opentokPeerObject: OpentokPeeObject, opentokPeerType: OpentokPeerType) {
        this.opentokPeerObject = opentokPeerObject;
        this.opentokPeerType = opentokPeerType;
    }

    run = (handleNetworkStats: NetworkStatsHandler): void => {
        this.intervalJob = setInterval(() => {
            this._checkStats(this.opentokPeerObject)
                .then((networkStats: NetworkStats) => {
                    if (this.isOnline && networkStats) handleNetworkStats(networkStats);
                    this.biConnected = true;
                }).catch(() => {
                    this.biConnected = false;
                });
        }, RUN_INTERVAL_TIMEOUT);
    }

    stop = (): void => {
        if (this.intervalJob) clearInterval(this.intervalJob);
        if (this.intervalJobCheckOnlineStatus) clearInterval(this.intervalJobCheckOnlineStatus);
    }

    _checkStats = (opentokPeerObject: OpentokPeeObject): Promise<any> => {
        return new Promise((resolve, reject) => {
            try {
                opentokPeerObject.getStats((err: any, statsResults: any) => {
                    if (err) reject(err);

                    let results: NetworkStats | null = null;
                    const stats = this._unifyStats(statsResults);
                    if (this.statsSamples.push(stats) > MAX_SAMPLES) {
                        this.statsSamples.shift();
                    }
                    if (this.statsSamples.length === MAX_SAMPLES) {
                        results = this._getStatsFromSamples(this.statsSamples);
                    }

                    resolve(results);
                });
            } catch (err) {
                reject(err);
            }
        });
    }

    _getPublisherStats = (stats: OpentokPublisherStats): PeerStats => {
        return {
            timestamp: stats.timestamp,
            audio: {
                bytesTransfered: stats.audio.bytesSent,
                packetsTransfered: stats.audio.packetsSent,
                packetsLost: stats.audio.packetsLost,
            },
            video: {
                bytesTransfered: stats.video.bytesSent,
                packetsTransfered: stats.video.packetsSent,
                packetsLost: stats.video.packetsLost,
                frameRate: stats.video.frameRate,
            }
        }
    }

    _getSubscriberStats = (stats: OpentokSubscriberStats): PeerStats => {
        return {
            timestamp: stats.timestamp,
            audio: {
                bytesTransfered: stats.audio.bytesReceived,
                packetsTransfered: stats.audio.packetsReceived,
                packetsLost: stats.audio.packetsLost,
            },
            video: {
                bytesTransfered: stats.video.bytesReceived,
                packetsTransfered: stats.video.packetsReceived,
                packetsLost: stats.video.packetsLost,
                frameRate: stats.video.frameRate,
            }
        }
    }

    _unifyStats = (statsResults: any): PeerStats => {
        let stats: PeerStats;
        switch (this.opentokPeerType) {
            case "publisher":
                stats = this._getPublisherStats(statsResults[0].stats)
                break;

            case "subscriber":
            default:
                stats = this._getSubscriberStats(statsResults)
                break;
        }
        return stats;
    }

    _getStatsFromSamples = (samples: PeerStats[]): NetworkStats => {

        // Calculate Weighted Average state
        let audioBWAvg = 0; // Bandwidth Weighted Avg
        let videoBWAvg = 0; // Bandwidth Weighted Avg
        let audioLPRAvg = 0; // Lost Packets Ratio Weighted Avg
        let videoLPRAvg = 0;
        let combinedBWAvg = 0;
        let combinedLPRAvg = 0;
        let prevStats = samples[0];
        samples.slice(1).forEach((stats, i) => {
            const state: NetworkMetrics = this.getMetrics(stats, prevStats);

            audioBWAvg += state.bitrate.audio * SAMPLE_WEIGHTS[i];
            videoBWAvg += state.bitrate.video * SAMPLE_WEIGHTS[i];
            combinedBWAvg += state.bitrate.combined * SAMPLE_WEIGHTS[i];

            audioLPRAvg += state.packetLoss.audio * SAMPLE_WEIGHTS[i];
            videoLPRAvg += state.packetLoss.video * SAMPLE_WEIGHTS[i];
            combinedLPRAvg += state.packetLoss.combined * SAMPLE_WEIGHTS[i];

            prevStats = stats;
        });

        const average: NetworkMetrics = {
            bitrate: {
                video: videoBWAvg,
                audio: audioBWAvg,
                combined: combinedBWAvg,
            },
            packetLoss: {
                video: audioLPRAvg,
                audio: videoLPRAvg,
                combined: combinedLPRAvg
            }
        };

        // Calculate state on the last interval
        const lastStats: PeerStats = samples[samples.length - 1];
        const penultimateStats: PeerStats = samples[samples.length - 2];
        const lastState: NetworkMetrics = this.getMetrics(lastStats, penultimateStats);
        const stream = this.opentokPeerObject.stream;

        return {
            average: average,
            lastState: lastState,
            quality: this.getQuality(stream, average),
        };
    }

    getMetrics = (currentStats: PeerStats, prevStats: PeerStats): NetworkMetrics => {
        const testIntervalTime = (currentStats.timestamp - prevStats.timestamp) / 1000; // milliseconds to seconds

        const audioBitrate = 8 * (currentStats.audio.bytesTransfered - prevStats.audio.bytesTransfered) / testIntervalTime;
        const audioPacketsLost = currentStats.audio.packetsLost - prevStats.audio.packetsLost;
        const audioPacketsTransfered = currentStats.audio.packetsTransfered - prevStats.audio.packetsTransfered;

        const videoBitrate = 8 * (currentStats.video.bytesTransfered - prevStats.video.bytesTransfered) / testIntervalTime;
        const videoPacketsLost = currentStats.video.packetsLost - prevStats.video.packetsLost;
        const videoPacketsTransfered = currentStats.video.packetsTransfered - prevStats.video.packetsTransfered;

        const audioPacketsTotal = audioPacketsLost + audioPacketsTransfered;
        const audioPacketsLostRatio = audioPacketsTotal > 0 ? audioPacketsLost / audioPacketsTotal : 0;

        const videoPacketsTotal = videoPacketsLost + videoPacketsTransfered;
        const videoPacketsLostRatio = videoPacketsTotal > 0 ? videoPacketsLost / videoPacketsTotal : 0;

        const combinedPacketsTotal = audioPacketsTotal + videoPacketsTotal;
        const combinedPacketsLostTotal = audioPacketsLost + videoPacketsLost;
        const combinedPacketsLostRatio = combinedPacketsTotal > 0 ? combinedPacketsLostTotal / combinedPacketsTotal : 0;

        return {
            bitrate: { //bps
                audio: audioBitrate,
                video: videoBitrate,
                combined: audioBitrate + videoBitrate,
            },
            packetLoss: {
                audio: audioPacketsLostRatio * 100,
                video: videoPacketsLostRatio * 100,
                combined: combinedPacketsLostRatio * 100,
            },
        }
    }

    getQuality = (stream: OpentokStream, { bitrate, packetLoss }: NetworkMetrics): NetworkQuality => {

        let networkQualityState: NetworkQualityState = AUDIO_VIDEO_SUPPORTED;
        let isVideoSupported: boolean = true;
        let isAudioSupported: boolean = true;

        if (stream?.hasVideo) {
            if (bitrate.combined < VIDEO_BW_THRESHOLD || packetLoss.combined > VIDEO_PL) {
                isVideoSupported = false;
                networkQualityState = VIDEO_QUALITY_AUDIO_ONLY;
            }
        } else if (stream?.hasAudio) {
            if (bitrate.audio < AUDIO_BW_THRESHOLD || packetLoss.audio > AUDIO_PL) {
                isAudioSupported = false;
                isVideoSupported = false;
                networkQualityState = AUDIO_QUALITY_BANDWIDTH_TOO_LOW;
            }
        }

        return {
            supported: {
                audio: isAudioSupported,
                video: isVideoSupported,
            },
            state: networkQualityState,
        };
    }

    checkOnlineStatus = async () => {
        try {

            if (!navigator.onLine) return false;

            // The test request should not have the default time of the browser
            const controller = new AbortController();
            const ControllerTimeout = setTimeout(() => controller.abort(), CHECK_CONNECTION_INTERVAL_TIMEOUT - 1000);

            const timestamp = (new Date()).getTime();
            // Let check if we can download this micro file
            const online = await fetch("/BUILD_TIMESTAMP?" + timestamp, { signal: controller.signal });
            clearTimeout(ControllerTimeout);

            return online.status >= 200 && online.status < 300; // either true or false
        } catch (err) {
            return false; // definitely offline
        }
    }

    runCheckOnlineStatus = (callback: OnlineStatusHandler): void => {
        let counter = 0;
        this.intervalJobCheckOnlineStatus = setInterval(async () => {
            let connectionStatus = await this.checkOnlineStatus();

            if (!connectionStatus) counter++;
            else counter = 0;

            let networkQuality: number | null = null;
            this.isOnline = counter > 0 ? false : true;
            if ((counter - 1) * CHECK_CONNECTION_INTERVAL_TIMEOUT >= 60000) {
                networkQuality = NO_CONNECTION;
            } else if (counter > 0) {
                networkQuality = TRYING_TO_CONNECT;
            }

            if (!this.isOnline && networkQuality !== null) callback(networkQuality);
        }, CHECK_CONNECTION_INTERVAL_TIMEOUT);
    }

    static getNetworkAssessment = ({ bitrate, packetLoss }: NetworkMetrics): NetworkAssessment => {
        if (!bitrate || !packetLoss) return NetworkAssessment.neutral;

        const bitrateBps = bitrate.combined;
        const packetLossRatio = Math.round(packetLoss.combined * 100) / 100; // Rounded with 2 decimals
        if (bitrateBps < BAD_BITRATE_THRESHOLD || packetLossRatio > BAD_PACKETLOSS_THRESHOLD) {
            return NetworkAssessment.bad;
        } else if (bitrateBps > GOOD_BITRATE_THRESHOLD && packetLossRatio < GOOD_PACKETLOSS_THRESHOLD) {
            return NetworkAssessment.good;
        }

        return NetworkAssessment.neutral;
    }
}

export default NetworkTest;
