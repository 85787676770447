import styled from 'styled-components/macro';

export const VolumeLevel = styled.span`
    background-color: #31ad6d;
    display: block;
    height: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
`;
export const VolumeContainer = styled.span`
    position: absolute;
    bottom: 14px;
    width: 6px;
    height: 13px;
    overflow: hidden;
    z-index: 1;
    border-radius: 10px;
`;
export const MicrophoneVolume = styled.div<{sm: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    ${({ sm }) => sm && `
        ${VolumeContainer} {
            bottom: 4px;
            width: 4.2px;
            height: 10px;
        }
    `}
`;