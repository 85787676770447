import type { FormRule } from '../types';

const FormRules: FormRule[] =  [
	{
		code: 'name',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Display name is required',
				rule: ['required'],
			}
		}
	},
	{
		code: 'description',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Description is required',
				rule: ['required'],
			},
		}
	},
	{
		code: 'slug',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Slug is required',
				rule: ['required'],
			},
		}
	},
	{
		code: 'clinic_id',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Clinic is required',
				rule: ['customRule', (value: any, data: any, Rules: any) => {
					if(data.is_shared !== false){
						return Rules.required(value, data);
					}
					return true;
				}],
			},
		}
	}
];

export default FormRules;