import React from 'react';
import Avatar from 'shared/Avatar';
import AVATAR_COLOR_STATUS from 'shared/constants/AVATAR_COLOR_STATUS';
import images from 'assets/images';
import Pill, { PillProps } from 'shared/Pill';
import MessageInline from 'shared/MessageInline';
import { Provider, IWaitingRoom } from 'shared/types';
import { IMessage } from 'shared/Messages/types';
import './WaitingRoom.scss';
import './WaitScreen.scss';
import MainScreen from '../../../../CallScreen/InCallScreen/MainScreen';


type Props = {
    provider: Provider, 
    waiting_room: IWaitingRoom, 
    className?: string,
    message?: IMessage,
    title: string,
    subtitle: string,
    pill?: PillProps,
}
const WaitScreen = (props: Props) => {
    const { provider, waiting_room, className, message } = props;
    const clsName = className ? className : "";
    return (
        <MainScreen id="main-screen" className={`wait-screen ${clsName}`}>
            <div className="has-text-centered">
                <h4 className="gray-90 text-h5-to-h4-medium mb-2">{props.title}</h4>
                <p className="gray-80 is-size-6">{props.subtitle}</p>
                
                {message && <MessageInline type={message.type} message={message.message}/>}

                <div className="container d-flex align-items-center justify-content-center">
                    <div className="avatar-village">
                        <Avatar
                            first_name={provider.first_name}
                            last_name={provider.last_name}
                            avatarColor={provider.presence && AVATAR_COLOR_STATUS[provider.presence]}
                            img={images.ic_provider}
                        />
                        <img className="v-logo-overlap" src={images.ic_villagemd_v} alt="village medical v icon" />
                    </div>
                </div>
                <h5 className="waiting-room-name gray-80 text-h6-to-h5-medium mt-2 mb-2">
                    {waiting_room.name}
                </h5>
                
                {props.pill && <Pill {...props.pill} />}
            </div>
            <img src={images.waiting_room_bg} className="background is-hidden-mobile" alt="village medical waiting room illustration" />
        </MainScreen>
    );
}
export default WaitScreen;
