import React, { useState } from 'react';
import { VIDEO_QUALITIES } from 'shared/constants/VIDEO_QUALITIES';
import { VideoQuality } from 'pages/CallScreen/CallSession/types';
import * as Styled from './styles';

type Props = {
    callSession: any,
}

const Quality = ({ callSession }: Props) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleSelection = (quality: VideoQuality) => {
        callSession.setVideoQuality(quality);
        setOpen(false);
    }
    return (
        <Styled.Quality>
            <Styled.QualityValue onClick={() => setOpen(!open)} >
                Quality ({callSession.videoQuality?.label || "Auto"})
            </Styled.QualityValue>
            {open &&
                <Styled.QualityList>
                    {VIDEO_QUALITIES.map((quality) => 
                        <Styled.QualityItem key={quality.label} onClick={() => handleSelection(quality) } >
                            {quality.label}
                        </Styled.QualityItem>
                    )}
                </Styled.QualityList>
            }
        </Styled.Quality>
    );
}
export default Quality;