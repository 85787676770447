import type { FormRule } from '../types';

const FormRules: FormRule[] =  [
	{
		code: 'first_name',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'First name is required',
				rule: ['required'],
			}
		}
	},
	{
		code: 'last_name',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Last name is required',
				rule: ['required'],
			},
		}
	},
	{
		code: 'email',
		element_type: 'input',
		rules: {
			email: {
				type: 'error',
				error_type: 'hard',
				message: 'Email is not valid',
				rule: ['email'],
			},
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Email is required',
				rule: ['required'],
			},
		}
	},
	{
		code: 'roles',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'At least one role should be assigned',
				rule: ['required'],
			},
		}
	},
	{
		code: 'clinics',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				on: ['create'],
				error_type: 'hard',
				message: 'At least one clinic should be assigned',
				rule: ['required'],
			},
		}
	},
	{
		code: 'npi',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'NPI is required',
				rule: ['customRule', (value: any, data: any, Rules: any) => {
					if(data.isProvider){
						return Rules.required(value, data);
					}
					return true;
				}],
			},
			numeric: {
				type: 'error',
				error_type: 'hard',
				message: 'NPI should be numeric',
				rule: ['numeric'],
			},
			lengthBetween: {
				type: 'error',
				error_type: 'hard',
				message: 'NPI should be a 10 digits number',
				rule: ['lengthBetween', 10, 10],
			},
		}
	}
];

export default FormRules;