import React from 'react';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import BoxedLayout from 'layouts/BoxedLayout';
import VISIT_COMPLETED_REASON from 'shared/constants/VISIT_COMPLETED_REASON';
import '../EndCall/EndCall.scss';


class PatientCallDisconnected extends React.Component<{}, {}> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        countlyAddEvent(countlyEvents.pagePatientCallDisconnected);
        countlyAddEvent(countlyEvents.visitCompleted, {reason: VISIT_COMPLETED_REASON.callDropped})
    }
    handleRejoin = (): void => {
        //ATTENTION: Maybe countly sending request will fail because of the window reload
        countlyAddEvent(countlyEvents.clickRejoin);
        window.location.reload()
    }
    render() {
        return (
            <BoxedLayout>
                <div className="box d-flex flex-row align-items-center mt-2">
                    <div className="column">
                        <figure className="image container post-call-icon">
                            <img
                                className="is-horizontal-center ic-band-aid"
                                src="/ic-band-aid.svg"
                                alt="Village medical oops band aid icon"
                            />
                        </figure>
                        <p className="has-text-centered post-call-title">Call disconnected</p>
                        <p className="post-call-subtitle">
                            Looks like something went wrong
                        </p>
                        <div className="field has-addons has-text-centered">
                            <button className="button is-primary is-medium is-fullwidth mt-4" onClick={this.handleRejoin} >
                                <span>Rejoin call</span>
                            </button>
                        </div>
                    </div>
                </div>
            </BoxedLayout>
        );
    };
}
export default PatientCallDisconnected;