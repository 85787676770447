import React from "react";
import { createSession } from 'opentok-react';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import { debounce } from 'utils/Basics';
import CallSessionContext from './CallSessionContext';
import NetworkTest from 'utils/Network/NetworkTest';
import {
    AUDIO_VIDEO_SUPPORTED,
    TRYING_TO_CONNECT,
    NO_CONNECTION
} from 'utils/Network/constants';
import {
    NetworkQualityState,
    NetworkAssessment,
    NetworkMetrics,
    NetworkStats
} from 'utils/Network/types';
import { SelectOption } from "shared/types";
import { Devices, DeviceSources, FacingMode, StreamState, VideoQuality } from "./types";
import type {
    OpentokMediaSource,
    OpentokPublisher,
    OpentokStream,
    OpentokSubscriber,
    OpentokError
} from "../opentok/types";
import type { SessionDisconnectEvent, ConnectionEvent } from "../opentok/events";
import { DisconnectReason, SessionConnectError } from "../opentok/enums";
import { VIDEO_QUALITY_240p, VIDEO_QUALITY_480p } from "shared/constants/VIDEO_QUALITIES";
import Feature from "shared/Feature";
import FF from "shared/constants/FF";

type CallSessionProps = {
    apiKey: string,
    externalSessionId: string,
    token: string,
    onNoParticipants: (state: CallSessionState) => void,
    onStreamStateUpdated?: (streamState: StreamState) => void,
    onPublisherDestroyed?: () => void,
    initialVideoState?: boolean,
    children: JSXElement,
    initialAudioSource?: OpentokMediaSource,
    initialVideoSource?: OpentokMediaSource,
}
type CallSessionState = Devices & DeviceSources & {
    error: any,

    // Network quality state & metrics
    networkStats?: NetworkStats,
    subscriberNetworkStats?: NetworkStats,
    networkQuality: NetworkQualityState,
    subscriberNetworkQuality?: NetworkQualityState,
    videoQuality?: VideoQuality,

    //Call
    streams: OpentokStream[],
    publisher?: OpentokPublisher,
    subscriber?: OpentokSubscriber,
    callEstablished?: boolean,

    //Controls
    audio: boolean,
    video: boolean,
    isPaused: boolean,
    screenShare: boolean,
    backgroundBlur: boolean,

    //Video Focus
    focusedStream?: OpentokStream | null,
    focusedVideoElement?: HTMLVideoElement,
}
export type { CallSessionState }

class CallSession extends React.Component<CallSessionProps, CallSessionState> {

    _is_mounted?: boolean
    sessionHelper: any
    timeoutJobSubscriber?: ReturnType<typeof setTimeout>;

    constructor(props: CallSessionProps) {
        super(props);
        this.state = {
            error: null,
            networkQuality: AUDIO_VIDEO_SUPPORTED,
            streams: [],

            audio: true,
            video: props.initialVideoState === undefined ? true : props.initialVideoState,
            isPaused: false,
            screenShare: false,
            backgroundBlur: window.localStorage.getItem("background_blur") === "true" ? true : false,

            cameraDevices: [],
            microphoneDevices: [],
            speakerDevices: [],
        };
        this.createOpentokSession();
    }
    createOpentokSession = () => {
        this.sessionHelper = createSession({
            apiKey: this.props.apiKey,
            sessionId: this.props.externalSessionId,
            token: this.props.token,
            onError: this.onError,
            onStreamsUpdated: (streams: OpentokStream[]) => {
                if (streams.length === 0 && typeof this.props.onNoParticipants !== 'undefined') {
                    this.props.onNoParticipants(this.state);
                }

                let focusedStream = streams.length > 0 ? streams[0] : null;

                let screenStream = streams.find((stream) => stream.videoType === 'screen');
                if (screenStream) {
                    focusedStream = screenStream;
                }

                if (this._is_mounted) {
                    this.setState({
                        streams: streams,
                        focusedStream: focusedStream,
                        screenShare: screenStream ? false : this.state.screenShare
                    });
                }
            }
        });

        this.sessionHelper.session.on('sessionDisconnected', (event: SessionDisconnectEvent) => {
            if (event.reason === DisconnectReason.networkDisconnected) {
                this.setNetworkQuality(NO_CONNECTION);
            }
        });
        // this.sessionHelper.session.on('sessionReconnecting', (event) => {
        //     // Can be used to show a spinner with Message, Bad Network trying to reconnect.
        //     console.log("sessionReconnecting event: ", event);
        // });
        // this.sessionHelper.session.on('sessionReconnected', (event) => {
        //     // Can be used to hide "Reconnecting... + spinner"
        //     console.log("sessionReconnected event: ", event);
        // });
        this.sessionHelper.session.on('connectionDestroyed', (event: ConnectionEvent) => {
            if (event.reason === DisconnectReason.networkDisconnected) {
                this.setNetworkQuality(NO_CONNECTION);
            }
        });
    }
    componentDidMount() {
        this._is_mounted = true;
        this.loadDevices();
        this.onDeviceChange();
    }
    componentWillUnmount() {
        this.sessionHelper.disconnect();
    }
    componentDidUpdate(prevProps: CallSessionProps) {
        if (prevProps.initialVideoState !== this.props.initialVideoState) {
            this.setState({
                video: !!this.props.initialVideoState
            });
        }
    }
    onError = (error: OpentokError): void => {
        switch (error.name) {
            case SessionConnectError.OT_SOCKET_CLOSE_TIMEOUT:
            case SessionConnectError.OT_CONNECT_FAILED:
            default:
                this.setNetworkQuality(NO_CONNECTION);
                break;
        }
        this.setState({ error: `Failed to connect: ${error.message}` });
    }
    setCallState = (state: any): void => {
        this.setState(state);
    }
    isBluring = false;
    _disableBackgroundBlur = () => {
        if (!window?.OT?.hasMediaProcessorSupport()) return;
        if (this.isBluring) return;

        if (this.state.backgroundBlur) {
            countlyAddEvent(countlyEvents.backgroundBlur, { value: "none" })
            this.isBluring = true;
            this.state.publisher?.clearVideoFilter().then(() => {
                this.setState({ backgroundBlur: false });
                window.localStorage.setItem("background_blur", "false");
            }).catch(() => {
                this.setState({ backgroundBlur: true });
            }).finally(() => this.isBluring = false);
        }
    }
    _enableBackgroundBlur = () => {
        if (!window?.OT?.hasMediaProcessorSupport()) return;
        if (this.isBluring) return;

        if (!this.state.backgroundBlur) {
            countlyAddEvent(countlyEvents.backgroundBlur, { value: "blur" })
            this.isBluring = true;
            this.state.publisher?.applyVideoFilter({
                type: 'backgroundBlur',
                blurStrength: "high"
            }).then(() => {
                this.setState({ backgroundBlur: true });
                window.localStorage.setItem("background_blur", "true");
            }).catch(() => {
                this.setState({ backgroundBlur: false });
            }).finally(() => this.isBluring = false);
        }
    }
    setBackgroundBlur = (willBlur: boolean) => {
        if (willBlur) {
            this._enableBackgroundBlur();
        } else {
            this._disableBackgroundBlur();
        }
    }
    handleFocus = (stream: OpentokStream): void => {
        this.setState({ focusedStream: stream });
    }
    handleAudio = (): void => {
        const nextAudioState = !this.state.audio;
        countlyAddEvent(nextAudioState ? countlyEvents.audioEnabled : countlyEvents.audioDisabled)
        this.setState({ audio: nextAudioState });
        if (this.props.onStreamStateUpdated) {
            this.props.onStreamStateUpdated({
                has_audio: nextAudioState,
                has_video: this.state.video
            });
        }
    }
    handleVideo = (): void => {
        const nextVideoState = !this.state.video;
        countlyAddEvent(nextVideoState ? countlyEvents.videoEnabled : countlyEvents.videoDisabled)
        this.setState({ video: nextVideoState });
        if (this.props.onStreamStateUpdated) {
            this.props.onStreamStateUpdated({
                has_audio: this.state.audio,
                has_video: nextVideoState
            });
        }
    }
    handleEnableAudioVideo = (): void => {
        countlyAddEvent(countlyEvents.videoEnabled)
        countlyAddEvent(countlyEvents.audioEnabled)
        this.setState({ audio: true, video: true });
        if (this.props.onStreamStateUpdated) {
            this.props.onStreamStateUpdated({
                has_audio: true,
                has_video: true
            });
        }
    }
    handleScreenShare = (): void => {
        this.setState({ screenShare: !this.state.screenShare });
    }
    handlePause = (): void => {
        countlyAddEvent(!this.state.isPaused ? countlyEvents.isPaused : countlyEvents.isUnpaused)
        this.setState({ isPaused: !this.state.isPaused });
    }
    handleCycleVideo = (): void => {
        let currentCameraIndex = this.state.cameraDevices.findIndex((device) => device.value === this.state.videoSource);
        let nextCameraIndex = currentCameraIndex + 1;
        if (nextCameraIndex >= this.state.cameraDevices.length) nextCameraIndex = 0;
        let nextVideoSource = this.state.cameraDevices[nextCameraIndex].value;

        countlyAddEvent(countlyEvents.setVideoSource, { videoSource: JSON.stringify(nextVideoSource) })
        debounce(() => {
            this.setState({
                videoSource: nextVideoSource.toString()
            });
        }, 300)
    }
    handleFocusedVideoCreated = (videoElement: HTMLVideoElement): void => {
        this.setState({ focusedVideoElement: videoElement });
    }
    onDeviceChange = (): void => {
        if (navigator.mediaDevices?.ondevicechange === undefined) {
            console.warn("mediaDevices.ondevicechange not supported.");
            return;
        }
        navigator.mediaDevices.ondevicechange = () => {
            debounce(() => {
                this.loadDevices()
            }, 1500)
        }
    }
    loadDevices = async (): Promise<void> => {
        const devices = await this.getDevices();
        if (!devices) return;

        const { audioSource, videoSource, speaker } = this.getDefaultDevices(devices);

        this.setState({
            cameraDevices: devices.cameraDevices,
            microphoneDevices: devices.microphoneDevices,
            speakerDevices: devices.speakerDevices,
            videoSource: videoSource,
            audioSource: audioSource,
            speaker,
            video: !videoSource ? false : this.state.video,
        });
    }
    getDevices = async (): Promise<Devices | false> => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            let cameraDevices: SelectOption[] = [];
            const microphoneDevices: SelectOption[] = [];
            const speakerDevices: SelectOption[] = [];
            devices.forEach((device) => {
                if (device.kind.toLowerCase() === "videoinput") {
                    // For Mobile should be only front/back camera
                    cameraDevices.push({
                        value: device.deviceId,
                        label: device.label,
                    });
                } else if (device.kind.toLowerCase() === "audioinput") {
                    microphoneDevices.push({
                        value: device.deviceId,
                        label: device.label,
                    });
                } else if (device.kind.toLowerCase() === "audiooutput") {
                    speakerDevices.push({
                        value: device.deviceId,
                        label: device.label,
                    });
                }
            });

            // Mobile multi-camera handling
            if (cameraDevices.length > 2) {
                const frontCameraDevices: SelectOption[] = cameraDevices.filter(camera => camera.label.toLowerCase().match(/.*front.*/));
                const backCameraDevices: SelectOption[] = cameraDevices.filter(camera => camera.label.toLowerCase().match(/.*back.*/));

                if (frontCameraDevices.length > 0 && backCameraDevices.length > 0) {
                    cameraDevices = [frontCameraDevices[0], backCameraDevices[0]];
                }
            }

            return {
                cameraDevices: cameraDevices,
                microphoneDevices: microphoneDevices,
                speakerDevices: speakerDevices
            };

        } catch (err: any) {
            console.warn(err.name + ": " + err.message);
            return false;
        }
    }
    getDefaultDevices = (devices: Devices): DeviceSources => {
        const { cameraDevices, microphoneDevices, speakerDevices } = devices;

        const defaultVideoSource = cameraDevices.length >= 1 ? cameraDevices[0].value : null;
        const defaultAudioSource = microphoneDevices.length >= 1 ? microphoneDevices[0].value : null;
        const defaultSpeaker = speakerDevices[0]?.value ?? null;

        const isValidCurrentVideoSource = cameraDevices.find((e) => e.value === this.state.videoSource)
        const isValidCurrentAudioSource = microphoneDevices.find((e) => e.value === this.state.audioSource)
        const isValidCurrentSpeaker = speakerDevices.find((e) => e.value === this.state.speaker);

        const videoSource = (isValidCurrentVideoSource ? this.state.videoSource : defaultVideoSource)?.toString();
        const audioSource = (isValidCurrentAudioSource ? this.state.audioSource : defaultAudioSource)?.toString();
        const speaker = (isValidCurrentSpeaker ? this.state.speaker : defaultSpeaker)?.toString();

        return { videoSource, audioSource, speaker };
    }
    getFacingMode = (videoSource?: string): FacingMode => {
        let cameraDevice = this.state.cameraDevices.find((el) => el.value === videoSource);
        if (!cameraDevice) return FacingMode.user;

        const regex = new RegExp(/front|facetime/, 'gi'); // may not cover all front camera labels.
        return regex.test(cameraDevice.label) ? FacingMode.user : FacingMode.environment;
    }
    setNetworkStats = (networkStats: NetworkStats): void => {
        this._adjustQuality(networkStats.average, this.state.subscriberNetworkStats?.average);
        this.setState({ networkStats: networkStats });
    }
    setSubscriberNetworkStats = (networkStats: NetworkStats): void => {
        this._adjustQuality(this.state.networkStats?.average, networkStats.average);
        this.setState({ subscriberNetworkStats: networkStats });
    }
    _adjustQuality = (publisher?: NetworkMetrics, subscriber?: NetworkMetrics): void => {
        if (Feature.hasNot(FF.autoAdjustVideoQuality)) return;

        const publisherQuality = publisher ? NetworkTest.getNetworkAssessment(publisher) : NetworkAssessment.neutral;
        const subscriberQuality = subscriber ? NetworkTest.getNetworkAssessment(subscriber) : NetworkAssessment.neutral;

        if (publisherQuality < NetworkAssessment.neutral || subscriberQuality < NetworkAssessment.neutral) {
            this.setVideoQuality(VIDEO_QUALITY_240p); // Low quality
        } else if (publisherQuality > NetworkAssessment.neutral || subscriberQuality > NetworkAssessment.neutral) {
            this.setVideoQuality(VIDEO_QUALITY_480p); // Auto/Normal quality
        }
    }
    setNetworkQuality = (networkQuality: NetworkQualityState): void => {
        if (networkQuality === NO_CONNECTION) {
            this.state.publisher.destroy();
        }
        this.setState({ networkQuality: networkQuality });
    }
    setSubscriberNetworkQuality = (networkQuality: NetworkQualityState): void => {
        this.setState({ subscriberNetworkQuality: networkQuality });
    }
    setVideoQuality = (quality: VideoQuality) => {
        // TELE-1527: Commented to reduce countly events.
        // countlyAddEvent(countlyEvents.videoQualityUpdated, {
        //     current: this.state.videoQuality,
        //     next: quality,
        // });
        this.setState({ videoQuality: quality });
    }
    onPublisherDestroyed = (publisher: OpentokPublisher): void => {
        publisher.on('streamDestroyed', () => {
            this.setNetworkQuality(NO_CONNECTION);
            if (this.props.onPublisherDestroyed) this.props.onPublisherDestroyed();
        });
    }
    onPublisherReady = (publisher: OpentokPublisher): void => {
        this.onPublisherDestroyed(publisher);
        this.setState({ publisher });
        if (this.state.subscriber) {
            this.onCallEstablished();
        }
    }
    onSubscriberDisconnected = (subscriber: OpentokSubscriber): void => {
        subscriber.on('disconnected', () => {
            this.timeoutJobSubscriber = setTimeout(() => {
                this.setSubscriberNetworkQuality(TRYING_TO_CONNECT);
            }, 6000);
        });
        subscriber.on('connected', () => {
            if (this.timeoutJobSubscriber) clearTimeout(this.timeoutJobSubscriber);
            this.setSubscriberNetworkQuality(AUDIO_VIDEO_SUPPORTED);
        });
        subscriber.on('destroyed', () => {
            if (this.timeoutJobSubscriber) clearTimeout(this.timeoutJobSubscriber);
            this.setSubscriberNetworkQuality(NO_CONNECTION);
        });
    }
    onSubscriberReady = (subscriber: OpentokSubscriber) => {
        this.onSubscriberDisconnected(subscriber);
        this.setState({ subscriber, subscriberNetworkQuality: AUDIO_VIDEO_SUPPORTED });
        if (this.state.publisher) {
            this.onCallEstablished();
        }
    }
    onSubscriberError = (error: OpentokError): void => {
        this.setSubscriberNetworkQuality(NO_CONNECTION);
    }
    onCallEstablished = (): void => {
        this.setState({ callEstablished: true });
    }
    render() {
        return (
            <CallSessionContext.Provider
                value={{
                    session: this.sessionHelper.session,
                    error: this.state.error,
                    networkStats: this.state.networkStats,
                    subscriberNetworkStats: this.state.subscriberNetworkStats,
                    networkQuality: this.state.networkQuality,
                    subscriberNetworkQuality: this.state.subscriberNetworkQuality,
                    videoQuality: this.state.videoQuality,
                    streams: this.state.streams,
                    publisher: this.state.publisher,
                    subscriber: this.state.subscriber,
                    callEstablished: this.state.callEstablished,

                    audio: this.state.audio,
                    backgroundBlur: this.state.backgroundBlur,
                    video: this.state.video,
                    isPaused: this.state.isPaused,
                    cameraDevices: this.state.cameraDevices,
                    microphoneDevices: this.state.microphoneDevices,
                    speakerDevices: this.state.speakerDevices,
                    audioSource: this.state.audioSource,
                    videoSource: this.state.videoSource,
                    initialAudioSource: this.props.initialAudioSource,
                    initialVideoSource: this.props.initialVideoSource,
                    facingMode: this.getFacingMode(this.state.videoSource?.toString()),
                    screenShare: this.state.screenShare,
                    speaker: this.state.speaker,

                    focusedStream: this.state.focusedStream,
                    focusedVideoElement: this.state.focusedVideoElement,

                    setState: this.setCallState,
                    setNetworkStats: this.setNetworkStats,
                    setSubscriberNetworkStats: this.setSubscriberNetworkStats,
                    setNetworkQuality: this.setNetworkQuality,
                    setSubscriberNetworkQuality: this.setSubscriberNetworkQuality,
                    setBackgroundBlur: this.setBackgroundBlur,
                    handleFocus: this.handleFocus,
                    handleAudio: this.handleAudio,
                    handleVideo: this.handleVideo,
                    handlePause: this.handlePause,
                    handleEnableAudioVideo: this.handleEnableAudioVideo,
                    handleScreenShare: this.handleScreenShare,
                    handleCycleVideo: this.handleCycleVideo,
                    handleFocusedVideoCreated: this.handleFocusedVideoCreated,
                    loadDevices: this.loadDevices,
                    onPublisherReady: this.onPublisherReady,
                    onSubscriberReady: this.onSubscriberReady,
                    onSubscriberError: this.onSubscriberError,
                    onCallEstablished: this.onCallEstablished,
                    setVideoQuality: this.setVideoQuality,
                }}
            >
                {this.props.children}
            </CallSessionContext.Provider>
        );
    }
}
export default CallSession;