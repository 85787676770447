
type action = {
    name: string,
    exec: any,
    time: number
}

class Inactivity {
    
    events: string[]
    actions: action[]
    onReset: () => void
    timeouts: any

    constructor(actions: action[]){
        this.events = [
            "load",
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress"
        ];
    
        /* 
        actions structure should be like below:
            [{
                name: "warn",
                time: 14 * 60,
                exec: ()=>{},
            }];
        */
        this.actions = actions;
        this.timeouts = {};
        this.onReset = () => {};
        this.setup();
    }

    setup = (): void => {
        for (var i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }

        this.setTimeout();
    };

    clearTimeout = (): void => {
        this.actions.forEach((action) => {
            if(this.timeouts[action.name]){
                clearTimeout(this.timeouts[action.name]);
            }
        });
    };

    setTimeout = (): void => {
        this.actions.forEach((action) => {
            this.timeouts[action.name] = setTimeout(action.exec, action.time * 1000);
        });
    };

    resetTimeout = (): void => {
        this.clearTimeout();
        this.setTimeout();
        if(this.onReset){
            this.onReset();
        }
    };

    destroy = (): void => {
        this.clearTimeout();

        for (var i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
    };
}
export default Inactivity;