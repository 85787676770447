import React from 'react';
import qs from "qs";
import { RouteComponentProps } from 'react-router-dom';
import HttpClient from 'utils/HttpClient';
import Loading from "shared/Loading";
import Input from 'shared/inputs/Input2';
import Select from 'shared/inputs/VmdSelect';
import MessageInline from 'shared/MessageInline';
import { IMessage, MessageType } from 'shared/Messages/types';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import withValidation from 'validations/withValidation';
import WaitingRoomsFormRules from 'validations/FormRules/WaitingRoomsFormRules';
import { IValidation } from 'validations/types';
import { Clinic, IUser, IWaitingRoom, SelectOption, Validation, WaitingRoom } from 'shared/types';
import ProvidersWaitingRoom from './components/ProvidersWaitingRoom';
import { Button, Text, Drawer, DrawerFooter, DrawerHeader, DrawerContent } from '@village/ui';
import { Box } from '@material-ui/core';


type FormWaitingRoomsProps = RouteComponentProps & IValidation & {
    match: RouteComponentProps['match'] & {
        params: { id: string }
    }
    mode: "new" | "edit",
    authUser: IUser,
    onCreate: (newWaitingRooms: WaitingRoom) => void,
    onUpdate: (providerId: number, postData: IWaitingRoom) => void,
}
type FormWaitingRoomsState = {
    loading: boolean,
    open: boolean,
    message: IMessage | null,
    clinics?: Clinic[],
    waiting_room: IWaitingRoom
}

class FormWaitingRooms extends React.Component<FormWaitingRoomsProps, FormWaitingRoomsState> {
    constructor(props: FormWaitingRoomsProps) {
        super(props);
        this.state = {
            loading: false,
            open: false,
            message: null,
            waiting_room: {}
        };
    }
    componentDidMount() {
        this.loadClinics();
        if (this.props.mode === 'edit') {
            this.getWaitingRoom(parseInt(this.props.match.params.id));
        }
        this.setState({ open: true });
    }
    getWaitingRoom(waiting_room_id: number): void {
        let apiName = 'telehealthApi';
        let path = '/waiting_rooms/' + waiting_room_id;

        this.setState({
            loading: true
        });
        HttpClient().get(apiName, path)
            .then((data) => {
                this.setState({
                    loading: false,
                    waiting_room: data
                });
            }).catch((error) => {
                // TODO: Display error message?
                this.setState({
                    loading: false
                });
            });
    }
    getClinics = (): SelectOption[] => {
        const clinics = this.state.clinics || this.props.authUser?.clinics || [];
        return clinics.map((clinic) => {
            return {
                value: clinic.id,
                code: clinic.code,
                label: clinic.name
            };
        });
    }
    loadClinics = (): void => {
        const apiName = 'telehealthApi';
        const path = '/clinics';
        HttpClient().get(apiName, path)
            .then((data) => {
                this.setState({
                    clinics: data
                });
            }).catch((error) => {
                console.log("Loading clinics error: ", error)
            });
    }
    getPostData = (waiting_room: IWaitingRoom): IWaitingRoom => {
        let data: any = {
            name: waiting_room.name?.trim(),
            description: waiting_room.description?.trim(),
            slug: waiting_room.slug?.trim(),
        };
        if (this.props.mode !== 'edit' || waiting_room.is_shared) {
            data.clinic_id = waiting_room.clinic_id;
        }
        return data;
    }
    createWaitingRoom(): void {
        const apiName = 'telehealthApi';
        const path = '/waiting_rooms';
        const { waiting_room } = this.state;
        const postData = this.getPostData(waiting_room);
        if (!this.props.validate(postData)) return;
        this.setState({
            loading: true
        });
        HttpClient().post(apiName, path, postData)
            .then((data) => {
                this.setState({
                    loading: false,
                    waiting_room: data,
                    message: {
                        type: MessageType.success,
                        message: "New waiting room created successfully.",
                    }
                });
                this.props.onCreate(data);
                this.handleClose();
            }).catch((error) => {
                this.setState({
                    loading: false,
                    message: {
                        type: MessageType.danger,
                        message: (error.response?.data?.detail || "Failed to create waiting room")
                    }
                });
                countlyAddEvent(countlyEvents.error, {
                    errorCode: error.name,
                    errorDescription: error.message,
                    comment: "Failed to create waiting room",
                    response: JSON.stringify(error.response)
                });
            });
    }
    updateWaitingRoom(waiting_room_id: number): void {
        const apiName = 'telehealthApi';
        const path = '/waiting_rooms/' + waiting_room_id;
        const { waiting_room } = this.state;
        const postData = this.getPostData(waiting_room);
        if (!this.props.validate({
            ...postData,
            is_shared: waiting_room.is_shared
        })) return;
        this.setState({
            loading: true
        });
        HttpClient().put(apiName, path, postData)
            .then((data) => {
                this.setState({
                    loading: false,
                    waiting_room: {
                        ...waiting_room,
                        ...postData
                    }
                });
                this.props.onUpdate(waiting_room_id, postData);
                this.handleClose();
            }).catch((error) => {
                // TODO: Display error message?
                this.setState({
                    loading: false,
                    message: {
                        type: MessageType.danger,
                        message: (error.response?.data?.detail || "Failed to update waiting room")
                    }
                });
                countlyAddEvent(countlyEvents.error, {
                    errorCode: error.name,
                    errorDescription: error.message,
                    comment: "Failed to update waiting room",
                    response: JSON.stringify(error.response)
                });
            });
    }
    handleClose = (): void => {
        this.setState({ open: false });
        setTimeout(() => {
            let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            this.props.history.push({
                pathname: '/waiting_rooms',
                search: '?' + qs.stringify(queryParams),
            });
        }, 300);
    }
    handleSubmit = (event: React.FormEvent): void => {
        event.preventDefault();

        if (this.props.mode === 'new') {
            this.createWaitingRoom();
        } else {
            this.updateWaitingRoom(parseInt(this.props.match.params.id));
        }
    }
    setField = (name: string, value: any): void => {
        this.setState({
            waiting_room: {
                ...this.state.waiting_room,
                [name]: value
            }
        });
        //Trigger validation for new updates:
        this.props.validateLive({
            ...this.state.waiting_room,
            [name]: value
        });
    }
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        this.setField(name, value);
    }
    handleChangeSelect = (name: string, value: string): void => {
        this.setField(name, value);
    }
    render() {

        const { waiting_room, message } = this.state;
        const validations = this.props.validations === true ? {} : this.props.validations;

        return (
            <Drawer open={this.state.open} onClose={this.handleClose} anchor='right' data-testid='form-waiting-rooms-drawer'>
                <DrawerHeader onClose={this.handleClose} >
                    <Text type="h6">{this.props.mode === 'new' ? 'Create' : 'Edit'} waiting room</Text>
                </DrawerHeader>
                <DrawerContent>
                    {this.state.loading
                        ? <Loading />
                        : <form id='waiting_room-form' method='POST' >
                            {message &&
                                <MessageInline type={message.type} message={message.message} />
                            }
                            <Box py={2}>
                                <Input
                                    label='Display name'
                                    name='name'
                                    fullWidth
                                    value={waiting_room.name}
                                    onChange={this.handleChange}
                                    validation={validations?.name as Validation}
                                />
                            </Box>
                            <Box py={2}>
                                <Input
                                    label='Description'
                                    name='description'
                                    fullWidth
                                    value={waiting_room.description}
                                    onChange={this.handleChange}
                                    validation={validations?.description as Validation}
                                />
                            </Box>
                            <Box py={2}>
                                <Input
                                    label='Slug'
                                    name='slug'
                                    fullWidth
                                    value={waiting_room.slug}
                                    onChange={this.handleChange}
                                    validation={validations?.slug as Validation}
                                />
                            </Box>

                            {(this.props.mode !== 'edit' || waiting_room.is_shared) &&
                                <Box py={2}>
                                    <Select
                                        label="Clinic"
                                        id="clinic_id"
                                        name="clinic_id"
                                        fullWidth
                                        value={waiting_room.clinic_id}
                                        onChange={this.handleChangeSelect}
                                        validation={validations?.clinic_id as Validation}
                                        options={this.getClinics()}
                                        showEmptyOption
                                    />
                                </Box>}

                            {this.props.mode === 'edit' &&
                                <Box py={2}>
                                    <ProvidersWaitingRoom waiting_room_id={parseInt(this.props.match.params.id)} />
                                </Box>
                            }

                        </form>}
                </DrawerContent>
                <DrawerFooter>
                    <Box display="flex" gridGap={16}>
                        <Button variant="secondary" size="medium" fullWidth onClick={this.handleClose} >
                            Cancel
                        </Button>
                        <Button type="submit" size="medium" fullWidth onClick={this.handleSubmit} >
                            Save
                        </Button>
                    </Box>
                </DrawerFooter>
            </Drawer>
        );
    }
}
export default withValidation(FormWaitingRooms, WaitingRoomsFormRules);
