const ServerTime  = {
    diffTolerance: 3,
    timeOffset: 0,
    getLocalTimeUnix: (): number => {
        return (new Date()).getTime() / 1000;
    },
    setTimeOffset: (serverTimeStamp: number): number => {
        // calculate the diff between the local time and the server time.
        const localTimeStamp = ServerTime.getLocalTimeUnix();
        ServerTime.timeOffset = serverTimeStamp - localTimeStamp + ServerTime.diffTolerance;

        return ServerTime.timeOffset;
    },
    getServerTimeStampNow: (): number => {
        const localTimeStamp = ServerTime.getLocalTimeUnix();
        /* 
            diffTolerance should not be included in this formula, 
            the tolerance is just added to compensate the time for request to be completed
        */
        const serverTimeStamp = ServerTime.timeOffset + localTimeStamp;
        
        return serverTimeStamp;
    }
};

export default ServerTime;