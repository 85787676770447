import uuid4 from 'uuid4';
import type { WSEvent, IWSMessage } from './types';


class WSMessage implements IWSMessage {

    uuid?: string
    user_id?: number
    action: keyof typeof WSEvent
    data: any
    recipients: any = [];

    constructor(obj: IWSMessage) {
        this.uuid = obj.uuid ? obj.uuid : uuid4();
        this.action = obj.action;
        this.data = obj.data;
        this.recipients = obj.recipients ? obj.recipients : [];
        this.user_id = obj.user_id ? obj.user_id : this.user_id;
    }
}
export default WSMessage;