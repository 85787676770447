import React from 'react';
import classnames from 'classnames';
import './Modal.scss';

type ModalProps = {
    modalFor?: string, 
    isOpen?: boolean,
    isModalFullScreen?: boolean, 
    transparent?: boolean, 
    white?: boolean, 
    children: JSXElement,
    onClose?: () => void,
}

const Modal = (props: ModalProps) => {
    const { modalFor, isModalFullScreen, isOpen, transparent, white, onClose, children } = props;
    let mainClass = classnames("modal",
        { "modal-full-screen": isModalFullScreen },
        { "is-active": isOpen },
        {'white' : white},
    );
    if(modalFor) mainClass = `${mainClass} ${modalFor}`;

    const bgClass = classnames("modal-background",
        {'transparent' : transparent},
    );

    return (
        <div className={mainClass}>
            <div className={bgClass} onClick={onClose}>
                {!transparent &&
                    <div id="modal-vmd-logo" className="media-left mx-5 my-5 is-hidden-mobile">
                        <figure>
                            <img src="../../village-medical-light-logo-60.png" width="155" height="28" alt="village medical logo" />
                        </figure>
                    </div>}
            </div>
            <div className="modal-content full-screen">
                {isOpen && children}
            </div>
            {onClose &&
                <button className="modal-close is-large" aria-label="close" onClick={onClose} ></button>
            }
        </div>
    );
};
Modal.defaultProps = {
    transparent: false
};
export default Modal;
