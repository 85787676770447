import styled from 'styled-components/macro';
import { Text } from '@village/ui';

export const TimelineItemInfo = styled(Text)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
`;
export const TimelineMarker = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column; 
    padding: 0px;
    gap: 8px;
`;
export const TimelineItemVline = styled.div`
    width: 1px;
    flex-grow: 1;
    background: #E2E4E8;
`;
export const TimelineItemIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;

    width: 32px;
    height: 32px;
    background: #F8F8F9;
    border-radius: 100px;
    color: #626A74;
`;
export const TimelineItemContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0px;
    gap: 4px;
`;
export const TimelineItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 0px;
    gap: 16px;

    &:first-child {
        ${TimelineItemVline}:first-child {
            visibility: hidden;
        }
    }
    &:last-child {
        ${TimelineItemVline}:last-child {
            visibility: hidden;
        }
    }
`;
export const Timeline = styled.div`
    
`;