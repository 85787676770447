import type { FormRule } from '../types';

const FormRules: FormRule[] = [
	{
		code: 'patient_phone_number',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: "Patient phone number is required",
				rule: ['required'],
			},
			phone: {
				type: 'error',
				error_type: 'hard',
				message: 'This is not a valid phone number',
				rule: ['phone'],
			}
		}
	},
	{
		code: 'patient_consented_to_sms',
		element_type: 'input',
		rules: {
			requiredIsTrue: {
				type: 'error',
				error_type: 'hard',
				message: "Patient consent is required prior to sending them text messages",
				rule: ['isTrue'],
			}
		}
	},
];

export default FormRules;