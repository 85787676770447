import React from 'react';
import HttpClient from 'utils/HttpClient';
import { WaitingRoom } from 'shared/types';
import SelectMultiple from 'shared/inputs/SelectMultiple';
import type { SelectMultipleValue, SelectMultipleOption, ActionMetaOption } from 'shared/inputs/SelectMultiple';

type Props = {
    provider_id: number
    assigned_waiting_rooms: WaitingRoom[]
}
type State = {
    loading: boolean,
    assigned_waiting_rooms: WaitingRoom[],
    options: SelectMultipleOption,
}
class WaitingRoomsProvider extends React.Component<Props, State>{
    constructor(props: Props){
        super(props);
        this.state = {
            loading: false,
            assigned_waiting_rooms: props.assigned_waiting_rooms,
            options: []
        };
    }
    componentDidMount(){
        this.loadOptions();
    }
    loadOptions = (search: string = "", callback?: (options: any) => void): void => {
        let apiName = 'telehealthApi';
        let path = '/waiting_rooms'; 
        let queryParams = {
            skip: 0,
            limit: 30,
            search: search
        };
        this.setState({loading: true});

        HttpClient().get(apiName, path, queryParams)
        .then( (data: WaitingRoom[]) => {
            this.setState({loading: false});
            this.setState({options: this.waitingRoomsToOptions(data)});
            if(callback) callback(this.waitingRoomsToOptions(data));

        }).catch((error) => {
            this.setState({loading: false});
        });
    }
    handleChange = (name: string, options: SelectMultipleValue, action: ActionMetaOption) => {
        if(action.action === "select-option" && action.option) { // Waiting room added
            this.assignProvider(action.option.value, this.props.provider_id, () => {
                this.setState({
                    assigned_waiting_rooms: options.map((option) => option.item),
                });
            });
        }
        if(["remove-value", "pop-value"].includes(action.action) && action.removedValue) { // Waiting room removed
            this.unassignProvider(action.removedValue.value, this.props.provider_id, () => {
                this.setState({
                    assigned_waiting_rooms: options.map((option) => option.item),
                });
            })
        }
        if(action.action === "clear" && action.removedValues) { // All Waiting room removed(cleared)
            action.removedValues.forEach((option) => {
                this.unassignProvider(option.value, this.props.provider_id)
            });
            this.setState({assigned_waiting_rooms: []});
        }
    }
    waitingRoomsToOptions = (waiting_rooms: WaitingRoom[]): SelectMultipleOption => {
        return waiting_rooms.map((waiting_room) => {
            return {
                label: waiting_room.name,
                value: waiting_room.id,
                item: waiting_room,
            }
        });
    }
    assignProvider(waiting_room_id: number | string, provider_id: number, onSuccess?: Function): void{
        let apiName = 'telehealthApi';
        let path = `/waiting_rooms/${waiting_room_id}/assign_provider`; 
        this.setState({
            loading: true
        });
        let postData = {
            id: provider_id
        }
        HttpClient().post(apiName, path, postData)
        .then( (data) => {
            this.setState({
                loading: false
            });
            if(onSuccess) onSuccess();
        }).catch((error) => {
            this.setState({
                loading: false
            });
        });
    }
    unassignProvider(waiting_room_id: number | string, provider_id: number, onSuccess?: Function): void {
        let apiName = 'telehealthApi';
        let path = `/waiting_rooms/${waiting_room_id}/unassign_provider`;
        this.setState({
            loading: true
        });
        let postData = {
            id: provider_id
        }
        HttpClient().post(apiName, path, postData)
            .then((data) => {
                this.setState({
                    loading: false
                });
                if (onSuccess) onSuccess();
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            });
    }

    render() {
        return (
            <SelectMultiple
                isLoading={this.state.loading}
                label="Assign waiting rooms"
                placeholder={"Select rooms to assign"}
                name="assign_wating_room"
                value={this.waitingRoomsToOptions(this.state.assigned_waiting_rooms)}
                defaultOptions={this.state.options}
                loadOptions={this.loadOptions}
                onChange={this.handleChange}
            />
        );
    }
}
export default WaitingRoomsProvider;