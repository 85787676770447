import styled from 'styled-components/macro';

export const VideoNameTag = styled.div`
    position: absolute;
    bottom: 128px;
    left: 12px;
    background-color: rgba(55, 61, 69, 0.8);
    font-size: 1rem;
    color: #fff;
    border-radius: 8px;
    padding: 6.5px 8px;
    z-index: 3;
    display: flex;
    align-items: center;
`;