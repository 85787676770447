import React, { memo, useRef, useState, useEffect, useMemo } from 'react';
import type { FC } from 'react';

import * as Styled from './styles';
import type { Props } from './types';

export const Tabs: FC<Props> = memo(({ variant = 'fullWidth', value, ...props }) => {
    const tabsRef = useRef<HTMLButtonElement | null>(null);
    const observerRef = useRef<ResizeObserver | null>(null);
    const [indicatorWidth, setIndicatorWidth] = useState(0);

    useEffect(() => {
        const selectedTabElement = tabsRef.current?.querySelector('[aria-selected=true] .MuiTab-wrapper');
        setIndicatorWidth(selectedTabElement?.scrollWidth ?? 0);

        if (selectedTabElement) {
            observerRef.current = new ResizeObserver((entries) => {
                window.requestAnimationFrame(() => {
                    if (!Array.isArray(entries) || !entries.length) {
                        return;
                    }
                    const [{ contentRect }] = entries;
                    setIndicatorWidth(contentRect.width);
                });
            });
            observerRef.current.observe(selectedTabElement);
        }

        return () => observerRef.current?.disconnect();
        
    }, [value]);

    const tabIndicatorProps = useMemo(
        () => ({
            children: indicatorWidth > 0 ? <Styled.Indicator width={indicatorWidth} /> : null,
        }),
        [indicatorWidth]
    );

    return (
        <Styled.MeterialTabs
            ref={tabsRef}
            variant={variant}
            {...props}
            value={value}
            TabIndicatorProps={tabIndicatorProps}
        />
    );
});