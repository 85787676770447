import React from 'react';
import { NetworkQualityState, NetworkStats } from 'utils/Network/types';
import {
    OpentokSession,
    OpentokStream,
    OpentokPublisher,
    OpentokSubscriber,
    OpentokError,
    OpentokMediaSource
} from '../opentok/types';
import { Devices, DeviceSources, FacingMode, VideoQuality } from './types';

type CallSessionContextState = Devices & DeviceSources & {
    session: OpentokSession,
    error: any,
    networkStats?: NetworkStats,
    subscriberNetworkStats?: NetworkStats,
    networkQuality: NetworkQualityState,
    subscriberNetworkQuality: NetworkQualityState,
    videoQuality?: VideoQuality,
    streams: OpentokStream[],
    publisher: OpentokPublisher,
    subscriber: OpentokSubscriber,
    callEstablished: boolean,
    initialAudioSource?: OpentokMediaSource,
    initialVideoSource?: OpentokMediaSource,

    audio: boolean,
    video: boolean,
    isPaused: boolean,
    backgroundBlur: boolean,

    facingMode: FacingMode,
    screenShare: boolean,

    focusedStream: OpentokStream,
    focusedVideoElement: HTMLVideoElement,

    setState: (nextState: any) => void,
    setNetworkStats: (networkStats: NetworkStats) => void,
    setSubscriberNetworkStats: (networkStats: NetworkStats) => void,
    setNetworkQuality: (quality: NetworkQualityState) => void,
    setSubscriberNetworkQuality: (quality: NetworkQualityState) => void,
    handleFocus: (stream: OpentokStream) => void,
    handleAudio: () => void,
    setBackgroundBlur: () => void,
    handleVideo: () => void,
    handlePause: () => void,
    handleEnableAudioVideo: () => void,
    handleScreenShare: () => void,
    handleCycleVideo: () => void,
    handleFocusedVideoCreated: (element: HTMLVideoElement) => void,
    loadDevices: () => Promise<void>,
    onPublisherReady: (publisher: OpentokPublisher) => void,
    onSubscriberReady: (subscriber: OpentokSubscriber) => void,
    onSubscriberError: (error: OpentokError) => void,
    onCallEstablished: () => void,
    setVideoQuality: () => void,
}
export type { CallSessionContextState }
const CallSessionContext = React.createContext<CallSessionContextState | {}>({});

export default CallSessionContext;