import styled from 'styled-components/macro';
import * as ControlBtnStyles from '../controls/ControlBtn/styles';

export const CallControls = styled.div`
    width: 100%;
    display: flex;
    justify-content: center !important;
    position: absolute;
    z-index: 4;
    bottom: 0;
    padding-bottom: 12px;
`;

export const CallControlBar = styled.div`
    display: flex;
    align-items: end !important;
    background-color: rgba(36, 40, 46, 0.96);
    border-radius: 16px;
    padding: 12px 32px;
    width: auto;
    margin: auto;
    position: relative;

    ${ControlBtnStyles.CallControlBtn} {
        margin-right: 48px;

        &:last-child {
            margin-right: 0;
        }
    }

    @media screen and (max-width: 767px) {
        ${ControlBtnStyles.CallControlBtn} {
            margin-right: 28px;
        }
    }

    @media screen and (min-width: 768px) {
        padding: 16px 48px;
        
        ${ControlBtnStyles.CallControlBtn} {
            margin-right: 56px;
        }
    }
`;