import React from 'react';
import FirstTimeCheckIn from './components/FirstTimeCheckIn';
import Loading from 'shared/Loading';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import withPatientRoom from 'providers/PatientRoom/withPatientRoom';
import { PatientRoomContextState } from 'providers/PatientRoom/PatientRoomContext';
import DeniedPermissions from 'pages/DeniedPermissions';

type MediaPermissions = {
    camera?: PermissionState,
    microphone?: PermissionState,
}
enum MediaPermissionsStatus {
    prompt= "prompt",
    granted= "granted",
    denied= "denied",
}
type Props = PatientRoomContextState & {
    first_name: string,
}
type State = {
    permissionsStatus?: MediaPermissionsStatus,
}
class SetupPermissions extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    componentDidMount(){
        this.loadCurrentPermissions();
    }
    loadCurrentPermissions = async () => {
        let permissions = await this.getCameraMicrophonePermissionStatus();
        let permissionsStatus = this.getPermissionsStatus(permissions);
        if (permissionsStatus === "granted") { //Let's make sure permissions are granted
            permissionsStatus = await this.checkMediaDevices();
        }
        this.setPermissionStatus(permissionsStatus);
    }
    getCameraMicrophonePermissionStatus = async (): Promise<MediaPermissions>  => {
        const currentPermissions: MediaPermissions = {};
        let result;

        // Not supported on Safari/IE
        if (navigator.permissions !== undefined) {

            try {
                result = await navigator.permissions.query({ name: 'camera' as PermissionName })
                currentPermissions.camera = result.state;

                result = await navigator.permissions.query({ name: "microphone" as PermissionName })
                currentPermissions.microphone = result.state;

            } catch (error: any) {
                countlyAddEvent(countlyEvents.error, {
                    errorCode: error.name,
                    errorDescription: error.message,
                    comment: "Not a problem at all, navigator.permissions might not be fully supported on your browser",
                });
            }
        }

        return currentPermissions;
    }
    getPermissionsStatus = (permissions: MediaPermissions): MediaPermissionsStatus => {
        let status = MediaPermissionsStatus.prompt;
        if (permissions.camera === "granted" && permissions.microphone === "granted") {
            status = MediaPermissionsStatus.granted;
        } else if (permissions.camera === "denied" || permissions.microphone === "denied") {
            status = MediaPermissionsStatus.denied;
        }
        
        return status;
    }
    setPermissionStatus = (status: MediaPermissionsStatus) => {
        if(status === MediaPermissionsStatus.granted){
            this.props.handlePatientEnterQueue();
        }
        this.setState({permissionsStatus: status})
    }
    handleCameraMicrophonePermissions = (): void => {
        const options = { audio: true, video: true };
        navigator.mediaDevices.getUserMedia(options)
            .then(async (mediaStream: MediaStream) => {
                const audioTrack = mediaStream.getAudioTracks()[0];
                const videoTrack = mediaStream.getVideoTracks()[0];
                if(audioTrack) this.props.setInitialAudioSource(audioTrack);
                if(videoTrack) this.props.setInitialVideoSource(videoTrack);
                this.setPermissionStatus(MediaPermissionsStatus.granted)
                countlyAddEvent(countlyEvents.allowAudioVideo);
            })
            .catch((error: any) => {
                this.setPermissionStatus(MediaPermissionsStatus.denied)
                countlyAddEvent(countlyEvents.denyAudioVideo, {
                    name: error.name,
                    message: error.message,
                });
            });
    }
    checkMediaDevices = async (): Promise<MediaPermissionsStatus> => {
        const options = { audio: true, video: true };
        try {
            await navigator.mediaDevices.getUserMedia(options);
            return MediaPermissionsStatus.granted;

        } catch (error: any) {
            countlyAddEvent(countlyEvents.notAccessibleDevices, {
                name: error.name,
                message: error.message,
                comment: "Devices might be already in use by other apps",
            });
            return MediaPermissionsStatus.denied;
        }
    }
    render() {
        return (
            this.state.permissionsStatus === MediaPermissionsStatus.granted ?
                null
            : this.state.permissionsStatus === MediaPermissionsStatus.denied ?
                <DeniedPermissions
                    handleCameraMicrophonePermissions={this.handleCameraMicrophonePermissions}
                />
            : this.state.permissionsStatus === MediaPermissionsStatus.prompt ?
                <FirstTimeCheckIn
                    first_name={this.props.first_name}
                    handleCameraMicrophonePermissions={this.handleCameraMicrophonePermissions}
                />
            :<div className="container is-fluid fullheight d-flex align-items-center justify-content-center" >
                <Loading />
            </div>

        );
    }
}
export default withPatientRoom(SetupPermissions);