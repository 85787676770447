import React from 'react';
import withBrowserInfo from './withBrowserInfo';
import PatientRoomProvider from 'providers/PatientRoom/PatientRoomProvider';
import StateRouter from 'shared/StateRouter/StateRouter';
import StateRoute from 'shared/StateRouter/StateRoute';
import WelcomeBack from './WelcomeBack';
import CheckIn from './CheckIn';
import SetupPermissions from './SetupPermissions';
import PatientCallSession from './PatientCallSession';
import EndCall from './EndCall';
import KickedOut from './KickedOut';
import PatientCallDisconnected from './PatientCallDisconnected';
import WaitingRoomNotFound from './WaitingRoomNotFound';
import './PatientRoomPage.scss';
import { RouteComponentProps } from 'react-router-dom';

const CheckBrowserCompatibility = withBrowserInfo(({ children }) => children );

type Props = RouteComponentProps & {}
const PatientRoomPage = (props: Props) => {
    return (
        <StateRouter>
            <PatientRoomProvider params={props.match.params}>
                <CheckBrowserCompatibility>
                    <StateRoute view="welcome_back" component={WelcomeBack} />
                    <StateRoute view="check_in" component={CheckIn} />
                    <StateRoute view="setup_permissions" component={SetupPermissions} />
                    <StateRoute view="call_session_started" component={PatientCallSession} />
                    <StateRoute view="call_disconnected" component={PatientCallDisconnected} />
                    <StateRoute view="end_call" component={EndCall} />
                    <StateRoute view="kicked_out" component={KickedOut} />
                    <StateRoute view="waiting_room_not_found" component={WaitingRoomNotFound} />
                </CheckBrowserCompatibility>
            </PatientRoomProvider>
        </StateRouter>
    );
}
export default PatientRoomPage;