import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import qs from 'qs';
import SSOAuth from 'utils/SSO/SSOAuth';
import consumeApp from 'providers/consumeApp';
import Loading from 'shared/Loading';
import HttpClient from 'utils/HttpClient';
import AzureError from './AzureError';
import type { IAzureError } from './AzureError';
import { CenteredContainer } from 'shared/PageLayout';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';


type OidcCallbackPageProps = {
    login: (user: any) => void,
    history: RouteComponentProps['history']
}
type OidcCallbackPageState = {
    sso_error?: IAzureError
}
class OidcCallbackPage extends React.Component<OidcCallbackPageProps, OidcCallbackPageState> {
    constructor(props: OidcCallbackPageProps){
        super(props);
        this.state = {};
    }
    componentDidMount(){
        countlyAddEvent(countlyEvents.pageAzureCallback);
        this.handleResponse();
    }
    handleResponse(){
        const urlSearchParams = window.location.search;
        const params = qs.parse(urlSearchParams, { ignoreQueryPrefix: true })
        if(params.error){
            this.showError(params);
        }else{
            this.exchangeCodeWithTokens(params);
        }
    }
    showError(params: any){
        this.setState({
            sso_error: {...params}
        });
    }
    exchangeCodeWithTokens(params: any){
        /**
         * Make request with "code" to get accessToken and idToken.
         * The request can be made on our backend or directly on sso service.
         */
        let apiName = 'telehealthApi';
        let path = `/users/oidc/callback?idp=azure`;

        HttpClient()
        .get(apiName, path, params)
        .then((data) => {
            window.sessionStorage.removeItem("VMD.TH.azure_auth_code_failure_count");
            SSOAuth.setAuthData(data);
            this.props.login(data.userData);
        }).catch((error) => {

            let azure_auth_code_failure_count = parseInt(window.sessionStorage.getItem("VMD.TH.azure_auth_code_failure_count") ?? "0");
            azure_auth_code_failure_count += 1;
            window.sessionStorage.setItem("VMD.TH.azure_auth_code_failure_count", azure_auth_code_failure_count.toString());
            if(azure_auth_code_failure_count < 3) {
                return window.location.replace("/azure-oidc/login");
            }

            countlyAddEvent(countlyEvents.error, {
                errorCode: error.name,
                errorDescription: error.message,
                comment: "Azure code exchange failed", 
            });
        });
    }
    render(){
        const sso_error = this.state.sso_error;

        return (
            <CenteredContainer >
                {sso_error ? <AzureError {...sso_error} history={this.props.history} /> : <Loading />}
            </CenteredContainer >
        );
    }
}
export default consumeApp(OidcCallbackPage);