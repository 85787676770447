type Props = {
    waiting_room: {
        name: string,
        url: string,
    },
}
const InviteSMSTemplate = (props: Props) => {
    return (`Village Medical: Hello! We are ready to check you in to your appointment with ${props.waiting_room.name}.
Please make sure you are using a device with a good internet connection that has access to a camera and audio.
Join your video visit using this link: ${props.waiting_room.url}`);
}

export default InviteSMSTemplate;
