import { rem } from 'polished';
import styled from 'styled-components';

type Direction = "row" | "row-reverse" | "column" | "column-reverse";
type AlignItemsValue = 
"normal"
| "stretch" 
| "center" 
| "start" 
| "flex-start" 
| "end" 
| "flex-end" 
| "baseline"
| "first baseline" 
| "last baseline" 
| "safe center" 
| "unsafe center" 
| "inherit" 
| "initial" 
| "revert" 
| "revert-layer" 
| "unset" ;


export const Container = styled.div<{
    direction?: Direction,
    scrollable?: boolean,
    grow?: boolean
    shrink?: boolean,
    alignItems?: AlignItemsValue,
    justifyContent?: AlignItemsValue
}>`
    display: flex;
    ${({ direction }) => `flex-direction: ${direction}`};
    flex-grow: ${({ grow }) => grow ? 1 : 0};
    flex-shrink: ${({ shrink }) => shrink ? 1 : 0};
    ${({ scrollable }) => scrollable && `overflow: auto;`}
    flex: 100%;
    align-items: ${({ alignItems }) => alignItems ? alignItems : 'normal'};
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : 'normal'};
`;

export const Column = styled.div<{
    grow?: boolean, 
    scrollable?: boolean,
    padding?: number,
    gap?: number,
    alignItems?: AlignItemsValue
}>`
    display: flex;
    flex-direction: column;
    align-items: ${({ alignItems }) => alignItems ? alignItems : 'normal'};
    padding: ${({ padding }) => padding !== undefined ? rem(padding) : rem(32)};
    gap: ${({ gap }) => gap !== undefined ? rem(gap) : rem(20)};
    flex-grow: ${({ grow }) => grow ? 1 : 0};
    ${({ scrollable }) => scrollable && `overflow: auto;`}
`;

export const Row = styled.div<{grow?: boolean, alignItems?: AlignItemsValue, gap?: number, wrap?: string}>`
    display: flex;
    flex-direction: row;
    ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
    padding: 0px;
    gap: ${({ gap }) => gap !== undefined ? rem(gap) : rem(20)};
    flex-grow: ${({ grow }) => grow ? 1 : 0};
    flex-wrap: ${({ wrap }) => wrap ? "wrap;" : "unset"};
`;