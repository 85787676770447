import type { TabProps } from '@material-ui/core';
import { Tab } from '@material-ui/core';
import { rem } from 'polished';
import styled from 'styled-components';

export const BaseTab = styled(Tab)<TabProps>`
    color: ${({ theme }) => theme.vui.component.tab.default.color};
    opacity: 1;
    text-transform: none;
    font-size: ${rem(14)};
    &.Mui-selected {
        color: ${({ theme }) => theme.vui.component.tab.selected.color};
        font-weight: 600;
    }
`;