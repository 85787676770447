import styled from 'styled-components/macro';


export const MediaStatusItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    &.on {
        color: #373D45;
    }

    &.off {
        color: #9299A2;
    }
`;

export const MediaStatus = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
`;