type Props = {
    waitingRoomName: string,
    text: string,
    inviteLink: string,
}
const InviteEmailTextLayoutTemplate = (props: Props) => {
    return (`
        Your Video Visit with ${props.waitingRoomName}

        ${props.text}
        Join Video Visit
        
        Appointment Details
        
        Online
        
        ${props.inviteLink}

        ${props.waitingRoomName}

        IMPORTANT: This is an automated message from Village Medical. Please do not reply. For any questions please contact your provider.
    `);
}

export default InviteEmailTextLayoutTemplate;