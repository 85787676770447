import React from 'react';
import type { Color } from '../types';


type BulmaSpinnerProps = {
    width: string | number,
    height: string | number,
    color: Color,
}

const BulmaSpinner = ({ width, height, color }: BulmaSpinnerProps) => {
    return (
        <div
            className="loader is-loading"
            style={{
                width: width,
                height: height,
                borderLeftColor: color,
                borderBottomColor: color,
            }}
        ></div>
    );
}
export default BulmaSpinner;