import ConfigFactory from 'ConfigFactory';

type Props = {
    waitingRoomName: string,
    text: string,
    inviteLink: string,
}

const InviteEmailLayoutTemplate = (props: Props) => {
    
    let hostname = ConfigFactory.getPatientHost();

    return (`
    <html>
        <head>
            <style>
                @font-face {
                    font-family: 'Gotham';
                    font-style: normal;
                    font-weight: 100;
                    src: local('Gotham Thin'), local('Gotham-Thin'), url('${hostname}/assets/fonts/gotham/Gotham-Thin.otf');
                }

                @font-face {
                    font-family: 'Gotham';
                    font-style: italic;
                    font-weight: 100;
                    src: local('Gotham ThinItalic'), local('Gotham-ThinItalic'), url('${hostname}/assets/fonts/gotham/Gotham-ThinItalic.otf');
                }

                @font-face {
                    font-family: 'Gotham';
                    font-style: normal;
                    font-weight: 200;
                    src: local('Gotham XLight'), local('Gotham-XLight'), url('${hostname}/assets/fonts/gotham/Gotham-XLight.otf');
                }

                @font-face {
                    font-family: 'Gotham';
                    font-style: italic;
                    font-weight: 200;
                    src: local('Gotham XLightItalic'), local('Gotham-XLightItalic'), url('${hostname}/assets/fonts/gotham/Gotham-XLightItalic.otf');
                }

                @font-face {
                    font-family: 'Gotham';
                    font-style: normal;
                    font-weight: 300;
                    src: local('Gotham Light'), local('Gotham-Light'), url('${hostname}/assets/fonts/gotham/Gotham-Light.otf');
                }

                @font-face {
                    font-family: 'Gotham';
                    font-style: italic;
                    font-weight: 300;
                    src: local('Gotham LightItalic'), local('Gotham-LightItalic'), url('${hostname}/assets/fonts/gotham/Gotham-LightItalic.otf');
                }

                @font-face {
                    font-family: 'Gotham';
                    font-style: normal;
                    font-weight: 400;
                    src: local('Gotham Book'), local('Gotham-Book'), url('${hostname}/assets/fonts/gotham/Gotham-Book.otf');
                }

                @font-face {
                    font-family: 'Gotham';
                    font-style: italic;
                    font-weight: 400;
                    src: local('Gotham BookItalic'), local('Gotham-BookItalic'), url('${hostname}/assets/fonts/gotham/GothamBookItalic.ttf');
                }

                @font-face {
                    font-family: 'Gotham';
                    font-style: normal;
                    font-weight: 500;
                    src: local('Gotham Medium'), local('GothamMedium'), url('${hostname}/assets/fonts/gotham/Gotham-Medium.otf');
                }

                @font-face {
                    font-family: 'Gotham';
                    font-style: normal;
                    font-weight: 700;
                    src: local('Gotham Bold'), local('Gotham-Bold'), url('${hostname}/assets/fonts/gotham/Gotham-Bold.otf');
                }

                @font-face {
                    font-family: 'Gotham';
                    font-style: normal;
                    font-weight: 800;
                    src: local('Gotham Black'), local('Gotham-Black'), url('${hostname}/assets/fonts/gotham/Gotham-Black.otf');
                }
            </style>
        </head>
        <body style="line-height: 1;">
            <div class="content" style="width: 600px;margin: auto;color: #373D45;font-family: Arial, Helvetica, sans-serif;">
                <div id="header" style="background: #F2F8F9;padding: 40px 0px;text-align: center;">
                    <img alt="Logo" src="${hostname}/village-medical-email-logo.png" style="max-height: 49px;">
                </div>
                <div class="wrapper" style="padding: 42px 48px;">
                    <h2 class="title" style="font-weight: 500;font-size: 24px;line-height: 31px;margin: 15px 0px;">
                        Your Video Visit with ${props.waitingRoomName}
                    </h2>
                    <p class="text" style="font-size: 16px;line-height: 26px;margin: 0px;">
                        ${props.text}
                    </p>
                    <div class="button-container" style="padding: 20px 0px;">
                        <a class="button" href="${props.inviteLink}" style="background: #0080A3;border-radius: 8px;font-size: 18px;line-height: 24px;padding: 13px;display: block;text-align: center;text-indent: 24px;color: #fff;text-decoration: none;">
                            <img class="ic-camera" src="${hostname}/assets/icons/24px/camera.png"> &nbsp;Join Video Visit
                        </a>
                    </div>
                    <div class="appointment" style="padding: 20px 0px;">
                        <h3 class="appointment-title" style="font-weight: 500;font-size: 20px;line-height: 26px;margin-bottom: 20px;">Appointment Details</h3>
                        <table style="width: 100%;">
                            <tr class="appointment-details-item" style="font-weight: 700;font-size: 16px;line-height: 21px;">
                                <td class="ic-container" style="padding-top: 21px;vertical-align: top;width: 52px;">
                                    <img class="ic ic-home-care" src="${hostname}/assets/icons/24px/home-care.png">
                                </td>
                                <td class="details-container" style="padding: 16px 0px;border-bottom: 1px solid #E2E4E8;">
                                    <p style="margin: 3px 0px;"> Online</p>
                                    <a class="link" href="${props.inviteLink}" style="font-weight: normal;font-size: 17px;line-height: 22px;text-decoration-line: underline;color: #00BBDC;">
                                        ${props.inviteLink}
                                    </a>
                                </td>
                            </tr>
                            <tr class="appointment-details-item" style="font-weight: 700;font-size: 16px;line-height: 21px;">
                                <td class="ic-container" style="padding-top: 21px;vertical-align: top;width: 52px;">
                                    <img class="ic ic-user" src="${hostname}/assets/icons/24px/user.png">
                                </td>
                                <td class="details-container" style="padding: 16px 0px;border-bottom: 1px solid #E2E4E8;">
                                <p style="margin: 3px 0px;">${props.waitingRoomName}</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="disclaimer" style="font-size: 14px;line-height: 18px;">
                        <span class="bold" style="font-weight: 700;">IMPORTANT</span>: This is an automated message from Village Medical. Please do not reply. For any questions please contact your provider.
                    </div>
                </div>
            </div>
        </body>
    </html>
    `);
}

export default InviteEmailLayoutTemplate;