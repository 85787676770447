import styled from 'styled-components/macro';
import { Text, Card as VuiCard } from '@village/ui';

export const Card = styled(VuiCard)`
    padding: 24px;
`;
export const Figure= styled.figure`
    display: block;
    margin: auto;
    width: 96px;
    height: 96px;
`;
export const TextCenter = styled.div`
    text-align: center;
`;
export const BodyText = styled(Text)`
    color: ${({ theme }) => theme.vui.colors.text.grayMuted}
`;