import React from 'react';
import { OTSubscriber } from 'opentok-react';
import VideoContainer from './VideoContainer';
import EventManager, { IEventManager } from 'utils/EventManager';
import { calculateCoverModeLoss } from 'utils/Basics';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import { TRYING_TO_CONNECT } from 'utils/Network/constants';
import { NetworkQualityState } from 'utils/Network/types';
import {
    OpentokError,
    OpentokEventHandlers,
    OpentokSession,
    OpentokStream,
    OpentokSubscriber
} from '../opentok/types';
import { VideoElementCreatedEvent, StreamPropertyChangedEvent } from '../opentok/events';
const platform = require('platform');

type Props = {
    onChangeAudio?: (hasAudio: boolean) => void,
    onChangeVideo?: (hasVideo: boolean) => void,
    onSubscriberReady?: (subscriber: OpentokSubscriber, videoElement: HTMLVideoElement) => void,
    onSubscriberError: (error: any) => void,
    stream: OpentokStream,
    session: OpentokSession,
    speaker?: string,
    networkQuality: NetworkQualityState,
    className?: string,
    properties: any,
}
type State = {
    error: any,
    audio: boolean,
    video: boolean,
    videoElement?: HTMLVideoElement,
    forceFitMode: string,
}
class SubscriberVideoContainer extends React.Component<Props, State> {

    otSubscriber: OpentokSubscriber
    subscriber: OpentokSubscriber
    opentokSubscriberEvents: string[]
    eventManager: IEventManager
    eventHandlers: OpentokEventHandlers

    constructor(props: Props) {
        super(props);
        this.state = {
            error: null,
            audio: true,
            video: true,
            forceFitMode: "",
        };
        this.subscriber = null;
        this.opentokSubscriberEvents = [
            'audioBlocked',
            'audioLevelUpdated', // Audio level is changed very-very fast, better don not countly it
            'audioUnblocked',
            'connected',
            'destroyed',
            'disconnected',
            'videoDimensionsChanged',
            'videoDisabled',
            'videoDisableWarning',
            'videoDisableWarningLifted',
            'videoEnabled',
            'videoElementCreated',
        ];
        this.eventManager = new EventManager();
        this.eventHandlers = this.listenSubscriberEvents();
        this.eventManager.on('videoElementCreated', this.onVideoElementCreated);
        this.addCountlyEventToOpentokEvents();
        this.otSubscriber = React.createRef();
    }
    componentDidMount() {
        if (this.props.onChangeAudio) this.props.onChangeAudio(!this.props.stream.hasAudio)
        this.props.session.on('streamPropertyChanged', (event: StreamPropertyChangedEvent) => {
            if (event.stream.id === this.props.stream.id) {
                switch (event.changedProperty) {
                    case 'hasVideo':
                        if (this.props.onChangeVideo) {
                            this.props.onChangeVideo(!event.newValue)
                        }
                        this.setState({ video: event.newValue });
                        break;
                    case 'hasAudio':
                        if (this.props.onChangeAudio) {
                            this.props.onChangeAudio(!event.newValue)
                        }
                        this.setState({ audio: event.newValue });
                        break;
                    default:
                        break;
                }
            }
        });
        window.addEventListener('resize', this.setFitMode);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.setFitMode)
    }
    componentDidUpdate(prevProps: Props) {
        if (!this.subscriber) {
            this.getSubscriber();
        }
        if (prevProps.speaker !== this.props.speaker) {
            this.setSpeakerSource(this.props.speaker);
        }
        if (prevProps.networkQuality !== this.props.networkQuality) {
            this.setState({ video: (this.props.networkQuality >= TRYING_TO_CONNECT) });
        }
    }
    getSubscriber(): void {
        if (this.otSubscriber) {
            this.subscriber = this.otSubscriber.current.getSubscriber();
            if (this.subscriber) {
                this.subscriber.element.children[0].children[0].style.backgroundImage = 'unset';
            }
        }
    }
    listenSubscriberEvents = (): OpentokEventHandlers => {
        let eventHandlers: OpentokEventHandlers = {};
        this.opentokSubscriberEvents.forEach((eventName) => {
            eventHandlers[eventName] = this.eventManager.handle(eventName);
        });
        return eventHandlers;
    }
    addCountlyEventToOpentokEvents = (): void => {
        const skipCountlyOnEvent = ['audioLevelUpdated', 'videoDimensionsChanged'];
        this.opentokSubscriberEvents.forEach((subscriberEvent) => {
            if (skipCountlyOnEvent.indexOf(subscriberEvent) > -1) return;
            this.eventManager.on(subscriberEvent, (event: any) => {
                let eventData = null
                try {
                    eventData = JSON.stringify(event);
                } catch (error) {
                    eventData = null
                }
                countlyAddEvent(`opentokEventSubscriber/${subscriberEvent}`, { data: eventData })
            });
        });
    }
    handleIOSAutoplayIssue = (element: HTMLVideoElement) => {
        const platformInfo = platform.parse(window.navigator.userAgent);
        const OSname = platformInfo?.os?.family?.toLowerCase();
        if (OSname === "ios" && element) {
            element.autoplay = false;
            element.addEventListener('play', () => element.autoplay = true)
        }
    }
    onVideoElementCreated = (event: VideoElementCreatedEvent): void => {
        const element = event.element;
        this.handleIOSAutoplayIssue(element);
        this.setState({ videoElement: element }, this.setFitMode);
        if (this.props.speaker) {
            this.setSpeakerSource(this.props.speaker, element);
        }
        if (this.props.onSubscriberReady) this.props.onSubscriberReady(this.subscriber, element);
    }
    toggleAudio = (): void => {
        this.setState({
            audio: !this.state.audio
        });
    }
    onError = (error: OpentokError): void => {
        countlyAddEvent(countlyEvents.opentokError, {
            type: "subscriber",
            error_name: error.name,
            error_message: error.message,
        });
        this.props.onSubscriberError(error);
        this.setState({ error: `Failed to subscribe: ${error.message}` });
    }
    setSpeakerSource = async (deviceId?: string, videoElement?: HTMLVideoElement): Promise<void> => {
        videoElement = videoElement ? videoElement : this.state.videoElement;

        if (!deviceId || !videoElement) return;

        if ('setSinkId' in videoElement) {
            try {
                await videoElement.setSinkId(deviceId);
            } catch (err) {
                console.warn('Could not update speaker ', err);
            }
        }
    }
    setFitMode = (): void => {
        let videoElement = this.state.videoElement;
        if (!videoElement) return;

        let coverModeLoss = calculateCoverModeLoss({
            availableWidth: videoElement.clientWidth,
            availableHeight: videoElement.clientHeight,
            objectWidth: videoElement.videoWidth,
            objectHeight: videoElement.videoHeight,
        });

        /**
         * We compare what's the percentage of video square-space(pixels) that are lost when we use cover mode.
         * If that space lost is more than 20% of the video then we switch into fitMode=contain,
         * which keeps the video intanct to not lose any pixel-space
         */
        let coverModeLossLimit = 18;
        const fitMode = coverModeLoss < coverModeLossLimit ? 'cover' : 'contain';
        this.setState({ forceFitMode: ' force-fitMode-' + fitMode });
    }
    render() {
        return (
            <VideoContainer error={this.state.error} className={this.props.className} >
                <OTSubscriber
                    ref={this.otSubscriber}
                    session={this.props.session}
                    key={this.props.stream.id}
                    stream={this.props.stream}
                    eventHandlers={this.eventHandlers}
                    className={`video ${this.state.forceFitMode}`}
                    properties={{
                        ...this.props.properties,
                        showControls: false,
                        subscribeToAudio: this.state.audio,
                        subscribeToVideo: this.state.video
                    }}
                    onError={this.onError}
                />
            </VideoContainer>
        );
    }
}
export default SubscriberVideoContainer;
