import React from 'react';
import CallParticipantList from './CallParticipantList';
import TruncateText from 'shared/TruncateText';
import Tooltip from 'shared/Tooltip';
import { UserBase } from 'shared/types';
import * as Styled from './styles';
import VillageUIThemeProvider from 'apps/VillageUIThemeProvider';
import { Link } from '@village/ui';


type ParticipantListProps = {
    users: UserBase[]
}
const ParticipantList = ({ users }: ParticipantListProps) => {
    const usersList = users.map(user => (`${user.first_name} ${user.last_name}`));
    return (
        <Styled.ParticipantList type="caption1">
            {usersList.length === 1 ?
                <TruncateText text={`With ${usersList[0]}`} />
                : usersList.length > 1 ?
                    <>
                        With&nbsp;
                        <Tooltip
                            html={
                                <VillageUIThemeProvider>
                                    <CallParticipantList usersList={usersList} />
                                </VillageUIThemeProvider>
                            }
                            trigger="click"
                        >
                            <Link className="call-participants" href="#" title={`${usersList.length} call participants`}>
                                {usersList.length} call participants
                            </Link>
                        </Tooltip>
                    </>
                    : null}
        </Styled.ParticipantList>
    );
}
export default ParticipantList;