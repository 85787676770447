import React from 'react';
import './EndCall.scss';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import BoxedLayout from 'layouts/BoxedLayout';
import withPatientRoom from 'providers/PatientRoom/withPatientRoom';
import { isMobile } from 'utils/Basics';
import { WaitingRoom } from 'shared/types';
import DownloadApp from '../DownloadApp';


type Props = {
    waiting_room: WaitingRoom
}
class EndCall extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        countlyAddEvent(countlyEvents.pageEndCall);
    }
    handleRejoin = () => {
        //ATTENTION: Maybe countly sending request will fail because of the window reload
        countlyAddEvent(countlyEvents.clickRejoin);
        window.location.reload()
    }
    render() {
        
        const {waiting_room} = this.props;

        const downloadAppTitle = isMobile() ? null : "We’re on mobile too!";
        const downloadAppSubTitle = isMobile() ? 
            "Download the Village Medical app for your next visit!" 
                : "Download our App to stay connected with your care team, schedule appointment, and more.";

        return (
            <BoxedLayout>
                <div className="box d-flex flex-column">
                    <div className="d-flex flex-row align-items-center mt-2 flex-grow-1">
                        <div className="column">
                            <figure className="image container post-call-icon">
                                <img src="../../ic-how-are-you.png" alt="village medical end call" />
                            </figure>
                            <p className="has-text-centered has-text-weight-bold title is-4 mt-4">Thank you</p>
                            <p className="has-text-centered is-size-6 appointment-ended-messsage">
                                Your visit with {waiting_room.name} has finished. Please expect a phone call from the 
                                clinic to schedule a follow-up appointment. You can also book a follow-up appointment
                                in the Village Medical app or website portal.
                            </p>
                            <p className="has-text-centered has-text-weight-bold title is-6">Call disconnected? </p>
                            <div className="has-text-centered has-text-weight-bold title is-6 mb-5">
                                <a href="#rejoin" className="is-size-6" onClick={this.handleRejoin}>
                                    Rejoin the waiting room
                                </a>
                            </div>
                        </div>
                    </div>
                    <DownloadApp 
                        title={downloadAppTitle} 
                        subtitle={downloadAppSubTitle}
                        screenContext={"endCall"}
                    />
                </div>
            </BoxedLayout>
        );
    };
}
export default withPatientRoom(EndCall);
