import React from 'react';
import { isMobile } from 'utils/Basics';
import VideoBtn from '../controls/VideoBtn';
import AudioBtn from '../controls/AudioBtn';
import FlipCameraBtn from '../controls/FlipCameraBtn';
import withMessages from 'shared/Messages/withMessages';
import { IToast } from 'shared/Messages/types';
import { CallSessionContextState } from '../CallSession/CallSessionContext';
import YouAreMutedToast from '../Messages/YouAreMutedToast';
import * as Styled from './styles';

type CallControlsProps = {
    callSession: CallSessionContextState,
    cameraUnavailable: boolean,
    children: JSXElement | JSXElement[],
    dismissByTag: (tag: string) => void,
    showToast: (toast: IToast) => void,
}
const CallControls = ({
    callSession,
    cameraUnavailable,
    children,
    dismissByTag,
    showToast
}: CallControlsProps) => {

    const handleAudio = () => {
        if (callSession.audio) {
            handleMute();
        } else {
            handleUnmute();
        }
    }
    const handleMute = () => {
        dismissByTag('mic-muted');
        showToast({ message: <YouAreMutedToast muted={true} />, tag: 'mic-muted' });
        callSession.handleAudio();
    }
    const handleUnmute = () => {
        dismissByTag('mic-muted');
        showToast({ message: <YouAreMutedToast muted={false} />, tag: 'mic-muted' });
        callSession.handleAudio();
    }

    return (
        <Styled.CallControls>
            <Styled.CallControlBar id="control-bar" className={`enable-blur`}>
                <VideoBtn callSession={callSession} cameraUnavailable={cameraUnavailable} />
                <AudioBtn callSession={callSession} onClick={handleAudio} />
                {isMobile() && callSession.cameraDevices.length >= 2 &&
                    <FlipCameraBtn callSession={callSession} />
                }
                {children}
            </Styled.CallControlBar>
        </Styled.CallControls>
    );
}
export default withMessages(CallControls);