import React from 'react';
import LeaveBtn from '../controls/LeaveBtn';
import EndBtn from '../controls/EndBtn';
import Quality from '../controls/Quality';
import NetworkInfo from '../controls/NetworkInfo';
import SettingsBtn from '../controls/SettingsBtn';
import VideoPreview from '../VideoPreview';
import SettingsModal from '../Settings/SettingsModal';
import AudioLevelProvider from '../AudioLevel/AudioLevelProvider';
import Messages from 'shared/Messages/Messages';
import withMessages from 'shared/Messages/withMessages';
import Inactivity from 'shared/Inactivity/Inactivity';
import NetworkTest from 'utils/Network/NetworkTest';
import {
    AUDIO_VIDEO_SUPPORTED,
    VIDEO_QUALITY_AUDIO_ONLY,
    AUDIO_QUALITY_BANDWIDTH_TOO_LOW
} from 'utils/Network/constants';
import type { NetworkQualityState, NetworkStats } from 'utils/Network/types';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import Feature from 'shared/Feature';
import FF from 'shared/constants/FF';
import VISIT_COMPLETED_REASON from 'shared/constants/VISIT_COMPLETED_REASON';
import { UserType } from 'shared/types';
import { IToast } from 'shared/Messages/types';
import { CallSessionContextState } from '../CallSession/CallSessionContext';
import { OpentokPublisher } from '../opentok/types';
import YouAreMutedToast from '../Messages/YouAreMutedToast';
import ProviderUnstableConToast from '../Messages/ProviderUnstableConToast';
import * as Styled from './styles';


type Position = {
    videoPreview: "up" | "down",
}
type VideoProperties = {
    videoSource?: any,
    videoFilter?: any,
}
type CallContainerProps = {
    userType: UserType,
    callSession: CallSessionContextState,
    activeHideControls: boolean,
    publisherName: string,
    containerClassName: string,
    cameraUnavailable: boolean,
    dismissByTag: (tag: string) => void,
    showToast: (toast: IToast) => void,
    onLeave: () => void,
    children: JSXElement,
}
type CallContainerState = {
    isOpenSettings: boolean,
    position: Position,
    hideControls: boolean,
}

class CallContainer extends React.Component<CallContainerProps, CallContainerState> {

    static defaultProps = {
        activeHideControls: false,
        containerClassName: "",
    }

    networkTestInstance?: NetworkTest
    controlBarSizeObserver?: ResizeObserver;
    constructor(props: CallContainerProps) {
        super(props);
        this.state = {
            isOpenSettings: false,
            position: {
                videoPreview: "up",
            },
            hideControls: false,
        };
    }
    componentDidMount() {
        const InactivityControls = new Inactivity([{
            name: "fade_controls",
            time: 3,
            exec: () => {
                if(this.props.activeHideControls) {
                    this.setState({
                        hideControls: true
                    });
                }
            },
        }]);

        InactivityControls.onReset = () => {
            if(this.props.activeHideControls) {
                this.setState({
                    hideControls: false
                });
            }
        }

        this.controlBarSizeObserver = new ResizeObserver(() => {
            this.alignVideoPreview();
        });

        const controlBarEl = document.querySelector('#control-bar')
        if (controlBarEl) this.controlBarSizeObserver.observe(controlBarEl)

        this.alignVideoPreview();
        window.addEventListener('resize', this.alignVideoPreview);
    }
    componentWillUnmount() {
        this.props.dismissByTag('mic-muted');
        window.removeEventListener("resize", this.alignVideoPreview)
        if (this.networkTestInstance) this.networkTestInstance.stop();
        if (this.controlBarSizeObserver) this.controlBarSizeObserver.disconnect();
    }
    handlePublisherReady = (publisher: OpentokPublisher) => {
        if (this.props.userType === "provider") {
            countlyAddEvent(countlyEvents.providerEnteredWaitingRoom);
        } else {
            countlyAddEvent(countlyEvents.patientEnteredWaitingRoom);
        }

        const { callSession } = this.props;
        callSession.onPublisherReady(publisher);

        // Test network
        if (Feature.has(FF.unstableConnectionTest)) {
            this.networkTestInstance = new NetworkTest(publisher, 'publisher');
            this.networkTestInstance.run((networkStats: NetworkStats) => {
                callSession.setNetworkStats(networkStats);
                this.handleNetworkStats(networkStats.quality.state);
            });
            this.networkTestInstance.runCheckOnlineStatus((networkQuality) => this.handleNetworkStats(networkQuality));
        }
    }
    alignVideoPreview = () => {
        const controls = document.querySelector('#control-bar') || { clientWidth: 0 };
        const videoPreview = document.querySelector('.mini-video') || { clientWidth: 0 };
        const pageWidth = window.innerWidth || document.body.clientWidth;
        const sideSpace = (pageWidth - controls.clientWidth) / 2;

        let position: Position = {
            videoPreview: "up",
        }
        if (videoPreview.clientWidth < sideSpace) {
            position.videoPreview = "down";
        } else {
            position.videoPreview = "up";
        }
        this.setState({ position: position });
    }
    handleMute = () => {
        const { callSession } = this.props;
        this.props.dismissByTag('mic-muted');
        this.props.showToast({ message: <YouAreMutedToast muted={true} />, tag: 'mic-muted' });
        if (callSession.audio) callSession.handleAudio();
    }
    handleUnmute = () => {
        const { callSession } = this.props;
        this.props.dismissByTag('mic-muted');
        this.props.showToast({ message: <YouAreMutedToast muted={false} />, tag: 'mic-muted' });
        if (!callSession.audio) callSession.handleAudio();
    }
    handleEnableVideo = () => {
        const { callSession } = this.props;
        if (!callSession.video) callSession.handleVideo();
    }
    handleDisableVideo = () => {
        const { callSession } = this.props;
        if (callSession.video) callSession.handleVideo();
    }
    toggleSettings = () => {
        this.setState({ isOpenSettings: !this.state.isOpenSettings });
    }
    shouldHideControls = () => {
        const { callSession } = this.props;
        return this.props.activeHideControls && this.state.hideControls && callSession.audio && callSession.video;
    }
    handleNetworkStats = (currentNetworkQuality: NetworkQualityState) => {

        const callSession = this.props.callSession;
        if (currentNetworkQuality === callSession.networkQuality) return;
        callSession.setNetworkQuality(currentNetworkQuality);

        // Skip if subscriber connection is not resumed
        this.props.dismissByTag('bad-network');
        if (callSession.subscriberNetworkQuality < AUDIO_QUALITY_BANDWIDTH_TOO_LOW) return;

        switch (currentNetworkQuality) {
            case VIDEO_QUALITY_AUDIO_ONLY:
            case AUDIO_QUALITY_BANDWIDTH_TOO_LOW:
                if (this.props.userType === 'provider') {
                    this.props.showToast({
                        message: (<ProviderUnstableConToast />),
                        tag: 'bad-network',
                        noTimeout: true,
                        position: "top center",
                    });
                }
                countlyAddEvent(countlyEvents.networkBad);
                break;

            case AUDIO_VIDEO_SUPPORTED:
                countlyAddEvent(countlyEvents.networkGood);
                this.props.dismissByTag('bad-network');
                break;

            default:
                this.props.dismissByTag('bad-network');
                break;
        }
    }
    shouldPublishVideo = () => {
        const { callSession, cameraUnavailable } = this.props;
        return callSession.video && !cameraUnavailable; // && callSession.networkQuality >= VIDEO_QUALITY_AUDIO_ONLY;
    }
    handleLeave = () => {
        const reason = this.props.userType === "patient" ?
            VISIT_COMPLETED_REASON.endedByPatient
            : VISIT_COMPLETED_REASON.endedByProvider;
        countlyAddEvent(countlyEvents.visitCompleted, { reason: reason })
        this.props.onLeave();
    }
    render() {

        const { callSession, containerClassName, cameraUnavailable } = this.props;
        const { position } = this.state;
        const videoProperties: VideoProperties = cameraUnavailable ? { videoSource: null } : {};
        if(window.localStorage.getItem("background_blur") === "true") {
            videoProperties.videoFilter = {
                type: 'backgroundBlur'
            };
        }
        
        return (
            <Styled.CallContainer className={containerClassName}>
                <link rel="prefetch" href="/ic-band-aid.svg" />
                <Messages />
                <Styled.ControlsContainer id="controls" className={this.shouldHideControls() ? 'fade-out' : 'fade-in'} >
                    <AudioLevelProvider>

                        {this.props.children && this.props.children}

                        <SettingsBtn onClick={this.toggleSettings} />

                        <SettingsModal
                            isOpen={this.state.isOpenSettings}
                            onClose={this.toggleSettings}
                            callSession={callSession}
                        />

                        {Feature.has(FF.videoQuality) &&
                            <Quality callSession={callSession} />}
                        
                        {Feature.has(FF.networkMetrics) &&
                        <NetworkInfo 
                            publisher={callSession.networkStats} 
                            subscriber={callSession.subscriberNetworkStats}
                        />}

                        {callSession.streams.length > 0 ?
                            <EndBtn onClick={this.handleLeave} />
                            : <LeaveBtn onClick={this.handleLeave} />}


                        <VideoPreview
                            className={position.videoPreview}
                            session={callSession.session}
                            audioSource={callSession.audioSource}
                            videoSource={cameraUnavailable ? null : callSession.videoSource}
                            videoQuality={callSession.videoQuality}
                            setMuted={this.handleMute}
                            setUnmuted={this.handleUnmute}
                            enableVideo={this.handleEnableVideo}
                            disableVideo={this.handleDisableVideo}
                            onPublisherReady={this.handlePublisherReady}
                            properties={{
                                name: this.props.publisherName,
                                publishAudio: callSession.audio,
                                publishVideo: this.shouldPublishVideo(),
                                facingMode: callSession.facingMode,
                                ...videoProperties,
                            }}
                        />

                    </AudioLevelProvider>
                </Styled.ControlsContainer>
            </Styled.CallContainer>
        );
    }
}
export default withMessages(CallContainer);