import React from 'react';
import '../EndCall/EndCall.scss';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import images from 'assets/images';
import BoxedLayout from 'layouts/BoxedLayout';

class KickedOut extends React.Component<{}, {}> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        countlyAddEvent(countlyEvents.pageKickedOut);
    }
    render() {
        return (
            <BoxedLayout>
                <div className="box d-flex flex-row align-items-center mt-2">
                    <div className="column">
                        <figure className="image container post-call-icon">
                            <img src={images.ic_provider} alt="village medical provider icon" />
                        </figure>
                        <p className="has-text-centered post-call-title">Oops! We're not ready for your appointment yet.</p>
                        <p className="post-call-subtitle">Please verify your appointment time. If you think this was a mistake, please rejoin using the link that was sent to you.</p>
                    </div>
                </div>
            </BoxedLayout>
        );
    };
}
export default KickedOut;
