import styled from 'styled-components';
import { Text } from '@village/ui';

export const HeaderText = styled(Text).attrs(()=>({type: 'caption1'})) `
    @media screen and (max-width: 767px) {
        display: none;
    }
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(min-content, 1fr)) min-content minmax(min-content, 1fr);
    grid-template-rows: auto;
    align-items: center;
    gap: 16px;
    @media screen and (max-width: 1023px) {
        grid-template-columns: repeat(3, minmax(min-content, 1fr)) min-content min-content;
    }
`;