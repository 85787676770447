import ConfigFactory from '../ConfigFactory';

class WaitingRoom {
    
    id: number
    name: string
    description: string
    slug: string

    constructor(id: number, name: string, description: string, slug: string) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.slug = slug;
    }
    
    get url() {
        if (this.slug) {
            return ConfigFactory.getPatientHost() + '/' + this.slug;
        }

        return "";
    }
}
export default WaitingRoom;