import { Text } from '@village/ui';
import styled from 'styled-components/macro';

export const TryToConnect = styled.div`
    text-align: center;
    background: rgba(47, 59, 77, 0.88);
    color: ${({ theme }) => theme.vui.colors.general.white};
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 14px;
    z-index: 3;
`;

export const TextCentered = styled.div`
    text-align: center;
`;

export const TryToConnectContainer = styled.div`
    flex: 1 1 auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 160px);
    margin: 48px 0 112px 0;
`;

export const LostConnectionTitle = styled(Text)`
    margin-bottom: 16px;

    @media only screen and (max-width: 767px) {
        margin-bottom: 12px;
    }
`;

export const LostConnectionText = styled(Text)`
    margin-bottom: 16px;

    @media only screen and (max-width: 767px) {
        margin-bottom: 12px;
    }
`;