import React from 'react';
import { WaitingRoom, Session } from 'shared/types';
import { IWebsocket } from 'utils/Websocket/types';

type RoomContextState = {
    waiting_rooms: WaitingRoom[],
    sessions: Session[],
    websocket: IWebsocket | null,
    cameraUnavailable: boolean,
    isUserReady: boolean,
    handleClinicianGoOffline: () => void,
    handleUpdatePatientStatus: () => void,
    setProviderStatus: (nextStatus: string) => void,
}
export type { RoomContextState }

const RoomContext = React.createContext<RoomContextState | {}>({});

export default RoomContext;