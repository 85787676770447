import React from 'react';
import UpdateChecker from 'layouts/partials/UpdateChecker';
import ConfigFactory from 'ConfigFactory';
import Avatar from 'shared/Avatar';
import Input from 'shared/inputs/Input';
import Checkbox from "shared/inputs/Checkbox";
import AVATAR_COLOR_STATUS from 'shared/constants/AVATAR_COLOR_STATUS';
import './CheckIn.scss';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import images from 'assets/images';
import BoxedLayout from 'layouts/BoxedLayout';
import withValidation from 'validations/withValidation';
import PatientFormRules from 'validations/FormRules/PatientFormRules';
import withPatientRoom from 'providers/PatientRoom/withPatientRoom';
import Messages from 'shared/Messages/Messages';
import { IValidation } from 'validations/types';
import { IWaitingRoom, PatientCheckIn, Provider, Validation } from 'shared/types';


type Props = IValidation & {
    loading: boolean,
    provider: Provider, 
    waiting_room: IWaitingRoom,
    handleCheckin: (patient: PatientCheckIn) => void,
}
type State = {
    [x: string]: any,
    first_name: string, 
    last_name: string, 
    patient_consent: boolean,
}
class CheckIn extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            patient_consent: false,
        };
    }
    componentDidMount() {
        countlyAddEvent(countlyEvents.pageCheckIn);
    }
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        this.setState({[name]: value});

        this.props.validateLive({...this.state, [name]: value });
    }
    handleCheckBoxChange = (name: string, checked: boolean) => {
        this.setState({
            [name]: checked
        }, () => this.props.validateLive(this.state));
    }
    handleSignup = (event: React.FormEvent) => {
        if (event) event.preventDefault();

        const { first_name, last_name, patient_consent } = this.state;
        if (patient_consent) countlyAddEvent(countlyEvents.checkCheckInConsent);
        countlyAddEvent(countlyEvents.clickCheckIn);
        
        if (!this.props.validate(this.state)) return;

        const stg = window.localStorage;
        if (stg) {
            try {
                stg.setItem('latestFirstName', first_name);
                stg.setItem('latestLastName', last_name);
                stg.setItem('latestCheckedInDate', new Date().toISOString());
            } catch {
                // Can occur on iOS private browsing
            }
        }
        this.props.handleCheckin({ first_name, last_name });
    }
    render() {
        const { provider, waiting_room } = this.props;
        const termsAndConditionLink = <span>I consent to the <a className="has-text-weight-medium" href="https://www.villagemedical.com/terms-and-conditions">Virtual Visit Terms</a></span>;
        const validations = this.props.validations === true ? {} : this.props.validations;

        return (
            <BoxedLayout>
                <div className="checkin box d-flex flex-row align-items-center">
                    <div className="column">
                        <UpdateChecker env={ConfigFactory.getEnv()} />
                        <Messages />
                        <div className="media-left my-4 is-hidden-tablet">
                            <figure>
                                <img src={images.logo_village_medical} width="155" height="28" alt="village medical logo" />
                            </figure>
                        </div>
                        <form id="patient-form" className="my-5" method='POST' onSubmit={this.handleSignup} >
                            <h4 className="has-text-centered gray-90 text-h5-to-h4-medium">Welcome!</h4>
                            <p className="has-text-centered gray-80 is-size-6 mb-5" >
                                Please check in below to let us know you are here
                            </p>

                            <div className="media my-5 clinician-card">
                                <div className="media-left">
                                    <Avatar
                                        first_name={provider.first_name}
                                        last_name={provider.last_name}
                                        avatarColor={provider.presence && AVATAR_COLOR_STATUS[provider.presence]}
                                        img={images.ic_provider}
                                    />
                                </div>
                                <img className="v-logo-overlap" src="../../../../ic-villagemd-v.svg" alt="village medical v icon" />
                                <div className="media-content is-pulled-left">
                                    <div className="column is-vcentered">
                                        <p className="subtitle is-7">Appointment with</p>
                                        <p className="title has-text-weight-bold is-6">{waiting_room?.name}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="my-4">
                                <div className="columns is-centered" >
                                    <div className="column" >
                                        <Input
                                            autoFocus={true}
                                            label="First name"
                                            classNameLabel="gray-80 ios-subtitle-15-semibold"
                                            name='first_name'
                                            placeholder='Enter your first name'
                                            value={this.state.first_name}
                                            className="gray-40 ios-body-1-regular"
                                            onChange={this.handleChange}
                                            validation={validations?.first_name as Validation}
                                        />
                                        <Input
                                            label="Last name"
                                            classNameLabel="gray-80 ios-subtitle-15-semibold"
                                            name='last_name'
                                            placeholder='Enter your last name'
                                            value={this.state.last_name}
                                            className="gray-40 ios-body-1-regular"
                                            onChange={this.handleChange}
                                            validation={validations?.last_name as Validation}
                                        />
                                        <div className="level-item is-pulled-left my-4">
                                            <Checkbox
                                                name="patient_consent"
                                                checked={this.state.patient_consent}
                                                value={this.state.patient_consent.toString()}
                                                onChange={this.handleCheckBoxChange}
                                                validation={validations?.patient_consent as Validation}
                                                label={termsAndConditionLink}
                                                className="gray-60 web-body-2-book"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="has-text-centered">
                                <button className={`button is-primary is-medium is-fullwidth ${this.props.loading ? 'is-loading' : ''}`} >
                                    Check in
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </BoxedLayout>
        )
    }
}
export default withPatientRoom(withValidation(CheckIn, PatientFormRules));
