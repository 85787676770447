import { Text } from '@village/ui';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { rem, rgba } from 'polished';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { ModalTheme } from './modal-theme';
import { ModalProps } from './types';


export const Modal = styled(MuiDialog)<ModalProps>`
    &.MuiDialog-root .MuiPaper-root {
        border-radius: ${rem(8)};
        background-color: ${ModalTheme.color.background};
        ${({ width }) => width && `width: ${rem(ModalTheme.width[width])}; `}
        ${({ height }) => height && height !=='auto' && `height: ${rem(ModalTheme.height[height])}; `}

        &.MuiDialog-paperFullScreen {
            width: 100%;
            border-radius: 0;
        }
    }
    &.MuiDialog-root .MuiPaper-elevation24 {
        box-shadow: 0px 2px 4px ${rgba(ModalTheme.color.shadow, 0.2)};
    }
`;

export const ModalHeader = styled(MuiDialogTitle)`
    &.MuiDialogTitle-root {
        padding: ${rem(20)};
        display: flex;
        position: relative;
    }
`;
export const ModalIcon = styled.div`
    margin-right: ${rem(10)};
    font-size: 0;
`;
export const ModalHeaderContent = styled.div`
    flex-grow: 1;
`;
export const ModalTitle = styled(Text)`
    color: ${ModalTheme.color.title};
`;
export const ModalSubtitle = styled(Text)`
    color: ${ModalTheme.color.subtitle};
`;
export const ModalClose = styled(IconButton)`
    color: ${ModalTheme.color.close};
    position: absolute;
    right: ${rem(10)};
    top: ${rem(10)};
    border-radius: 4px;

    &:active {
        background-color: ${ModalTheme.color.closeHover};
    }
    
    &:active {
        background-color: ${ModalTheme.color.closePressed};
    }
`;
export const ModalContent = styled(MuiDialogContent)`
    &.MuiDialogContent-root {
        padding: ${rem(20)};
        border-color: ${ModalTheme.color.border};
    }
`;
export const ModalFooter = styled(MuiDialogActions)`
    &.MuiDialogActions-root {
        padding: ${rem(20)};
        gap: ${rem(12)};
    }
    &.MuiDialogActions-spacing > :not(:first-child) {
        margin-left: 0;
    }
`;