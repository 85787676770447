import React, { ReactNode } from 'react';
import { BaseInput, InputContainer, Label, HelperText } from 'village-ui/Input/styles';
import * as Styled from './styles';


export type SelectOption = {
    value: string | number,
    label: string,
    [key: string]: any
}
export interface SelectProps {
    readonly id?: string,
    readonly name: string,
    readonly value?: React.SelectHTMLAttributes<HTMLSelectElement>['value'] | null,
    readonly options: SelectOption[],
    readonly disabled?: boolean,
    readonly readOnly?: boolean,
    readonly error?: ReactNode,
    readonly helperText?: ReactNode;
    readonly label?: string,
    readonly placeholder?: string,
    readonly className?: string,
    readonly inputClassName?: string,
    readonly fullWidth?: boolean
    readonly showEmptyOption?: boolean,
    readonly onChange?: React.ChangeEventHandler<HTMLSelectElement>,
}
export const Select = ({
    error,
    helperText,
    fullWidth,
    id,
    name,
    value,
    label,
    options,
    onChange,
    placeholder,
    className,
    inputClassName,
    disabled,
    readOnly,
    ...props
}: SelectProps) => {

    const hasError = Boolean(error);
    const status = error ? 'error' : disabled || readOnly ? 'disabled' : 'default'

    return (
        <InputContainer className={className}>
            {label ? <Label type="caption1" htmlFor={name}>{label}</Label> : null}
            <Styled.NativeSelect
                id={id}
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
                readOnly={readOnly}
                placeholder={placeholder}
                input={
                    <BaseInput
                        $status={status}
                        error={hasError}
                        fullWidth={fullWidth}
                        className={inputClassName}
                    />
                }
                IconComponent={() => <Styled.ChevronDownIcon />}
            >
                {(props.showEmptyOption || placeholder) &&
                    <option value="">{placeholder ? placeholder : "-Select-"}</option>}
                {options.map(({ value, label }) =>
                    <option key={value} value={value}>{label}</option>
                )}
            </Styled.NativeSelect>
            {helperText || hasError ? (
                <HelperText type="caption1" $error={hasError}>{error ?? helperText}</HelperText>
            ) : null}
        </InputContainer>
    );
}