import { rem } from 'polished';
import styled from 'styled-components';
import { Text } from '@village/ui';
import { ModalHeader as VuiModalHeader } from 'village-ui/Modal';

export const ModalHeader = styled(VuiModalHeader)`
    &.MuiDialogTitle-root {
        padding: ${rem(16)} ${rem(20)};
    }
`;

export const ModalTitle = styled(Text)`
    display: flex;
    justify-content: center;
`;

export const TestVideoContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: ${rem(24)};
`;