import React from 'react';
import ControlBtn from './ControlBtn';
import countlyEvents from '../../../countly/events';
import { Icon } from 'icon';

type SettingsBtnProps = {
    onClick: () => void
}
const SettingsBtn = ({ onClick }: SettingsBtnProps) => {
    return (
        <ControlBtn
            sm
            variant='dark'
            id="btn-settings"
            onClick={onClick}
            eventCountly={countlyEvents.clickSettingsGear}
        >
            <Icon name="settings" size={1.5} />
        </ControlBtn>
    );
}
export default SettingsBtn;