import React, { ReactNode } from 'react';
import * as Styled from './styles';
import { Icon } from 'icon';

type VideoContainerProps = {
    id?: string,
    className?: string, 
    error: any, 
    children: ReactNode,
    style?: any, 
}
const VideoContainer = ({id, className, error, children, style}: VideoContainerProps) => {
    return (
        <Styled.VideoContainer id={id} className={className} style={style} >
            {error &&  
                <Styled.CameraDenied>
                    <div className="icon-container" >
                        <Icon name="videocam_off_red" size={3} />
                    </div>
                    <Styled.CameraDeniedText>Can't access camera</Styled.CameraDeniedText>
                </Styled.CameraDenied>
            }
            {children}
        </Styled.VideoContainer>
    );
}
export default VideoContainer;