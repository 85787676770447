import { OpentokMediaSource } from 'pages/CallScreen/opentok/types';
import { createContext } from 'react';
import { CallSessionState } from '../../pages/CallScreen/CallSession/CallSession';
import { VisitSession } from '../../pages/CallScreen/CallSession/types';
import { IProvider, IWaitingRoom, PatientCheckIn } from '../../shared/types';

type UpdatedStream = {
    has_audio: boolean
    has_video: boolean
}
type PatientRoomContextState = {
    // ...this.state.patient_room,
    provider?: IProvider,
    waiting_room?: IWaitingRoom

    loading: boolean
    isOnHold: boolean
    
    visitSession?: VisitSession,
    session_id?: number

    audio_only_call: boolean
    first_name?: string
    last_name?: string
    initialAudioSource?: OpentokMediaSource,
    initialVideoSource?: OpentokMediaSource,

    
    //methods
    setInitialAudioSource: (audioSource: OpentokMediaSource) => void,
    setInitialVideoSource: (videoSource: OpentokMediaSource) => void,
    handlePublisherDestroyed: () => void,
    handleLeave: () => void,
    handleKickedOut: () => void,
    handleCheckin: (patient: PatientCheckIn) => void,
    handleStreamStateUpdated: (updatedStream: UpdatedStream) => void,
    handlePatientEnterQueue: () => void,
    handleNoParticipants: (callSessionState: CallSessionState) => void,
}
export type { PatientRoomContextState }

const PatientRoomContext = createContext<PatientRoomContextState>({
    loading: false,
    isOnHold: false,
    
    session_id: 0,

    audio_only_call: false,
    first_name: "",
    last_name: "",
    initialAudioSource: null,
    initialVideoSource: null,
    
    //methods
    setInitialAudioSource: () => {},
    setInitialVideoSource: () => {},
    handlePublisherDestroyed: () => {},
    handleLeave: () => {},
    handleKickedOut: () => {},
    handleCheckin: () => {},
    handleStreamStateUpdated: () => {},
    handlePatientEnterQueue: () => {},
    handleNoParticipants: () => {},
});

export default PatientRoomContext;
