import { rem } from 'polished';
import styled from 'styled-components/macro';


export const Pill = styled.div`
    display: inline-block;
    border: none;
    padding: ${rem(5)} ${rem(12)};
    text-align: center;
    border-radius: ${rem(16)};
    margin-right: ${rem(10)};
    white-space: nowrap;

    &.gray {
        background-color: #f0f2f3;
        color: #abb1b9;
    }

    &.green {
        background-color: #D9ECF1;
        color: #0080A3;
    }

    &.purple {
        background-color: #f0eef8;
        color: #4e38a6;
    }

    &.neutral {
        background-color: #E5F1F4;
        color: #455C7C;
    }

    &.orange {
        background-color: #FFF7F0;
        color: #CF7A3B;
    }

    &.red {
        background-color: #FCE6E8;
        color: #CB002C;
    }

    &.in-call {
        background-color: #F2F8F9;
        color: #17335B;
    }
`;