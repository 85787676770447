import React from 'react';
import Loading from 'shared/Loading';
import { TRYING_TO_CONNECT } from 'utils/Network/constants';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import { UserType } from 'shared/types';
import { Text } from '@village/ui';
import * as Styled from './styles';

type CountdownProps = { startValue: number }
type CountdownState = { counter: number }

class Countdown extends React.Component<CountdownProps, CountdownState> {

    intervalJob?: ReturnType<typeof setInterval>;

    constructor(props: CountdownProps) {
        super(props);
        this.state = { counter: props.startValue }
    }
    componentDidMount() {
        this.intervalJob = setInterval(this.run, 1000);
    }
    run = (): void => {
        let nextValue = this.state.counter - 1;
        if (nextValue < 0 && this.intervalJob) clearInterval(this.intervalJob);
        nextValue = nextValue > 0 ? nextValue : 0;
        this.setState({
            counter: nextValue
        });
    }
    render() {
        return (<Text type='h4'><b>{this.state.counter}</b> sec</Text>);
    }
}


enum NetworkStatus { // TODO moving into CONSTANTS?
    me_disconnected = "me_disconnected",
    patient_disconnected = "patient_disconnected",
    provider_disconnected = "provider_disconnected",
    all_connected = "all_connected",
}
type CallNetworkQuality = {
    networkQuality: number,
    subscriberNetworkQuality: number
}
type TryToConnectProps = {
    callSession: CallNetworkQuality,
    userType: UserType,
}
class TryToConnect extends React.Component<TryToConnectProps, {}> {
    constructor(props: TryToConnectProps) {
        super(props);
        this.state = {};
    }
    componentDidUpdate(prevProps: TryToConnectProps) {
        let callSession = this.props.callSession;
        let prevCallSession = prevProps.callSession;

        // Send countly events:
        const status = this.getConnectionStatus(callSession, this.props.userType);
        const prevStatus = this.getConnectionStatus(prevCallSession, this.props.userType);
        this.logCountlyNetworkIssues(status, prevStatus);

    }
    logCountlyNetworkIssues = (status: NetworkStatus, prevStatus: NetworkStatus): void => {

        if (prevStatus !== status) {
            if (status === NetworkStatus.me_disconnected) {
                countlyAddEvent(countlyEvents.networkDisconnected);
            } else if (
                status === NetworkStatus.patient_disconnected
                || status === NetworkStatus.provider_disconnected
            ) {
                countlyAddEvent(countlyEvents.networkDisconnectedParticipant);
            } else if (
                prevStatus === NetworkStatus.me_disconnected
                && status === NetworkStatus.all_connected
            ) {
                countlyAddEvent(countlyEvents.networkReconnected);
            } else {
                countlyAddEvent(countlyEvents.networkReconnectedParticipant);
            }
        }
    }
    getConnectionStatus = (callSession: CallNetworkQuality, userType: UserType): NetworkStatus => {
        let status = NetworkStatus.all_connected;
        if (this.areYouDisconnected(callSession)) status = NetworkStatus.me_disconnected;
        else if (this.isPatientDisconnected(callSession, userType)) status = NetworkStatus.patient_disconnected;
        else if (this.isProviderDisconnected(callSession, userType)) status = NetworkStatus.provider_disconnected;

        return status;
    }
    areYouDisconnected = (callSession: CallNetworkQuality): boolean => {
        return callSession.networkQuality === TRYING_TO_CONNECT;
    }
    isPatientDisconnected = (callSession: CallNetworkQuality, userType: UserType): boolean => {
        return callSession.subscriberNetworkQuality === TRYING_TO_CONNECT && userType === 'provider';
    }
    isProviderDisconnected = (callSession: CallNetworkQuality, userType: UserType): boolean => {
        return callSession.subscriberNetworkQuality === TRYING_TO_CONNECT && userType === 'patient';
    }
    render() {

        const { callSession, userType } = this.props;
        const CONNECTION_STATUS = this.getConnectionStatus(callSession, userType);

        return (
            CONNECTION_STATUS === "me_disconnected" ?
                <Styled.TryToConnect>
                    <Styled.TryToConnectContainer>
                        <Styled.TextCentered>
                            <Loading type="mui-spinner" color="#FFFFFF" />
                            <Styled.LostConnectionTitle type='h3'>
                                Hold on
                            </Styled.LostConnectionTitle>
                            <Styled.LostConnectionText type="sub3">
                                Connection lost. We are trying to get you back into the visit.
                            </Styled.LostConnectionText>
                            {userType === 'provider' &&
                                <Countdown startValue={60} />
                            }
                        </Styled.TextCentered>
                    </Styled.TryToConnectContainer>
                </Styled.TryToConnect>
                : CONNECTION_STATUS === "patient_disconnected" ?
                    <Styled.TryToConnect>
                        <Styled.TryToConnectContainer>
                            <Styled.TextCentered>
                                <Loading type="mui-spinner" color="#FFFFFF" />
                                <Styled.LostConnectionText type="sub3">
                                    Patient lost connection. Attempting to reconnect them.
                                </Styled.LostConnectionText>
                                <Countdown startValue={60} />
                            </Styled.TextCentered>
                        </Styled.TryToConnectContainer>
                    </Styled.TryToConnect>
                    : CONNECTION_STATUS === "provider_disconnected" ?
                        <Styled.TryToConnect>
                            <Styled.TryToConnectContainer>
                                <Styled.TextCentered>
                                    <Loading type="mui-spinner" color="#FFFFFF" />
                                    <Styled.LostConnectionText type="sub3">
                                        Your provider lost connection. We’re attempting to reconnect them.
                                    </Styled.LostConnectionText>
                                </Styled.TextCentered>
                            </Styled.TryToConnectContainer>
                        </Styled.TryToConnect>
                        : null
        );
    }
}
export { Countdown };
export default TryToConnect;
