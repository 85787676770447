import React from 'react';
import ListClinics from './ListClinics';
import FormClinics from './FormClinics';
import MyRoute from 'core/router/MyRoute';
import HttpClient from 'utils/HttpClient';
import { debounce } from 'utils/Basics';
import { Clinic, IClinic } from 'shared/types';
import { Column } from 'shared/Flex';

type ClinicsPageProps = {}

type ClinicsPageState = {
    loading: boolean,
    page: number,
    perPage: number,
    search: string,
    clinics: Clinic[]
}

class ClinicsPage extends React.Component<ClinicsPageProps, ClinicsPageState> {

    noLoadMore?: boolean

    constructor(props: ClinicsPageProps) {
        super(props);
        this.state = {
            loading: true,
            page: 0,
            perPage: 30,
            search: "",
            clinics: []
        };
        this.handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        this.getClinics();
        document.getElementById('content')?.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        document.getElementById('content')?.removeEventListener('scroll', this.handleScroll);
    }
    getClinics(page = 0): void {

        const apiName = 'telehealthApi';
        const path = '/clinics';
        const queryParams = {
            skip: page * this.state.perPage,
            limit: this.state.perPage,
            search: this.state.search
        };

        this.setState({
            loading: true,
            clinics: page ? this.state.clinics : []
        });

        HttpClient().get(apiName, path, queryParams)
            .then((data) => {
                this.setState({
                    loading: false,
                    page: page,
                    clinics: [...this.state.clinics, ...data]
                });
                this.noLoadMore = false;
                if (data.length < this.state.perPage) {
                    this.noLoadMore = true;
                }
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            });
    }
    handleScroll = (e: Event): void => {
        debounce(() => {
            const element = e.target as Element
            if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                element.scrollTop--;
                if (!this.noLoadMore) {
                    this.getClinics(this.state.page + 1);
                }
            }
        }, 200);
    }
    handleSearch = (event: any): void => {
        this.setState({ search: event?.target?.value });
        debounce(() => this.getClinics(), 400);
    }
    newClinic = (clinic: Clinic): void => {
        this.setState({
            clinics: [clinic, ...this.state.clinics]
        });
    }
    updateClinic = (clinic_id: number, data: IClinic): void => {
        this.setState({
            clinics: this.state.clinics.map((item) => {
                return item.id === clinic_id ? { ...item, ...data } : item;
            })
        });
    }
    handleRemove = (clinic: Clinic) => (): void => {
        let isConfirmed = window.confirm("Are you sure you want to remove?");
        if (isConfirmed) {
            let apiName = 'telehealthApi';
            let path = '/clinics/' + clinic.id;
            this.setState({
                loading: true
            });
            HttpClient().delete(apiName, path)
                .then((data) => {
                    this.setState({
                        loading: false,
                        clinics: this.state.clinics.filter((item) => item.id !== clinic.id)
                    });
                }).catch((error) => {
                    this.setState({
                        loading: false
                    });
                });
        }
    }
    render() {

        return (
            <Column grow scrollable>
                <ListClinics
                    clinics={this.state.clinics}
                    loading={this.state.loading}
                    search={this.state.search}
                    onSearch={this.handleSearch}
                    onRemove={this.handleRemove}
                />
                <MyRoute
                    exact
                    path={"/clinics/new"}
                    title={"New clinic"}
                    mode="new"
                    onCreate={this.newClinic}
                    component={FormClinics}
                />
                <MyRoute
                    exact
                    path={"/clinics/edit/:id"}
                    title={"Edit clinic"}
                    mode="edit"
                    onUpdate={this.updateClinic}
                    component={FormClinics}
                />
            </Column>
        );
    }
}
export default ClinicsPage;