import { Config } from "../shared/types";

const config: Config = {
    "storage": sessionStorage,
    "aws_project_region": "us-east-1",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "telehealthApi",
            "endpoint": "https://thapi.uat.villagemd.com",
            "region": "us-east-1"
        },
        {
            "name": "teleHealthSocket",
            "endpoint": "wss://thmessaging.uat.villagemd.com/ws",
            "region": "us-east-1"
        }
    ],
    "patient_host": ["https://telehealth.uat.docos.me"],
    "provider_host": "https://telehealth.uat.docos.me",

    // For Onelogin Resources
    "onelogin": {
        HOST: "https://villagemd.onelogin.com",
        OIDC_CLIENT_ID: "0b5d34d0-d11a-0139-e0cb-0ae14b876f55100286",
        REDIRECT_URI: "https://telehealth.uat.docos.me/oidc/callback"
    },
    // For Azure Resources
    "azure": {
        TENANT_ID: "e2cbf95a-41ca-4a3c-8268-fbfe6502b1a6",
        HOST: "https://login.microsoftonline.com",
        OIDC_CLIENT_ID: "10606849-7a56-4093-b2a4-98c7ef70d454",
        REDIRECT_URI: "https://telehealth.uat.docos.me/azure-oidc/callback"
    },
    // For Opentok resources
    "opentok": {
        API_KEY: "47288944"
    },
    // Countly
    "countly": {
        app_key: "a6e25a6d8882aee14a83928181801f9b6e2d405e",
        url: "https://countly.docos.com/"
    },
    "sentry_dsn": "https://3fbaf9492b134272af9e295b9b98d590@o47892.ingest.sentry.io/5958949",
    "POLLING_INTERVAL_MS": -1,
    "GCP_CAPTCHA_SITE_KEY": "6LeZz0weAAAAAO9Cu9XW3ZKQZBdMNsbBTPN9Jx1E",
};

export default config;
