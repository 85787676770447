import React from 'react';
import SESSION_STATUS from 'shared/constants/SESSION_STATUS';
import { IUser, Session } from 'shared/types';
import PatientQueueElement from './PatientQueueElement';
import { Text } from '@village/ui';
import * as Styled from './styles';


type PatientQueueProps = {
    sessions: Session[],
    authUser: IUser,
    onUpdatePatientStatus: (
        sessionId: number,
        patientId: number,
        newPatientStatus: SESSION_STATUS,
        kickedOut: boolean
    ) => () => void,
}

const PatientQueue = ({ sessions, authUser, onUpdatePatientStatus }: PatientQueueProps) => {
    if (sessions.length === 0) {
        return <Text type="body1" className="has-text-centered">No patients in queue yet</Text>
    }
    return (
        <>
            <Styled.Grid>
                <Styled.HeaderText type="caption1">Patient's name</Styled.HeaderText>
                <Styled.HeaderText type="caption1">Status</Styled.HeaderText>
                <Styled.HeaderText type="caption1">Room</Styled.HeaderText>
                <div/>
                <div/>
                {sessions.map((session) =>
                    <PatientQueueElement
                        key={session.id}
                        me={authUser}
                        {...session}
                        onUpdatePatientStatus={onUpdatePatientStatus}
                    />
                )}
            </Styled.Grid>
        </>
    );
}
export default PatientQueue;