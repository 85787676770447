import styled from 'styled-components';

export const CallContainer = styled.div`
    background-color: #373d45;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column !important;
`;

export const ControlsContainer = styled.div`
    height: 100%;
    overflow-y: hidden;
    position: relative;

    #btn-settings {
        position: absolute;
        z-index: 4;
        top: 13px;
        left: 12px;
    }
    #btn-leave, #btn-end {
        position: absolute;
        z-index: 4;
        top: 13px;
        right: 12px;
    }

    .mini-video {
        position: absolute;
        z-index: 2;
        bottom: 128px;
        right: 12px;
    }

    .enable-blur > * {
        filter: blur(8px);
        -webkit-filter: blur(8px);
    }
    
    .disable-blur {
        filter: blur(0);
        -webkit-filter: blur(0);
    }

    &.fade-out {
        #control-bar {
          -webkit-animation: fade-out-controls 1s; /* Safari, Chrome and Opera > 12.1 */
          -moz-animation: fade-out-controls 1s; /* Firefox < 16 */
          -ms-animation: fade-out-controls 1s; /* Internet Explorer */
          -o-animation: fade-out-controls 1s; /* Opera < 12.1 */
          animation: fade-out-controls 1s;
          bottom: -132px;
          opacity: 0;
        }
    
        #btn-settings,
        #btn-leave {
          -webkit-animation: fade-out-top-controls 1s; /* Safari, Chrome and Opera > 12.1 */
          -moz-animation: fade-out-top-controls 1s; /* Firefox < 16 */
          -ms-animation: fade-out-top-controls 1s; /* Internet Explorer */
          -o-animation: fade-out-top-controls 1s; /* Opera < 12.1 */
          animation: fade-out-top-controls 1s;
          top: -40px;
          opacity: 0;
        }
    
        .call-with-shadow {
          display: none;
        }
    
        .mini-video.up,
        .video-name-tag.up {
          animation: move-down-controls 1s;
          bottom: 12px;
        }
      }
    
      &.fade-in {
        #control-bar {
          -webkit-animation: fade-in-controls 0.5s; /* Safari, Chrome and Opera > 12.1 */
          -moz-animation: fade-in-controls 0.5s; /* Firefox < 16 */
          -ms-animation: fade-in-controls 0.5s; /* Internet Explorer */
          -o-animation: fade-in-controls 0.5s; /* Opera < 12.1 */
          animation: fade-in-controls 0.5s;
          bottom: 0;
          opacity: 1;
        }
    
        #btn-settings,
        #btn-leave {
          -webkit-animation: fade-in-top-controls 0.5s; /* Safari, Chrome and Opera > 12.1 */
          -moz-animation: fade-in-top-controls 0.5s; /* Firefox < 16 */
          -ms-animation: fade-in-top-controls 0.5s; /* Internet Explorer */
          -o-animation: fade-in-top-controls 0.5s; /* Opera < 12.1 */
          animation: fade-in-top-controls 0.5s;
          top: 13px;
          opacity: 1;
        }
    
        .mini-video.up,
        .video-name-tag.up {
          animation: move-up-controls 1s;
          bottom: 128px;
        }
      }
    
      .mini-video.down,
      .video-name-tag.down {
        bottom: 12px;
      }
    }
    
    @keyframes fade-in-controls {
      from {
        bottom: -132px;
        opacity: 0;
      }
    
      to {
        bottom: 0;
        opacity: 1;
      }
    }
    
    @keyframes move-up-controls {
      from {
        bottom: 12px;
      }
    
      to {
        bottom: 128px;
      }
    }
    
    /* Firefox < 16 */
    @-moz-keyframes fade-in-controls {
      from {
        bottom: -132px;
        opacity: 0;
      }
    
      to {
        bottom: 0;
        opacity: 1;
      }
    }
    
    @-moz-keyframes move-up-controls {
      from {
        bottom: 12px;
      }
    
      to {
        bottom: 128px;
      }
    }
    
    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fade-in-controls {
      from {
        bottom: -132px;
        opacity: 0;
      }
    
      to {
        bottom: 0;
        opacity: 1;
      }
    }
    
    @-webkit-keyframes move-up-controls {
      from {
        bottom: 12px;
      }
    
      to {
        bottom: 128px;
      }
    }
    
    /* Internet Explorer */
    @-ms-keyframes fade-in-controls {
      from {
        bottom: -132px;
        opacity: 0;
      }
    
      to {
        bottom: 0;
        opacity: 1;
      }
    }
    
    @-ms-keyframes move-up-controls {
      from {
        bottom: 12px;
      }
    
      to {
        bottom: 128px;
      }
    }
    
    /* Opera < 12.1 */
    @-o-keyframes fade-in-controls {
      from {
        bottom: -132px;
        opacity: 0;
      }
    
      to {
        bottom: 0;
        opacity: 1;
      }
    }
    
    @-o-keyframes move-up-controls {
      from {
        bottom: 12px;
      }
    
      to {
        bottom: 128px;
      }
    }
    
    @keyframes fade-out-controls {
      from {
        bottom: 0;
        opacity: 1;
      }
    
      to {
        bottom: -132px;
        opacity: 0;
      }
    }
    
    @keyframes move-down-controls {
      from {
        bottom: 128px;
      }
    
      to {
        bottom: 12px;
      }
    }
    
    /* Firefox < 16 */
    @-moz-keyframes fade-out-controls {
      from {
        bottom: 0;
        opacity: 1;
      }
    
      to {
        bottom: -132px;
        opacity: 0;
      }
    }
    
    @-moz-keyframes move-down-controls {
      from {
        bottom: 128px;
      }
    
      to {
        bottom: 12px;
      }
    }
    
    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fade-out-controls {
      from {
        bottom: 0;
        opacity: 1;
      }
    
      to {
        bottom: -132px;
        opacity: 0;
      }
    }
    
    @-webkit-keyframes move-down-controls {
      from {
        bottom: 128px;
      }
    
      to {
        bottom: 12px;
      }
    }
    
    /* Internet Explorer */
    @-ms-keyframes fade-out-controls {
      from {
        bottom: 0;
        opacity: 1;
      }
    
      to {
        bottom: -132px;
        opacity: 0;
      }
    }
    
    @-ms-keyframes move-down-controls {
      from {
        bottom: 128px;
      }
    
      to {
        bottom: 12px;
      }
    }
    
    /* Opera < 12.1 */
    @-o-keyframes fade-out-controls {
      from {
        bottom: 0;
        opacity: 1;
      }
    
      to {
        bottom: -132px;
        opacity: 0;
      }
    }
    
    @-o-keyframes move-down-controls {
      from {
        bottom: 128px;
      }
    
      to {
        bottom: 12px;
      }
    }
    
    @keyframes fade-in-top-controls {
      from {
        top: -40px;
        opacity: 0;
      }
    
      to {
        top: 13px;
        opacity: 1;
      }
    }
    
    /* Firefox < 16 */
    @-moz-keyframes fade-in-top-controls {
      from {
        top: -40px;
        opacity: 0;
      }
    
      to {
        top: 13px;
        opacity: 1;
      }
    }
    
    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fade-in-top-controls {
      from {
        top: -40px;
        opacity: 0;
      }
    
      to {
        top: 13px;
        opacity: 1;
      }
    }
    
    /* Internet Explorer */
    @-ms-keyframes fade-in-top-controls {
      from {
        top: -40px;
        opacity: 0;
      }
    
      to {
        top: 13px;
        opacity: 1;
      }
    }
    
    /* Opera < 12.1 */
    @-o-keyframes fade-in-top-controls {
      from {
        top: -40px;
        opacity: 0;
      }
    
      to {
        top: 13px;
        opacity: 1;
      }
    }
    
    @keyframes fade-out-top-controls {
      from {
        top: 13px;
        opacity: 1;
      }
    
      to {
        top: -40px;
        opacity: 0;
      }
    }
    
    /* Firefox < 16 */
    @-moz-keyframes fade-out-top-controls {
      from {
        top: 13px;
        opacity: 1;
      }
    
      to {
        top: -40px;
        opacity: 0;
      }
    }
    
    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fade-out-top-controls {
      from {
        top: 13px;
        opacity: 1;
      }
    
      to {
        top: -40px;
        opacity: 0;
      }
    }
    
    /* Internet Explorer */
    @-ms-keyframes fade-out-top-controls {
      from {
        top: 13px;
        opacity: 1;
      }
    
      to {
        top: -40px;
        opacity: 0;
      }
    }
    
    /* Opera < 12.1 */
    @-o-keyframes fade-out-top-controls {
      from {
        top: 13px;
        opacity: 1;
      }
    
      to {
        top: -40px;
        opacity: 0;
      }
    }
    
`;