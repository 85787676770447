import React from 'react';
import ConfigFactory from 'ConfigFactory';
import withPatientRoom from 'providers/PatientRoom/withPatientRoom';
import CallSession from '../../CallScreen/CallSession/CallSession';
import PatientCallContainer from './PatientCallContainer';
import { PatientRoomContextState } from 'providers/PatientRoom/PatientRoomContext';

type Props = PatientRoomContextState & {}
const PatientCallSession = (props: Props) => {

    const { visitSession } = props;
    const opentok_api_key = ConfigFactory.getOpentokApiKey();

    return (
        visitSession ?
            <CallSession
                apiKey={opentok_api_key}
                externalSessionId={visitSession.external_session_id}
                token={visitSession.external_token}
                initialAudioSource={props.initialAudioSource}
                initialVideoSource={props.initialVideoSource}
                initialVideoState={!props.audio_only_call}
                onNoParticipants={props.handleNoParticipants}
                onStreamStateUpdated={props.handleStreamStateUpdated}
                onPublisherDestroyed={props.handlePublisherDestroyed}
            >
                <PatientCallContainer
                    isOnHold={props.isOnHold}
                    firstName={props.first_name}
                    lastName={props.last_name}
                    provider={props.provider}
                    waiting_room={props.waiting_room}
                    onLeave={props.handleLeave}
                />
            </CallSession>
        : null
    );
};
export default withPatientRoom(PatientCallSession);