import React, { ReactNode } from 'react';

type NoCameraMessageProps = {
    icon: ReactNode,
    dismissButton: ReactNode,
    message: string,
}
type NoCameraMessageState = {
    show: boolean
}
class NoCameraMessage extends React.Component<NoCameraMessageProps, NoCameraMessageState> {
    constructor(props: NoCameraMessageProps) {
        super(props);
        this.state = {
            show: false,
        };
    }
    toggle = () => {
        this.setState({ show: !this.state.show });
    }
    render() {
        return (
            <div>
                <div className="message-wrapper d-flex justify-content-end">
                    <div className="flex-fill d-flex justify-content-center align-items-center">
                        <div className="message-text-container">
                            <div className="d-flex justify-content-center align-items-center" >
                                {this.props.icon}
                                <div className="text has-text-centered">
                                    We can't access your camera.&nbsp;
                                    <span className="is-underlined" role="button" onClick={this.toggle} >
                                        {this.state.show ? "Hide reasons" : "Reasons why this can happen"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>{this.props.dismissButton}</div>
                </div>
                {this.state.show &&
                    <div className="message-wrapper darker" >
                        <div className="message-text-container">
                            <ol className="p-1" type="1">
                                <li>You have denied camera or microphone access</li>
                                <li>Another app (like Skype, Zoom, FaceTime or other) is already using your camera. Close the other app or stop the camera for the other app</li>
                            </ol>
                            <div className="message-child-box lighter">
                                <h6>Error message</h6>
                                <p className="is-family-code">
                                    {this.props.message}
                                </p>
                            </div>
                        </div>
                    </div>}
            </div>
        );
    }
}
export default NoCameraMessage;