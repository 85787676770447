import React from 'react';
import SubscriberVideoContainer from '../VideoContainer/SubscriberVideoContainer';
import VideoNameTag from '../VideoContainer/elements/VideoNameTag';
import VideoAvatar from '../VideoContainer/elements/VideoAvatar';
import { OpentokError, OpentokSession, OpentokStream, OpentokSubscriber } from '../opentok/types';
import MainScreen, { MainScreenShadow } from './MainScreen';
import html2canvas from 'html2canvas';
import { debounce } from 'utils/Basics';


type FocusedVideoProps = {
	speaker?: string,
	networkQuality: number,
	stream: OpentokStream,
	videoElement: HTMLVideoElement,
	session: OpentokSession,
	onSubscriberReady: (subscriber: OpentokSubscriber, videoElement: HTMLVideoElement) => void,
	onSubscriberError: (error: OpentokError) => void,
}
type FocusedVideoState = {
	hasVideo: boolean,
	hasAudio: boolean,
}
let canvasStream: MediaStream;
let originalSteamTrack: MediaStreamTrack;
class FocusedVideo extends React.Component<FocusedVideoProps, FocusedVideoState> {
	constructor(props: FocusedVideoProps){
		super(props);
		this.state = {
			hasVideo: props.stream.hasVideo,
			hasAudio: props.stream.hasAudio,
		};
	}
	componentDidMount(): void {
		this.props.session.on('streamPropertyChanged', this.handleStreamPropertyChanged);
	}
	handleStreamPropertyChanged = (event: any) => {
		if (event.stream.id === this.props.stream.id) {
			switch (event.changedProperty) {
				case 'hasVideo':
					this.setState({ hasVideo: event.newValue }, () => {
						if(document.pictureInPictureElement && this.props.videoElement) {
							debounce(() => {
								if(!this.state.hasVideo) {
									this.getAvatarStream();
								} else {
									this.setOriginalStream();
								}
							}, 300);
						}
					});
					break;
				case 'hasAudio':
					this.setState({hasAudio: event.newValue});
					break;
				default:
					break;
			}
		}
	}
	getAvatarStream = () => {
		const clonedVideoAvatar = document.querySelector("#video-avatar")?.cloneNode(true);
		if(!clonedVideoAvatar || !this.props.videoElement) return;

		// Create a container to define size of VideoAvatar for canvas
		const avatarContainer = document.createElement('div');
		avatarContainer.style.width = this.props.stream.videoDimensions.width + "px";
		avatarContainer.style.height = this.props.stream.videoDimensions.height + "px";
		avatarContainer.appendChild(clonedVideoAvatar);
		document.body.appendChild(avatarContainer);
		
		html2canvas(avatarContainer as HTMLElement).then((canvas: HTMLCanvasElement) => {
			canvasStream = canvas.captureStream();
			let videoCanvasTrack = canvasStream.getVideoTracks()[0];
			const videoStream: MediaStream = this.props.videoElement.srcObject as MediaStream;
			if(!videoStream) return;
			originalSteamTrack = videoStream.getVideoTracks()[0];
			if(!originalSteamTrack) return;

			videoStream.removeTrack(originalSteamTrack);
			videoStream.addTrack(videoCanvasTrack);
			document.body.removeChild(avatarContainer);
		});
	}
	setOriginalStream = () => {
		if(this.props.videoElement && originalSteamTrack){
			let videoStream: MediaStream = this.props.videoElement.srcObject as MediaStream;
			if(videoStream) {
				const videoStreamTrack = videoStream.getVideoTracks()[0];
				videoStream.removeTrack(videoStreamTrack);
			} else {
				videoStream = new MediaStream();
				this.props.videoElement.srcObject = videoStream;
			}
			videoStream.addTrack(originalSteamTrack);
		}
	}
	render(){
		return (
			<MainScreen id="main-screen">
				<MainScreenShadow className="call-with-shadow" />

				<SubscriberVideoContainer
					className={!this.state.hasVideo ? 'is-hidden' : ''}
					session={this.props.session}
					stream={this.props.stream}
					speaker={this.props.speaker}
					onSubscriberReady={this.props.onSubscriberReady}
					onSubscriberError={this.props.onSubscriberError}
					networkQuality={this.props.networkQuality}
					properties={{
						name: this.props.stream.name,
						fitMode: "contain", //cover, contain
						height: '100%',
						width: '100%',
					}}
				/>
			
				{!this.state.hasVideo && <VideoAvatar text={this.props.stream.name} />}
	
				<VideoNameTag 
					isMuted={!this.state.hasAudio} 
					name={this.props.stream.name} 
				/>
			</MainScreen>
		);
	}
}
export default FocusedVideo;
