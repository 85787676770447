import React from 'react';
import './FirstTimeCheckIn.scss';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import images from 'assets/images';
import BoxedLayout from 'layouts/BoxedLayout';

type Props = {
    first_name: string,
    handleCameraMicrophonePermissions: (event: React.MouseEvent) => void
}
class FirstTimeCheckIn extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        countlyAddEvent(countlyEvents.pageFirstTimeCheckIn);
    }
    handleCameraMicrophonePermissions = (event: React.MouseEvent) => {
        countlyAddEvent(countlyEvents.clickGiveAccessCameraMic);
        this.props.handleCameraMicrophonePermissions(event);
    }
    render() {
        const first_name = this.props.first_name;

        return (
            <BoxedLayout>
                <div className="box d-flex flex-row align-items-center first-time">
                    <div className="column">
                        <h4 className="has-text-centered gray-90 text-h5-to-h4-medium">
                            Hi, {first_name}
                        </h4>
                        <h4 className="has-text-centered gray-90 text-h5-to-h4-medium">
                            Let's set you up for your visit!
                        </h4>
                        <p className="has-text-centered gray-80 is-size-6">
                            To have a video visit, we need access to your camera and microphone
                        </p>
                        <figure className="my-5">
                            <img className="is-horizontal-center first-time-check-in-img" src={images.first_time_check_in} alt="village medical first time check-in" />
                        </figure>
                        <div className="has-text-centered">
                            <button className="give-av-permission button is-primary is-medium is-fullwidth" onClick={this.handleCameraMicrophonePermissions} >
                                Give access
                        </button>
                        </div>
                    </div>
                </div>
            </BoxedLayout>
        )
    }
}
export default FirstTimeCheckIn;
