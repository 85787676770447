import React from 'react';
import AppContext from './AppContext';


function consumeApp(WrappedComponent: ReactComponent) {

    class consumeApp extends React.Component<any, {}> {
        
        displayName: string = 'consumeApp';
        static WrappedComponent: ReactComponent;

        constructor(props: any) {
            super(props);
            this.state = {};
        }
        render() {

            return (
                <AppContext.Consumer>
                    {(context) =>
                        <WrappedComponent {...context} {...this.props} />
                    }
                </AppContext.Consumer>
            );
        }
    };
    consumeApp.WrappedComponent = WrappedComponent;
    return consumeApp;
}
export default consumeApp;