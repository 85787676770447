import { CountlyKey } from "./types/countly"

const countlyEvents: Record<string,CountlyKey> = {
    allowAudioVideo: "allowAudioVideo",
    audioDisabled: "audioDisabled",
    audioEnabled: "audioEnabled",
    backgroundBlur: "backgroundBlur",
    checkCheckInConsent: "checkCheckInConsent",
    clickWBRejoin: "clickWBRejoin",
    clickNotYou: "clickNotYou",
    clickCheckIn: "clickCheckIn",
    clickConfirmSMSInvite: "clickConfirmSMSInvite",
    clickFlipCameraButton: "clickFlipCameraButton",
    clickGiveAccessCameraMic: "clickGiveAccessCameraMic",
    clickKickPatient: "clickKickPatient",
    clickLeave: "clickLeave",
    clickLeaveCancel: "clickLeaveCancel",
    clickLeaveConfirm: "clickLeaveConfirm",
    clickMuteButton: "clickMuteButton",
    clickRejoin: "clickRejoin",
    clickScreenShareButton: "clickScreenShareButton",
    clickSettingsGear: "clickSettingsGear",
    clickPauseButton: "clickPauseButton",
    clickVideoButton: "clickVideoButton",
    clickYesUnmuteMe: "clickYesUnmuteMe",
    denyAudioVideo: "denyAudioVideo",
    notAccessibleDevices: "notAccessibleDevices",
    denyNotification: "denyNotification",
    error: "error",
    errorCaptcha: "errorCaptcha",
    errorCheckin: "errorCheckin",
    errorNoParticipants: "errorNoParticipants",
    incompatibleBrowser: "incompatibleBrowser",
    isPaused: "isPaused",
    isUnpaused: "isUnpaused",
    issueAutoMuted: "issueAutoMuted",
    sendingInviteEmail: "sendingInviteEmail",
    
    networkBad: "networkBad",
    networkDisconnected: "networkDisconnected",
    networkReconnected: "networkReconnected",
    networkDisconnectedParticipant: "networkDisconnectedParticipant",
    networkReconnectedParticipant: "networkReconnectedParticipant",
    networkGood: "networkGood",
    
    unsupportedChromeiOS: "unsupportedChromeiOS",

    oopsIsBelowMinimumVersion: "oopsIsBelowMinimumVersion",
    oopsUnableToGetBrowserInfo: "oopsUnableToGetBrowserInfo",
    oopsUnableToGetBrowserVersion: "oopsUnableToGetBrowserVersion",
    oopsUserIsOnUnsupportedBrowser: "oopsUserIsOnUnsupportedBrowser",
    opentokRequirementsFailed: "opentokRequirementsFailed",
    opentokError: "opentokError",
    refreshingPage: "refreshingPage",

    pageInCall: "pageInCall",
    pagePatient404Page: "pagePatient404Page",
    
    pagePatientCallDisconnected: "pagePatientCallDisconnected",
    pageProviderDisconnectedRoom: "pageProviderDisconnectedRoom",
    pageProviderCallDisconnected: "pageProviderCallDisconnected",

    pageOneloginCallback: "pageOneloginCallback",
    pageOneloginError: "pageOneloginError",
    pageAzureCallback: "pageAzureCallback",
    pageAzureError: "pageAzureError",

    pageDeniedPermissions: "pageDeniedPermissions",
    pageWelcomeBack: "pageWelcomeBack",
    pageCheckIn: "pageCheckIn",
    pageFirstTimeCheckIn: "pageFirstTimeCheckIn",
    pageHoldCall: "pageHoldCall",
    pageEndCall: "pageEndCall",
    pageKickedOut: "pageKickedOut",
    pageOops: "pageOops",
    pageWaitingRoom: "pageWaitingRoom",
    pageWaitingRoomNotFound: "pageWaitingRoomNotFound",
    pagePresentProviderSurvey: "pagePresentProviderSurvey",
    patientSawProviderLeaveMeeting: "patientSawProviderLeaveMeeting",

    setAudioSource: "setAudioSource",
    setSpeaker: "setSpeaker",
    setVideoSource: "setVideoSource",
    smsInviteSent: "smsInviteSent",
    surveySubmit: "surveySubmit",
    videoDisabled: "videoDisabled",
    videoEnabled: "videoEnabled",
    videoQualityUpdated: "videoQualityUpdated",
    wsMessageMissed: "wsMessageMissed",
    downloadAppiOS: "downloadAppiOS",
    downloadAppAndroid: "downloadAppAndroid",
    log: "log",
    visitCompleted: "visitCompleted",
    patientEnteredWaitingRoom: "patientEnteredWaitingRoom",
    providerEnteredWaitingRoom: "providerEnteredWaitingRoom",
};

export default countlyEvents;
