class UserStorage {

    storage: Storage = sessionStorage;
    namespace: string = "VMD.TH.";

    constructor(storage: Storage, userId?: number | string) {
        this.storage = storage;
        this.namespace = this.namespace + userId + ".";
    }

    getItem(key: string): string | null {
        return this.storage.getItem(this.namespace + key);
    }

    setItem(key: string, value: string): void {
        this.storage.setItem(this.namespace + key, value);
    }

    removeItem(key: string): void {
        this.storage.removeItem(this.namespace + key);
    }
}
export default UserStorage;