import type { FormRule } from '../types';

const FormRules: FormRule[] =  [
	{
		code: 'first_name',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'First name is required',
				rule: ['required'],
			},
			looksLikeEmail: {
				type: 'warning',
				error_type: 'soft',
				message: 'Please provide your first name not an email',
				rule: ['customRule', (value: any, data: any, Rules: any) => !value || !value.includes('@')],
			},
		}
	},
	{
		code: 'last_name',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Last name is required',
				rule: ['required'],
			},
			looksLikePassword: {
				type: 'warning',
				error_type: 'soft',
				message: 'Please provide your last name not a password',
				rule: ['customRule', (value: any, data: any, Rules: any) => {
					const passwordChars = new Set(['$', '#', '!']);
					return !value || !value.split('').some( (c: string) => passwordChars.has(c));
				}],
			}
		}
	},
	{
		code: 'patient_consent',
		element_type: 'input',
		rules: {
			isTrue: {
				type: 'error',
				error_type: 'hard',
				message: 'Your consent is required',
				rule: ['isTrue'],
			},
		}
	}
];

export default FormRules;