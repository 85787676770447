import React from 'react';
import DownloadApp from 'pages/PatientRoom/DownloadApp';
import './ErrorScreen.scss';
import BoxedLayout from 'layouts/BoxedLayout';


interface ErrorScreenProps {
    oopsTitle?: string | null,
    oopsMessageComponent?: React.ReactElement | null,
    oopsMessage?: string | null,
    showRefreshButton?: boolean,
    noDownloadApp?: boolean,
    onRefreshPage?: (event: React.MouseEvent<HTMLButtonElement>) => void,
}

const ErrorScreen = (props: ErrorScreenProps) => {
    return (
        <BoxedLayout>
            <div className="box d-flex flex-column" >
                <div className="d-flex flex-row align-items-center flex-grow-1">
                    <div className="column">
                        <img className="is-horizontal-center ic-band-aid" src="/ic-band-aid.svg" alt="village medical oops band aid icon" />
                        <p className="gray-90 has-text-centered has-text-weight-bold is-size-5">
                            Oops!
                        </p>
                        <p className="gray-90 has-text-centered has-text-weight-bold is-size-5 mb-4">
                            {props.oopsTitle ? props.oopsTitle : "Something went wrong"}
                        </p>
                        {props.oopsMessageComponent ? props.oopsMessageComponent
                            : <div id="oops-message" className="gray-80 has-text-centered is-size-6 mb-4" >
                                {props.oopsMessage ? props.oopsMessage : "Please refresh the page"}
                            </div>}
                        {props.showRefreshButton &&
                            <div id="oops-refresh-button" className="field has-addons has-text-centered">
                                <button className="button is-primary is-medium is-fullwidth mt-4" onClick={props.onRefreshPage} >
                                    <span className="icon is-small">
                                        <i className="fas fa-redo"></i>
                                    </span>
                                    <span>Refresh</span>
                                </button>
                            </div>
                        }
                    </div>
                </div>
                {!props.noDownloadApp &&
                    <DownloadApp 
                        title="Prefer having a visit via our mobile app?" 
                        subtitle="Download Village Medical app now!"
                        screenContext={"errorScreen"}
                    />
                }
            </div>
        </BoxedLayout>
    );
}
export default ErrorScreen;