import React from 'react';
import type { AuthUser } from '../shared/types';

type AppContextState = {
    isLoggedIn: boolean,
    authUser: AuthUser,
    checkingAuthentication: boolean,
    updateAuthUser: (data: any) => void,
    login: (user: any) =>  void,
    logout: () =>  void,
    checkCurrentUser: () => void,
}
const AppContext = React.createContext<AppContextState | {} >({});

export default AppContext;