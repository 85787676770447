import { createTheme } from '@material-ui/core';
import { defaultVillageUiTheme } from '@village/ui';

export const globalBaseFontSize = 16;

export const theme = createTheme({
    ...defaultVillageUiTheme,
    vui: {
        ...defaultVillageUiTheme.vui,
        platform: "web",
    },
});