import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import { Icon } from 'icon';
import BoxedLayout from 'layouts/BoxedLayout';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Column, Container, Row } from 'shared/Flex';
import MessageInline from 'shared/MessageInline';
import { IMessage, IToast, MessageType } from 'shared/Messages/types';
import withMessages from 'shared/Messages/withMessages';
import type { AuthUser, Session } from 'shared/types';
import HttpClient from 'utils/HttpClient';
import { FeedbackButton } from 'village-ui/FeedbackButton';

import { Divider, Link, Text } from '@village/ui';

import GoodQuestionnaire from './components/GoodQuestionnaire';
import GreatQuestionnaire from './components/GreatQuestionnaire';
import PoorQuestionnaire from './components/PoorQuestionnaire';
import * as Styled from './styles';
import { NavLink } from 'core/router';

enum CallQuality {
    poor = -1,
    okay = 0,
    great = 1,
}

type Props = RouteComponentProps & {
    authUser: AuthUser,
    match: RouteComponentProps['match'] & {
        params: { session_id: string }
    }
    showToast: (toast: IToast) => void,
};
const ProviderSurvey = ({ showToast, match, history, authUser }: Props) => {
    const [session, setSession] = useState<Session>();
    const [quality, setQuality] = useState<CallQuality>();
    const [message, setMessage] = useState<IMessage>();

    useEffect(() => {
        loadSession();
        countlyAddEvent(countlyEvents.pagePresentProviderSurvey, {
            providerId: authUser?.id,
            sessionId: match.params.session_id,
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const loadSession = () => {
        if (!authUser?.id) return;

        const userId = authUser?.id;
        const sessionId = match.params.session_id;
        let apiName = 'telehealthApi';
        let path = `/providers/${userId}/sessions/${sessionId}`;
        HttpClient().get(apiName, path)
            .then((session) => {
                setSession(session);
            }).catch((error: any) => {
                setMessage({
                    type: MessageType.danger,
                    message: (error.response?.data?.detail || "Failed to load session")
                });
                countlyAddEvent(countlyEvents.error, {
                    errorCode: error.name,
                    errorDescription: error.message,
                    comment: "Failed to load session",
                    response: JSON.stringify(error.response)
                });
            });
    }

    const handleSubmit = (values: any) => {
        if (!quality && quality !== 0) return;

        countlyAddEvent(countlyEvents.surveySubmit, {
            quality: quality < 0 ? "Poor" : quality > 0 ? "Great" : "Okay",
            ...values
        });

        const sessionId = match.params.session_id;
        let apiName = 'telehealthApi';
        let path = `/sessions/${sessionId}/provider/feedback`;
        const postData = {
            ...values,
            quality: quality,
        };
        HttpClient().post(apiName, path, postData).then(() => {
            showToast({
                message: (
                    <Container>
                        <Icon name="check" size={1.25} />&nbsp;Your feedback has been sent successfully
                    </Container>
                ),
                position: "top center"
            });
            history.push("/dashboard");
        }).catch((error: any) => {
            setMessage({
                type: MessageType.danger,
                message: (error.response?.data?.detail || "Failed to save feedback")
            });
            countlyAddEvent(countlyEvents.error, {
                errorCode: error.name,
                errorDescription: error.message,
                comment: "Failed to save feedback",
                response: JSON.stringify(error.response)
            });
        });
    }

    return (
        <BoxedLayout>
            <Styled.Card>
                <Column gap={24} padding={0}>
                    <Column gap={16} padding={0}>
                        <Styled.Figure>
                            <img src="/ic-how-are-you.png" alt="village medical end call" />
                        </Styled.Figure>
                        <Column gap={4} padding={0}>
                            <Styled.TextCenter>
                                <Text type='h5'>Call ended</Text>
                            </Styled.TextCenter>
                            <Styled.TextCenter>
                                <Styled.BodyText type='body1'>
                                    Your appointment
                                    {session ? ` with ${session.patient.first_name} ${session.patient.last_name} ` : " "}
                                    is complete.
                                </Styled.BodyText>
                            </Styled.TextCenter>
                        </Column>
                        <Styled.TextCenter>
                            <Text type='h6'>
                                <NavLink to="/dashboard" component={Link}>Return to Dashboard</NavLink>
                            </Text>
                        </Styled.TextCenter>
                    </Column>

                    <Divider direction="horizontal" />

                    <Column gap={12} padding={0}>
                        <Text type='h6'>How was the quality of the call?</Text>
                        <Row gap={12} alignItems="center" >
                            <FeedbackButton onClick={setQuality} value={CallQuality.poor} checked={quality === CallQuality.poor}>
                                <Icon name="emoji_dissatisfied" size={1.5} />
                                <Text type='h6'>Poor</Text>
                            </FeedbackButton>
                            <FeedbackButton onClick={setQuality} value={CallQuality.okay} checked={quality === CallQuality.okay} >
                                <Icon name="emoji_neutral" size={1.5} />
                                <Text type='h6'>Okay</Text>
                            </FeedbackButton>
                            <FeedbackButton onClick={setQuality} value={CallQuality.great} checked={quality === CallQuality.great} >
                                <Icon name="emoji_happy" size={1.5} />
                                <Text type='h6'>Great</Text>
                            </FeedbackButton>
                        </Row>
                    </Column>


                    {message && <MessageInline type={message.type} message={message.message} />}

                    {quality === CallQuality.poor ? <PoorQuestionnaire onSubmit={handleSubmit} />
                        : quality === CallQuality.okay ? <GoodQuestionnaire onSubmit={handleSubmit} />
                            : quality === CallQuality.great ? <GreatQuestionnaire onSubmit={handleSubmit} />
                                : null}

                </Column>
            </Styled.Card>
        </BoxedLayout>
    );
}
export default withMessages(ProviderSurvey);