import React, { ReactNode, useEffect } from 'react';
import withMessages from './withMessages';
import type {
	MessagesPosition,
	Message,
	Toast,
	ToastGroups,
	MessageType
} from './types';
import { Icon } from 'icon';
import './messages.scss';

type MessagesProps = {
	position: MessagesPosition,
	messages: Message[],
	toasts: Toast[],
	timeout: number,
	maxStack: number,
	dismissMessage: (uuid: string) => void,
	dismissToast: (uuid: string) => void,
}

const Messages = (props: MessagesProps) => {

	useEffect(() => {
		//Dismiss all alert messages
		props.messages.forEach((msg) => props.dismissMessage(msg.uuid))
		// eslint-disable-next-line
	}, []);

	const dismissTimeout = (msg: Message | Toast) => {
		if (msg.noTimeout) return;

		setTimeout(() => {
			props.dismissToast(msg.uuid);
		}, props.timeout)

		return null;
	}
	// const dismissNow = (uuid: string) => () => {
	// 	props.dismissToast(uuid);
	// }
	const dismissMessage = (uuid: string) => () => {
		props.dismissMessage(uuid);
	}
	const getMessageIcon = (type: MessageType): ReactNode => {
		return (
			['danger', 'warning'].includes(type) ?
				<div className="icon-container" >
					<Icon name="warning" size={1} />
				</div>
				: null
		);
	}
	const getDimissButton = (uuid: string): ReactNode => {
		return (
			<div>
				<div className="dismiss" onClick={dismissMessage(uuid)}>
					<Icon name="times" size={1} />
				</div>
			</div>
		);
	}
	const groupToastsByPosition = (toasts: Toast[]): ToastGroups => {
		const groups: ToastGroups = {};
		toasts.forEach((toast) => {
			let position = (toast.position || props.position).toLowerCase()
				.split(" ")
				.map((part) => part.trim())
				.join(" ");

			if (!groups[position]) groups[position] = [];
			groups[position].push(toast);
		});

		return groups;
	}

	const { toasts, messages } = props;
	const toastGroups = groupToastsByPosition(toasts);

	return (
		<React.Fragment>
			{messages.length > 0 ?
				<div className={"alert-message-container"} >
					{messages.map((msg, i) => i < props.maxStack &&
						<div className={`alert-message alert-${msg.type}`} key={`msg-alert-${i}`} >
							{msg.component ?
								React.createElement(msg.component, {
									icon: getMessageIcon(msg.type),
									message: msg.message,
									dismissButton: getDimissButton(msg.uuid)
								})
								:
								<div className="message-wrapper" >
									<div className="d-flex justify-content-between align-items-center" >
										<div className="flex-fill message-text-container">
											<div className="d-flex justify-content-center align-items-center" >
												{getMessageIcon(msg.type)}
												<div className="text">{msg.message}</div>
											</div>
										</div>
										{getDimissButton(msg.uuid)}
									</div>
								</div>
							}
						</div>)}
				</div>
				: null}
			{Object.keys(toastGroups).map((position) =>
				<div className={`toast-message-container ${position}`} key={position} >
					{toastGroups[position].map((msg, i) => i < props.maxStack &&
						<div className={"toast-message"} key={`msg-toast-${i}`} >
							{msg.message}
							{props.timeout && dismissTimeout(msg)}
						</div>)}
				</div>
			)}
		</React.Fragment>
	);
}
Messages.defaultProps = {
	position: "middle center",
	timeout: 3000, //ms
	maxStack: 5,
	toasts: [],
	messages: [],
};
export default withMessages(Messages);
