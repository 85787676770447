import React, { useContext } from 'react';
import images from 'assets/images';
import PatientRoomContext from 'providers/PatientRoom/PatientRoomContext';
import './DownloadApp.scss';
import events from 'countly/events';
import { countlyAddEvent, countlyRemoteConfig } from 'countly';
import { isiOS, isAndroid } from 'utils/Basics';
import urls from 'assets/urls';
import { CountlyKey } from 'countly/types/countly';

const platform = require('platform');
platform.parse(window.navigator.userAgent);

type Props = {
    title?: string | null,
    subtitle?: string | null,
    screenContext: string,
}
const DownloadApp = (props: Props) => {
    const { provider } = useContext(PatientRoomContext);
    if (!provider) return null;

    const isProviderInEnabledMarket = () => {
        let enabledMarkets = countlyRemoteConfig('enable_download_app_markets') as string;
        let enabledProviders = countlyRemoteConfig('enable_download_app_providers') as string;

        if (enabledMarkets === "*") return true;
        if (enabledProviders) {
            const enabledProvidersArray = enabledProviders.split(",")
                .map((providerName: string) => providerName.trim().toLowerCase());

            if (provider.npi && enabledProvidersArray.indexOf(provider.npi) > -1) return true;
        }

        if (enabledMarkets) {
            const enabledMarketsArray = enabledMarkets.split(",")
                .map((marketName: string) => marketName.trim().toLowerCase());

            if (provider.clinics?.find((clinic) => enabledMarketsArray.indexOf(clinic.market.name.toLowerCase()) > -1)) {
                return true;
            }
        }

        return false;
    }
    const platformInfo = platform.parse(window.navigator.userAgent);

    const downloadCounter = (eventName: CountlyKey) => () => {
        countlyAddEvent(eventName, { screen: props.screenContext });
    };

    return (
        isProviderInEnabledMarket() ?
            <div className="download-app">
                {props.title && <p className="has-text-centered has-text-weight-bold" >{props.title}</p>}
                {props.subtitle && <p className="has-text-centered" >{props.subtitle}</p>}
                <div className="d-flex justify-content-center" >
                    {!isAndroid(platformInfo.os.family) &&
                        <div className="download-ios" >
                            <a onClick={downloadCounter(events.downloadAppiOS)} href={urls.DOWNLOAD_IOS_APP} >
                                <img alt="Download from App store" title="Download from App store" src={images.ios_download_app} />
                            </a>
                        </div>
                    }

                    {!isiOS(platformInfo.os.family) &&
                        <div className="download-android" >
                            <a onClick={downloadCounter(events.downloadAppAndroid)} href={urls.DOWNLOAD_ANDROID_APP}>
                                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                            </a>
                        </div>
                    }
                </div>
            </div>
            : null
    );
};
export default DownloadApp;