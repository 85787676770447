import React from 'react';
import withAudioLevel from '../../AudioLevel/withAudioLevel';
import * as Styled from './styles';
import { Icon } from 'icon';

type MicrophoneVolumeProps = {
    sm: boolean, 
    isOn: boolean, 
    audioLevel: number
}
const MicrophoneVolume = ({sm, isOn, audioLevel}: MicrophoneVolumeProps) => {
    return (
        <Styled.MicrophoneVolume sm={sm} >
            {sm ? 
                <Icon name={isOn ? "mic" :"mic_off"} size={0.875}/>
            : <Icon name={isOn ? "mic" :"mic_off_red_slash"} size={2}/>}

            {isOn && 
                <Styled.VolumeContainer>
                    <Styled.VolumeLevel style={{height: audioLevel+'%'}} />
                </Styled.VolumeContainer>
            }
        </Styled.MicrophoneVolume>
    );
}
export default withAudioLevel(MicrophoneVolume);