import React from 'react';
import './DeniedPermissions.scss';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import BoxedLayout from 'layouts/BoxedLayout';
import TroubleshootCaseList from './components/TroubleshootCaseList';
import TroubleshootBrowserList from './components/TroubleshootBrowserList';
import TroubleshootSafari from './components/TroubleshootSafari';
import TroubleshootChromeDesktop from './components/TroubleshootChromeDesktop';
import TroubleshootChromeIOS from './components/TroubleshootChromeIOS';
import TroubleshootChromeAndroid from './components/TroubleshootChromeAndroid';
import TroubleshootDeviceInUse from './components/TroubleshootDeviceInUse';
import { DeniedPermissionViews } from './types';
import { Icon } from 'icon';
const { detect } = require('detect-browser');
const platform = require('platform');

type Props = {
    handleIgnore?: (event: React.MouseEvent) => void,
    handleCameraMicrophonePermissions: (event: React.MouseEvent) => void
}
type State = {
    view: DeniedPermissionViews[]
}
class DeniedPermissions extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            view: [DeniedPermissionViews.case_list],
        };
    }
    componentDidMount() {
        countlyAddEvent(countlyEvents.pageDeniedPermissions);
    }
    handleCameraMicrophonePermissions = (event: React.MouseEvent) => {
        countlyAddEvent(countlyEvents.clickGiveAccessCameraMic);
        this.props.handleCameraMicrophonePermissions(event);
    }
    getBrowserCase = (): DeniedPermissionViews => {
        const browser = detect();
        const browserName = browser.name.toLowerCase();
        const platformInfo = platform.parse(window.navigator.userAgent);
        const OSname = platformInfo.os.family.toLowerCase();
        
        let browserCase;
        if(['ios', 'safari'].indexOf(browserName) > -1) {
            browserCase = DeniedPermissionViews.safari;
        } else if (["crios", "chrome"].indexOf(browserName) > -1 && OSname === "ios"){
            browserCase = DeniedPermissionViews.chrome_ios;
        } else if (browserName === "chrome" && OSname === "android"){
            browserCase = DeniedPermissionViews.chrome_android;
        } else if (browserName === "chrome" && OSname === "windows"){
            browserCase = DeniedPermissionViews.chrome_desktop;
        } else if (browserName === "chrome" && OSname === "os x"){
            browserCase = DeniedPermissionViews.chrome_desktop;
        }

        return browserCase as DeniedPermissionViews;
    }
    handleView = (nextView: DeniedPermissionViews) => () => {
        if(nextView === DeniedPermissionViews.browser_list) {
            const browserCase = this.getBrowserCase();
            if(browserCase) nextView = browserCase;
        }
        
        countlyAddEvent(`permissionsTroubleshoot_${nextView}`);
        this.setState({view: [...this.state.view, nextView]});
    }
    goBackView = () => {
        this.state.view.pop();
        this.setState({view: [...this.state.view]});
    }
    render() {
        const currentView = this.state.view[this.state.view.length - 1];

        return (
            <BoxedLayout onLogoClick={this.props.handleIgnore}>
                <div className="box d-flex flex-row align-items-center first-time">
                    <div className="column">
                        {this.state.view.length > 1 &&
                            <div data-testid="go-back" className="back-button" onClick={this.goBackView} >
                                <Icon name="arrow_back" size={1.75} />
                            </div>
                        }
                        {currentView === DeniedPermissionViews.case_list ? 
                            <TroubleshootCaseList 
                                onClick={this.handleView} 
                                onGivePermissions={this.handleCameraMicrophonePermissions} 
                            />
                        : currentView === DeniedPermissionViews.browser_list ? 
                            <TroubleshootBrowserList onClick={this.handleView} />
                        : currentView === DeniedPermissionViews.safari ? 
                            <TroubleshootSafari />
                        : currentView === DeniedPermissionViews.chrome_desktop ? 
                            <TroubleshootChromeDesktop />
                        : currentView === DeniedPermissionViews.chrome_ios ? 
                            <TroubleshootChromeIOS />
                        : currentView === DeniedPermissionViews.chrome_android ? 
                            <TroubleshootChromeAndroid />
                        : currentView === DeniedPermissionViews.device_in_use ? 
                            <TroubleshootDeviceInUse />
                        : null}
                    </div>
                </div>
            </BoxedLayout>
        )
    }
}
export default DeniedPermissions;
