import React from 'react';
import Validate from './Validate';
import type {
    ValidationMode,
    Rule,
    FormRule,
    Validations,
    IValidation
} from './types';


function withValidation(WrappedComponent: ReactComponent, FormRules: FormRule[]){
    class withValidation extends React.Component{
        
        displayName: string = 'withValidation'
        isValidated: boolean;
        validations: IValidation['validations'];
        static WrappedComponent: ReactComponent;

        constructor(props: any){
            super(props);
            this.state = {};

            // Validation vars:
            this.isValidated = false;
            this.validations = true;
            // ****************
        }
        validationReset: IValidation['validationReset'] = () => {
            this.isValidated = false;
            this.validations = true;
        }
        validateLive: IValidation['validateLive'] = (values, mode = null) => {
            return this.validateForm(values, mode);
        }
        validate: IValidation['validate'] = (values = null, mode = null) => {
            this.isValidated = true;
            return this.validateForm(values, mode);
        }
        validateForm = (values: any = null, mode: ValidationMode = null): boolean => {
            if (!values) return true;
            
            const rules = this.isValidated ? FormRules : Validate.filterSoftRules(FormRules);
            let newValidations = Validate.check(rules, values, mode);
            
            if (JSON.stringify(this.validations) !== JSON.stringify(newValidations) ) {
                this.validations = newValidations;
                this.forceUpdate();
            }
            
            let isValid = true;
            if(newValidations !== true) {
                isValid = this._isValidComplex(newValidations);
            }

            return isValid;
        }
        _isValid = (validation: Validations | Rule): boolean => {
            return validation.error_type === "hard" ? false : true;
        }
        _isValidComplex = (validations: Validations): boolean => {
            let isValid = true;
            for(const validationKey in validations) {
                const validationResult = validations[validationKey]
                if(Array.isArray(validationResult)) {
                    isValid = this._isValidArrayComplex(validationResult);
                } else if('rule' in validationResult){
                    isValid = this._isValid(validationResult);
                } else {
                    isValid = this._isValidComplex(validationResult);
                }

                if(!isValid) return false;
            }

            return true;
        }
        _isValidArrayComplex = (validations: Validations[]): boolean => {
            const valid = validations.find((validation) => !this._isValidComplex(validation))
            return valid ? true : false;
        }
        render(){
            return (
                <WrappedComponent 
                    {...this.props}
                    validate={this.validate}
                    validateLive={this.validateLive}
                    validationReset={this.validationReset}
                    validations={this.validations}
				/>
            );
        }
    }
    
    withValidation.WrappedComponent = WrappedComponent;
    return withValidation;
}
export default withValidation;