import React from 'react';
import FocusedVideo from './InCallScreen/FocusedVideo';
import WaitingParticipant from './InCallScreen/WaitingParticipant';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import TryToConnect from './InCallScreen/TryToConnect';
import { UserType } from 'shared/types';
import { CallSessionContextState } from './CallSession/CallSessionContext';
import { OpentokSubscriber } from './opentok/types';
import NetworkTest from 'utils/Network/NetworkTest';
import { NO_CONNECTION } from 'utils/Network/constants';
import type { NetworkStats } from 'utils/Network/types';
import Feature from 'shared/Feature';
import FF from 'shared/constants/FF';

type InCallScreenProps = {
    userType: UserType,
    onSubscriberReady?: (subscriber: OpentokSubscriber, videoElement: HTMLVideoElement) => void,
    callSession: CallSessionContextState
}

class InCallScreen extends React.Component<InCallScreenProps, {}> {
    
    networkTestInstance?: NetworkTest

    constructor(props: InCallScreenProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        countlyAddEvent(countlyEvents.pageInCall);
    }
    componentWillUnmount(): void {
        if (this.networkTestInstance) this.networkTestInstance.stop();
    }
    monitorNetwork = (subscriber: OpentokSubscriber) => {
        if (!Feature.has(FF.unstableConnectionTest)) return;

        this.networkTestInstance = new NetworkTest(subscriber, 'subscriber');
        this.networkTestInstance.run((networkStats: NetworkStats) => {
            this.props.callSession.setSubscriberNetworkStats(networkStats);
        });
    }
    handleSubscriberReady = (subscriber: OpentokSubscriber, videoElement: HTMLVideoElement): void => {
        const callSession = this.props.callSession;
        callSession.handleFocusedVideoCreated(videoElement);
        callSession.onSubscriberReady(subscriber);
        this.monitorNetwork(subscriber)
        if (this.props.onSubscriberReady) {
            this.props.onSubscriberReady(subscriber, videoElement);
        }
        
        if(this.props.userType === "provider"){
            countlyAddEvent(countlyEvents.patientEnteredWaitingRoom);
        } else {
            countlyAddEvent(countlyEvents.providerEnteredWaitingRoom);
        }
    }
    handleSubscribeTimeout = () => {
        const callSession = this.props.callSession;
        callSession.setSubscriberNetworkQuality(NO_CONNECTION);
    }
    render() {

        const callSession = this.props.callSession;
        
        /**
         * Make sure the publisher is ready first and try to subscribe
         * Required for issue: 
         * NotAllowedError: play() failed because the user didn't interact with the document first.
         */
        return (
        <>
            <TryToConnect callSession={callSession} userType={this.props.userType} />
            
            {callSession.publisher && callSession.focusedStream ?
                <FocusedVideo
                    session={callSession.session}
                    stream={callSession.focusedStream}
                    videoElement={callSession.focusedVideoElement}
                    speaker={callSession.speaker}
                    networkQuality={callSession.networkQuality}
                    onSubscriberReady={this.handleSubscriberReady}
                    onSubscriberError={callSession.onSubscriberError}
                />
                :
                <WaitingParticipant
                    isVideoDisabled={!callSession.video}
                    isAudioDisabled={!callSession.audio}
                    onTimeout={this.handleSubscribeTimeout}
                    onEnableAudioVideo={callSession.handleEnableAudioVideo}
                />
            }
        </>
        );
    }
}
export default InCallScreen;
