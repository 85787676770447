import React from 'react';
import type { Validation } from '../types';


interface InputProps {
    validation?: Validation,
    divClassName?: string,
    classNameLabel?: string,
    className?: string,
    id?: string,
    label?: string,
    value: string,
    name: string,
    type: string,
    disabled?: boolean,
    placeholder?: string,
    autoComplete?: string,
    autoCorrect?: string,
    autoFocus?: boolean,
    readOnly?: boolean,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    onFocus?: React.FocusEventHandler<HTMLInputElement>,
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>,
    onClick?: React.MouseEventHandler<HTMLInputElement>,
}

const Input = (props: InputProps) => {
    const classNameValidation = (): string => {
        if (!props.validation) return "";

        switch (props.validation.type) {
            case "success":
                return " is-success ";
            case "warning":
                return " is-warning ";
            case "error":
            default:
                return " is-danger ";
        }
    }
    const classNameLabel = (): string => {
        if (!props.validation) return "label";

        let labelClass = "";
        switch (props.validation.type) {
            case "success":
                labelClass = " has-text-success ";
                break;
            case "warning":
                labelClass = " has-text-warning-dark ";
                break;
            case "error":
            default:
                labelClass = " has-text-danger ";
                break;
        }

        return "label " + labelClass;
    }

    let divClassName = "field " + (props.divClassName ? props.divClassName : "");
    let inputClassName = "input " + (props.className ? props.className : "");

    return (
        <div className={divClassName} >
            {props.label &&
                <label htmlFor={props.name} className={`${classNameLabel()} ${props.classNameLabel}`} >
                    {props.label}
                </label>
            }
            <input
                id={props.id ? props.id : props.name}
                className={inputClassName + classNameValidation()}
                type={props.type}
                name={props.name}
                value={props.value}
                autoComplete={props.autoComplete}
                autoCorrect={props.autoCorrect}
                autoFocus={props.autoFocus}
                onChange={props.onChange}
                onFocus={props.onFocus}
                onKeyDown={props.onKeyDown}
                onClick={props.onClick}
                disabled={props.disabled}
                readOnly={props.readOnly}
                placeholder={props.placeholder ? props.placeholder : ""}
            />
            {props.validation &&
                <p className={"help" + classNameValidation()}>
                    {props.validation.message}
                </p>
            }
        </div>
    );
}
Input.defaultProps = {
    type: "text",
    value: "",
}
export default Input;
