import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CheckPermission } from 'utils/Authorizer';
import { Provider } from 'shared/types';
import Loading from "shared/Loading";
import { InputSearch } from "shared/inputs/InputSearch";
import { Button, Text, Table, TableBody, TableHead, TableRow, TableCell, TableContainer } from '@village/ui';
import { Icon } from 'icon';
import * as Styled from 'shared/PageLayout';
import SelectRole from 'shared/SelectRole';
import SelectClinic from 'shared/SelectClinic';
import ProviderOptions from './components/ProviderOptions';

type FilterValues = {
    clinic_id?: number,
    role_id?: number,
}
type ListProvidersProps = RouteComponentProps & {
    search: string,
    providers: Provider[],
    filter: FilterValues,
    loading: boolean,
    onSearch: React.ChangeEventHandler<HTMLInputElement>,
    onRemove: (provider: Provider) => React.MouseEventHandler<HTMLSpanElement>
    onFilter: (filter: FilterValues) => void
}
type ListProvidersState = {
    isFilterOpen: boolean
}
class ListProviders extends React.Component<ListProvidersProps, ListProvidersState> {
    constructor(props: ListProvidersProps) {
        super(props);
        this.state = {
            isFilterOpen: false
        };
    }
    openFormNew = () => {
        this.props.history.push('/providers/new');
    }
    handleChangeFilter = (name: string, value: string): void => {
        this.props.onFilter({
            ...this.props.filter,
            [name]: value
        })
    }
    render() {

        const { filter, search } = this.props;
        return (
            <>
                <Text type="h4">Providers</Text>
                <Styled.ActionBar>
                    <Styled.SearchContainer>
                        <InputSearch
                            fullWidth
                            label=""
                            placeholder="Search"
                            value={search}
                            name="search"
                            onChange={this.props.onSearch}
                        />
                    </Styled.SearchContainer>

                    <SelectClinic
                        placeholder="All clinics"
                        label=""
                        name="clinic_id"
                        value={filter?.clinic_id}
                        onChange={this.handleChangeFilter}
                    />

                    <SelectRole
                        placeholder="All roles"
                        label=""
                        name="role_id"
                        value={filter?.role_id}
                        onChange={this.handleChangeFilter}
                    />

                    <CheckPermission act='POST' obj={'/providers'} >
                        <Button size="medium" onClick={this.openFormNew} >
                            <Icon name="plus" size={0.875} /> &nbsp;Add new
                        </Button>
                    </CheckPermission>
                </Styled.ActionBar>

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><Text type="caption1">First name</Text></TableCell>
                                <TableCell><Text type="caption1">Last name</Text></TableCell>
                                <TableCell><Text type="caption1">Roles</Text></TableCell>
                                <TableCell><Text type="caption1">Clinics</Text></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.providers.map((provider) =>
                                <TableRow key={provider.id}>
                                    <TableCell><Text type="body1">{provider.first_name}</Text></TableCell>
                                    <TableCell><Text type="body1">{provider.last_name}</Text></TableCell>
                                    <TableCell><Text type="body1">{provider?.roles?.map((e) => e.name).join(", ")}</Text></TableCell>
                                    <TableCell><Text type="body1">{provider?.clinics?.map((e) => e.name).join(", ")}</Text></TableCell>
                                    <TableCell $action>
                                        <ProviderOptions
                                            provider={provider}
                                            onRemove={this.props.onRemove(provider)}
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                            {this.props.loading &&
                                <TableRow id="loading-row" >
                                    <TableCell colSpan={5} >
                                        <Loading />
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }
}
export default withRouter(ListProviders);
