import React from 'react';
import ListMarkets from './ListMarkets';
import FormMarkets from './FormMarkets';
import MyRoute from 'core/router/MyRoute';
import HttpClient from 'utils/HttpClient';
import { debounce } from 'utils/Basics';
import { IMarket, Market } from 'shared/types';
import { Column } from 'shared/Flex';


type MarketsPageProps = {}

type MarketsPageState = {
    loading: boolean,
    page: number,
    perPage: number,
    search: string,
    markets: Market[]
}

class MarketsPage extends React.Component<MarketsPageProps, MarketsPageState> {
    constructor(props: MarketsPageProps) {
        super(props);
        this.state = {
            loading: true,
            page: 0,
            perPage: 30,
            search: "",
            markets: []
        };
    }

    componentDidMount() {
        this.getMarkets();
        document.getElementById('content')?.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        document.getElementById('content')?.removeEventListener('scroll', this.handleScroll);
    }

    async getMarkets(page: number = 0): Promise<void> {
        const apiName = 'telehealthApi';
        const path = '/markets';
        const queryParams = {
            skip: page * this.state.perPage,
            limit: this.state.perPage,
            search: this.state.search
        };
        this.setState({ 
            loading: true
        });
        try {
            const markets = await HttpClient().get(apiName, path, queryParams);
            this.setState({
                page,
                markets: page > 0 ? this.state.markets.concat(markets) : markets
            });
        } catch(error) {
            // TODO TBD globally
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    handleScroll = (e: Event): void => {
        debounce(() => {
            const element = e.target as Element
            if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                element.scrollTop--;
                if (this.state.markets.length % this.state.perPage === 0) {
                    this.getMarkets(this.state.page + 1);
                }
            }
        }, 200);
    }

    handleSearch = (event: any): void => {
        this.setState({ search: event?.target?.value });
        debounce(() => this.getMarkets(), 400);
    }

    newMarket = (market: Market): void => {
        this.setState({
            markets: [market, ...this.state.markets]
        });
    }

    updateMarket = (market_id: number, data: IMarket): void => {
        this.setState({
            markets: this.state.markets.map(item => {
                return item.id === market_id ? { ...item, ...data } : item;
            })
        });
    }

    handleRemove = (market: Market) => (): void => {
        const isConfirmed = window.confirm("Are you sure you want to remove?");
        if (isConfirmed) {
            const apiName = 'telehealthApi';
            const path = `/markets/${market.id}`;
            this.setState({
                loading: true
            });
            HttpClient().delete(apiName, path)
            .then(() => {
                this.setState({
                    loading: false,
                    markets: this.state.markets.filter((item) => item.id !== market.id)
                });
            })
            .catch((error: any) => {
                this.setState({
                    loading: false
                });
            });
        }
    }

    render() {
        return (
            <Column grow scrollable>
                <ListMarkets
                    markets={this.state.markets}
                    loading={this.state.loading}
                    search={this.state.search}
                    onSearch={this.handleSearch}
                    onRemove={this.handleRemove}
                />
                <MyRoute
                    exact
                    path="/markets/new"
                    title="New market"
                    mode="new"
                    onCreate={this.newMarket}
                    component={FormMarkets}
                />
                <MyRoute
                    exact
                    path="/markets/edit/:id"
                    title="Edit market"
                    mode="edit"
                    onUpdate={this.updateMarket}
                    component={FormMarkets}
                />
            </Column>
        );
    }
}

export default MarketsPage;
