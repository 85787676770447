import React from 'react';
import qs from "qs";
import { RouteComponentProps } from 'react-router-dom';
import HttpClient from 'utils/HttpClient';
import Loading from "shared/Loading";
import Input from 'shared/inputs/Input2';
import MessageInline from 'shared/MessageInline';
import { IMessage, MessageType } from 'shared/Messages/types';
import { IMarket, Market, Validation } from 'shared/types';
import withValidation from 'validations/withValidation';
import MarketsFormRules from 'validations/FormRules/MarketsFormRules';
import { IValidation } from 'validations/types';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import { Button, Text, Drawer, DrawerFooter, DrawerHeader, DrawerContent } from '@village/ui';
import { Box } from '@material-ui/core';


type FormMarketsProps = RouteComponentProps & IValidation & {
    match: RouteComponentProps['match'] & {
        params: { id: string }
    }
    mode: "new" | "edit",
    onCreate: (newMarket: IMarket) => void,
    onUpdate: (marketId: number, postData: IMarket) => void,
}

type FormMarketsState = {
    loading: boolean,
    open: boolean,
    message: IMessage | null,
    market: IMarket,
}

class FormMarkets extends React.Component<FormMarketsProps, FormMarketsState> {
    constructor(props: FormMarketsProps) {
        super(props);
        this.state = {
            loading: false,
            open: false,
            message: null,
            market: {
                name: '',
            },
        };
    }

    componentDidMount() {
        if (this.props.mode === 'edit') {
            this.getMarket(parseInt(this.props.match.params.id));
        }
        this.setState({ open: true });
    }

    async getMarket(marketId: number): Promise<void> {
        const apiName = 'telehealthApi';
        const path = `/markets/${marketId}`;
        let message: IMessage | null = null;
        this.setState({
            loading: true,
            message
        });
        try {
            const market = await HttpClient().get(apiName, path);
            this.setState({
                market
            });
        } catch (error: any) {
            message = {
                type: MessageType.danger,
                message: error.toString() as string
            }
        } finally {
            this.setState({
                loading: false,
                message
            });
        }
    }

    cleanMarket = (): IMarket => ({
        name: this.state.market.name.trim()
    })

    async createMarket(): Promise<void> {
        const apiName = 'telehealthApi';
        const path = '/markets';
        const postData = this.cleanMarket();
        if (!this.props.validate(postData)) return;
        let message: IMessage | null = null;
        this.setState({
            loading: true,
            message
        });
        try {
            const market = await HttpClient().post(apiName, path, postData);
            this.setState({
                market
            });
            message = {
                type: MessageType.success,
                message: "New market created successfully."
            }
            this.props.onCreate(market);
            this.handleClose();
        } catch (error: any) {
            message = {
                type: MessageType.danger,
                message: error.toString()
            }
            countlyAddEvent(countlyEvents.error, {
                errorCode: error.name,
                errorDescription: error.message,
                comment: "Failed to create market",
                response: JSON.stringify(error.response)
            });
        } finally {
            this.setState({
                loading: false,
                message
            });
        }
    }

    async updateMarket(marketId: number): Promise<void> {
        const apiName = 'telehealthApi';
        const path = `/markets/${marketId}`;
        const putData = this.cleanMarket();
        if (!this.props.validate(putData, 'update')) return;
        let message: IMessage | null = null;
        this.setState({
            loading: true,
            message
        });
        try {
            const market: Market = await HttpClient().put(apiName, path, putData);
            this.setState({
                market
            });
            message = {
                type: MessageType.success,
                message: "Market edited successfully."
            }
            this.props.onUpdate(marketId, market);
            this.handleClose();
        } catch (error: any) {
            message = {
                type: MessageType.danger,
                message: error.toString()
            }
            countlyAddEvent(countlyEvents.error, {
                errorCode: error.name,
                errorDescription: error.message,
                comment: "Failed to update market",
                response: JSON.stringify(error.response)
            });
        } finally {
            this.setState({
                loading: false,
                message
            });
        }
    }

    handleClose = (): void => {
        this.setState({ open: false });
        setTimeout(() => {
            const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            this.props.history.push({
                pathname: '/markets',
                search: `?${qs.stringify(queryParams)}`,
            });
        }, 300);
    }

    handleSubmit = (event: React.FormEvent): void => {
        event.preventDefault();
        if (this.props.mode === 'new') {
            this.createMarket();
        } else {
            this.updateMarket(parseInt(this.props.match.params.id));
        }
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const market = {
            ...this.state.market,
            [event.target.name]: event.target.value
        }
        this.setState({
            market
        });
        this.props.validateLive(market, this.props.mode === 'new' ? 'create' : 'update');
    }

    render() {

        const { market, message } = this.state;
        const validations = this.props.validations === true ? {} : this.props.validations;

        return (
            <Drawer open={this.state.open} onClose={this.handleClose} anchor='right' data-testid='form-markets-drawer'>
                <DrawerHeader onClose={this.handleClose} >
                    <Text type="h6">{this.props.mode === 'new' ? 'Create' : 'Edit'} market</Text>
                </DrawerHeader>
                <DrawerContent>
                    {this.state.loading ?
                        <Loading />
                        : <form id='market-form' method='POST'>
                            {message && <MessageInline type={message.type} message={message.message} />}
                            <Box py={2}>
                                <Input
                                    label='Name'
                                    name='name'
                                    fullWidth
                                    value={market.name}
                                    onChange={this.handleChange}
                                    validation={validations?.name as Validation}
                                />
                            </Box>
                        </form>}
                </DrawerContent>
                <DrawerFooter>
                    <Box display="flex" gridGap={16}>
                        <Button variant="secondary" size="medium" fullWidth onClick={this.handleClose} >
                            Cancel
                        </Button>
                        <Button size="medium" fullWidth type="submit" onClick={this.handleSubmit} >
                            Save
                        </Button>
                    </Box>
                </DrawerFooter>
            </Drawer>
        );
    }
}

export default withValidation(FormMarkets, MarketsFormRules);
