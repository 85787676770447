import React, { useContext } from 'react';
import TabsContext from './tab-context';
import { TabProps } from '@material-ui/core';


type TabPanelProps = {
    className?: string,
    value: TabProps['value'],
    children: JSX.Element
}

export const TabPanel = ({ className, value, children }: TabPanelProps): JSX.Element | null => {
    const { activeTab } = useContext(TabsContext);
    return (
        value === activeTab ?
            <div className={className} >{children}</div>
            : null
    );
}