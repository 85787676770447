import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import { GridComponent, LegendComponent, TooltipComponent, TitleComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { DataRow, PatientSource } from '../types';

// Register the required components
echarts.use([TitleComponent, LegendComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer]);

type Serie = {
    name: string,
    type: 'bar',
    stack: 'total',
    label: {
        show: true
    },
    emphasis: {
        focus: 'series'
    },
    data: number[]
}
type StructuredData = {
    [x in PatientSource]: {
        [y: string]: number;
    };
};
type VmdBarChartProps = {
    categories: string[], 
    data?: DataRow[]
}
const VmdBarChart = ({ categories, data }: VmdBarChartProps) => {
    const getCategoryValues = (
        serie: PatientSource, 
        categories: string[], 
        structuredData: StructuredData
    ): number[] => {

        return categories.map((date) => {
            if (!structuredData[serie]) return 0;
            if (!structuredData[serie][date]) return 0;
            return structuredData[serie][date];
        });
    }
    const getSeries = (data: DataRow[]): Serie[] => {

        const structuredData: any = {};
        let series: PatientSource[] = [];

        data.forEach((row) => {
            if (!(row.patient_source in structuredData)) structuredData[row.patient_source] = {};
            structuredData[row.patient_source][row.at_date] = row.count;

            if (series.indexOf(row.patient_source) < 0) {
                series.push(row.patient_source);
            }
        });

        return series.map((serie) => {
            return {
                name: serie,
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: getCategoryValues(serie, categories, structuredData)
            };
        });
    }
    const getOptions = (data: DataRow[]) => {

        const options = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    // Use axis to trigger tooltip
                    type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                }
            },
            legend: { show: true },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: categories
            },
            yAxis: {
                type: 'value'
            },
            series: getSeries(data)
        };

        return options;
    }

    return (
        data ? <ReactEChartsCore echarts={echarts} option={getOptions(data)} /> : null
    );
}
export default VmdBarChart;