import { rem } from 'polished';
import styled from 'styled-components';

export const List = styled.ul`
    li {
        padding: ${rem(8)} 0;
        text-align: left;

        &:last-child {
            padding-bottom: 0;
        }
    }    
`;
