import styled from 'styled-components/macro';

export const LeaveBtn = styled.button`
    
    background-color: #c13c47;
    border-color: transparent;
    color: #fff;

    border-radius: 8px;
    padding: 10px 17px;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;
    border-width: 1px;
    
    cursor: pointer;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    
    -webkit-appearance: none;
    align-items: center;
    border: 1px solid transparent;
    box-shadow: none;
    display: inline-flex;
    height: 2.5em;
    vertical-align: top;

    &:hover {
        background-color: #ce626b;
    }

    &:active {
        background-color: #9a3039;
    }

    @media screen and (min-width: 768px) {
        height: 50px;
        font-size: 18px;
    }
`;