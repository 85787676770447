import React from 'react';

import VillageUIThemeProvider from './VillageUIThemeProvider';
import { Switch, Redirect } from 'react-router-dom';
import { MyRoute } from 'core/router';
import ClinicalRoomProvider from 'providers/Room/ClinicalRoomProvider';

//Cognito authentication page components:
// import LoginPage from 'pages/Auth/LoginPage';
// import ForgotPasswordPage from 'pages/Auth/ForgotPasswordPage';
// import ChangePasswordPage from 'pages/Auth/ChangePasswordPage';
// import OidcLoginPage from 'pages/Auth/Onelogin/OidcLoginPage';
// import OidcCallbackPage from 'pages/Auth/Onelogin/OidcCallbackPage';
import OidcAzureLoginPage from 'pages/Auth/Azure/OidcAzureLoginPage';
import OidcAzureCallbackPage from 'pages/Auth/Azure/OidcAzureCallbackPage';
import ProviderRoomPage from 'pages/ProviderRoom/ProviderRoomPage';
import ProviderLayout from 'layouts/ProviderLayout';
import RoomLayout from 'layouts/RoomLayout';
import ProfilePage from 'pages/ProfilePage';
import DashboardPage from 'pages/Dashboard/DashboardPage';
import ProvidersPage from 'pages/Providers/ProvidersPage';
import ClinicsPage from 'pages/Clinics/ClinicsPage';
import MarketsPage from 'pages/Markets/MarketsPage';
import WaitingRoomsPage from 'pages/WaitingRooms/WaitingRoomsPage';
import ReportsPage from 'pages/Reports/ReportsPage';
import LogsPage from 'pages/Logs/LogsPage';
import Provider404Page from 'pages/Provider404Page';
import ProviderSurvey from 'pages/ProviderRoom/ProviderSurvey';


const ProviderApp = () => {
    return (
        <VillageUIThemeProvider>
            <Switch>
                {/* {this.props.isLoggedIn && this.props.authUser.challengeName === 'NEW_PASSWORD_REQUIRED' ?
                    <MyRoute exact path='/change-password' component={ChangePasswordPage} private redirectTo='/login' />
                    :
                    <Redirect from='/change-password' to='/' />
                }

                {this.props.isLoggedIn && this.props.authUser.challengeName === 'NEW_PASSWORD_REQUIRED' &&
                    <Redirect to={"/change-password"} />
                }
                <MyRoute exact path='/login' component={LoginPage} onlyPublic redirectTo='/dashboard' />
                <MyRoute exact path='/forgot-password' component={ForgotPasswordPage} onlyPublic redirectTo='/dashboard' /> */}

                {/* Comment the line above to re-enable cognito login*/}
                <Redirect exact from='/login' to="/azure-oidc/login" />
                <Redirect exact from='/oidc/login' to="/azure-oidc/login" />
                {/* <MyRoute exact path='/oidc/login' component={OidcLoginPage} onlyPublic redirectTo='/dashboard' /> */}
                {/* <MyRoute exact path='/oidc/callback' component={OidcCallbackPage} onlyPublic redirectTo='/dashboard' /> */}
                <MyRoute exact path='/azure-oidc/login' component={OidcAzureLoginPage} onlyPublic redirectTo='/dashboard' />
                <MyRoute exact path='/azure-oidc/callback' component={OidcAzureCallbackPage} onlyPublic redirectTo='/dashboard' />
                <Redirect exact from='/' to="/azure-oidc/login" />

                <ClinicalRoomProvider>
                    <MyRoute exact path='/session/:session_id' private component={RoomLayout} redirectTo='/login'>
                        <MyRoute exact path='/session/:session_id' private component={ProviderRoomPage} redirectTo='/login' />
                    </MyRoute>
                    
                    <MyRoute exact path='/session/:session_id/provider/feedback' private component={ProviderSurvey} redirectTo='/login' />
                    
                    <MyRoute 
                        path='/:path(dashboard|profile|markets|clinics|providers|waiting_rooms|reports|logs)' 
                        component={ProviderLayout} 
                        private 
                        redirectTo="/login" 
                    >
                        <Switch>
                            <MyRoute exact path='/dashboard' component={DashboardPage} redirectTo='/login' />
                            <MyRoute path='/providers' title="Providers" component={ProvidersPage} redirectTo='/login' />
                            <MyRoute path='/markets' title="Markets" component={MarketsPage} redirectTo='/login' />
                            <MyRoute path='/clinics' title="Clinics" component={ClinicsPage} redirectTo='/login' />
                            <MyRoute path='/waiting_rooms' title="Waiting Rooms" component={WaitingRoomsPage} redirectTo='/login' />
                            <MyRoute path='/reports' title="Reports" component={ReportsPage} redirectTo='/login' />
                            <MyRoute path='/logs' title="Logs" component={LogsPage} redirectTo='/login' />
                            <MyRoute exact path='/profile' component={ProfilePage} private redirectTo='/login' />
                        </Switch>
                    </MyRoute>
                </ClinicalRoomProvider>
                <MyRoute path="/" private component={Provider404Page} />
            </Switch>
        </VillageUIThemeProvider>
    );
}
export default ProviderApp;