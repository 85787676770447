import React, { useEffect, useState } from 'react';
import qs from "qs";
import dayjs from 'dayjs';
import { RouteComponentProps } from 'react-router-dom';
import HttpClient from 'utils/HttpClient';
import Loading from "shared/Loading";
import { Button, Text, Drawer, DrawerFooter, DrawerHeader, DrawerContent } from '@village/ui';
import Timeline from './components/Timeline';
import { Fields, TimelineItem, TimelineItemDataUpdated } from './components/types';
import { Log, Provider } from 'shared/types';
import LOG_ACTION from 'shared/constants/LOG_ACTION';

export const logsToTimeline = (logs: Log[]): TimelineItem[] => {
    const formatDateTime = (date_time: any): string => {
        return dayjs(date_time).format("MMM D, YYYY h:mm A");
    }
    const getAuthorName = (author?: Provider): string => {
        return author ? `${author.first_name} ${author.last_name} ` : "System";
    }

    const getPreviousUpdate = (startIndex: number, logs: Log[]): Log | undefined => {
        for (let i = startIndex + 1; i < logs.length; i++) {
            if ([LOG_ACTION.update, LOG_ACTION.create].includes(logs[i].action)) {
                return logs[i];
            }
        }
    }
    const getUpdateDiff = (current: Log, prev: Log): TimelineItemDataUpdated[] => {
        let changes: TimelineItemDataUpdated[] = [];
        for (let key in current.data) {
            if (key in Fields && current.data[key] !== prev.data[key]) {
                changes.push({
                    what: key as Fields,
                    prev: prev.data[key],
                    next: current.data[key],
                })
            }
        }

        return changes;
    }

    const hybridTimeline: (TimelineItem | TimelineItem[] | null)[] = logs.map((log, i) => {
        switch (log.action) {
            case LOG_ACTION.provider__assign_role:
            case LOG_ACTION.provider__unassign_role:
                return {
                    date_time: formatDateTime(log.created_at),
                    author: getAuthorName(log.author),
                    action: log.action,
                    data: log.data
                };
            case LOG_ACTION.clinic__assign_provider:
            case LOG_ACTION.clinic__unassign_provider:
                return {
                    date_time: formatDateTime(log.created_at),
                    author: getAuthorName(log.author),
                    action: log.action,
                    data: log.clinic
                };
            case LOG_ACTION.waiting_room__assign_provider:
            case LOG_ACTION.waiting_room__unassign_provider:
                return {
                    date_time: formatDateTime(log.created_at),
                    author: getAuthorName(log.author),
                    action: log.action,
                    data: log.waiting_room
                };
            case LOG_ACTION.update:
                const previousUpdateLog = getPreviousUpdate(i, logs);
                if (previousUpdateLog) {
                    const updateDiffs = getUpdateDiff(log, previousUpdateLog);
                    return updateDiffs.map((diffData) => {
                        return {
                            date_time: formatDateTime(log.created_at),
                            author: getAuthorName(log.author),
                            action: log.action,
                            data: diffData
                        }
                    });
                }
                return null;

            case LOG_ACTION.create:
            default:
                return {
                    date_time: formatDateTime(log.created_at),
                    author: getAuthorName(log.author),
                    action: log.action,
                    data: null
                };
        }
    });

    let timeline: TimelineItem[] = [];
    hybridTimeline.forEach((item) => {
        if (Array.isArray(item)) {
            timeline = [...timeline, ...item];
        } else if (item) {
            timeline.push(item);
        }
    });

    return timeline;
}

type Props = RouteComponentProps & {
    match: RouteComponentProps['match'] & {
        params: { id: string }
    }
}
const LogsProviders = ({ history, match, location }: Props) => {

    const [open, setOpen] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [logs, setLogs] = useState<Log[]>([]);

    const gotToProviderDetails = () => history.push(`/providers/edit/${match.params.id}`);
    const loadProviderChangeLogs = () => {
        let apiName = 'telehealthApi';
        let path = '/logs';
        const params = {
            skip: 0,
            limit: 30,
            entity_type: "provider",
            entity_id: match.params.id
        };
        setLoading(true);
        HttpClient().get(apiName, path, params)
            .then((logs) => {
                setLogs(logs)
            }).finally(() => setLoading(false));
    }
    const handleClose = (): void => {
        setOpen(false)

        setTimeout(() => {
            let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
            history.push({
                pathname: '/providers',
                search: '?' + qs.stringify(queryParams),
            });
        }, 300);
    }
    useEffect(() => loadProviderChangeLogs(), []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Drawer open={open} onClose={handleClose} anchor='right' >
            <DrawerHeader onClose={handleClose} >
                <Text type="h6">Change logs</Text>
            </DrawerHeader>
            <DrawerContent>
                {loading ? <Loading /> : <Timeline items={logsToTimeline(logs)} />}
            </DrawerContent>
            <DrawerFooter>
                <Button size="medium" variant="secondary" fullWidth type="submit" onClick={gotToProviderDetails} >
                    Open provider details
                </Button>
            </DrawerFooter>
        </Drawer>
    );
}
export default LogsProviders;