import React from 'react';
import WaitScreen from './WaitScreen';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import { Provider, IWaitingRoom } from 'shared/types';
import { MessageType } from 'shared/Messages/types';

type Props = {
    provider: Provider,
    waiting_room: IWaitingRoom,
}
class ProviderDisconnectedRoom extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        countlyAddEvent(countlyEvents.pageProviderDisconnectedRoom);
    }
    render() {

        return (
            <WaitScreen
                title={`Please hold`}
                subtitle="You're in the waiting room"
                className="disconnected-room"
                message={{
                    type: MessageType.info,
                    message: `Looks like your provider has a technical issue. Please hold while we resolve it.`
                }}
                provider={this.props.provider}
                waiting_room={this.props.waiting_room}
            />
        );
    }
}
export default ProviderDisconnectedRoom;