import React from 'react';
import { Color } from '../types';
import * as Styled from './styles';

type AvatarProps = {
    first_name: string,
    last_name: string,
    avatarColor?: Color,
    img?: string,
    className?: string,
}

const Avatar = ({ first_name, last_name, avatarColor, img, className }: AvatarProps) => {
    let avatarText = first_name.charAt(0) + (last_name ? last_name.charAt(0) : first_name.charAt(1));
    avatarText = avatarText.toUpperCase();

    return (
        <Styled.AvatarWrapper id="avatar" avatarColor={img ? undefined : avatarColor} className={className + " avatar"} >
            {img ? <img src={img} alt="avatarText" /> : <Styled.AvatarText>{avatarText}</Styled.AvatarText>}
        </Styled.AvatarWrapper>
    );
}
export default Avatar;
