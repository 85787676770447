import React from 'react';
import BoxedLayout from '../layouts/BoxedLayout';

// TODO: This page can be same like WaitingRoomNotFound.js or different
// currently keeping this separate 
class Patient404Page extends React.Component {
    render() {
        return (
            <BoxedLayout>
                <div className='box'>
                    <div className="media-content my-4">
                        <figure>
                            <img src="../../village-medical-dark-logo-60.png" width="155" height="28" alt="docOS logo" />
                        </figure>
                    </div>
                    <h2 className="has-text-left has-text-weight-bold is-size-3">Oops!</h2>
                    <p className="has-text-left is-size-5" >
                        It appears that room doesn't exist
                    </p>
                </div>
            </BoxedLayout>
        );
    }
}
export default Patient404Page;