import React from 'react';
import ControlBtn from './ControlBtn';
import countlyEvents from 'countly/events';
import { TRYING_TO_CONNECT } from 'utils/Network/constants';
import { Icon } from 'icon';

type ShareScreenBtnProps = {
    callSession: {
        networkQuality: number,
        screenShare: boolean,
        handleScreenShare: () => void,
    }
}
const ShareScreenBtn = ({ callSession }: ShareScreenBtnProps) => {
    return (
        <ControlBtn
            id="ctrl-btn-screen"
            active={callSession.networkQuality > TRYING_TO_CONNECT}
            onClick={callSession.handleScreenShare}
            className="disable-blur"
            label={callSession.screenShare ? 'Stop sharing' : 'Share screen'}
            eventCountly={countlyEvents.clickScreenShareButton}
        >
            <Icon name="share_screen" size={2} />
        </ControlBtn>
    );
}
export default ShareScreenBtn;