import React, { memo } from 'react';
import {
    DialogContentProps,
    DialogActionsProps
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import * as Styled from './styles';
import {
    ModalProps,
    ModalHeaderProps,
    ModalTitleProps,
    ModalSubtitleProps
} from './types';

export const Modal = memo(({ children, ...containerProps }: ModalProps) => {

    const theme = useTheme();
    const isFullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Styled.Modal fullScreen={isFullScreen} {...containerProps} >
            {children}
        </Styled.Modal>
    );
});

export const ModalTitle = memo(({ children, ...containerProps }: ModalTitleProps) => {
    return (
        <Styled.ModalTitle type="h5" {...containerProps} >
            {children}
        </Styled.ModalTitle>
    );
});

export const ModalSubtitle = memo(({ children, ...containerProps }: ModalSubtitleProps) => {
    return (
        <Styled.ModalSubtitle type="body2" {...containerProps} >
            {children}
        </Styled.ModalSubtitle>
    );
});

export const ModalHeader = memo(({ children, icon, onClose, ...containerProps }: ModalHeaderProps) => {
    return (
        <Styled.ModalHeader disableTypography {...containerProps} >
            {icon && <Styled.ModalIcon>{icon}</Styled.ModalIcon>}
            <Styled.ModalHeaderContent>{children}</Styled.ModalHeaderContent>
            {onClose &&
                <Styled.ModalClose data-testid="modal-close" aria-label="close" onClick={onClose} >
                    <CloseIcon fontSize="small" />
                </Styled.ModalClose>
            }
        </Styled.ModalHeader>
    );
});

export const ModalContent = memo(({ children, ...containerProps }: DialogContentProps) => {
    return (
        <Styled.ModalContent dividers {...containerProps}>
            {children}
        </Styled.ModalContent>
    );
});

export const ModalFooter = memo(({ children, ...containerProps }: DialogActionsProps) => {
    return (
        <Styled.ModalFooter {...containerProps}>
            {children}
        </Styled.ModalFooter>
    );
});