import React from 'react';
import * as Styled from './styles';
import type { HTMLAttributes } from 'react';

const MainScreen = ({children, ...containerProps}: HTMLAttributes<HTMLDivElement>) => { 
    return (
        <Styled.MainScreen {...containerProps}>
            {children}
        </Styled.MainScreen>
    );
}
export default MainScreen;

export const MainScreenShadow = ({...containerProps}: HTMLAttributes<HTMLDivElement>) => { 
    return (
        <Styled.MainScreenShadow {...containerProps} />
    );
}