import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import NavLink from "core/router/NavLink";
import { CheckPermission } from 'utils/Authorizer';
import Loading from "shared/Loading";
import { InputSearch } from "shared/inputs/InputSearch";
import { Clinic } from 'shared/types';
import { Button, Text, ButtonIcon, Table, TableBody, TableHead, TableRow, TableCell, TableContainer } from '@village/ui';
import { PlusIcon, TrashIcon, PencilIcon } from '@village/icons';
import * as Styled from 'shared/PageLayout';

type ListClinicsProps = RouteComponentProps & {
    search: string,
    clinics: Clinic[],
    loading: boolean,
    onSearch: React.ChangeEventHandler<HTMLInputElement>,
    onRemove: (clinic: Clinic) => React.MouseEventHandler<HTMLSpanElement>
}
class ListClinics extends React.Component<ListClinicsProps, {}> {
    constructor(props: ListClinicsProps) {
        super(props);
        this.state = {};
    }
    openForm = (clinicId: number | 'new') => () => {
        if (clinicId === 'new')
            return this.props.history.push('/clinics/new');
        else
            return this.props.history.push('/clinics/edit/' + clinicId);
    }
    render() {
        return (
            <>
                <Text type="h4">Clinics</Text>
                <Styled.ActionBar>
                    <Styled.SearchContainer>
                        <InputSearch
                            fullWidth
                            label=""
                            placeholder="Search"
                            value={this.props.search}
                            name="search"
                            onChange={this.props.onSearch}
                        />
                    </Styled.SearchContainer>
                    <CheckPermission act='POST' obj={'/clinics'} >
                        <Button size="medium" onClick={this.openForm('new')} startIcon={<PlusIcon size="md" />}>
                            Add new
                        </Button>
                    </CheckPermission>
                </Styled.ActionBar>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><Text type="caption1">Code</Text></TableCell>
                                <TableCell><Text type="caption1">Name</Text></TableCell>
                                <TableCell><Text type="caption1">Market</Text></TableCell>
                                <TableCell><Text type="caption1">Nr. of Providers</Text></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.clinics.map((clinic) =>
                                <TableRow key={clinic.id}>
                                    <TableCell><Text type="body1">{clinic.code}</Text></TableCell>
                                    <TableCell><Text type="body1">{clinic.name}</Text></TableCell>
                                    <TableCell><Text type="body1">{clinic?.market?.name}</Text></TableCell>
                                    <TableCell><Text type="body1">{clinic?.user_count}</Text></TableCell>
                                    <TableCell $action>
                                        <CheckPermission act='DELETE' obj={`/clinics/${clinic.id}`} >
                                            <ButtonIcon size="small" onClick={this.props.onRemove(clinic)} title='Delete clinic' >
                                                <TrashIcon name="delete" />
                                            </ButtonIcon>
                                        </CheckPermission>
                                        <CheckPermission act='PUT' obj={`/clinics/${clinic.id}`} >
                                            <NavLink to={"/clinics/edit/" + clinic.id} >
                                                <ButtonIcon size="small" title='Edit clinic'>
                                                    <PencilIcon name="edit" />
                                                </ButtonIcon>
                                            </NavLink>
                                        </CheckPermission>
                                    </TableCell>
                                </TableRow>
                            )}
                            {this.props.loading &&
                                <TableRow id="loading-row" >
                                    <TableCell colSpan={5} >
                                        <Loading />
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }
}
export default withRouter(ListClinics);
