import React from 'react';
import TestVideoPreview from '../elements/TestVideoPreview';
import TestMicrophone from '../elements/TestMicrophone';
import TestSpeaker from '../elements/TestSpeaker';
import SelectBackground from '../elements/SelectBackground';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import { ucfirst } from 'utils/Text';
import { adaptVersionsToSemver, isMobile } from 'utils/Basics';
import Feature from 'shared/Feature';
import FF from 'shared/constants/FF';
import { CallSessionContextState } from '../../CallSession/CallSessionContext';
import { OpentokPublisher } from '../../opentok/types';
import * as Styled from './styles';
import { CountlyKey } from 'countly/types/countly';
import { Modal, ModalContent } from 'village-ui/Modal';
import { Box } from '@material-ui/core';
import Select from 'shared/inputs/VmdSelect';

const semverLt = require('semver/functions/lt');
const platform = require('platform');


type SettingsModalProps = {
    callSession: CallSessionContextState,
    isOpen: boolean,
    onClose: () => void,
}
type SettingsModalState = {
    openModal: false,
    publisher?: OpentokPublisher,
}
class SettingsModal extends React.Component<SettingsModalProps, SettingsModalState> {
    constructor(props: SettingsModalProps) {
        super(props);
        this.state = {
            openModal: false,
        };
    }
    componentDidMount() {
        this.createSettingsPublisher()
            .then(publisher => {
                const callSession = this.props.callSession;
                // publisher.setVideoSource(callSession.videoSource);
                // publisher.setAudioSource(callSession.audioSource);
                publisher.publishVideo(!callSession.video);
                publisher.publishAudio(!callSession.audio);
                this.setState({ publisher: publisher })
            })
            .catch(error => {
                countlyAddEvent(countlyEvents.error, {
                    errorCode: error.name,
                    errorDescription: error.message,
                    comment: "Failed to create publisher on settings",
                });
            });
    }
    componentWillUnmount() {
        this.destroySettingsPublisher();
    }
    componentDidUpdate(prevProps: SettingsModalProps) {
        const callSession = this.props.callSession;
        const prevCallSession = prevProps.callSession;
        const publisher = this.state.publisher;

        if (callSession.videoSource !== prevCallSession.videoSource) {
            if (publisher) publisher.setVideoSource(callSession.videoSource);
        }
        if (callSession.audioSource !== prevCallSession.audioSource) {
            if (publisher) publisher.setAudioSource(callSession.audioSource);
        }
        if (callSession.video !== prevCallSession.video) {
            if (publisher) publisher.publishVideo(!callSession.video)
        }
        if (callSession.audio !== prevCallSession.audio) {
            if (publisher) publisher.publishAudio(!callSession.audio);
        }
        if (callSession.backgroundBlur !== prevCallSession.backgroundBlur && publisher) {
            if (callSession.backgroundBlur) {
                publisher?.applyVideoFilter({ type: 'backgroundBlur', blurStrength: "high" });
            } else {
                publisher?.clearVideoFilter();
            }
        }
    }
    handleChange = (name: string, value: string): void => {
        const countlyEventName = `set${ucfirst(name)}` as CountlyKey;
        if (countlyEventName in countlyEvents) {
            countlyAddEvent(countlyEventName, { [name]: value });
        }
        const callSession = this.props.callSession;
        callSession.setState({
            [name]: value
        });
    }
    isSpeakerSelectDisabled = (): boolean => {
        const callSession = this.props.callSession;
        return callSession.speakerDevices.length < 1;
    }
    createSettingsPublisher = (): Promise<OpentokPublisher> => {
        return (new Promise((resolve, reject) => {
            const fallbackPublisher = window.OT.initPublisher(undefined, {
                insertDefaultUI: false,
                mirror: false, //false - only for facingMode=environment (back camera)
                frameRate: 30, // 30, 15, 7, 1
                resolution: '640x480', // 1280x720(16:9), 640x480(4:3), 320x240(4:3).
                publishAudio: false,
                publishVideo: false,
            }, (error: any) => {
                if (!error) {
                    resolve(fallbackPublisher);
                } else {
                    reject(error);
                }
            });
        }));
    }
    destroySettingsPublisher = (): void => {
        if (this.state.publisher) {
            this.state.publisher.publishAudio(false);
            this.state.publisher.publishVideo(false);
            this.state.publisher.destroy();
        }
    }
    osSupportsReadVolume = (): boolean => {
        const platformInfo = platform.parse(window.navigator.userAgent);
        const osVersion = adaptVersionsToSemver(platformInfo.os.version)

        return platformInfo.os.family.toLowerCase() === "ios" && semverLt(osVersion, "15.0.0") ? false : true;
    }
    render() {

        const { isOpen, onClose, callSession } = this.props;
        const defaultSpeakers = [{ value: "default", label: "Default" }];

        return (
            <Modal
                id="settings"
                aria-labelledby="settings"
                open={isOpen}
                width="lg"
                height="auto"
            >
                <Styled.ModalHeader onClose={onClose}>
                    <Styled.ModalTitle type='h4'>Call settings</Styled.ModalTitle>
                </Styled.ModalHeader>
                <ModalContent>
                    <Styled.TestVideoContainer>
                        <TestVideoPreview
                            isVideoOn={callSession.video}
                            isBluring={callSession.backgroundBlur}
                            facingMode={callSession.facingMode}
                            publisher={callSession.publisher}
                            settingsPublisher={this.state.publisher}
                            videoSource={callSession.videoSource}
                        />
                    </Styled.TestVideoContainer>

                    <Box py={1.25}>
                        <Select
                            fullWidth
                            id="videoSource"
                            label="Camera"
                            name="videoSource"
                            options={callSession.cameraDevices}
                            value={callSession.videoSource}
                            onChange={this.handleChange}
                        />
                    </Box>

                    <Box py={1.25}>
                        <SelectBackground
                            value={callSession.backgroundBlur}
                            onChange={callSession.setBackgroundBlur}
                        />
                    </Box>

                    <Box py={1.25}>
                        <Box pb={2}>
                            <Select
                                fullWidth
                                id="audioSource"
                                label="Microphone"
                                name="audioSource"
                                options={callSession.microphoneDevices}
                                value={callSession.audioSource}
                                onChange={this.handleChange}
                            />
                        </Box>
                        <Feature item
                            code={FF.microphoneInSettingsPage}
                            condition={this.osSupportsReadVolume}
                        >
                            <TestMicrophone
                                isAudioOn={callSession.audio}
                                settingsPublisher={this.state.publisher}
                                audioSource={callSession.audioSource}
                            />
                        </Feature>
                    </Box>

                    {!isMobile() &&
                        <Box py={1.25}>
                            <Select
                                fullWidth
                                id="speaker"
                                label="Speaker"
                                name="speaker"
                                disabled={this.isSpeakerSelectDisabled()}
                                options={this.isSpeakerSelectDisabled() ? defaultSpeakers : callSession.speakerDevices}
                                value={callSession.speaker ? callSession.speaker : 'default'}
                                onChange={this.handleChange}
                            />
                        </Box>
                    }
                    <TestSpeaker />
                </ModalContent>
            </Modal>
        );
    }
}
export default SettingsModal;
