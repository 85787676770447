import React from 'react';
import withValidation from 'validations/withValidation';
import EmailInviteFormRules from 'validations/FormRules/EmailInviteFormRules';
import MessageInline from 'shared/MessageInline';
import withMessages from 'shared/Messages/withMessages';
import HttpClient from 'utils/HttpClient';
import InviteEmailTemplate from './templates/InviteEmailTemplate';
import InviteEmailLayoutTemplate from './templates/InviteEmailLayoutTemplate';
import InviteEmailTextLayoutTemplate from './templates/InviteEmailTextLayoutTemplate';
import Input from 'shared/inputs/Input2';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import ConfigFactory from 'ConfigFactory';
import { IValidation } from 'validations/types';
import { Validation, WaitingRoom } from 'shared/types';
import { IMessage, IToast, MessageType } from 'shared/Messages/types';
import { Box } from '@material-ui/core';
import { Modal, ModalHeader, ModalTitle, ModalContent, ModalFooter } from 'village-ui/Modal';
import { Button, Radio } from '@village/ui';
import { Icon } from 'icon';

enum EmailType {
    text = "text",
    html = "html",
}
type EmailInviteModalProps = IValidation & {
    isOpen: boolean,
    waiting_room: WaitingRoom,
    userData: any,
    onClose: () => void,
    showToast: (msg: IToast) => void,
}
type EmailInviteModalState = {
    [x: string]: any,
    loading?: boolean,
    email_method: "docOS" | "personal",
    email_message: string | null,
    patient_email: string,
    message: IMessage | null,
    waiting_room_url?: string,
}

class EmailInviteModal extends React.Component<EmailInviteModalProps, EmailInviteModalState> {
    constructor(props: EmailInviteModalProps) {
        super(props);
        this.state = {
            email_method: "docOS",
            email_message: null,
            patient_email: "",
            message: null,
        };
    }
    componentDidUpdate(prevProps: EmailInviteModalProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            // Reset state:
            this.setState({
                email_method: "docOS",
                email_message: null,
                patient_email: "",
                message: null,
            });
            this.props.validationReset();
        }
    }
    getOriginalEmailMessage = (): string => {
        return InviteEmailTemplate();
    }
    getEmailText = (): string => {
        return this.state.email_message ? this.state.email_message : this.getOriginalEmailMessage();
    }
    getEmailSubject = (): string => {
        return `Village Medical | ${this.props.waiting_room.name} has shared a link to access your video visit`;
    }
    getEmailMessage = (type = EmailType.html): string => {
        switch (type) {
            case EmailType.text:
                return this.getEmailTextMessage();

            case EmailType.html:
            default:
                return this.getEmailHtmlMessage();
        }
    }
    getEmailHtmlMessage = (): string => {
        const text = this.getEmailText().replace(/\n/g, "<br/>").replace(/%0d%0a/g, "<br/>");
        return InviteEmailLayoutTemplate({
            text: text,
            waitingRoomName: this.props.waiting_room.name,
            inviteLink: this.props.waiting_room.url,
        });
    }
    getEmailTextMessage = (): string => {
        const text = this.getEmailText();
        return InviteEmailTextLayoutTemplate({
            text: text,
            waitingRoomName: this.props.waiting_room.name,
            inviteLink: this.props.waiting_room.url,
        });
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const { name, value } = event.target;
        this.setState({ [name]: value }, () => this.props.validateLive(this.state));
    }
    handleSendEmail: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        return this.state.email_method === "personal" ? this.sendPersonalEmail() : this.sendDocOSEmail(event);
    }
    sendPersonalEmail = (): void => {
        countlyAddEvent(countlyEvents.sendingInviteEmail, {
            sentFromMailClient: "personal mail client",
            to: "unknown",
            subject: this.getEmailSubject(),
            isTextChanged: "unknown",
        });
        window.location.href = `mailto:?subject=${this.getEmailSubject()}&body=${encodeURIComponent(this.getEmailMessage())}`;
        this.props.onClose();
    }
    sendDocOSEmail = (event: React.MouseEvent): void => {
        event.preventDefault();
        if (!this.props.validate(this.state)) return;

        this.setState({
            loading: true,
            message: null
        });

        let apiName = 'telehealthApi';
        let path = '/providers/send_email_invite';
        const subject = this.getEmailSubject();
        const text = this.getEmailText()
        let hostname = ConfigFactory.getPatientHost();

        let postData = {
            reply_to: this.props.userData.email,
            to: this.state.patient_email,
            subject: subject,
            body: text,
            hostname: hostname,
            waiting_room_id: this.props.waiting_room.id,
        };

        countlyAddEvent(countlyEvents.sendingInviteEmail, {
            sentFromMailClient: "docoOS",
            to: postData.to,
            subject: postData.subject,
            isTextChanged: this.state.email_message !== this.getOriginalEmailMessage(),
        });

        HttpClient().post(apiName, path, postData)
            .then(() => {
                this.setState({
                    loading: false,
                    message: null,
                });
                this.props.showToast({
                    message: "Email invitation was sent successfully to the patient.",
                });
                this.props.onClose();
            }).catch((error) => {
                this.setState({
                    loading: false,
                    message: {
                        type: MessageType.danger,
                        message: (error.response?.data?.detail || "Failed to mail text message"),
                    }
                });
                countlyAddEvent(countlyEvents.error, {
                    errorCode: error.name,
                    errorDescription: error.message,
                    comment: "Failed to mail text message",
                    response: JSON.stringify(error.response)
                });

                document
                    .querySelector('#email-invite-modal section.modal-card-body')
                    ?.scrollTo({ top: 0, behavior: 'smooth' })
            });
    }
    render() {

        const validations = this.props.validations === true ? {} : this.props.validations;
        const message = this.state.message;

        return (
            <Modal
                id="email-invite-modal"
                onClose={this.props.onClose}
                aria-labelledby="email-invite-modal"
                open={this.props.isOpen}
                width="lg"
                height="auto"
            >
                <ModalHeader icon={<Icon name="mail" size={1.5} />} onClose={this.props.onClose} >
                    <ModalTitle>Invite with email</ModalTitle>
                </ModalHeader>
                <ModalContent>
                    {message && <MessageInline type={message.type} message={message.message} />}
                    <div>
                        <Radio
                            name="email_method"
                            value="docOS"
                            checked={this.state.email_method === "docOS"}
                            onChange={this.handleChange}
                            label="Send from docOS"
                        />
                        &nbsp;&nbsp;
                        <Radio
                            name="email_method"
                            value="personal"
                            checked={this.state.email_method === "personal"}
                            onChange={this.handleChange}
                            label="Use my personal email client"
                        />
                    </div>

                    {this.state.email_method === "docOS" ?
                        <React.Fragment>
                            <Box py={2}>
                                <Input
                                    label='Patient email'
                                    type='text'
                                    name='patient_email'
                                    fullWidth
                                    value={this.state.patient_email}
                                    onChange={this.handleChange}
                                    validation={validations?.patient_email as Validation}
                                />
                            </Box>
                            <Box py={2}>
                                <Input
                                    label='Waiting room URL'
                                    type='text'
                                    name='waiting_room_url'
                                    fullWidth
                                    value={this.props.waiting_room.url}
                                    onChange={() => { }}
                                    readOnly
                                />
                            </Box>
                            <Box py={2}>
                                <Input
                                    label='Message'
                                    type='textarea'
                                    name='email_message'
                                    fullWidth
                                    value={this.getEmailText().replace(/%0d%0a/g, "\n")}
                                    onChange={this.handleChange}
                                    rows={10}
                                />
                            </Box>
                        </React.Fragment>
                        : null}
                </ModalContent>
                <ModalFooter>
                    <Button variant="secondary" size="medium" onClick={this.props.onClose} >
                        Cancel
                    </Button>
                    <Button size="medium" onClick={this.handleSendEmail} >
                        Send
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default withMessages(withValidation(EmailInviteModal, EmailInviteFormRules));
