import styled from 'styled-components/macro';
import { AvatarWrapper } from 'shared/Avatar/styles';

export const VideoAvatar = styled.div`
    background-color: #24282e;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    ${AvatarWrapper} {
        width: 72px;
        height: 72px;

        @media (min-width: 768px) {
            width: 112px;
            height: 112px;
            
            img {
                width: 100%;
            }
        }
    }
`;