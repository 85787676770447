import { rem } from 'polished';
import { defaultVillageUiTheme as theme } from '@village/ui';
import styled from 'styled-components/macro';
import { Color } from 'shared/types';

type AvatarWrapperProps = {
    avatarColor?: Color
}

export const AvatarWrapper = styled.div<AvatarWrapperProps>`
    height: ${rem(44)};
    width: ${rem(44)};
    border-radius: ${rem(80)};
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ avatarColor }) => avatarColor && `background-color: ${avatarColor}`}
`;

export const AvatarText = styled.div`
    color: ${theme.vui.colors.text.emphasis};
    font-size: ${rem(14)};
    letter-spacing: 0;
`;