import React from 'react';
import { Input } from '@village/ui';
import type { InputProps } from '@village/ui';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

export const InputSearch = (props: InputProps) => {
    if (props.type === 'textarea') return <></>;
    return (
        <Input
            label="label"
            type="text"
            startAdornment={
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
            }
            placeholder="Placeholder"
            {...props}
        />
    );
}