import { countlyRemoteConfig } from 'countly';
import FF from './constants/FF';
import { isMobile } from 'utils/Basics';


type FeatureConditionProps = {
    onlyMobile?: boolean,
    onlyDesktop?: boolean,
    condition?: () => boolean,
}

type FeatureProps = FeatureConditionProps & {
    code: FF,
    item?: boolean,
    children: JSX.Element
}

const Feature = (props: FeatureProps): JSX.Element | null => {
    return (
        props.item ? Feature.item(props)
            : Feature.has(props.code, props) ? props.children
                : null
    );
};
Feature.has = (code: FF, props: FeatureConditionProps = {}): boolean => {
    //Implement logic to check if feature is enabled/disabled
    return (
        Feature.isDeviceEnabled(props)
        && Feature.isEnabled(code)
        && Feature.evaluateCondition(props)
    );
};
Feature.hasNot = (code: FF, props = {}): boolean => {
    return !Feature.has(code, props);
};
Feature.item = (props: FeatureProps): JSX.Element | null => {
    return Feature.has(props.code, props) ? props.children : null
};
Feature.isEnabled = (featureCode: FF): boolean => {
    return countlyRemoteConfig(featureCode) as boolean;
}
Feature.isDeviceEnabled = (props: FeatureConditionProps = {}): boolean => {

    let device = null;
    if (props.onlyMobile) {
        device = "onlyMobile";
    } else if (props.onlyDesktop) {
        device = "onlyDesktop";
    }

    switch (device) {
        case "onlyMobile":
            return isMobile();

        case "onlyDesktop":
            return !isMobile();

        default:
            return true;
    }
}
Feature.evaluateCondition = (props: FeatureConditionProps): boolean => {
    if (props.condition) return props.condition();
    return true;
}
export default Feature;