import React from 'react';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import BoxedLayout from 'layouts/BoxedLayout';

class WaitingRoomNotFound extends React.Component<{}, {}> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        countlyAddEvent(countlyEvents.pageWaitingRoomNotFound);
    }
    render() {
        return (
            <BoxedLayout>
                <div className="box d-flex flex-column" >
                    <div className="d-flex flex-row align-items-center flex-grow-1">
                        <div className="column">
                            <img className="is-horizontal-center ic-band-aid" src="/ic-band-aid.svg" alt="village medical oops band aid icon" />
                            <p className="gray-90 has-text-centered has-text-weight-bold is-size-5">
                                Oops!
                            </p>
                            <div id="oops-message" className="gray-80 has-text-centered is-size-6 mb-4" >
                                It appears that room doesn't exist
                            </div>
                        </div>
                    </div>
                </div>
            </BoxedLayout>
        );
    }
}
export default WaitingRoomNotFound;