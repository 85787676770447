import type { FormRule } from '../types';

const FormRules: FormRule[] = [
	{
		code: 'patient_email',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: "Patient email is required",
				rule: ['required'],
			},
			email: {
				type: 'error',
				error_type: 'hard',
				message: 'This is not a valid email',
				rule: ['email'],
			}
		}
	},
];

export default FormRules;