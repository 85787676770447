import React from 'react';
import StateRouterContext from './StateRouterContext';
import StateRouterHistory from './StateRouterHistory';

type StateRouterProps = {
    view?: string,
    children: JSXElement,
}
type StateRouterState = {
    view?: string
}
class StateRouter extends React.Component<StateRouterProps, StateRouterState> {
    constructor(props: StateRouterProps){
        super(props);
        this.state = {view: props.view};
    }
    render(){
        return (
            <StateRouterContext.Provider value={{
                view: this.state.view,
                history: (new StateRouterHistory(this)),
            }} >
                {this.props.children}
            </StateRouterContext.Provider>
        );
    }
}
export default StateRouter;