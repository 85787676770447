import React from 'react';
import MessagesContext from './MessagesContext';
import type { IMessage, IToast, Message, Toast } from './types';
var uuid4 = require('uuid4');


type MessagesProviderProps = {
    children: React.ReactElement
}
type MessagesProviderState = {
    messages: Message[],
    toasts: Toast[],
}
class MessagesProvider extends React.Component<MessagesProviderProps, MessagesProviderState> {

    state = {
        messages: [],
        toasts: []
    };

    render() {
        return (
            <MessagesContext.Provider
                value={{
                    messages: this.state.messages,
                    toasts: this.state.toasts,
                    showToast: (msg: IToast) => {
                        this.setState((prevState) => {
                            return {
                                toasts: [...prevState.toasts, {
                                    ...msg,
                                    uuid: uuid4(),
                                }],
                            }
                        });
                    },
                    showMessage: (msg: IMessage) => {
                        this.setState((prevState) => {
                            return {
                                messages: [...prevState.messages, {
                                    ...msg,
                                    uuid: uuid4(),
                                }],
                            }
                        });
                    },
                    dismissMessage: (uuid: string) => {
                        this.setState((prevState) => {
                            return {
                                messages: prevState.messages.filter((msg)=> msg.uuid !== uuid),
                            };
                        });
                    },
                    dismissToast: (uuid: string) => {
                        this.setState((prevState) => {
                            return {
                                toasts: prevState.toasts.filter((msg)=> msg.uuid !== uuid),
                            };
                        });
                    },
                    dismissByTag: (tag: string) => {
                        this.setState((prevState) => {
                            return {
                                toasts: prevState.toasts.filter((msg)=> msg.tag !== tag),
                                messages: prevState.messages.filter((msg)=> msg.tag !== tag),
                            };
                        });
                    }
                }}
            >
                {this.props.children}
            </MessagesContext.Provider>
        );
    }
}
export default MessagesProvider;