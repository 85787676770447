import { createContext } from 'react';
import StateRouterHistory from './StateRouterHistory';

type StateRouterContextState = {
    view?: string,
    history: StateRouterHistory | null,
}
export type { StateRouterContextState }

const StateRouterContext = createContext<StateRouterContextState>({
    history: null,
});

export default StateRouterContext;
