import React from 'react';
import './EnvironmentBanner.scss';

interface EnvironmentBannerProps {
    env: string
}

const EnvironmentBanner = ({ env }: EnvironmentBannerProps) => {
    return (
        env === 'production' ? null :
            <div id="env-banner" className={"banner " + env}>
                <b>Attention:</b> You are in <span id="env-name">{env.toUpperCase()}</span> environment.
            </div>
    );
};

export default EnvironmentBanner;