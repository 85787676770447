import React from 'react';
import { Autocomplete } from 'village-ui/Autocomplete';
import type { Validation } from 'shared/types';
import type { OptionBase, OptionGroupBase, Props as SelectAsyncProps } from 'village-ui/Autocomplete';
import { SingleValue, MultiValue, ActionMeta } from 'react-select';

interface Option extends OptionBase {};
interface OptionGroup extends OptionGroupBase<Option> {};

type SelectMultipleValue = MultiValue<OptionBase>
type SelectMultipleOption = MultiValue<OptionBase>
type ActionMetaOption = ActionMeta<OptionBase>

type SelectMultipleProps = Omit<SelectAsyncProps<Option, OptionGroup>, 'onChange'> & {
    name: string,
    disabled?: boolean,
    validation?: Validation,
    onChange: (name: string, value: SelectMultipleOption, actionMeta: ActionMetaOption) => void,
}
export type { SelectMultipleProps, SelectMultipleValue, SelectMultipleOption, ActionMetaOption }

const SelectMultiple = ({
    name, 
    disabled, 
    validation,
    onChange,
    ...props
}: SelectMultipleProps) => {

    const handleChange = (option: MultiValue<OptionBase> | SingleValue<OptionBase>, actionMeta: ActionMetaOption) => {
        onChange(name, option as SelectMultipleOption, actionMeta);
    }

    return (
        <Autocomplete
            isMulti
            isClearable={false}
            isDisabled={disabled}
            onChange={handleChange}
            error={validation && validation.message}
            {...props}
        />
    );
}
export default SelectMultiple;