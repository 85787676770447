import React from 'react';
import WaitScreen from './WaitScreen';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import { Provider, IWaitingRoom } from 'shared/types';

type Props = {
    firstName: string,
    lastName?: string,
    provider: Provider,
    waiting_room: IWaitingRoom,
}
class WaitingRoom extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        countlyAddEvent(countlyEvents.pageWaitingRoom);
    }
    render() {
        return (
            <WaitScreen
                title={`Welcome, ${this.props.firstName}!`}
                subtitle="You're in the waiting room"
                pill={{
                    text: "We're preparing for your visit",
                    color: "green",
                }}
                provider={this.props.provider}
                waiting_room={this.props.waiting_room}
            />
        );
    }
}
export default WaitingRoom;
