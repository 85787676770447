import styled from 'styled-components/macro';
import { rem } from 'polished';
import { Card, Divider } from '@village/ui';
import VmdSelect from 'shared/inputs/VmdSelect';

export const InviteCard = styled(Card)`
    margin-bottom: ${rem(24)};
`;

export const InviteCardBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: ${rem(8)};
    padding-top: ${rem(20)};
    button {
        white-space: nowrap
    }
`;

export const VerticalDivider = styled(Divider)`
    margin: ${rem(12)} 0;
`;

export const Select = styled(VmdSelect)`
    flex-grow: 1;

    .MuiInputBase-root {
        height: ${rem(48)};
    }
`;