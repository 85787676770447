import React from 'react';
import EnvironmentBanner from '../shared/EnvironmentBanner';
import ConfigFactory from '../ConfigFactory';
import Feature from '../shared/Feature';
import FF from '../shared/constants/FF';
import SystemMessage from '../shared/SystemMessage';
import './BoxedLayout.scss';


type Props = {
    children: JSXElement,
    onLogoClick?: (event: React.MouseEvent) => void,
}
const BoxedLayout = ({ children, onLogoClick }: Props) => {
    return (
        <div className='boxed-page'>
            <EnvironmentBanner env={ConfigFactory.getEnv()} />
            <Feature item code={FF.messagePatientTop} >
                <SystemMessage keyName="message_patient_top" />
            </Feature>
            <div className="boxed-container">
                <div id="boxed-vmd-logo" className={`is-hidden-mobile ${onLogoClick ? "pointer" : ""}`} onClick={onLogoClick} >
                    <figure>
                        <img src="/village-medical-light-logo-60.png" width="155" height="28" alt="village medical logo" />
                    </figure>
                </div>
                <div className="boxed-centered">
                    <div className="boxed-content">
                        <div className='boxed-content-padding'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default BoxedLayout;
