import React from 'react';
import { Modal, ModalHeader, ModalTitle, ModalContent } from 'village-ui/Modal';
import { Text, Link } from '@village/ui';
import { Icon } from 'icon';
import * as Styled from './styles';
import urls from 'assets/urls';

type Props = {
    isOpen: boolean,
    onClose: () => void
}
const FeedbackModal = ({ isOpen, onClose }: Props) => {

    return (
        <Modal
            id="feedback-modal"
            aria-labelledby="feedback-modal"
            open={isOpen}
            onClose={onClose}
            width="xl"
            height="auto"
        >
            <ModalHeader onClose={onClose}>
                <ModalTitle>What can we help you with?</ModalTitle>
            </ModalHeader>
            <ModalContent>
                <Styled.Section>
                    <Icon name="info" size={1.5} />
                    <Styled.SectionContent>
                        <Text type='h6'>I want to report an issue or I need help with access</Text>
                        <Text type='body1'>
                            <Styled.SectionContentList>
                                <li>For urgent matters, contact your <b>Clinic Support Staff</b></li>
                                <li>
                                    To report an issue or for other questions, please submit a&nbsp;
                                    <Link href={urls.URL_TSD} target="_blank">
                                        Technology Service Desk ticket
                                    </Link>
                                </li>
                            </Styled.SectionContentList>
                        </Text>
                    </Styled.SectionContent>
                </Styled.Section>
            </ModalContent>
        </Modal>
    );
}
export default FeedbackModal;
