import React from 'react';
import { Prompt } from 'react-router-dom';
import { NO_CONNECTION } from 'utils/Network/constants';
import { Provider, WaitingRoom } from 'shared/types';
import WaitingRoomScreen from './components/WaitingRoom';
import HoldRoom from './components/HoldRoom';
import ProviderDisconnectedRoom from './components/ProviderDisconnectedRoom';
import InCallScreen from '../../../CallScreen/InCallScreen';
import CallContainer from '../../../CallScreen/CallContainer';
import CallControls from '../../../CallScreen/CallControls';
import withCallSession from '../../../CallScreen/CallSession/withCallSession';
import { CallSessionContextState } from '../../../CallScreen/CallSession/CallSessionContext';

type Props = {
    callSession: CallSessionContextState, 
    provider: Provider,
    waiting_room: WaitingRoom, 
    firstName: string,
    lastName: string,
    isOnHold: boolean,
    onLeave: () => void,
}
type State = {
    shouldWarn: boolean
}
class PatientCallContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            shouldWarn: true,
        };
        this.warnOnReload = this.warnOnReload.bind(this);
    }
    componentDidMount() {
        window.addEventListener('beforeunload', this.warnOnReload);
    }
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.warnOnReload);
    }
    warnOnReload(e: any) {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = "Leaving this page will reset the wizard";
    }
    render() {
        const { callSession, waiting_room, provider } = this.props;
        const publisherName = this.props.firstName + ' ' + this.props.lastName;

        return (
            <CallContainer 
                userType='patient'
                callSession={callSession} 
                containerClassName={callSession.streams.length === 0 && "waiting-room"} 
                publisherName={publisherName}
                onLeave={this.props.onLeave}
            >
                <Prompt 
                    when={this.state.shouldWarn} 
                    message="Are you sure you want to leave? This will end your call in progress" 
                />
                {callSession.streams.length > 0 ?
                    <InCallScreen 
                        userType='patient' 
                        callSession={callSession}
                    />
                    : this.props.isOnHold ?
                        <HoldRoom provider={provider} waiting_room={waiting_room} />
                        : callSession.subscriberNetworkQuality === NO_CONNECTION ?
                            <ProviderDisconnectedRoom provider={provider} waiting_room={waiting_room} />
                        : <WaitingRoomScreen
                            firstName={this.props.firstName}
                            lastName={this.props.lastName}
                            provider={provider}
                            waiting_room={waiting_room}
                        />
                }
                <CallControls callSession={callSession} />
            </CallContainer>
        );
    }
}
export default withCallSession(PatientCallContainer);
