import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import consumeApp from '../../providers/consumeApp';

interface MyRouteProps extends RouteProps {
	private?: boolean,
	isLoggedIn?: boolean,
	redirectTo?: string,
	onlyPublic?: boolean,
	component?: any,
	children?: any,
}

const MyRoute = (props: MyRouteProps) => {

	let componentRouteProps: { [key: string]: any } = Object.assign({}, props);
	const routeProps: RouteProps = {};

	if (typeof props.path !== 'undefined') {
		routeProps.path = props.path;
		delete componentRouteProps.path;
	}
	if (typeof props.exact !== 'undefined') {
		routeProps.exact = props.exact;
		delete componentRouteProps.exact;
	}
	if (typeof props.strict !== 'undefined') {
		routeProps.strict = props.strict;
		delete componentRouteProps.strict;
	}
	if (typeof props.location !== 'undefined') {
		routeProps.location = props.location;
		delete componentRouteProps.location;
	}
	if (typeof props.sensitive !== 'undefined') {
		routeProps.sensitive = props.sensitive;
		delete componentRouteProps.sensitive;
	}
	if (typeof props.component !== 'undefined') {
		delete componentRouteProps.component;
	}
	if (typeof props.children !== 'undefined') {
		delete componentRouteProps.children;
	}

	let componentProps: { [key: string]: any } = {};
	Object.keys(componentRouteProps).map((property) => {
		if (['title'].indexOf(property) < 0) {
			componentProps[`${property}`] = componentRouteProps[`${property}`];
			delete componentRouteProps[`${property}`];
		}
		return property;
	});

	// const addReferer = () => {
	// 	return '?ref=' + encodeURIComponent(btoa(window.location.pathname+window.location.search));
	// };

	const routeRender = (renderProps: any) => {
		if (props.private && !props.isLoggedIn) {
			window.sessionStorage.setItem("refUri", window.location.pathname+window.location.search);
			return (props.redirectTo && <Redirect to= {props.redirectTo} />)
		} else if (props.onlyPublic && props.isLoggedIn) {
			const ref = window.sessionStorage.getItem("refUri");
			window.sessionStorage.removeItem("refUri");
			return (props.redirectTo && <Redirect to= { ref ? ref : props.redirectTo } />)
		} else {
			if (props.children) {
				return React.createElement(
					props.component,
					{ ...renderProps, route: componentRouteProps, ...componentProps },
					props.children
				)
			} else {
				return React.createElement(
					props.component,
					{ ...renderProps, route: componentRouteProps, ...componentProps }
				)
			}
		}
	}

	return (<Route {...routeProps} render={ routeRender } />);
};
export default consumeApp(MyRoute);