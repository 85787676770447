import React from 'react';
import HttpClient from 'utils/HttpClient';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events'
import { debounce } from 'utils/Basics';
import { SingleValue, MultiValue } from 'react-select';
import { Autocomplete } from 'village-ui/Autocomplete';
import type { OptionBase, OptionGroupBase, Props as SelectAsyncProps } from 'village-ui/Autocomplete';
import type { SelectOption, Validation, WaitingRoom } from 'shared/types';


interface Option extends OptionBase {};
interface OptionGroup extends OptionGroupBase<Option> {};
type SelectWaitingRoomProps = Omit<
    SelectAsyncProps<Option, OptionGroup>, 
    'onChange' | 
    'defaultOptions' | 
    'loadOptions'
> & {
    name: string,
    validation?: Validation,
    onChange: (name: string, value: SelectOption | undefined) => void,
}

interface SelectWaitingRoomState {
    loading: boolean,
    waiting_rooms: WaitingRoom[]
}

class SelectWaitingRoom extends React.Component<SelectWaitingRoomProps, SelectWaitingRoomState> {
    constructor(props: SelectWaitingRoomProps){
        super(props);
        this.state = {
            loading: false,
            waiting_rooms: []
        };
    }
    componentDidMount(){
        this.loadOptions();
    }
    handleChangeSelect = (option: MultiValue<OptionBase> | SingleValue<OptionBase>) => {
        this.props.onChange(this.props.name, option as SelectOption)
    }
    handleLoadOptions = (search: string, callback: (options: any) => void) => {
        debounce(() => this.loadOptions(search, callback), 400);
    }
    loadOptions = (search: string = "", callback?: (options: any) => void) => {
        let apiName = 'telehealthApi';
        let path = '/waiting_rooms'; 
        let queryParams = {
            skip: 0,
            limit: 30,
            search: search
        };
        this.setState({
            loading: true
        });
        HttpClient().get(apiName, path, queryParams)
        .then( (data: any) => {
            this.setState({
                loading: false,
                waiting_rooms: data
            }, () => {
                if(callback) callback(this.getWRsToSelectOptions());
            });

        }).catch((error) => {
            this.setState({
                loading: false
            });
            countlyAddEvent(countlyEvents.error, {
                errorCode: error.name,
                errorDescription: error.message,
                comment: "Error on loading waiting_rooms on selectWaitingRooms",
            });
        });
    }
    getWRsToSelectOptions = (): SelectOption[] => {
        return this.state.waiting_rooms.map((waiting_room) => {
            return {
                value: waiting_room.id,
                label: waiting_room.name,
                item: waiting_room,
            };
        })
        // sort options by label
        .sort((a, b) => (a.label > b.label) ? 1 : -1);
    }
    render(){
        const { validation, ...props } = this.props;
        return (
            <Autocomplete
                {...props}
                onChange={this.handleChangeSelect}
                defaultOptions={this.getWRsToSelectOptions()}
                loadOptions={this.handleLoadOptions}
                error={validation && validation.message}
            />
        );
    }
}
export default SelectWaitingRoom;