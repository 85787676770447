import React, { ReactNode } from 'react';
import { Icon } from 'icon';
import './MessageInline.scss'
import { MessageType } from './Messages/types';

interface MessageInlineProps {
    type: MessageType,
    title?: string,
    message: string | ReactNode,
}

const MessageInline = ({type, title, message}: MessageInlineProps) => {

    let messageType = `is-${type}`;
    return(
        <article className={`message ${messageType}`} >
            {MessageType.info === type &&
                <div className="icon-container" >
                    <Icon name={"info"} size={1.25} />
                </div>
            }
            {title &&
                <div className="message-header">
                    <p>{title}</p>
                </div>
            }
            <div className="message-body">
                {message}
            </div>
        </article>
    );
}
export default MessageInline;