import React from 'react';
import images from 'assets/images';


const TroubleshootChromeDesktop = () => {
    return (
        <div>
            <h4 className="gray-90 text-h5-to-h4-medium mb-4">
                Chrome Desktop on Windows or MacOS
            </h4>
            <p className="is-size-7 has-text-weight-medium">
                Follow these steps to fix the issue:
            </p>
            <div>
                <p>1. Click the Lock icon on the far left of the address bar.</p>
                <img src={images.chrome_lock} alt="Chrome Lock" />
            </div>
            <hr/>
            <div>
                <p>2. Use the dropdowns to change both the Camera and Microphone statuses to Allow.</p>
                <img src={images.chrome_allow} alt="Chrome Allow" />
            </div>
            <hr/>
            <div>
                <p>3. Return to the link your provider emailed or texted to you and follow the instructions to join the virtual visit.</p>
                <img src={images.text_message} alt="Text Message" />
            </div>
            <hr/>
            <div>
                <p>4. When prompted to allow access to the camera and microphone, click Allow.</p>
                <img src={images.ios_chrome_prompt} alt="iOS/Chrome Prompt" />
            </div>
        </div>
    );
};
export default TroubleshootChromeDesktop;