import React from 'react';
import { Text } from '@village/ui';
import type { PillProps } from './types';
import * as Styled from './styles';


const Pill = (props: PillProps) => {
    return (
        <Styled.Pill className={props.color} >
            <Text type="body2">{props.text}</Text>
        </Styled.Pill>
    );
}
export default Pill;