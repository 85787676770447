enum FF {
    pauseButton = 'enable_provider_pause',
    shareScreen = 'enable_provider_share_screen',
    providerNotification = "enable_provider_notification",
    videoInSettingsPage = "enable_video_in_settings_page",
    microphoneInSettingsPage = "enable_microphone_in_settings_page",
    audioOnlyCall = "enable_audio_only",
    removePatient = "enable_remove_patient",
    waitingTime = "enable_waiting_time",
    messagePatientTop = "enable_message_patient_top",
    messageProviderTop = "enable_message_provider_top",
    unstableConnectionTest = "enable_unstable_connection_test",
    networkMetrics = "enable_network_metrics",
    videoQuality = "enable_video_quality",
    autoAdjustVideoQuality = "enable_auto_adjust_video_quality",
    reports = "enable_reports",
    providerSurvey = "enable_provider_survey",
}
export default FF