import Rules from './Rules';
import type {
    RuleName,
    ValidationMode,
    Rule,
    FormRuleInput,
    FormRuleComplex,
    FormRule,
    Validations,
} from './types';


const Validate = {
	filterSoftRules: (formRules: FormRule[]): FormRule[] => {
		const newRules: FormRule[] = [];
		formRules.forEach((element) => {

			switch(element.element_type){
				case "arrayComplex":
				case "complex":
					const complexInputRules: FormRuleComplex = { 
						...element,
						inputs: Validate.filterSoftRules(element.inputs)
					};
					if (complexInputRules.inputs.length > 0) {
						newRules.push(complexInputRules);
					}
				break;

				case "input":
				default:
					const inputRules: FormRuleInput = { ...element, rules: {} };
					for (const ruleName in element.rules) {
						const rule: Rule = element.rules[ruleName];
						if (rule.error_type === 'soft') {
							inputRules.rules[ruleName] = rule;
						}
					}
					if (Object.keys(inputRules.rules).length > 0) {
						newRules.push(inputRules);
					}
				break;
			}
		});
		return newRules;
	},
	check: (rules: FormRule[], values: any, mode: ValidationMode = null): Validations | true => {
		if (!values) return true;

		const validations: Validations = {};
		rules.forEach((el: FormRule) => {
			if (el.element_type === 'arrayComplex') {
				let arrayComplexValidations: Validations[] = [];
				let itemComplexValidation: any;
				
				values[el.code].forEach((itemComplex: any, i: number) => {
					itemComplexValidation = Validate.check(el.inputs, itemComplex);
					if (itemComplexValidation !== true) {
						arrayComplexValidations[i] = itemComplexValidation;
					}
				});

				if (arrayComplexValidations.length > 0) {
					validations[el.code] = arrayComplexValidations;
				}

			} else if (el.element_type === 'complex') {
				const complexValidations = Validate.check(el.inputs, values[el.code]);
				if (complexValidations !== true) {
					validations[el.code] = complexValidations;
				}
			} else if (el.rules) {
				Object.keys(el.rules).forEach((ruleIdentity: string) => {
					if(!el.rules) return;

					const rule: Rule = el.rules[ruleIdentity]
					
					// If mode is set then do validation only 
					// on the specific mode for the current rule
					if (mode && rule.on && rule.on.indexOf(mode) < 0) {
						return;
					}

					const ruleParams = rule.rule;
					const ruleName: RuleName = ruleParams[0]; //RuleName Ex: Rules.{RuleName}
					const args = ruleParams.slice(1);
					const currentValue = values[el.code];

					if (Rules[ruleName](currentValue, values, args[0], args[1]) === false) {
						if (ruleName === 'required') {
							validations[el.code] = rule;
						} else if (ruleName === 'customRule') {
							validations[el.code] = rule;
						} else if (!empty(currentValue)) {
							validations[el.code] = rule;
						}
					}

				});
			}
		});

		if (Object.keys(validations).length < 1) return true;

		return validations;
	},
};
export default Validate;

function empty(v: any, trim: boolean = false): boolean {
	/*
	 * Value '0'(zero) is not an empty
	 * */

	if (Array.isArray(v) && v.length === 0) {
		return true;
	} else if (Array.isArray(v) && v.length > 0) {
		return false;
	}

	if (trim && typeof v === 'string') {
		v = v.trim();
	}
	if (typeof v === 'undefined' || v === null || v === '' || (typeof v === 'object' && v.constructor === 'Object' && Object.keys(v).length === 0)) {
		return true;
	}
	return false;
}