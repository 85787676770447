import React from 'react';
import ReactLoading, {LoadingType} from "react-loading";
import MaterialSpinner from 'react-spinner-material';
import BulmaSpinner from './BulmaSpinner';
import type { Color } from '../types';
import * as Styled from './styles';

interface LoadingProps {
    type: LoadingType | "BulmaSpinner" | "mui-spinner",
    color: Color,
    delay: number,
    width: number,
    height: number,
    radius?: string,
    stroke?: string,
}

const Loading = (props: LoadingProps) => {
    return (
        <Styled.LoadingContainer data-testid="loading">
            {props.type === 'BulmaSpinner' ?
                <BulmaSpinner
                    width={props.width}
                    height={props.height}
                    color={props.color}
                />
                : props.type === 'mui-spinner' ?
                    <MaterialSpinner 
                        radius={props.radius ? props.radius : 30} 
                        stroke={props.stroke ? props.stroke : 3} 
                        color={props.color}
                        visible={true} 
                    />
                    :
                    <ReactLoading
                        type={props.type}
                        delay={props.delay}
                        width={props.width}
                        height={props.height}
                        color={props.color}
                    />
            }
        </Styled.LoadingContainer>

    );
}
Loading.defaultProps = {
    type: "mui-spinner",
    delay: 0,
    width: 34,
    height: 34,
    color: "#007bff",
};
export default Loading;