import WebSocket from 'isomorphic-ws';
import qs from 'qs';
import uuid4 from 'uuid4';
import ConfigFactory from 'ConfigFactory';
import WSMessage from './WSMessage';
import WS_CODE from 'shared/constants/WS_CODE'; // eslint-disable-line no-lone-blocks
import { WEBSOCKET_TIMEOUT_MS, HEARTBEAT_INTERVAL_MS } from "shared/constants/CONFIG_VARS"; // eslint-disable-line no-lone-blocks
import HttpClient from '../HttpClient';
import type { WSEvent, IWSMessage, WSsender, IWebsocket } from './types';
import type { QueryParams } from 'shared/types';
/*
    Resources:
        https://aws.amazon.com/blogs/compute/announcing-websocket-apis-in-amazon-api-gateway/
        https://docs.aws.amazon.com/apigateway/latest/developerguide/apigateway-how-to-call-websocket-api-connections.html
        https://github.com/serverless/serverless/issues/6149
        https://docs.aws.amazon.com/apigateway/latest/developerguide/apigateway-websocket-control-access-iam.html
        https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
        https://medium.com/build-succeeded/using-web-socket-aws-api-gateway-to-allow-your-event-driven-lambda-based-api-to-push-data-to-your-212855db344b
        https://medium.com/@sumindaniro/secure-aws-api-gateway-using-a-lambda-authorizer-40a1da825b16
        https://www.youtube.com/watch?v=3SCdzzD0PdQ

        https://medium.com/@kavitanambissan/real-time-updates-in-your-react-app-with-amazon-websocket-api-gateway-aws-lambda-mongodb-6341a4dd57d5
        https://medium.com/@kavitanambissan/react-chat-app-with-amazon-websocket-api-gateway-and-aws-lambda-part-ii-c9b10f114a13
        https://github.com/kvnam/rc-chat-front/tree/master/src

        // Chat
        https://codeburst.io/how-to-build-a-react-chat-app-with-aws-api-gateway-websockets-and-cognito-custom-authorizer-6f84f2da47ec

*/

class MySocket implements IWebsocket {
    
    ws?: typeof WebSocket;
    listeners: any = {};
    queueMessages: any[] = [];
    closed: boolean = true;
    onOpenHandler: Function[] = [];
    onCloseHandler: Function[] = [];
    connectHandler?: () => void;
    sender: WSsender = "http";
    userId?: number;
    heartbeat: any = null;

    setUser = (userId: number): void => {
        this.userId = userId;
    }
    runHeartbeat(): void {
        this.heartbeat = setInterval(() => {
            this.sendWs({ action: "heartbeat" });
        }, HEARTBEAT_INTERVAL_MS);
    }
    stopHeartbeat(): void {
        if (this.heartbeat) clearInterval(this.heartbeat);
    }
    connect = (handler: () => void): void => {
        this.connectHandler = handler;
        handler();
    }
    reconnect = (): void => {
        if (this.connectHandler) this.connectHandler();
    }
    _buildWsUrl(queryParams: QueryParams): string {
        const wsEndpoint = ConfigFactory.getEndpoint('teleHealthSocket');
        queryParams.connection_id = uuid4();
        const wssUrl = wsEndpoint?.endpoint + (queryParams ? '?' + qs.stringify(queryParams) : '');
        
        return wssUrl;
    }
    iniSocket = (queryParams: QueryParams) => {
        const wssUrl = this._buildWsUrl(queryParams);
        this.onOpen(this.resumeSending);

        this.ws = new WebSocket(wssUrl);
        this.ws.onopen = (openEvent: any) => {
            this.closed = false;
            this.onOpenHandler.forEach((handler) => handler() )
        }
        this.ws.onclose = (closeEvent: any) => {
            this.closed = true;
            if (closeEvent.code === WS_CODE.ABNORMAL_CLOSURE) { //Abnormal Closure
                setTimeout(() => { // Try to keep re-estabilish connection
                    this.reconnect();
                }, WEBSOCKET_TIMEOUT_MS);
            }
            this.onCloseHandler.forEach((handler: Function) => handler() )
        }
        this.ws.onmessage = (msg: IWSMessage) => {
            let message = JSON.parse(msg.data);
            message = new WSMessage(message);
            if (this.listeners[message.action]) {
                this.listeners[message.action](message);
            }
        }
        this.ws.onerror = (error: any) => console.error("WS error: ", error);
    }
    onOpen = (func: Function) => {
        this.onOpenHandler.push(func)
    }
    onClose = (func: Function) => {
        this.onCloseHandler.push(func)
    }
    readyState = () => {
        return this.ws ? this.ws.readyState : false;
    }
    listen = (event: keyof typeof WSEvent, func: (msg: IWSMessage) => void) => {
        this.listeners[event] = func;
    }
    send = (data: IWSMessage): void => {
        if (!data.user_id && this.userId) data.user_id = this.userId;

        if (this.readyState() !== 1) {
            this.queueMessages.push(data);
        } else if (this.readyState() === 1) {
            switch (this.sender) {
                case "http":
                    this.sendHttp(data);
                    break;

                case "ws":
                default:
                    this.sendWs(data);
                    break;

            }
        }
    }
    sendWs(data: IWSMessage): void {
        if (this.ws && this.readyState() === 1) {
            this.ws.send(JSON.stringify(data));
        }
    }
    sendHttp(data: IWSMessage): void {
        let apiName = 'telehealthApi';
        let path = '/sessions/broadcast';
        HttpClient().post(apiName, path, data)
            .catch((e) => console.log("sendHttp error: ", e));
    }
    resumeSending = (): void => {
        while (this.readyState() === 1 && this.queueMessages.length > 0) {
            let msg = this.queueMessages.pop();
            this.send(msg);
        }
    }
    close = (): void => {
        this.stopHeartbeat();
        if (this.ws && this.readyState() === 1) {
            this.ws.close();
        }
    }
};
export default MySocket;