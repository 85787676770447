import React, { PropsWithChildren } from 'react';
import TabsContext from './tab-context';
import { TabProps } from '@material-ui/core';

type TabsProps = {
    default: TabProps['value'],
}
type TabsState = {
    activeTab: TabProps['value']
}
class TabsContainer extends React.Component<PropsWithChildren<TabsProps>, TabsState> {
    constructor(props: TabsProps) {
        super(props);
        this.state = {
            activeTab: props.default
        };
    }
    setActive = (activeTab: TabProps['value'] ) => {
        this.setState({ activeTab });
    }
    render() {
        return (
            <TabsContext.Provider
                value={{
                    activeTab: this.state.activeTab,
                    setActive: this.setActive,
                }}
            >
                {this.props.children}
            </TabsContext.Provider>
        );
    }
}
export { TabsContainer };