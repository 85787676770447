import React, { memo } from 'react';
import type { FC, MouseEventHandler, SVGProps } from 'react';

import { ReactComponent as ApartmentIcon } from './icons/apartment-icon.svg';
import { ReactComponent as Dashboardcon } from './icons/dashboard-icon.svg';
import { ReactComponent as DoubleArrowLeftIcon } from './icons/double-arrow-left-icon.svg';
import { ReactComponent as DoubleArrowRightIcon } from './icons/double-arrow-right-icon.svg';
import { ReactComponent as GroupIcon } from './icons/group-icon.svg';
import { ReactComponent as LeaderboardIcon } from './icons/leaderboard-icon.svg';
import { ReactComponent as LogoutIcon } from './icons/logout-icon.svg';
import { ReactComponent as MeetingRoomIcon } from './icons/meeting-room-icon.svg';
import { ReactComponent as PublicIcon } from './icons/public-icon.svg';
import { ReactComponent as ListAltIcon } from './icons/list-alt-icon.svg';
import { ReactComponent as HelpOutlineIcon } from './icons/help-outline-icon.svg';
import { ReactComponent as MicIcon } from './icons/mic-icon.svg';
import { ReactComponent as MicOffIcon } from './icons/mic-off-icon.svg'; //Maybe is synonim of
import { ReactComponent as MicOffRedIcon } from './icons/mic-off-red-icon.svg';
import { ReactComponent as MicOffWhiteIcon } from './icons/mic-off-white-icon.svg';
import { ReactComponent as MicOnWhiteIcon } from './icons/mic-on-white-icon.svg';
import { ReactComponent as MicOffRedSlashIcon } from './icons/mic-off-red-slash-icon.svg';
import { ReactComponent as VideocamIcon } from './icons/videocam-icon.svg';
import { ReactComponent as VideocamOffIcon } from './icons/videocam-off-icon.svg';
import { ReactComponent as VideocamOffRedSlashIcon } from './icons/videocam-off-red-slash-icon.svg';
import { ReactComponent as VideocamOffRedIcon } from './icons/videocam-off-red-icon.svg';
import { ReactComponent as WirelessOffIcon } from './icons/wireless-off-icon.svg';
import { ReactComponent as InfoIcon } from './icons/info-icon.svg';
import { ReactComponent as InfoWhiteIcon } from './icons/info-white-icon.svg';
import { ReactComponent as InfoGrayIcon } from './icons/info-gray-icon.svg';
import { ReactComponent as SpeakerGreenIcon } from './icons/speaker-green-icon.svg';
import { ReactComponent as BulbIcon } from './icons/bulb-icon.svg';
import { ReactComponent as FlipCameraIcon } from './icons/flip-camera-icon.svg';
import { ReactComponent as ShareScreenIcon } from './icons/share-screen-icon.svg';
import { ReactComponent as PauseIcon } from './icons/pause-icon.svg';
import { ReactComponent as PipIcon } from './icons/pip-icon.svg';
import { ReactComponent as SettingsIcon } from './icons/settings-icon.svg';
import { ReactComponent as TimesIcon } from './icons/times-icon.svg';
import { ReactComponent as WarningIcon } from './icons/warning-icon.svg';
import { ReactComponent as ArrowBackIcon } from './icons/arrow-back-icon.svg';
import { ReactComponent as ArrowForwardIcon } from './icons/arrow-forward-icon.svg';
import { ReactComponent as ChevronLeftIcon } from './icons/chevron-left-icon.svg';
import { ReactComponent as ChevronRightIcon } from './icons/chevron-right-icon.svg';
import { ReactComponent as ChevronDownIcon } from './icons/chevron-down-icon.svg';
import { ReactComponent as DeleteIcon } from './icons/delete-icon.svg';
import { ReactComponent as EditIcon } from './icons/edit-icon.svg';
import { ReactComponent as CancelIcon } from './icons/cancel-icon.svg';
import { ReactComponent as BadgeIcon } from './icons/badge-icon.svg';
import { ReactComponent as MailIcon } from './icons/mail-icon.svg';
import { ReactComponent as DeviceIcon } from './icons/device-icon.svg';
import { ReactComponent as PlusIcon } from './icons/plus-icon.svg';
import { ReactComponent as EllipsisIcon } from './icons/ellipsis-icon.svg';
import { ReactComponent as EmojiDissatisfiedIcon } from './icons/emoji-dissatisfied-icon.svg';
import { ReactComponent as EmojiNeutralIcon } from './icons/emoji-neutral-icon.svg';
import { ReactComponent as EmojiHappyIcon } from './icons/emoji-happy-icon.svg';
import { ReactComponent as CheckIcon } from './icons/check-icon.svg';
import { ReactComponent as FileIcon } from './icons/file-icon.svg';
import { ReactComponent as BlurIcon } from './icons/blur-icon.svg';
import { ReactComponent as CircleSlashIcon } from './icons/circle-slash-icon.svg';

const iconComponents = {
    'double-arrow-left': DoubleArrowLeftIcon,
    'double-arrow-right': DoubleArrowRightIcon,
    'dashboard': Dashboardcon,
    'public': PublicIcon,
    'apartment': ApartmentIcon,
    'group': GroupIcon,
    'meeting-room': MeetingRoomIcon,
    'leaderboard': LeaderboardIcon,
    'logout': LogoutIcon,
    'list-alt': ListAltIcon,
    'help-outline': HelpOutlineIcon,
    'mic': MicIcon,
    'mic_off': MicOffIcon,
    'mic_off_red': MicOffRedIcon,
    'mic_off_white': MicOffWhiteIcon,
    'mic_on_white': MicOnWhiteIcon,
    'mic_off_red_slash': MicOffRedSlashIcon,
    'videocam': VideocamIcon,
    'videocam_off': VideocamOffIcon,
    'videocam_off_red': VideocamOffRedIcon,
    'videocam_off_red_slash': VideocamOffRedSlashIcon,
    'wireless_off': WirelessOffIcon,
    'info': InfoIcon,
    'info_white': InfoWhiteIcon,
    'info_gray': InfoGrayIcon,
    'speaker_green': SpeakerGreenIcon,
    'bulb': BulbIcon,
    'flip_camera': FlipCameraIcon,
    'share_screen': ShareScreenIcon,
    'pause': PauseIcon,
    'pip': PipIcon,
    'settings': SettingsIcon,
    'times': TimesIcon,
    'warning': WarningIcon,
    'arrow_back': ArrowBackIcon,
    'arrow_forward': ArrowForwardIcon,
    'chevron_left': ChevronLeftIcon,
    'chevron_right': ChevronRightIcon,
    'chevron_down': ChevronDownIcon,
    'delete': DeleteIcon,
    'edit': EditIcon,
    'cancel': CancelIcon,
    'badge': BadgeIcon,
    'mail': MailIcon,
    'device': DeviceIcon,
    'plus': PlusIcon,
    'ellipsis': EllipsisIcon,
    'emoji_dissatisfied': EmojiDissatisfiedIcon,
    'emoji_neutral': EmojiNeutralIcon,
    'emoji_happy': EmojiHappyIcon,
    'check': CheckIcon,
    'file': FileIcon,
    'blur': BlurIcon,
    'circle_slash': CircleSlashIcon,
};

type IconName = keyof typeof iconComponents;
type IconSizeRem = 0.5 | 0.75 | 0.875 | 1 | 1.125 | 1.375 | 1.5 | 1.25 | 1.75 | 2 | 2.5 | 3 | 3.5 | 4;

interface Props {
    readonly className?: string;
    readonly name: IconName;
    readonly size?: IconSizeRem;
    readonly width?: IconSizeRem;
    readonly height?: IconSizeRem;
    readonly onClick?: MouseEventHandler<SVGElement>;
}

const Icon: FC<Props> = memo(({ name, size, height, width, className, onClick }) => {
    const heightSpacing = height ?? size;
    const widthSpacing = width ?? size;

    const iconProps: SVGProps<SVGSVGElement> = {
        className: className + ` icon-${name}`,
        height: heightSpacing ? `${heightSpacing}rem` : '100%',
        onClick,
        width: widthSpacing ? `${widthSpacing}rem` : '100%',
    };

    const Component = iconComponents[name];

    return <Component {...iconProps} />;
});

export type { IconName, IconSizeRem };
export { Icon };