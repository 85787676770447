import React from 'react';
import ControlBtn from './ControlBtn';
import countlyEvents from 'countly/events';
import { TRYING_TO_CONNECT } from 'utils/Network/constants';
import { Icon } from 'icon';

type VideoBtnProps = {
    callSession: {
        networkQuality: number,
        video: boolean,
        handleVideo: () => void
    },
    cameraUnavailable: boolean
}
const VideoBtn = ({ callSession, cameraUnavailable }: VideoBtnProps) => {
    return (
        <ControlBtn
            id="ctrl-btn-video"
            active={!cameraUnavailable && callSession.networkQuality > TRYING_TO_CONNECT}
            onClick={callSession.handleVideo}
            className="disable-blur"
            label={callSession.video ? "Stop my video" : "Start my video"}
            eventCountly={countlyEvents.clickVideoButton}
        >
            <Icon name={callSession.video ? "videocam" : "videocam_off_red_slash"} size={2} />
        </ControlBtn>
    );
}
export default VideoBtn;