import React from 'react';
import { Icon } from 'icon';
import * as Styled from './styles';

type VideoNameTagProps = {
	name: string,
	isMuted: boolean,
}
type VideoNameTagState = {
	className: string
}
class VideoNameTag extends React.Component<VideoNameTagProps, VideoNameTagState> {
	constructor(props: VideoNameTagProps) {
		super(props);
		this.state = { className: "" };
	}
	componentDidMount() {
		this.alignNameTag();
		window.addEventListener('resize', this.alignNameTag);
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.alignNameTag)
	}
	alignNameTag = () => {
		const controls = document.querySelector('#control-bar') || { clientWidth: 0 };
		const mutedMsgContainer = document.querySelector('.video-name-tag') || { clientWidth: 0 };
		const sideSpace = (window.screen.width - controls.clientWidth) / 2;

		let position = "up";
		if (mutedMsgContainer.clientWidth < sideSpace) {
			position = "down";
		} else {
			position = "up";
		}

		this.setState({
			className: position
		});
	}
	render() {
		return (
			<Styled.VideoNameTag className={`video-name-tag ${this.state.className}`}>
				{this.props.name}&nbsp;{this.props.isMuted && <Icon name="mic_off_red" size={1.25} />}
			</Styled.VideoNameTag>
		);
	}
}
export default VideoNameTag;