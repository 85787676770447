import React from 'react';
import StateRouterContext from './StateRouterContext';


function withStateRouter(WrappedComponent: ReactComponent) {
    class withStateRouter extends React.Component<any, {}> {
        
        displayName: string = 'withStateRouter'
        static WrappedComponent: ReactComponent;

        constructor(props: any) {
            super(props);
            this.state = {};
        }
        render() {
            return (
                <StateRouterContext.Consumer>
                    {(context) =>
                        <WrappedComponent {...context} {...this.props} />
                    }
                </StateRouterContext.Consumer>
            );
        }
    };
    withStateRouter.WrappedComponent = WrappedComponent;
    return withStateRouter;
}
export default withStateRouter;