import React from 'react';
import * as Sentry from "@sentry/react";
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import MessageInline from 'shared/MessageInline';
import { IMessage, IToast, MessageType } from 'shared/Messages/types';
import withMessages from 'shared/Messages/withMessages';
import Input from 'shared/inputs/Input2';
import { Validation, WaitingRoom } from 'shared/types';
import HttpClient from 'utils/HttpClient';
import SmsInviteFormRules from 'validations/FormRules/SmsInviteFormRules';
import ValidationMessage from 'validations/ValidationMessage';
import { IValidation } from 'validations/types';
import withValidation from 'validations/withValidation';
import { Modal, ModalContent, ModalFooter, ModalHeader, ModalTitle } from 'village-ui/Modal';

import { Box } from '@material-ui/core';
import { Icon } from 'icon';
import { Button, Checkbox } from '@village/ui';

import InviteSMSTemplate from './templates/InviteSMSTemplate';

type SmsInviteModalProps = IValidation & {
    isOpen: boolean,
    waiting_room: WaitingRoom,
    onClose: () => void,
    showToast: (msg: IToast) => void,
}
type SmsInviteModalState = {
    [x: string]: any,
    text_message: string,
    patient_phone_number: string,
    patient_consented_to_sms: boolean,
    message: IMessage | null,
}
class SmsInviteModal extends React.Component<SmsInviteModalProps, SmsInviteModalState> {
    constructor(props: SmsInviteModalProps) {
        super(props);
        this.state = {
            text_message: "",
            patient_phone_number: "",
            patient_consented_to_sms: false,
            message: null,
        };
    }
    componentDidUpdate(prevProps: SmsInviteModalProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            // Reset state:
            this.setState({
                text_message: "",
                patient_phone_number: "",
                patient_consented_to_sms: false,
                message: null,
            });
            this.props.validationReset();
        }
    }
    getOriginalSmsMessage = () => {
        let templateDataParse = {
            waiting_room: this.props.waiting_room
        };

        return InviteSMSTemplate(templateDataParse);
    }
    getSmsMessage = () => {
        return this.state.text_message ? this.state.text_message : this.getOriginalSmsMessage();
    }
    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }
    handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.value = event.target.value.replace(/[- )(]/g, '');

        if (event.target.value === "+") {
            event.target.value = event.target.value.slice(0, -1);
        }
        if (event.target.value.substring(0, 2) !== '+1') {
            event.target.value = '+1' + event.target.value;
        }

        this.setState({
            [`${event.target.name}`]: event.target.value
        });

        //Trigger validation on first input.
        this.props.validate({
            [`${event.target.name}`]: event.target.value
        });
    }
    handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        this.setState({
            [`${event.target.name}`]: checked
        }, () => this.props.validateLive(this.state));
    }
    OnSMSConfirm: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();

        if (!this.props.validate(this.state)) return;
        countlyAddEvent(countlyEvents.clickConfirmSMSInvite);

        this.setState({
            loading: true,
            message: null
        });

        let apiName = 'telehealthApi';
        let path = '/providers/send_sms_invite';

        let postData = {
            patient_phone_number: this.state.patient_phone_number,
            message: this.getSmsMessage(),
            waiting_room_id: this.props.waiting_room.id,
        };

        HttpClient().post(apiName, path, postData)
            .then(() => {
                this.setState({
                    loading: false,
                    message: null
                });

                countlyAddEvent(countlyEvents.smsInviteSent, {
                    phone_number: postData.patient_phone_number
                });

                this.props.showToast({
                    message: "Text message invite was sent successfully to the patient.",
                });
                this.props.onClose();
            }).catch((error) => {
                Sentry.captureException({
                    error, 
                    section: "SmsInviteModal",
                    patient_phone_number: postData.patient_phone_number,
                    waiting_room_id: postData.waiting_room_id,
                    message: postData.message,
                });
                
                this.setState({
                    loading: false,
                    message: {
                        type: MessageType.danger,
                        message: (error.response?.data?.detail || "Failed to send text message"),
                    }
                });
                document
                    .querySelector('#sms-invite-modal section.modal-card-body')
                    ?.scrollTo({ top: 0, behavior: 'smooth' })
            });
    }
    render() {

        const validations = this.props.validations === true ? {} : this.props.validations;

        return (
            <Modal
                onClose={this.props.onClose}
                aria-labelledby="sms-invite-modal"
                open={this.props.isOpen}
                width="lg"
                height="auto"
            >
                <ModalHeader icon={<Icon name="device" size={1.5} />} onClose={this.props.onClose}>
                    <ModalTitle>Invite with text messaging</ModalTitle>
                </ModalHeader>
                <ModalContent>
                    {this.state.message &&
                        <MessageInline
                            type={this.state.message.type}
                            message={this.state.message.message}
                        />
                    }
                    <Box py={2}>
                        <Input
                            label='Patient phone number'
                            name='patient_phone_number'
                            fullWidth
                            value={this.state.patient_phone_number}
                            onChange={this.handlePhoneNumberChange}
                            placeholder="(312) 465-7900"
                            validation={validations?.patient_phone_number as Validation}
                        />
                    </Box>
                    <Box py={2}>
                        <Input
                            label='Waiting room URL'
                            type='text'
                            name='waiting_room_url'
                            fullWidth
                            value={this.props.waiting_room.url}
                            onChange={() => { }}
                            readOnly
                        />
                    </Box>
                    <Box py={2}>
                        <Input
                            label='Message'
                            type='textarea'
                            name='text_message'
                            fullWidth
                            value={this.getSmsMessage()}
                            onChange={this.handleChange}
                            rows={2}
                        />
                    </Box>
                    <Checkbox
                        name="patient_consented_to_sms"
                        checked={this.state.patient_consented_to_sms}
                        onChange={this.handleCheckBoxChange}
                        label="Patient consented to receiving text messages"
                    />
                    <ValidationMessage validation={validations?.patient_consented_to_sms as Validation} />
                </ModalContent>
                <ModalFooter>
                    <Button variant="secondary" size="medium" onClick={this.props.onClose} >
                        Cancel
                    </Button>
                    <Button size="medium" onClick={this.OnSMSConfirm} >
                        Send
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default withMessages(withValidation(SmsInviteModal, SmsInviteFormRules));
