import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import NavLink from "core/router/NavLink";
import { CheckPermission } from 'utils/Authorizer';
import Loading from "shared/Loading";
import { InputSearch } from "shared/inputs/InputSearch";
import { Market } from 'shared/types';
import { Button, ButtonIcon, Text, Table, TableBody, TableHead, TableRow, TableCell, TableContainer } from '@village/ui';
import { PlusIcon, TrashIcon, PencilIcon } from '@village/icons';
import * as Styled from 'shared/PageLayout';


type ListMarketsProps = RouteComponentProps & {
    search: string,
    markets: Market[],
    loading: boolean,
    onSearch: React.ChangeEventHandler<HTMLInputElement>,
    onRemove: (clinic: Market) => React.MouseEventHandler<HTMLSpanElement>
}

class ListMarkets extends React.Component<ListMarketsProps, {}> {
    constructor(props: ListMarketsProps) {
        super(props);
        this.state = {};
    }

    openFormNew = (): void => {
        this.props.history.push('/markets/new');
    }

    render() {
        return (
            <>
                <Text type="h4">Markets</Text>
                <Styled.ActionBar>
                    <Styled.SearchContainer>
                        <InputSearch
                            fullWidth
                            label=""
                            placeholder="Search"
                            value={this.props.search}
                            name="search"
                            onChange={this.props.onSearch}
                        />
                    </Styled.SearchContainer>
                    <CheckPermission act='POST' obj='/markets' >
                        <Button id="btn-new-market" size="medium" onClick={this.openFormNew} startIcon={<PlusIcon/>}>
                            Add new
                        </Button>
                    </CheckPermission>
                </Styled.ActionBar>

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><Text type="caption1">Name</Text></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.markets.map(market =>
                                <TableRow key={market.id}>
                                    <TableCell><Text type="body1">{market.name}</Text></TableCell>
                                    <TableCell $action>
                                        <CheckPermission act='DELETE' obj={`/markets/${market.id}`}>
                                            <ButtonIcon size="small" onClick={this.props.onRemove(market)} title="Delete market">
                                                <TrashIcon name="delete"/>
                                            </ButtonIcon>
                                        </CheckPermission>
                                        <CheckPermission act='PUT' obj={`/markets/${market.id}`}>
                                            <NavLink to={`/markets/edit/${market.id}`}>
                                                <ButtonIcon size="small" title="Edit market" >
                                                    <PencilIcon name="edit"/>
                                                </ButtonIcon>
                                            </NavLink>
                                        </CheckPermission>
                                    </TableCell>
                                </TableRow>
                            )}
                            {this.props.loading &&
                                <TableRow id="loading-row" >
                                    <TableCell colSpan={5} >
                                        <Loading />
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }
}
export default withRouter(ListMarkets);
