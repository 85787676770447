import React from 'react';
import { withRouter } from 'react-router-dom';
import { initCountly } from './index';

type CountlyTrackerProps = {
    location: any,
    match: any,
    history: any,
    children?: JSXElement,
}
class CountlyTracker extends React.Component<CountlyTrackerProps> {
    countly: any;

    constructor(props: CountlyTrackerProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        try {
            this.countly = initCountly();
        } catch (error: any) {
            localStorage.clear();
            console.warn("error: ", error);
        }
    }
    render() {
        return (this.props.children);
    }
}
export default withRouter(CountlyTracker);