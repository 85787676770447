import React from 'react';
import images from 'assets/images';
import { DeniedPermissionViews } from '../types';
import { Icon } from 'icon';

type Props = {
    onClick: (view: DeniedPermissionViews) => () => void,
    onGivePermissions: (event: React.MouseEvent) => void,
}
const TroubleshootCaseList = ({ onClick, onGivePermissions }: Props) => {
    return (
        <div>
            <img className="is-horizontal-center ic-no-access" src={images.ic_no_access_large} alt="village medical no access icon" />
            <h4 className="gray-90 text-h5-to-h4-medium mb-4">
                We can't access your camera and microphone
            </h4>
            <p className="is-size-7 has-text-weight-medium">
                To fix this and prepare for your call, review the list of possible issues and solutions:
            </p>
            <div className="list">
                <div className="list-item" role="button" onClick={onClick(DeniedPermissionViews.browser_list)} >
                    <div className="list-item-content">
                        Access to your camera and microphone is disabled
                    </div>
                    <div className="list-arrow">
                        <Icon name="chevron_right" size={2} />
                    </div>
                </div>
                <div className="list-item" role="button" onClick={onClick(DeniedPermissionViews.device_in_use)} >
                    <div className="list-item-content">
                        Your camera and microphone is being used by another application
                    </div>
                    <div className="list-arrow">
                        <Icon name="chevron_right" size={2} />
                    </div>
                </div>
            </div>
            {/* <div className="has-text-centered has-text-weight-bold mb-5">
                <a href="https://www.villagemedical.com/villageplus-virtual-visit-troubleshooting">View help article</a>
            </div> */}
            <div className="has-text-centered">
                <button className="give-av-permission button is-primary is-medium is-fullwidth" onClick={onGivePermissions}>
                    Give access
                </button>
            </div>
        </div>
    );
}
export default TroubleshootCaseList;