import React, { useState } from 'react';
import { Button, Checkbox, Text } from '@village/ui';
import { Column } from 'shared/Flex';
import Input from 'shared/inputs/Input2';


type Values = {
    issue_speaker: boolean,
    issue_microphone: boolean,
    issue_camera: boolean,
    issue_video_quality: boolean,
    issue_network_connection: boolean,
    issue_other: boolean,
    other_feedback: string,
}
type Props = {
    onSubmit: (values: Values) => void
};

const GoodQuestionnaire = ({ onSubmit }: Props) => {
    const [values, setValues] = useState<{
        [key: string]: any,
        issue_speaker: boolean,
        issue_microphone: boolean,
        issue_camera: boolean,
        issue_video_quality: boolean,
        issue_network_connection: boolean,
        issue_other: boolean,
        other_feedback: string,
    }>({
        issue_speaker: false,
        issue_microphone: false,
        issue_camera: false,
        issue_video_quality: false,
        issue_network_connection: false,
        issue_other: false,
        other_feedback: ""
    })
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValues((values) => ({
            ...values,
            [e.target.name]: e.target.value
        }));
    }
    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues((values) => ({
            ...values,
            [event.target.name]: event.target.checked
        }));
    }
    const handleSubmit = () => onSubmit(values);

    return (
        <>
            <Column gap={12} padding={0}>
                <Text type="caption1">What could’ve worked better?</Text>
                <Column gap={8} padding={0}>
                    <Checkbox
                        name="issue_speaker"
                        value={true}
                        checked={values.issue_speaker}
                        onChange={handleChangeCheckbox}
                        label="Speaker"
                    />
                    <Checkbox
                        name="issue_microphone"
                        value={true}
                        checked={values.issue_microphone}
                        onChange={handleChangeCheckbox}
                        label="Microphone"
                    />
                    <Checkbox
                        name="issue_camera"
                        value={true}
                        checked={values.issue_camera}
                        onChange={handleChangeCheckbox}
                        label="Camera"
                    />
                    <Checkbox
                        name="issue_video_quality"
                        value={true}
                        checked={values.issue_video_quality}
                        onChange={handleChangeCheckbox}
                        label="Video Quality"
                    />
                    <Checkbox
                        name="issue_network_connection"
                        value={true}
                        checked={values.issue_network_connection}
                        onChange={handleChangeCheckbox}
                        label="Network Connection"
                    />
                    <Checkbox
                        name="issue_other"
                        value={true}
                        checked={values.issue_other}
                        onChange={handleChangeCheckbox}
                        label="Other (please specify in the input below)"
                    />
                </Column>
            </Column>
            <Column gap={12} padding={0}>
                <Text type="caption1">Any other feedback?</Text>
                <Input
                    label=''
                    type='textarea'
                    name='other_feedback'
                    data-testid='other_feedback'
                    fullWidth
                    value={values.other_feedback}
                    onChange={handleChange}
                    rows={10}
                />
            </Column>
            <Button size="medium" fullWidth onClick={handleSubmit} >
                Send feedback
            </Button>
        </>
    );
}
export default GoodQuestionnaire;