import React, { ReactNode } from 'react';
import { DatePickerProps } from 'react-date-picker';
import * as Styled from './styles';

export interface MyDatePickerProps {
    readonly onChange: DatePickerProps['onChange'],
    readonly value: DatePickerProps['value'],
    readonly clearIcon?: DatePickerProps['clearIcon'],
    readonly name?: string,
    readonly label?: string,
    readonly className?: string,
    readonly disabled?: boolean,
    readonly readOnly?: boolean,
    readonly error?: ReactNode,
}

const DatePicker = ({ name, label, className, error, disabled, readOnly, ...props }: MyDatePickerProps) => {
    const status = error ? 'error' : disabled || readOnly ? 'disabled' : 'default'
    return (
        <Styled.InputContainer>
            {label && <Styled.Label htmlFor={name} type="caption1">{label}</Styled.Label>}
            <Styled.DatePicker
                $status={status}
                className={className}
                onChange={props.onChange}
                value={props.value}
                clearIcon={props.clearIcon}
            />
        </Styled.InputContainer>
    );
}
export default DatePicker;