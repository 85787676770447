import React from 'react';
import ReactDOM from 'react-dom';
import { OTPublisher } from 'opentok-react';
import NoCameraMessage from '../Messages/NoCameraMessage';
import withAudioLevel from '../AudioLevel/withAudioLevel';
import withMessages from 'shared/Messages/withMessages';
import EventManager, { IEventManager } from 'utils/EventManager';
import { debounce, isObjectPropertyUpdated } from 'utils/Basics';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import VideoContainer from './VideoContainer';
import { IMessage, MessageType } from 'shared/Messages/types';
import type {
    OpentokError,
    OpentokEventHandlers,
    OpentokPublisher,
    OpentokSession
} from '../opentok/types';
import type { AudioLevelUpdatedEvent, VideoElementCreatedEvent } from '../opentok/events';
import { PublisherError } from '../opentok/enums';
import ModalConfirm from 'shared/Modal/ModalConfirm';
import type { ModalConfirmProps } from 'shared/Modal/ModalConfirm';
import { FacingMode, VideoQuality } from '../CallSession/types';
const platform = require('platform');


type Props = {
    videoQuality?: VideoQuality,
    audioSource: string,
    videoSource: string,
    session: OpentokSession,
    eventHandlers: OpentokEventHandlers,
    properties: any,
    showMessage: (msg: IMessage) => void,
    setAudioLevel: (volume: number) => void,
    audioLevel: number,
    onPublisherReady?: (publisher: OpentokPublisher, videoElement: HTMLVideoElement) => void,
    setMuted: () => void,
    setUnmuted: () => void,
    enableVideo: () => void,
    disableVideo: () => void,
}
type State = {
    error: any,
    videoElement?: HTMLVideoElement,
    isAutoMuted: boolean,
    facingMode: FacingMode
}

const AutoMutedDialog = (props: ModalConfirmProps) => {
    return ReactDOM.createPortal(<ModalConfirm {...props} />, document.body);
}

class PublisherVideoContainer extends React.Component<Props, State> {

    otPublisher: OpentokPublisher
    publisher: any
    opentokPublisherEvents: string[]
    eventManager: IEventManager
    eventHandlers: OpentokEventHandlers
    isStreamingInterval?: ReturnType<typeof setInterval>
    audioInputWorks: number
    monitorMicrophoneTimer?: ReturnType<typeof setTimeout>;

    constructor(props: Props) {
        super(props);
        this.state = {
            error: null,
            isAutoMuted: false,
            facingMode: props?.properties?.facingMode
        };
        this.otPublisher = React.createRef();
        this.publisher = null;
        this.opentokPublisherEvents = [
            'accessAllowed',
            'accessDenied',
            'accessDialogClosed',
            'accessDialogOpened',
            'destroyed',
            'mediaStopped',
            'streamDestroyed',
            'streamCreated',
            'videoDimensionsChanged',
            'audioLevelUpdated',
            'videoElementCreated',
        ];
        this.eventManager = new EventManager();
        this.eventHandlers = this.listenPublisherEvents();
        this.eventManager.on('audioLevelUpdated', this.onAudioLevelUpdated);
        this.eventManager.on('videoElementCreated', this.onVideoElementCreated);
        this.eventManager.on('mediaStopped', this.onMediaStopped);
        this.addCountlyEventToOpentokEvents();
        this.audioInputWorks = 0;
    }
    componentDidMount() {
        this.getPublisher();
        if (this.publisher) {
            const platformInfo = platform.parse(window.navigator.userAgent);
            const OSname = platformInfo?.os?.family?.toLowerCase();
            // Used only for iOS
            if (OSname === "ios") this.setMediaTrackEvents('audio', this.publisher.getAudioSource())
            if (OSname === "ios") this.setMediaTrackEvents('video', this.publisher.getVideoSource().track)
        }
    }

    componentWillUnmount() {
        if (this.publisher) {
            this.publisher.publishAudio(false);
            this.publisher.publishVideo(false);
        }
    }

    componentDidUpdate(prevProps: Props) {
        const platformInfo = platform.parse(window.navigator.userAgent);
        const OSname = platformInfo?.os?.family?.toLowerCase();

        if (!this.publisher) {
            this.getPublisher();
        } else {
            if (this.isPropUpdated(prevProps, 'audioSource')) {
                this.publisher.setAudioSource(this.props.audioSource);
                // Used only for iOS
                if (OSname === "ios") this.setMediaTrackEvents('audio', this.publisher.getAudioSource())
            }
            if (this.isPropUpdated(prevProps, 'videoSource')) {
                this.publisher.setVideoSource(this.props.videoSource).then(() => {
                    this.setState({
                        facingMode: this.props?.properties?.facingMode
                    });
                });
                // Used only for iOS
                if (OSname === "ios") this.setMediaTrackEvents('video', this.publisher.getVideoSource().track)
            }
            if (this.isPropUpdated(prevProps, 'properties', 'width')) {
                this.publisher.element.style.width = this.props.properties.width;
            }
            if (this.isPropUpdated(prevProps, 'properties', 'height')) {
                this.publisher.element.style.height = this.props.properties.height;
            }
            if(JSON.stringify(this.props.videoQuality) !== JSON.stringify(prevProps.videoQuality)) {
                if(this.props.videoQuality?.constraints) {
                    this.setVideoQuality(this.props.videoQuality.constraints);
                }
            }
        }
    }
    setVideoQuality = (newConstraints: MediaTrackConstraints) => {
        const videoMediaStreamTrack: MediaStreamTrack = this.publisher.getVideoSource().track;
        const currentConstraints = videoMediaStreamTrack?.getConstraints() || {};
        videoMediaStreamTrack?.applyConstraints({
            ...currentConstraints,
            ...newConstraints
        });
    }
    // Used only for iOS
    setMediaTrackEvents = (type: 'audio' | 'video', mediaTrack: any) => {
        if (!mediaTrack) return;

        mediaTrack.addEventListener('ended', (event: any) => {
            countlyAddEvent(`mediaTrack/${type}/ended`, {
                type: type,
                data: JSON.stringify(event)
            });
        })
        mediaTrack.addEventListener('mute', (event: any) => {
            countlyAddEvent(`mediaTrack/${type}/mute`, {
                type: type,
                enabled: mediaTrack.enabled,
                muted: mediaTrack.muted,
                publishAudio: this.props.properties.publishAudio
            });
            if (type === 'audio' && this.props.properties.publishAudio) {
                countlyAddEvent(countlyEvents.issueAutoMuted);
                this.setState({ isAutoMuted: true });
                this.props.setMuted();
            }
        })
        mediaTrack.addEventListener('unmute', (event: any) => {
            countlyAddEvent(`mediaTrack/${type}/unmute`, {
                type: type,
                enabled: mediaTrack.enabled,
                muted: mediaTrack.muted,
            });
            if (type === 'audio') {
                this.setState({ isAutoMuted: false });
                this.props.setUnmuted();
            }
        })
    }
    isPropUpdated = (prevProps: Props, ...keys: string[]): boolean => {
        return isObjectPropertyUpdated(prevProps, this.props, ...keys);
    }

    getPublisher(): void {
        if (this.otPublisher) {
            this.publisher = this.otPublisher.current.getPublisher();
            if (this.publisher) {
                this.publisher.element.children[0].children[0].style.backgroundImage = 'unset';
            }
        }
    }

    onError = (error: OpentokError): void => {
        countlyAddEvent(countlyEvents.opentokError, {
            type: "publisher",
            error_name: error.name,
            error_message: error.message,
        });

        if (error.name === PublisherError.OT_USER_MEDIA_ACCESS_DENIED) {
            console.warn("OT_USER_MEDIA_ACCESS_DENIED");
        }

        this.setState({
            error: error.message
        });

        this.props.showMessage({
            component: NoCameraMessage,
            message: error.message,
            type: MessageType.danger
        });
    }

    listenPublisherEvents = (): OpentokEventHandlers => {
        let eventHandlers: OpentokEventHandlers = {}
        this.opentokPublisherEvents.forEach((eventName) => {
            eventHandlers[eventName] = this.eventManager.handle(eventName);
        });
        return eventHandlers;
    }
    addCountlyEventToOpentokEvents = (): void => {
        const skipCountlyOnEvent = ['audioLevelUpdated', 'videoDimensionsChanged'];
        this.opentokPublisherEvents.forEach((publisherEvent) => {
            if (skipCountlyOnEvent.indexOf(publisherEvent) > -1) return;
            this.eventManager.on(publisherEvent, (event: any) => {
                let eventData = null
                try {
                    eventData = JSON.stringify(event);
                } catch (error) {
                    eventData = null
                }
                countlyAddEvent(`opentokEventPublisher/${publisherEvent}`, { data: eventData })
            });
        });
    }
    // Used only for iOS
    isAudioStreaming = () => {
        const audioTrack = this.publisher.getAudioSource();

        // Call started: should set to muted
        if (this.props.properties.publishAudio && audioTrack && audioTrack.enabled && audioTrack.muted) {
            countlyAddEvent(countlyEvents.issueAutoMuted);
            this.props.setMuted();
            this.setState({ isAutoMuted: true });
            clearInterval(this.isStreamingInterval);
        }
    }
    monitorMicrophone = (audioLevel: number): void => {
        this.audioInputWorks = (this.audioInputWorks + audioLevel) / 2;
        if (!this.monitorMicrophoneTimer) {
            this.monitorMicrophoneTimer = setTimeout(() => {
                if (this.audioInputWorks === 0) {
                    this.deviceAccessRefresh();
                } else {
                    this.audioInputWorks = 0;
                }
                this.monitorMicrophoneTimer = undefined;
            }, 10_000);
        }
    }
    onAudioLevelUpdated = (event: AudioLevelUpdatedEvent): void => {
        if (this.props.properties.publishAudio) {
            this.props.setAudioLevel(event.audioLevel);
            // Used only for iOS
            const platformInfo = platform.parse(window.navigator.userAgent);
            const OSname = platformInfo?.os?.family?.toLowerCase();
            if (OSname === "ios") this.monitorMicrophone(event.audioLevel);
        }
    }
    onVideoElementCreated = (event: VideoElementCreatedEvent): void => {
        const element = event.element;
        this.handleIOSAutoplayIssue(element);
        this.setState({ videoElement: element });
        if (this.props.onPublisherReady) this.props.onPublisherReady(this.publisher, element);

        // Used only for iOS
        const platformInfo = platform.parse(window.navigator.userAgent);
        const OSname = platformInfo?.os?.family?.toLowerCase();
        if (OSname === "ios" && this.publisher) {
            let videoTrack = this.publisher.getVideoSource();
            let audioTrack = this.publisher.getAudioSource();
            this.setMediaTrackEvents('video', videoTrack.track);
            this.setMediaTrackEvents('audio', audioTrack);
            this.isStreamingInterval = setInterval(this.isAudioStreaming, 4000);
        }
    }
    handleIOSAutoplayIssue = (element: HTMLVideoElement) => {
        const platformInfo = platform.parse(window.navigator.userAgent);
        const OSname = platformInfo?.os?.family?.toLowerCase();
        if (OSname === "ios" && element) {
            element.autoplay = false;
            element.addEventListener('play', () => element.autoplay = true)
        }
    }
    onMediaStopped = (event: any): void => {
        // When call is not finished and unmute is pressed this event is triggered
        debounce(() => this.deviceAccessRefresh().catch((error: any) => {
            countlyAddEvent(countlyEvents.error, {
                constraint: error.constraint,
                name: error.name,
                message: error.message,
            });
        }), 1500);
    }
    deviceAccessRefresh = (): Promise<any> => {
        return (new Promise((resolve, reject) => {
            const options = { audio: true, video: true };
            const wasVideoEnabled = this.props.properties.publishVideo;
            if (wasVideoEnabled) this.publisher.publishVideo(false);
            const prevAudioTrack = this.publisher.getAudioSource();
            navigator.mediaDevices.getUserMedia(options)
                .then((mediaStream) => {
                    mediaStream.getTracks().forEach((track) => {
                        if (track.kind === "audio") {
                            if (prevAudioTrack) prevAudioTrack.stop();
                            this.publisher.setAudioSource(track)
                                .then(() => {
                                    setTimeout(() => {
                                        if (wasVideoEnabled) this.publisher.publishVideo(true);
                                        this.publisher.setAudioSource("workaround_trigger_mic_reset")
                                            .catch((error: any) => console.warn(error))
                                    }, 1000);
                                }).catch((error: any) => console.warn(error));
                        }
                    });
                    resolve(mediaStream);

                    if (this.isStreamingInterval) clearInterval(this.isStreamingInterval);
                    this.isStreamingInterval = setInterval(this.isAudioStreaming, 4000);
                }).catch((error) => reject(error))
        }));
    }
    handleUnmuteMe = () => {
        if (this.state.isAutoMuted) {
            countlyAddEvent(countlyEvents.clickYesUnmuteMe);
            this.deviceAccessRefresh().then((mediaStream) => {
                this.setState({ isAutoMuted: false });
                this.props.setUnmuted();
            }).catch((error) => {
                countlyAddEvent(countlyEvents.error, {
                    errorCode: error.name,
                    errorDescription: error.message,
                    comment: "Unable to re-gain Mic/Cam access",
                });
            });
        }
    }
    render() {

        const facingMode = this.state.facingMode;

        return (
            <VideoContainer error={this.state.error} >
                <OTPublisher
                    ref={this.otPublisher}
                    session={this.props.session}
                    className={`video ${facingMode === 'user' ? 'mirrored' : ''}`}
                    eventHandlers={{
                        ...this.eventHandlers,
                        ...this.props.eventHandlers,
                    }}
                    properties={{
                        mirror: false, //false - only for facingMode=environment (back camera)
                        ...this.props.properties
                    }}
                    onError={this.onError}
                />
                <AutoMutedDialog
                    isOpen={this.state.isAutoMuted}
                    title={"Did you end a call from another app?"}
                    subtitle={`
                        Looks like you received a call from another app. 
                        To continue your virtual visit you should not have an active call in another application.
                    `}
                    onConfirm={this.handleUnmuteMe}
                    confirmText={"Yes, Unmute me"}
                    confirmBtnType="primary"
                />
            </VideoContainer>
        );
    }
}
export default withAudioLevel(withMessages(PublisherVideoContainer));
