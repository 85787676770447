import React from 'react';
import Tooltip from './Tooltip';
import TextTruncate from 'react-text-truncate';

type TruncateTextProps = {
    disabled?: boolean,
    text?: string,
    line?: number,
    truncateText?: string,
}
const TruncateText = ({disabled, text, line, truncateText}: TruncateTextProps) => {
    return (
        <Tooltip disabled={disabled} title={text} >
            <span role="button">
                <TextTruncate
                    line={line}
                    truncateText={truncateText}
                    text={text}
                />
            </span>
        </Tooltip>
    );
}

TruncateText.defaultProps = {
	disabled: false,
	line: 1,
	truncateText: "..."
};
export default TruncateText;