import React from 'react';
import HttpClient from 'utils/HttpClient';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events'
import { debounce, isObjectPropertyUpdated } from 'utils/Basics';
import { SingleValue, MultiValue } from 'react-select';
import { Autocomplete } from 'village-ui/Autocomplete';
import type { OptionBase, OptionGroupBase, Props as SelectAsyncProps } from 'village-ui/Autocomplete';
import type { SelectOption, Validation, Provider } from 'shared/types';


interface Option extends OptionBase {};
interface OptionGroup extends OptionGroupBase<Option> {};
type SelectUserProps = Omit<
    SelectAsyncProps<Option, OptionGroup>, 
    'onChange' | 
    'defaultOptions' | 
    'loadOptions'
> & {
    name: string,
    validation?: Validation,
    value?: number,
    byWaitingRoomId?: number,
    onChange: (name: string, value: SelectOption | undefined) => void,
}

interface SelectUserState {
    loading: boolean,
    providers: Provider[]
}


class SelectUser extends React.Component<SelectUserProps, SelectUserState> {
    constructor(props: SelectUserProps){
        super(props);
        this.state = {
            loading: false,
            providers: []
        };
    }
    componentDidMount(){
        this.loadOptions();
    }
    componentDidUpdate(prevProps: SelectUserProps){
        if(this.props.byWaitingRoomId && isObjectPropertyUpdated(prevProps, this.props, 'byWaitingRoomId') ){
            this.loadOptions();
        }
    }
    handleChangeSelect = (option: MultiValue<OptionBase> | SingleValue<OptionBase>) => {
        this.props.onChange(this.props.name, option as SelectOption)
    }
    handleLoadOptions = (search: string, callback: (options: any) => void) => {
        debounce(() => this.loadOptions(search, callback), 400);
    }
    loadOptions = (search: string = "", callback?: (options: any) => void) => {
        const { byWaitingRoomId } = this.props;
        let apiName = 'telehealthApi';
        let path = byWaitingRoomId ? `/waiting_rooms/${byWaitingRoomId}/providers` : '/providers'; 
        let queryParams = {
            skip: 0,
            limit: 30,
            search: search
        };
        this.setState({
            loading: true
        });
        HttpClient().get(apiName, path, queryParams)
        .then( (data: any) => {
            this.setState({
                loading: false,
                providers: data
            }, () => {
                if(callback) callback(this.getProvidersToSelectOptions());
            });

        }).catch((error) => {
            this.setState({
                loading: false
            });
            countlyAddEvent(countlyEvents.error, {
                errorCode: error.name,
                errorDescription: error.message,
                comment: "Error on loading providers on selectUser", 
            });
        });
    }
    getProvidersToSelectOptions = (): OptionBase[] => {
        return this.state.providers.map((provider) => {
            return {
                value: provider.id,
                label: provider.first_name + ' ' + provider.last_name,
                item: provider,
            };
        })
        // sort options by label
        .sort((a, b) => (a.label > b.label) ? 1 : -1);
    }
    getValue = () => {
        this.getProvidersToSelectOptions();
    }
    render(){
        const { validation, ...props } = this.props;

        return (
            <Autocomplete
                {...props}
                onChange={this.handleChangeSelect}
                defaultOptions={this.getProvidersToSelectOptions()}
                loadOptions={this.handleLoadOptions}
                error={validation && validation.message}
            />
        );
    }
}
export default SelectUser;