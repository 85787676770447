import React from 'react';
import * as Styled from './styles';

const makeTwoDigits = (number: number) => {
    return number.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    });
}

const RoomTimer = () => {
    const [time, setTime] = React.useState(0);

    React.useEffect(() => {
        let timer = setInterval(() => setTime((v) => v + 1), 1000);
        return () => {
            clearInterval(timer);
        }
    }, [])

    const formatMessage = (time: number) => {
        let formatted = '';
        const hours = Math.floor(time / 3600);
        let rest = time - hours * 3600;
        const minutes = Math.floor(rest / 60);
        rest = rest - minutes * 60;
        formatted = `${makeTwoDigits(minutes)}:${makeTwoDigits(rest)}`;
        if (hours > 0) {
            formatted = `${makeTwoDigits(hours)}:` + formatted;
        }
        return formatted;
    }

    return (
        <Styled.RoomTimer data-testid="room-timer">
            {formatMessage(time)}
        </Styled.RoomTimer>
    );
}

export default RoomTimer;