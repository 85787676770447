import React from 'react';
import { NavLink } from 'react-router-dom';
import SESSION_STATUS from 'shared/constants/SESSION_STATUS';
import MediaStatus from './components/MediaStatus';
import PatientDropdownActions from './components/PatientDropdownActions';
import PatientPill from './components/PatientPill';
import * as Styled from './styles';
import { Color, IUser, Patient, SessionUser, WaitingRoom } from 'shared/types';
import { Text, Button } from '@village/ui';
import { CameraFilledIcon } from '@village/icons';

type Props = {
    id: number,
    me: IUser, //TODO
    session_users: SessionUser[],
    patient_id: number,
    patient: Patient,
    waiting_room_id: number,
    avatarColor?: Color,
    onUpdatePatientStatus: (
        sessionId: number,
        patientId: number,
        newPatientStatus: SESSION_STATUS,
        kickedOut: boolean
    ) => () => void,
}
type State = {}

class PatientQueueElement extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    getSessionStatus = (userId: number): SESSION_STATUS | undefined => {
        let status;

        if (!this.props.session_users) return status;

        // two status == 1 means there is a call in progress.
        const totalOnForSession = this.props.session_users.filter(({ session_id, status }) => {
            return session_id === this.props.id && status === 1
        }).length;

        if (totalOnForSession >= 2) return status = SESSION_STATUS.in_call;

        const user = this.props.session_users.find((el) => el.user_id === userId);
        if (user) status = user.status;

        return status;
    }
    getUserOnSession = (userId: number): SessionUser | undefined => {
        return this.props.session_users.find((el) => el.user_id === userId);
    }
    isYourDrop = (): boolean => {
        if (this.isInCall()) {
            const totalUsers = this.props.session_users.length;
            const totalUsersOff = this.props.session_users.filter((el) => el.status === SESSION_STATUS.off).length;
            const mySessionUser = this.props.session_users.find((el) => el.user_id === this.props.me.id);
            if (totalUsersOff + 1 === totalUsers && mySessionUser) {
                return true;
            }
        }

        return false;
    }
    isInCall = (): boolean => {
        return SESSION_STATUS.in_call === this.getSessionStatus(this.props.patient_id);
    }
    getWaitingRoom = (waiting_room_id: number): WaitingRoom | undefined => {
        return this.props.me.waiting_rooms?.find((el) => el.id === waiting_room_id);
    }
    render() {

        const patient_session = this.getUserOnSession(this.props.patient_id);
        const IS_YOUR_DROP = this.isYourDrop();
        const IS_IN_CALL = this.isInCall();
        const waiting_room = this.getWaitingRoom(this.props.waiting_room_id)

        return (
            <Styled.PatientCard>
                <Styled.PatientNameCell>
                    <Styled.Avatar
                        first_name={this.props.patient.first_name}
                        last_name={this.props.patient.last_name}
                        avatarColor={this.props.avatarColor}
                    />
                    <Text type="body1">
                        {this.props.patient.first_name} {this.props.patient.last_name}
                    </Text>
                </Styled.PatientNameCell>

                <PatientPill
                    status={this.getSessionStatus(this.props.patient_id)}
                    patient={patient_session}
                    session_users={this.props.session_users}
                />

                <Text type="body1">{waiting_room?.slug}</Text>

                <MediaStatus
                    hasAudio={patient_session?.has_audio}
                    hasVideo={patient_session?.has_video}
                />

                <Styled.PatientActions>
                    <PatientDropdownActions
                        session_id={this.props.id}
                        patient={this.props.patient}
                        isInCall={IS_IN_CALL && !IS_YOUR_DROP}
                        onUpdatePatientStatus={this.props.onUpdatePatientStatus}
                    />
                    <Styled.ActionButton>
                        {IS_IN_CALL && !IS_YOUR_DROP ?
                            <Button id="start-video-call" disabled size="medium" startIcon={<CameraFilledIcon />}>
                                {IS_YOUR_DROP ? "Rejoin video call" : "Start video call"}
                            </Button>
                            :
                            <NavLink id="start-video-call" to={`/session/${this.props.id}?video=1`}>
                                <Button size="medium" fullWidth startIcon={<CameraFilledIcon />}>
                                    {IS_YOUR_DROP ? "Rejoin video call" : "Start video call"}
                                </Button>
                            </NavLink>
                        }
                    </Styled.ActionButton>
                </Styled.PatientActions>
            </Styled.PatientCard>
        );
    }
}
export default PatientQueueElement;