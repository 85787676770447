import styled from 'styled-components';
import { TabList as VmdTabList, Tab as VmdTab } from 'village-ui/Tabs';

export const TabList = styled(VmdTabList)`
    background-color: transparent;
    border-bottom: 1px solid #e2e4e8;
`;

export const Tab = styled(VmdTab)`
    &.MuiTab-root {
        line-height: 2.5;
        min-width: 110px;
        padding: 6px 0px;
    }
`;