import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import qs from "qs";
import { Text, Drawer, DrawerHeader, DrawerContent } from '@village/ui';

type LogDetailsProps = RouteComponentProps & {
    logs: any,
    match: RouteComponentProps['match'] & {
        params: { id: string }
    }
}

const LogDetails = ({ logs, match, location, history }: LogDetailsProps) => {
    const [isOpen, setIsOpen] = useState(true);

    const handleClose = () => {
        setIsOpen(false);
        setTimeout(() => {
            let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
            history.push({
                pathname: '/logs',
                search: '?' + qs.stringify(queryParams),
            });
        }, 300);
    }
    const log = logs.find((log: any) => log.id === parseInt(match.params.id));

    return (
        <Drawer open={isOpen} onClose={handleClose} anchor='right' >
            <DrawerHeader onClose={handleClose} >
                <Text type="h6">Log details</Text>
            </DrawerHeader>
            <DrawerContent>
                {log && Object.keys(log.data).map((key, i) =>
                    <div>{key}: {log.data[key]?.toString()}</div>
                )}
            </DrawerContent>
        </Drawer>
    );
}
export default LogDetails;