import styled from 'styled-components';

export const ValidationMessage = styled.div<{type: "error" | "warning" | "success"}>`
    display: block;
    font-size: .75rem;
    margin-top: .25rem;
    ${({ type }) => 
        type === "error" ? `color: #d90012;` 
        : type === "success" ? `color: #48c774;` 
        : type === "warning" ? `color: #947600;` 
        : ""
    }
`;