import React from "react";
import { countlyAddEvent, countlyUserDetails } from 'countly';
import countlyEvents from 'countly/events';
import RoomContext from "./RoomContext";
import Websocket from 'utils/Websocket/Websocket';
import WSMessage from "utils/Websocket/WSMessage";
import HttpClient from 'utils/HttpClient';
import consumeApp from '../consumeApp';
import SSOAuth from "utils/SSO/SSOAuth";
import Feature from 'shared/Feature';
import FF from "shared/constants/FF";
import AVATAR_COLOR_STATUS from "shared/constants/AVATAR_COLOR_STATUS";
import ConfigFactory from 'ConfigFactory';
import WaitingRoom from 'utils/WaitingRoom';
import SESSION_STATUS from "shared/constants/SESSION_STATUS";
import Loading from "shared/Loading";
import { setCookie, getCookie, detectDevices } from "utils/Basics";
import PlaySound from "utils/PlaySound";
import sound from 'assets/sound/magical-ding-pop-notification.mp3';
import type { IWaitingRoom, Session, AuthUser } from 'shared/types';
import { IWebsocket } from "utils/Websocket/types";
import DeniedPermissions from 'pages/DeniedPermissions';

const { POLLING_INTERVAL_MS } = ConfigFactory.getConfig();

type ClinicalRoomProviderProps = {
    isLoggedIn: boolean,
    updateAuthUser: (data: any) => AuthUser,
    authUser: AuthUser,
    children: JSXElement
}

type ClinicalRoomProviderState = {
    permissionsStatus?: boolean
    waiting_rooms: IWaitingRoom[],
    sessions: Session[],
    cameraUnavailable: boolean,
    loading: boolean,
    isUserReady: boolean
}
type WsUpdatedSession = {
    id: number,
    type: "removed" | "added",
    session?: Session
}

class ClinicalRoomProvider extends React.Component<ClinicalRoomProviderProps, ClinicalRoomProviderState> {

    monitorWsUpdatedSession: WsUpdatedSession[]
    socket?: IWebsocket
    _isUnmounted?: boolean

    constructor(props: ClinicalRoomProviderProps) {
        super(props);
        this.state = {
            waiting_rooms: [],
            sessions: [],
            cameraUnavailable: false,
            loading: false,
            isUserReady: false,
        };
        this.monitorWsUpdatedSession = [];
        this.socket = new Websocket();
    }
    componentDidMount() {
        if (this.props.isLoggedIn) {
            this._getMe().then((authUser: AuthUser) => this._requestPermissions(authUser));
        }
    }
    componentWillUnmount() {
        this._isUnmounted = true
        this.closeSocket()
    }
    _notifyPatientsInQueue = (session?: Session | null): void => {
        // Do not ring if provider already in call
        const provider_status = getCookie('provider_status');
        if (provider_status === "busy") return;

        PlaySound(sound);
        const body = session ?
            `Patient ${session.patient.first_name} has checked into the waiting room ${session.waiting_room.slug}.`
            :
            "A patient has checked into the waiting room.";
        this._handleNotification(body, "patientCheckedIn");
    }
    _getMe = (): Promise<any> => {
        this.setState({
            loading: true
        });
        let apiName = 'telehealthApi';
        let path = '/providers/me';

        return (new Promise((resolve, reject) => {
            HttpClient()
            .retries(1)
            .get(apiName, path)
                .then((data) => {
                    let user = this.props.updateAuthUser(data);
                    countlyUserDetails({
                        name: user?.fullName,
                        username: user?.username
                    });
    
                    this._setupSocket();
                    this._eachInterval(() => {
                        // Check the efficency of the previous poll before making the next 
                        this._monitorWsStability();
                        this._getSessions(data.id, (newSessions) => {
                            const monitorWsUpdatedSession = this._monitorWsCorrectionMeasurement(newSessions)
                            let addedSessions = monitorWsUpdatedSession.filter((el) => el.type === 'added');
                            if (addedSessions.length > 0) this._notifyPatientsInQueue(addedSessions.length === 1 ? addedSessions[0].session : null)
                        })
                    }, POLLING_INTERVAL_MS);
    
                    this.setState({
                        waiting_rooms: data.waiting_rooms.map((el: WaitingRoom) => {
                            return new WaitingRoom(el.id, el.name, el.description, el.slug);
                        }),
                        isUserReady: true
                    });
    
                    resolve(user);
                }).catch((error) => reject(error) )
                .finally(() => {
                    this.setState({
                        loading: false
                    });
                });
        }));
    }
    _eachInterval = (callback: Function, interval: number) => {
        if (this._isUnmounted || interval < 0) return;

        setTimeout(() => {
            callback()
            this._eachInterval(callback, interval)
        }, interval);
    }
    _getSessions = (provider_id: number, callback?: (newSessions: Session[]) => void): void => {
        let apiName = 'telehealthApi';
        // TODO
        // Maybe this request should be transformed into:
        // let path = `/providers/me/sessions`;
        let path = `/providers/${provider_id}/sessions`;

        HttpClient().get(apiName, path)
            .then((data) => {
                const newSessions = data.map((session: Session) => {
                    return {
                        avatarColor: AVATAR_COLOR_STATUS.available,
                        ...session,
                        waiting_room: this.state.waiting_rooms.find((el) => el.id === session.waiting_room_id),
                    };
                });
                if (callback) callback(newSessions)

                this.setState({
                    sessions: newSessions
                });
            }).catch((error) => console.log("Response error: ", error));
    }
    _getSession = (sessionId: number, callback?: (session: Session) => void): void => {
        let provider_id = this.props.authUser?.id;
        let apiName = 'telehealthApi';
        let path = `/providers/${provider_id}/sessions/${sessionId}`;

        HttpClient().get(apiName, path)
            .then((newSession) => {
                let currentSessions = this.state.sessions.filter((el) => el.id !== sessionId);
                newSession.waiting_room = this.state.waiting_rooms.find((el) => el.id === newSession.waiting_room_id);
                this.setState({
                    sessions: [...currentSessions, {
                        avatarColor: AVATAR_COLOR_STATUS.available,
                        ...newSession,
                    }]
                });
                if (callback) callback(newSession);
            });
    }
    _monitorWsStability = () => {
        let correction = this.monitorWsUpdatedSession.length;
        if (correction > 0) {
            countlyAddEvent(countlyEvents.wsMessageMissed, {
                data: {
                    correction: correction
                }
            });
        }
    }
    _monitorWsCorrectionMeasurement = (newSessions: Session[]): WsUpdatedSession[] => {
        this.monitorWsUpdatedSession = [];

        newSessions.forEach((newSession) => {
            let session = this.state.sessions.find((el) => el.id === newSession.id);
            if (!session) {
                this.monitorWsUpdatedSession.push({
                    id: newSession.id,
                    type: "added",
                    session: newSession,
                });
            }
        });
        this.state.sessions.forEach((existedSession) => {
            let session = newSessions.find((el) => el.id === existedSession.id);
            if (!session) {
                this.monitorWsUpdatedSession.push({
                    id: existedSession.id,
                    type: "removed"
                });
            }
        });

        return this.monitorWsUpdatedSession;
    }
    _monitorWsReceivedMessage = (sessionId: number): WsUpdatedSession[] => {
        this.monitorWsUpdatedSession = this.monitorWsUpdatedSession.filter((el) => el.id !== sessionId);

        return this.monitorWsUpdatedSession;
    }
    _setupSocket = async () => {
        const provider_id = this.props.authUser?.id;
        if (!this.socket || !provider_id) return;

        this.socket.setUser(provider_id);
        this.socket.connect(async () => {
            const idToken = SSOAuth.getIdToken();
            if (idToken) {
                this.socket?.iniSocket({ authorization: 'bearer', key: idToken });
            }
            this._getSessions(provider_id);
        });
        this.socket.runHeartbeat();

        this.socket.listen('patient_checked_in', (message) => {
            let sessionId = message.data.session_id;
            this._monitorWsReceivedMessage(sessionId);
            this._getSession(sessionId, this._notifyPatientsInQueue);
        });

        this.socket.listen('patient_exited', (message) => {
            let sessionId = message.data.session_id;
            this._monitorWsReceivedMessage(sessionId);
            this.setState({
                sessions: this.state.sessions.filter((el) => el.id !== sessionId)
            })
        });

        this.socket.listen('stream_updated', (message) => {
            let patientId = message.user_id
            let session_user, session;
            session = this.state.sessions.find((el) => el.patient_id === patientId);
            if (session) session_user = session.session_users.find((el) => el.user_id === patientId);
            if (session_user) {
                const { has_audio, has_video } = message.data.stream;
                session_user.has_audio = has_audio;
                session_user.has_video = has_video;
            }
            this.setState({
                sessions: this.state.sessions
            })
        });

        this.socket.listen('patient_in_call', (message) => {
            let sessionId = message.data.session_id;
            this._monitorWsReceivedMessage(sessionId);
            this._getSession(sessionId);
        });

        this.socket.listen('patient_on_hold', (message) => {
            let patientId = message.user_id;
            let updatedAt = message.data.updated_at;
            let session_user, session;
            session = this.state.sessions.find((el) => el.patient_id === patientId);
            if (session) session_user = session.session_users.find((el) => el.user_id === patientId);
            if (session_user) session_user.status = SESSION_STATUS.on_hold;
            if (session_user) session_user.updated_at = updatedAt;
            this.setState({
                sessions: this.state.sessions
            })
        });

        if (this._isUnmounted) {
            this.socket.close();
        }
    }

    _forceDevicePermissions = (authUser: AuthUser, hasWebcam: boolean, hasMicrophone: boolean) => {
        if(this._shouldForcePermissionDevice(authUser, hasWebcam, hasMicrophone)) {
            this.setState({permissionsStatus: false});
        }
    }
    _shouldForcePermissionDevice = (authUser: AuthUser, hasWebcam: boolean, hasMicrophone: boolean) => {
        const hasOnlyProviderRole = authUser?.roles?.length === 1 && authUser.roles[0].code === "provider";
        if(hasOnlyProviderRole && (hasWebcam || hasMicrophone)) {
            return true;
        } else {
            return false
        }
    }
    _ignoreDeniedPermissions = () => {
        this.setState({permissionsStatus: true});
    }
    _requestPermissions = (authUser: AuthUser) => {
        detectDevices(({ hasWebcam, hasMicrophone }) => {
            if(hasWebcam) {
                this._requestPermissionsAudioVideo().then(({ status }) => {
                    if(!status) this._forceDevicePermissions(authUser, hasWebcam, hasMicrophone);
                    else this.setState({permissionsStatus: true});
                });
            } else {
                this._requestPermissionsAudioOnly().then(({ status }) => {
                    if(!status) this._forceDevicePermissions(authUser, hasWebcam, hasMicrophone);
                    else this.setState({permissionsStatus: true});
                });
            }
        });
    }
    _requestPermissionsAudioVideo = (): Promise<any> => {
        return (new Promise((resolve, reject) => {
            if (navigator.mediaDevices.getUserMedia !== null) {
                const constraints = { audio: true, video: true };
                navigator.mediaDevices.getUserMedia(constraints)
                    .then((result) => {
                        this._requestNotificationPermission();
                        resolve({status: true})
                    })
                    .catch((error) => {
                        switch (error.name) {
                            case "NotAllowedError":
                                resolve({status: false})
                                alert("Please allow camera and microphone access to host a docOS virtual visit.")
                                break;
                            case "NotReadableError":
                            case "NotFoundError":
                            case "OverconstrainedError":
                                this._requestPermissionsAudioOnly().then(({ status }) => resolve({ status }));
                                break;
                            default:
                                console.warn(error.name + ": " + error.message);
                                break;
                        }
                    });
            }
        }));
    }
    _requestPermissionsAudioOnly = (): Promise<any> => {
        return (new Promise((resolve, reject) => {
            if (navigator.mediaDevices.getUserMedia !== null) {
                const constraints = { audio: true };
                navigator.mediaDevices.getUserMedia(constraints)
                    .then(() => {
                        this.setState({cameraUnavailable: true});
                        this._requestNotificationPermission();
                        resolve({status: true})
                    })
                    .catch((error) => {
                        switch (error.name) {
                            case "NotAllowedError":
                                resolve({status: false})
                                break;
                            case "NotFoundError":
                            case "NotReadableError":
                            case "OverconstrainedError":
                                alert("Please allow camera and microphone access to host a docOS virtual visit.")
                                break;
                            default:
                                console.warn(error.name + ": " + error.message);
                                break;
                        }
                    });
            }
        }));
    }
    _requestNotificationPermission = (): void => {
        if (!("Notification" in window)) {
            console.warn("Notification is not supported on this browser. Please use Google Chrome.");
            alert("Notification is not supported on this browser. Please use Google Chrome.");
        } else {
            Notification.requestPermission((permission) => {
                if (permission !== "granted") {
                    countlyAddEvent(countlyEvents.denyNotification);
                }
            });
        }
    }
    _handleNotification = (body: string, tag: string) => {
        const title = "docOS Virtual Visits";
        const options = {
            body: body,
            renotify: true,
            tag: tag
        }
        if (Notification.permission === "granted" && Feature.has(FF.providerNotification)) {
            let notification = new Notification(title, options);
            setTimeout(notification.close.bind(notification), 5000);
        } else if (Notification.permission !== "granted" && Feature.has(FF.providerNotification)) {
            countlyAddEvent(countlyEvents.denyNotification);
        }
    }
    _setPatientKickedOutView(patientId: number, sessionId: number) {
        countlyAddEvent(countlyEvents.clickKickPatient, { patientId: JSON.stringify(patientId) })

        let msg = new WSMessage({
            action: "patient_kicked_out",
            recipients: [{ type: "user", value: patientId }],
            data: {
                session_id: sessionId
            }
        });

        if (this.socket) {
            this.socket.send(msg);
        }
    }
    handleUpdatePatientStatus = (
        sessionId: number,
        patientId: number,
        newPatientStatus: SESSION_STATUS,
        kickedOut = false
    ) => () => {
        if (kickedOut) this._setPatientKickedOutView(patientId, sessionId);

        let apiName = 'telehealthApi';
        let path = `/patients/${patientId}/sessions/${sessionId}/status`;
        let data = {
            status: newPatientStatus
        };
        HttpClient().put(apiName, path, data)
            .then((data) => {
                if (newPatientStatus === SESSION_STATUS.off) {
                    this.setState({
                        sessions: this.state.sessions.filter((el) => el.id !== sessionId)
                    })
                }
            })
            .catch((error) => {
                console.warn(`Updating Patient Status failed: ${error}`);
            });
    }
    closeSocket = () => {
        this._isUnmounted = true;
        if (this.socket) {
            this.socket.close();
        }
    }
    setProviderStatus = (nextStatus: string) => {
        setCookie("provider_status", nextStatus);
    }
    render() {

        const LoadingScreen = () => (
            <div className="container is-fluid fullheight has-background-light d-flex align-items-center justify-content-center" >
                <Loading />
            </div>
        );

        return (
            <RoomContext.Provider
                value={{
                    waiting_rooms: this.state.waiting_rooms,
                    isUserReady: this.state.isUserReady,
                    sessions: this.state.sessions,
                    cameraUnavailable: this.state.cameraUnavailable,
                    websocket: this.socket,
                    handleClinicianGoOffline: this.closeSocket,
                    handleUpdatePatientStatus: this.handleUpdatePatientStatus,
                    setProviderStatus: this.setProviderStatus,
                }}
            >
                {this.state.loading ? 
                    <LoadingScreen /> 
                : this.state.permissionsStatus === false ? 
                    <DeniedPermissions 
                        handleIgnore={this._ignoreDeniedPermissions}
                        handleCameraMicrophonePermissions={() => this._requestPermissions(this.props.authUser)}
                    />
                : this.props.children}
            </RoomContext.Provider>
        );
    }
}
export default consumeApp(ClinicalRoomProvider);
