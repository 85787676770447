import React from 'react';
import type { Env } from '../../shared/types';


let timeoutAutoCheck: ReturnType<typeof setTimeout> | undefined

type Props = {
    env: Env | string
}
type State = {
    available_updates: boolean
}
class UpdateChecker extends React.Component<Props, State> {

    autoCheckTime: number

    constructor(props: Props) {
        super(props);
        this.state = {
            available_updates: false
        }
        this.autoCheckTime = 15 * 60 * 1000; //15 min
    }
    componentDidMount() {
        if (this.props.env === 'development') return;
        this.checkUpdate();
        if (!timeoutAutoCheck) {
            timeoutAutoCheck = this.autoCheckUpdate();
        }
    }
    autoCheckUpdate(): ReturnType<typeof setTimeout> {
        return setTimeout(() => {
            this.checkUpdate();
            this.autoCheckUpdate()
        }, this.autoCheckTime);
    }
    checkUpdate(): void {
        const timestamp = (new Date()).getTime();
        const build_timestamp_url = window.location.origin + "/BUILD_TIMESTAMP?" + timestamp;
        fetch(build_timestamp_url)
            .then(response => {
                /**
                 * Reading file as blob so we don't care about the Content-Type of the response
                 */
                response.blob().then(blob => {
                    if (blob.hasOwnProperty('text')) {
                        blob.text().then(build_timestamp => {
                            if (build_timestamp !== process.env.REACT_APP_BUILD_TIMESTAMP) {
                                this.setState({ available_updates: true });
                            } else {
                                this.setState({ available_updates: false });
                            }
                        })
                    }
                }).catch((error) => console.warn("Failed to read blob on new updates", error));

            }).catch((error) => {
                // Just ignoring the error
                console.log("Failed to Check new updates:", error);
            });


    }
    hideInfoBar(): void {
        this.setState({ available_updates: false });
    }
    forceReload(): void {
        window.location.reload(); // reload without cache
    }
    render() {
        return (
            this.state.available_updates ?
                <div id="updates-banner" className="p-1 has-text-centered has-background-primary" >
                    Updates available.&nbsp;
                    <b
                        role="button"
                        className="is-underlined"
                        onClick={this.forceReload.bind(this)}
                    >
                        Click here
                    </b>
                    &nbsp;to update or&nbsp;
                    <b
                        role="button"
                        className="is-underlined"
                        onClick={this.hideInfoBar.bind(this)}
                    >
                        remind me later
                    </b>.
                    Keep seeing this message? We suggest you to reload the page using CTRL + F5.
                </div>
                : null
        );
    }
}
export default UpdateChecker;