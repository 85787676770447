import React from 'react';
import HttpClient from 'utils/HttpClient';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events'
import Select from 'shared/inputs/VmdSelect';
import type { Props as SelectProps, SelectOption } from 'shared/inputs/VmdSelect';
import type { Market } from 'shared/types';


type SelectMarketProps = Omit<SelectProps, 'options'>;

interface SelectMarketState {
    markets: Market[]
}

class SelectMarket extends React.Component<SelectMarketProps, SelectMarketState> {
    constructor(props: SelectMarketProps){
        super(props);
        this.state = {
            markets: []
        };
    }
    componentDidMount(){
        this.load();
    }
    load = () => {
        let apiName = 'telehealthApi';
        let path = '/markets';
        HttpClient().get(apiName, path)
            .then((data: any) => {
                this.setState({
                    markets: data
                });
            }).catch((error) => {
                countlyAddEvent(countlyEvents.error, {
                    errorCode: error.name,
                    errorDescription: error.message,
                    comment: "Error on loading markets on selectMarkets", 
                });
            });
    }
    getMarkets = (): SelectOption[] => {
        return this.state.markets.map((market) => {
            return {
                value: market.id,
                label: market.name
            };
        });
    }
    handleChangeSelect = (name: string, value: string) => {
        if(this.props.onChange) this.props.onChange(name, value)
    }
    render(){
        return (
            <Select
                className={this.props.className}
                placeholder={this.props.placeholder}
                label={this.props.label}
                id={this.props.id}
                name={this.props.name}
                value={this.props.value}
                onChange={this.handleChangeSelect}
                validation={this.props.validation}
                options={this.getMarkets()}
                showEmptyOption
                fullWidth
            />
        );
    }
}
export default SelectMarket;