import { ReactNode } from 'react';

enum MessageType { // TODO Move to enum
    danger = "danger",
    warning = "warning",
    info = "info",
    success = "success"
}
export { MessageType };

type VerticalPosition = "top" | "middle" | "bottom";
type HorizontalPosition = "left" | "center" | "right";
type MessagesPosition = `${VerticalPosition} ${HorizontalPosition}`;

interface IMessage {
    type: MessageType,
    message: string | ReactNode,
    component?: ReactComponent,
}
type Message = IMessage & {
    uuid: string,
    tag?: string,
    noTimeout?: boolean,
}
interface IToast {
    tag?: string,
    position?: MessagesPosition,
    noTimeout?: boolean,
    message: string | React.ReactElement,
}
type Toast = IToast & {
    uuid: string,
}
type ToastGroups = {
    [key: string]: Toast[];
};

export type {
    MessagesPosition,
    IMessage,
    Message,
    Toast,
    IToast,
    ToastGroups,
}