import React, { useState } from 'react';
import { Button, Text } from '@village/ui';
import { Column } from 'shared/Flex';
import Input from 'shared/inputs/Input2';

type Values = {
    other_feedback: string,
}
type Props = {
    onSubmit: (values: Values) => void
};

const GreatQuestionnaire = ({ onSubmit }: Props) => {
    const [values, setValues] = useState<Values>({ other_feedback: "" });
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValues((values) => ({
            ...values,
            [e.target.name]: e.target.value
        }));
    }
    const handleSubmit = () => onSubmit(values);

    return (
        <>
            <Column gap={12} padding={0}>
                <Text type="caption1">How can we improve?</Text>
                <Input
                    label=''
                    type='textarea'
                    name='other_feedback'
                    data-testid='other_feedback'
                    fullWidth
                    value={values.other_feedback}
                    onChange={handleChange}
                    rows={10}
                />
            </Column>

            <Button size="medium" fullWidth onClick={handleSubmit} >
                Send feedback
            </Button>
        </>
    );
}
export default GreatQuestionnaire;