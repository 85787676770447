import React from 'react';
import dayjs from 'dayjs';
import HttpClient from 'utils/HttpClient';
import Loading from 'shared/Loading';
import { cleanQueryParams, debounce, isObjectPropertyUpdated } from 'utils/Basics';
import INVITE_LOGS_ACTIONS from 'shared/constants/INVITE_LOGS_ACTIONS';
import { Provider, WaitingRoom } from 'shared/types';
import { Text, Table, TableBody, TableHead, TableRow, TableCell, TableContainer } from '@village/ui';

type InviteLog = {
    id: number,
    provider: Provider,
    waiting_room: WaitingRoom,
    created_at: string,
    action: keyof typeof INVITE_LOGS_ACTIONS,
    data: {
        receiver: string,
    },
}
type FilterValues = {
    sender_id?: number,
    waiting_room_id?: number,
    search?: string,
}
type InviteLogListProps = {
    provider_id?: number,
    filter?: FilterValues
}
type InviteLogListState = {
    loading: boolean,
    page: number,
    perPage: number,
    invite_logs: InviteLog[],
}

class InviteLogList extends React.Component<InviteLogListProps, InviteLogListState> {

    noLoadMore?: boolean

    constructor(props: InviteLogListProps) {
        super(props);
        this.state = {
            loading: true,
            page: 0,
            perPage: 30,
            invite_logs: [],
        };
        this.handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        this.getInviteLogs();
        document.getElementById('content')?.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        document.getElementById('content')?.removeEventListener('scroll', this.handleScroll);
    }
    componentDidUpdate(prevProps: InviteLogListProps) {
        if (this.props.filter && isObjectPropertyUpdated(prevProps, this.props, 'filter')) {
            debounce(() => this.getInviteLogs(), 400);
        }
    }
    getInviteLogs(page: number = 0): void {
        const { filter } = this.props;
        let apiName = 'telehealthApi';
        let path = `/providers/${this.props.provider_id}/invite_logs`;
        let queryParams = cleanQueryParams({
            ...filter,
            skip: page * this.state.perPage,
            limit: this.state.perPage
        });

        this.setState({
            loading: true,
            invite_logs: page ? this.state.invite_logs : []
        });

        HttpClient().get(apiName, path, queryParams)
            .then((data) => {
                this.setState({
                    loading: false,
                    page: page,
                    invite_logs: [...this.state.invite_logs, ...data]
                });
                this.noLoadMore = (data.length < this.state.perPage);
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            });
    }
    handleScroll = (e: Event): void => {
        debounce(() => {
            const element = e.target as Element
            if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                element.scrollTop--;
                if (!this.noLoadMore) {
                    this.getInviteLogs(this.state.page + 1);
                    this.noLoadMore = false;
                }
            }
        }, 200);
    }
    render() {
        if (this.state.invite_logs.length === 0) {
            return <Text type="body1" className="has-text-centered">No logs found</Text>
        }
        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Text type="caption1">Date & time (UTC)</Text></TableCell>
                            <TableCell><Text type="caption1">Action</Text></TableCell>
                            <TableCell><Text type="caption1">Sender</Text></TableCell>
                            <TableCell><Text type="caption1">Receiver</Text></TableCell>
                            <TableCell><Text type="caption1">Waiting room</Text></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.invite_logs.map((invite_log) =>
                            <TableRow key={invite_log.id}>
                                <TableCell><Text type="body1">{dayjs(invite_log.created_at).format("MMM D, YYYY h:mm A")}</Text></TableCell>
                                <TableCell><Text type='body1'>{INVITE_LOGS_ACTIONS[invite_log.action]}</Text></TableCell>
                                <TableCell>
                                    <Text type="body1">
                                        {invite_log?.provider?.first_name} {invite_log?.provider?.last_name}
                                    </Text>
                                </TableCell>
                                <TableCell><Text type="body1">{invite_log?.data?.receiver}</Text></TableCell>
                                <TableCell><Text type="body1">{invite_log?.waiting_room?.name}</Text></TableCell>
                            </TableRow>
                        )}
                        {this.state.loading &&
                            <TableRow id="loading-row" >
                                <TableCell colSpan={5} >
                                    <Loading />
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}
export default InviteLogList;