import React from 'react';
import { Select } from 'village-ui/Select';
import type { SelectProps, SelectOption } from 'village-ui/Select';
import type { Validation } from 'shared/types';

type Props = Omit<SelectProps, 'onChange'> & {
    validation?: Validation,
    onChange?: (name: string, value: string) => void,
}
export type { Props, SelectOption};

const VmdSelect = ({ validation, onChange, ...props }: Props) => {
    
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const { name, value } = event.target;
        if(onChange) onChange(name, value);
    }

    return (
        <Select
            onChange={handleChange}
            error={validation && validation.message}
            {...props}
        />
    );
}
export default VmdSelect;
