import { useEffect, useState } from 'react';

type VendorLoaderProps = {
    children?: React.ReactElement
    id: string,
    tagName: string,
    onLoad?: () => void,
    onUnload?: () => void,
    [key: string]: any,
}

const VendorLoader = ({ children, id, tagName, onLoad, onUnload, ...attrs }: VendorLoaderProps) => {
    const [loaded, setLoaded] = useState(false);

    const load = () => {
        if (document.getElementById(id)) return null;

        const element = document.createElement(tagName);
        element.id = id;
        Object.keys(attrs).forEach((k) => {
            if (k in element) {
                element.setAttribute(k, attrs[k])
            }
        });

        element.onload = () => {
            setLoaded(true);
            if (onLoad) onLoad();
        };
        document.head.prepend(element);
    };

    const unload = () => {
        const element = document.getElementById(id)
        if (element) element.remove();
        if (onUnload) onUnload();
    }

    useEffect(() => {
        load();

        return function cleanup() { unload(); }
    });

    return (children && loaded ? children : null);
}
export default VendorLoader;