import React from 'react';
import PublisherVideoContainer from '../VideoContainer/PublisherVideoContainer';
import VideoAvatar from '../VideoContainer/elements/VideoAvatar';
import { calculateElementsFitSize, Size } from 'utils/Basics';
import { FacingMode, VideoQuality } from '../CallSession/types';
import { OpentokMediaSource, OpentokEventHandlers, OpentokPublisher, OpentokSession } from '../opentok/types';
import * as Styled from './styles';

type VideoPreviewProps = {
    onPublisherReady: (publisher: OpentokPublisher) => void,
    videoQuality?: VideoQuality,
    session: OpentokSession,
    properties: {
        name: string,
        publishAudio: boolean,
        publishVideo: boolean, 
        facingMode: FacingMode,
        audioSource?: OpentokMediaSource,
        videoSource?: OpentokMediaSource,
    },
    eventHandlers?: OpentokEventHandlers,
    className: string,
    audioSource?: string,
    videoSource?: string | null,
    setMuted: () => void,
    setUnmuted: () => void,
    enableVideo: () => void,
    disableVideo: () => void,
}
type VideoPreviewState = {
    videoElement?: HTMLVideoElement,
    fitSize?: Size
}

class VideoPreview extends React.Component<VideoPreviewProps, VideoPreviewState> {
    constructor(props: VideoPreviewProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.resizeVideoPreview();
        window.addEventListener('resize', this.resizeVideoPreview);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeVideoPreview);
    }
    handlePublisherReady = (publisher: OpentokPublisher, videoElement: HTMLVideoElement): void => {
        if(this.props.onPublisherReady) this.props.onPublisherReady(publisher)
        this.setState({ videoElement }, () => {
            setTimeout(this.resizeVideoPreview, 800);
        });
    }
    resizeVideoPreview = (): void => {
        const videoPreview = document.querySelector('.mini-video-sizes') as HTMLElement;
        const fitSize = calculateElementsFitSize(this.state.videoElement, videoPreview);
        if(fitSize) this.setState({ fitSize });
    }
    render() {
        const { publishVideo, name } = this.props.properties;
        const { fitSize } = this.state;
        
        return (
            <Styled.VideoPreview className={`mini-video ${this.props.className && this.props.className}`} style={fitSize} >
                <Styled.VideoPreviewSize className="mini-video-sizes" />
                <Styled.VideoPreviewContainer hidden={!publishVideo}>
                    {this.props.audioSource && 
                    <PublisherVideoContainer
                        session={this.props.session}
                        videoQuality={this.props.videoQuality}
                        audioSource={this.props.audioSource}
                        videoSource={this.props.videoSource}
                        eventHandlers={this.props.eventHandlers}
                        onPublisherReady={this.handlePublisherReady}
                        setMuted={this.props.setMuted}
                        setUnmuted={this.props.setUnmuted}
                        enableVideo={this.props.enableVideo}
                        disableVideo={this.props.disableVideo}
                        properties={{
                            width: '100%',
                            height: '100%',
                            showControls: false,
                            ...this.props.properties
                        }}
                    />
                    }
                </Styled.VideoPreviewContainer>
                {!publishVideo && <VideoAvatar text={name} />}
            </Styled.VideoPreview>
        );
    }
}
export default VideoPreview;
