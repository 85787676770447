import { OutlinedInput } from '@material-ui/core';
import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';
import type { InputStatus } from './types';

export interface BaseProps {
    readonly $status: InputStatus;
}
export interface LabelProps {
    readonly htmlFor?: string;
}

export const InputContainer = styled.div`
    position: relative;
`;

export const Label = styled(Text).attrs((props: LabelProps) => ({ 
    tag: 'label', 
    htmlFor: props.htmlFor 
}))<LabelProps>`
    display: block;
    margin-bottom: ${rem(6)};
    color: ${({ theme }) => theme.vui.colors.neutral.gray80};
`;

export const HelperText = styled(Text)<{ readonly $error: boolean }>`
    margin-top: ${rem(8)};
    color: ${({ theme, $error }) => ($error ? theme.vui.colors.feedback.redError : theme.vui.colors.neutral.gray60)};
`;

export const BaseInput = styled(OutlinedInput)<BaseProps>`
    &.MuiOutlinedInput-root {
        color: ${({ theme, $status }) => theme.vui.component.input[$status].default.textColor};
        /* Override iOS / Android font color change */
        -webkit-text-fill-color: ${({ theme, $status }) => theme.vui.component.input[$status].default.textColor};
        -webkit-opacity: 1;

        background-color: ${({ theme, $status }) => theme.vui.component.input[$status].default.backgroundColor};
        .MuiInputBase-input::placeholder {
            color: #9299A2;
            -webkit-text-fill-color: #9299A2;
            -webkit-opacity: 1;
            opacity: 1;
        }
        &.MuiOutlinedInput-multiline {
            padding: ${rem(13)} ${rem(16)};
            min-height: ${rem(56)};
        }
        .MuiOutlinedInput-input {
            font-size: ${rem(16)};
            line-height: 1.3;
            box-sizing: border-box;
        }
        .MuiOutlinedInput-input:not(.MuiOutlinedInput-inputMultiline) {
            padding: ${rem(13)} ${rem(16)};
            height: ${rem(56)};
            &.MuiOutlinedInput-inputAdornedStart {
                padding-left: 0;
            }
            &.MuiOutlinedInput-inputAdornedEnd {
                padding-right: 0;
            }
        }
        .MuiOutlinedInput-notchedOutline {
            border-color: ${({ theme, $status }) => theme.vui.component.input[$status].default.borderColor};
            border-width: ${({ theme, $status }) => theme.vui.component.input[$status].default.borderWidth}px;
        }
        &.Mui-focused {
            color: ${({ theme, $status }) => theme.vui.component.input[$status].focus.textColor};
            -webkit-text-fill-color: ${({ theme, $status }) => theme.vui.component.input[$status].focus.textColor};
            -webkit-opacity: 1;
            background-color: ${({ theme, $status }) => theme.vui.component.input[$status].focus.backgroundColor};
            .MuiOutlinedInput-notchedOutline {
                border-color: ${({ theme, $status }) => theme.vui.component.input[$status].focus.borderColor};
                border-width: ${({ theme, $status }) => theme.vui.component.input[$status].focus.borderWidth}px;
            }
        }
        &:hover:not(.Mui-focused) {
            color: ${({ theme, $status }) => theme.vui.component.input[$status].hover.textColor};
            -webkit-text-fill-color: ${({ theme, $status }) => theme.vui.component.input[$status].hover.textColor};
            -webkit-opacity: 1;
            background-color: ${({ theme, $status }) => theme.vui.component.input[$status].hover.backgroundColor};
            .MuiOutlinedInput-notchedOutline {
                border-color: ${({ theme, $status }) => theme.vui.component.input[$status].hover.borderColor};
                border-width: ${({ theme, $status }) => theme.vui.component.input[$status].hover.borderWidth}px;
            }
        }
    }
`;