
type RuleFunction = (value: any, data: any, Rules: any) => boolean

const Rules = {
	required: (value: any, data: any): boolean => {
		if (!empty(value, true) || value === 0) {
			return true;
		}
		return false;
	},
	password: (value: any, data: any): boolean => {
		if (empty(value, true)) return true;

		// Password Requirements with min length of 8.
		var rgx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\]{}\-“!@#%&/,><:;|_~`])\S{8,256}$/;

		if (isString(value)) {
			return rgx.test(value);
		}

		if (Array.isArray(value)) {
			let isOneValidAtLeast = false;
			value.map((el) => {
				if (rgx.test(el.email)) {
					isOneValidAtLeast = true;
				}

				return el;
			});

			return isOneValidAtLeast;
		}

		return false;
	},
	phone: (value: any, data: any): boolean => {
		if (empty(value, true)) return true;

		// For U.S. phone numbers only with format +18005559999
		var rgx = /^\+([0-9]){11}$/;

		//if it is a string than it means we have the simple value
		if (isString(value)) {

			return rgx.test(value);
		}

		if (Array.isArray(value)) {
			let isOneValidAtLeast = false;
			value.map((el) => {
				if (rgx.test(el.phone)) {
					isOneValidAtLeast = true;
				}
				return el;
			});

			return isOneValidAtLeast;
		}

		return false;

	},
	mobile: (value: any, data: any): boolean => {
		if (empty(value, true)) return true;
		// toDo('Rules.mobile');
		return false;
	},
	email: (value: any, data: any): boolean => {
		if (empty(value, true)) return true;

		var rgx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		//if it is a string than it means we have the simple value
		if (isString(value)) {
			return rgx.test(value);
		}

		if (Array.isArray(value)) {
			let isOneValidAtLeast = false;
			value.map((el) => {
				if (rgx.test(el.email)) {
					isOneValidAtLeast = true;
				}

				return el;
			});

			return isOneValidAtLeast;
		}

		return false;

	},
	equalTo: (value: any, data: any, equalToField: string): boolean => {
		if (empty(value, true)) return true;
		if (value === data[equalToField]) {
			return true;
		}
		return false;
	},
	numeric: (value: any, data: any): boolean => {
		if (empty(value, true)) return true;
		if (isNaN(value)) return false;
		return true;
	},
	naturalNumber: (value: any, data: any): boolean => {
		if (empty(value, true)) return true;
		// toDo('Rules.maxLennumericgth');
		return false;
	},
	min: (value: any, data: any, min: any): boolean => {
		if (empty(value, true)) return true;
		// toDo('Rules.min (it can validate only numbers and dates)', value, data, min);
		return false;
	},
	greaterThan: (value: any, data: any, min: any): boolean => {
		if (empty(value, true)) return true;
		// toDo('Rules.greaterThan is not completed (it can validate only numbers and dates)', value, data, min);
		if (parseInt(value, 10) > parseInt(min, 10)) {
			return true;
		} else {
			return false;
		}
	},
	lessThan: (value: any, data: any, min: any): boolean => {
		if (empty(value, true)) return true;
		// toDo('Rules.lessThan is not completed (it can validate only numbers and dates)', value, data, min);
		if (parseInt(value, 10) < parseInt(min, 10)) {
			return true;
		} else {
			return false;
		}
	},
	max: (value: any, data: any, max: any): boolean => {
		if (empty(value, true)) return true;

		if (max instanceof Date) { // date
			value = new Date(value);
			return max > value;
		} else if (!isNaN(max)) { //Number
			return Number(max) > Number(value);
		}

		return max > value;
	},
	between: (value: any, data: any): boolean => {
		if (empty(value, true)) return true;
		// toDo('Rules.between (it can validate only numbers and dates)');
		return false;
	},
	alphaNumeric: (value: any, data: any): boolean => {
		if (empty(value, true)) return true;
		// toDo('Rules.alphaNumeric');
		return false;
	},
	onlyAlpha: (value: any, data: any): boolean => {
		if (empty(value, true)) return true;
		// toDo('Rules.onlyAlpha');
		return true;
	},
	minLength: (value: any, data: any, min: any): boolean => {
		if (empty(value, true)) return true;
		if (min <= value.length) {
			return true;
		}
		return false;
	},
	maxLength: (value: any, data: any, max: any): boolean => {
		if (empty(value, true)) return true;
		if (value.length <= max) {
			return true;
		}
		return false;
	},
	lengthBetween: (value: any, data: any, min: any, max: any): boolean => {
		if (empty(value)) value = '';
		if (min <= value.length && value.length <= max) {
			return true;
		}
		return false;
	},
	iban: (value: any, data: any): boolean => {
		if (empty(value, true)) return true;
		// toDo('Rules.iban');
		return false;
	},
	ip: (value: any, data: any): boolean => {
		if (empty(value, true)) return true;
		// toDo('Rules.ip');
		return false;
	},
	creditCard: (value: any, data: any): boolean => {
		if (empty(value, true)) return true;
		// toDo('Rules.creditCard');
		return false;
	},
	date: (value: any, data: any): boolean => {
		if (empty(value, true)) return true;
		// toDo('Rules.date');
		return false;
	},
	datetime: (value: any, data: any) => {
		if (empty(value, true)) return true;
		// toDo('Rules.datetime');
		return false;
	},
	time: (value: any, data: any): boolean => {
		if (empty(value, true)) return true;
		// toDo('Rules.time');
		return false;
	},
	customRegex: (value: any, data: any, myRegex: RegExp | string | number): boolean => {
		if (empty(value, true)) return true;
		return value.match(myRegex);
	},
	customRule: (value: any, data: any, ruleFunction: RuleFunction): boolean => {
		return ruleFunction(value, data, Rules);
	},
	isTrue: (value: any, data: any): boolean => {
		if (!value) return false;
		return value;
	}
};
export default Rules;

function isString(value: any): boolean {
	if (typeof value === 'string' || value instanceof String) {
		return true;
	}
	return false;
}

function empty(v: any, trim: boolean = false): boolean {
	/*
	 * Value '0'(zero) is not an empty
	 * */

	if (Array.isArray(v) && v.length === 0) {
		return true;
	} else if (Array.isArray(v) && v.length > 0) {
		return false;
	}

	if (trim && typeof v === 'string') {
		v = v.trim();
	}
	if (typeof v === 'undefined' || v === null || v === '' || (typeof v === 'object' && v.constructor === 'Object' && Object.keys(v).length === 0)) {
		return true;
	}
	return false;
}
