import React from 'react';
import consumeApp from 'providers/consumeApp';
import withClinicalRoom from 'providers/Room/withClinicalRoom';
import { IUser, SelectOption, WaitingRoom } from 'shared/types';
import EmailInviteModal from './components/EmailInviteModal';
import SmsInviteModal from './components/SmsInviteModal';
import * as Sentry from "@sentry/react";
import { Button } from '@village/ui';
import { Text } from '@village/ui';
import * as Styled from './styles';
import { Icon } from 'icon';
import { Row } from 'shared/Flex';


enum InviteModalType {
    email = "email",
    sms = "sms",
}
type InviteCardProps = {
    authUser: IUser,
    waiting_rooms: WaitingRoom[]
};
type InviteCardState = {
    [x: string]: any,
    open_modal: InviteModalType | null,
    waiting_room_url: string | null,
};
class InviteCard extends React.Component<InviteCardProps, InviteCardState> {
    constructor(props: InviteCardProps) {
        super(props);
        this.state = {
            open_modal: null, //email|sms
            waiting_room_url: null,
        };
    }
    copyWaitingRoomUrl = (): void => {
        let waiting_room_url = null;
        try {
            waiting_room_url = this.getSelectedWaitingRoomUrl();
        } catch(e) {
            const { waiting_rooms } = this.props;
            Sentry.captureException(e, {
                tags: {
                    section: "CopyWaitingRoomUrl",
                    waiting_rooms_length: waiting_rooms.length,
                },
            });
        }

        if (waiting_room_url) navigator.clipboard.writeText(waiting_room_url);
    }
    handleChangeWr = (name: string, value: string): void => {
        this.setState({ [`${name}`]: value });
        const preferences = this.props.authUser.preferences;
        preferences.setItem("last_selected_wr_url", value);
    }
    handleOpenModal = (modal: InviteModalType) => () => {
        this.setState({ open_modal: modal });
    }
    handleModalClose = (): void => {
        this.setState({ open_modal: null });
    }
    getWaitingRoomOptions = (): SelectOption[] => {
        const { waiting_rooms } = this.props;

        // Sort waiting rooms by alphabet order:
        let wr_list = (waiting_rooms || []).sort((a, b) => (a.name > b.name) ? 1 : -1);

        return wr_list.map((el) => {
            let label = el.name + ' | ' + el.url;

            return {
                label: label,
                value: el.url
            }
        });
    }
    getSelectedWaitingRoom = (): WaitingRoom | undefined => {
        const { waiting_rooms } = this.props;
        let waiting_room_url = "";
        try{
            waiting_room_url = this.getSelectedWaitingRoomUrl();
        } catch(e) {
            
            // Need to troubleshoot why we keep having backend error: 422 Unprocessable Content
            const { authUser } = this.props;
            Sentry.captureException(e, {
                tags: {
                    section: "InviteCard",
                    user_id: authUser.id,
                    stateWaitingRoomURL: this.state.waiting_room_url,
                    lastSelectedWrUrl: authUser.preferences.getItem('last_selected_wr_url'),
                    waiting_rooms_length: waiting_rooms.length,
                },
            });
        }

        let waiting_room = waiting_rooms?.find((el) =>
            el.url === waiting_room_url
        );

        return waiting_room;
    }
    getSelectedWaitingRoomUrl = (): string => {
        if (this.state.waiting_room_url) return this.state.waiting_room_url;

        const { authUser, waiting_rooms } = this.props;
        if (Array.isArray(waiting_rooms) && waiting_rooms.length > 0) {
            // If there is a valid wr in the preferences then consider it as the default one
            const preferences = authUser.preferences;
            const last_selected_wr_url = preferences.getItem('last_selected_wr_url');
            if (last_selected_wr_url) {
                let found = waiting_rooms.find((el) => el.url === last_selected_wr_url);
                if (found) return last_selected_wr_url;
            }

            // Else use the first wr as the default
            return waiting_rooms[0].url;
        }
        throw Error("Waiting room URL not provided");
    }
    render() {
        const selectedWaitingRoom = this.getSelectedWaitingRoom();
        
        return (
            <Styled.InviteCard>
                <Text type="h6">Invite someone to your waiting room</Text>
                <Styled.InviteCardBody>
                    <Text type="body1">To invite someone to your waiting room share this link:</Text>
                    <Row gap={24} wrap="true">
                        <Row gap={16} grow>
                            <Styled.Select
                                fullWidth
                                name="waiting_room_url"
                                options={this.getWaitingRoomOptions()}
                                value={selectedWaitingRoom?.url || ""}
                                onChange={this.handleChangeWr}
                            />
                            <Button size="medium" onClick={this.copyWaitingRoomUrl}>
                                Copy link
                            </Button>
                        </Row>
                        <Styled.VerticalDivider direction="vertical" />
                        <Row gap={16}>
                            <Button variant="secondary" size="medium" onClick={this.handleOpenModal(InviteModalType.email)}>
                                <Icon name="mail" size={1.5} />&nbsp; Send email
                            </Button>
                            <Button variant="secondary" size="medium" onClick={this.handleOpenModal(InviteModalType.sms)}>
                                <Icon name="device" size={1.5} />&nbsp; Send text
                            </Button>
                        </Row>
                    </Row>
                </Styled.InviteCardBody>

                {selectedWaitingRoom &&
                <EmailInviteModal
                    isOpen={this.state.open_modal === InviteModalType.email}
                    onClose={this.handleModalClose}
                    userData={this.props.authUser.attributes}
                    waiting_room={selectedWaitingRoom}
                />}
                
                {selectedWaitingRoom &&
                <SmsInviteModal
                    isOpen={this.state.open_modal === InviteModalType.sms}
                    onClose={this.handleModalClose}
                    waiting_room={selectedWaitingRoom}
                />}

            </Styled.InviteCard>
        );
    }
}
export default consumeApp(withClinicalRoom(InviteCard));