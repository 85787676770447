import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import NavLink from "core/router/NavLink";
import { CheckPermission } from 'utils/Authorizer';
import Loading from "shared/Loading";
import { InputSearch } from "shared/inputs/InputSearch";
import { WaitingRoom } from 'shared/types';
import { Button, ButtonIcon, Text, Table, TableBody, TableHead, TableRow, TableCell, TableContainer } from '@village/ui';
import { PlusIcon, TrashIcon, PencilIcon } from '@village/icons';
import * as Styled from 'shared/PageLayout';


type ListWaitingRoomsProps = RouteComponentProps & {
    search: string,
    loading: boolean,
    waiting_rooms: WaitingRoom[],
    onRemove: (provider: WaitingRoom) => React.MouseEventHandler<HTMLSpanElement>
    onSearch: React.ChangeEventHandler<HTMLInputElement>,
}
type ListWaitingRoomsState = {}

class ListWaitingRooms extends React.Component<ListWaitingRoomsProps, ListWaitingRoomsState> {
    constructor(props: ListWaitingRoomsProps) {
        super(props);
        this.state = {};
    }
    openFormNew = (): void => {
        this.props.history.push('/waiting_rooms/new');
    }
    render() {
        return (
            <>
                <Text type="h4">Waiting rooms</Text>
                <Styled.ActionBar>
                    <Styled.SearchContainer>
                        <InputSearch
                            fullWidth
                            label=""
                            placeholder="Search"
                            value={this.props.search}
                            name="search"
                            onChange={this.props.onSearch}
                        />
                    </Styled.SearchContainer>
                    <CheckPermission act='POST' obj={'/waiting_rooms'} >
                        <Button size="medium" onClick={this.openFormNew} startIcon={<PlusIcon />} >
                            Add new
                        </Button>
                    </CheckPermission>
                </Styled.ActionBar>

                <TableContainer>
                    <Table id="waiting_room-list">
                        <TableHead>
                            <TableRow>
                                <TableCell><Text type="caption1">Name</Text></TableCell>
                                <TableCell><Text type="caption1">Description</Text></TableCell>
                                <TableCell><Text type="caption1">Slug</Text></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.waiting_rooms.map((waiting_room) =>
                                <TableRow key={waiting_room.id} id={"waiting_room-" + waiting_room.id} >
                                    <TableCell><Text type="body1">{waiting_room.name}</Text></TableCell>
                                    <TableCell><Text type="body1">{waiting_room.description}</Text></TableCell>
                                    <TableCell><Text type="body1">{waiting_room.slug}</Text></TableCell>
                                    <TableCell $action>
                                        <CheckPermission act='DELETE' obj={`/waiting_rooms/${waiting_room.id}`} >
                                            <ButtonIcon size="small" onClick={this.props.onRemove(waiting_room)} title='Delete waiting room' >
                                                <TrashIcon name="delete"/>
                                            </ButtonIcon>
                                        </CheckPermission>
                                        <CheckPermission act='GET' obj={`/waiting_rooms/${waiting_room.id}`} >
                                            <NavLink to={"/waiting_rooms/edit/" + waiting_room.id} >
                                                <ButtonIcon size="small" title='Edit waiting room'>
                                                    <PencilIcon name="edit"/>
                                                </ButtonIcon>
                                            </NavLink>
                                        </CheckPermission>
                                    </TableCell>
                                </TableRow>
                            )}
                            {this.props.loading &&
                                <TableRow id="loading-row" >
                                    <TableCell colSpan={5} >
                                        <Loading />
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }
}
export default withRouter(ListWaitingRooms);
