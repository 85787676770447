import React from 'react';
import { countlyRemoteConfig } from '../countly';

interface SystemMessageProps {
    keyName: string,
}

const SystemMessage = (props: SystemMessageProps) => {
    const content = countlyRemoteConfig(props.keyName) as string;
    if(!content) return null;
    
    return (
        <div className="banner is-size-6" >
            {content}
        </div>
    );
};
export default SystemMessage;