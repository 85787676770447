import React from 'react';
import { SelectOption, WaitingRoom } from 'shared/types';
import InviteLogList from './components/InviteLogList';
import SelectUser from 'shared/SelectUser';
import { SingleValue, MultiValue } from 'react-select';
import type { OptionBase } from 'village-ui/Autocomplete';
import { Autocomplete } from 'village-ui/Autocomplete';
import * as Styled from 'shared/PageLayout';
import { InputSearch } from "shared/inputs/InputSearch";
import { Column } from 'shared/Flex';

type InviteLogsProps = {
    provider_id?: number,
    waiting_rooms: WaitingRoom[]
}
type FilterValues = {
    sender_id?: number,
    waiting_room_id?: number,
    search?: string
}
type InviteLogsState = {
    filter: FilterValues
}

class InviteLogs extends React.Component<InviteLogsProps, InviteLogsState> {
    constructor(props: InviteLogsProps){
        super(props);
        this.state = {
            filter: {},
        };
    }
    handleChangeWaitingRoom = (option: MultiValue<OptionBase> | SingleValue<OptionBase>) => {
        const opt = option as SelectOption
        this.setState({
            filter:{ 
                ...this.state.filter,
                sender_id: undefined, 
                waiting_room_id: opt?.value as number 
            }
        });
    }
    handleChangeProvider = (name: string, option?: SelectOption): void => {
        const opt = option as SelectOption
        this.setState({
            filter: { 
                ...this.state.filter, 
                sender_id: opt?.value as number
            }
        });
    }
    getWRsToSelectOptions = (): SelectOption[] => {
        return this.props.waiting_rooms?.map((waiting_room) => {
            return {
                value: waiting_room.id,
                label: waiting_room.name,
                item: waiting_room,
            };
        })
        // sort options by label
        .sort((a, b) => (a.label > b.label) ? 1 : -1);
    }
    handleSearch = (event: any): void => {
        this.setState({ filter: {
            ...this.state.filter,
            search: event?.target?.value
        }})
    }
    render(){
        const { filter } = this.state;
        return (
            <Column gap={20} padding={0} >
                <Styled.ActionBar>
                    <Column grow padding={0} >
                        <InputSearch
                            fullWidth
                            label=""
                            placeholder="Search"
                            value={filter.search}
                            name="search"
                            onChange={this.handleSearch}
                        />
                    </Column>
                    <Column grow padding={0} >
                        <Autocomplete
                            isClearable
                            placeholder="All  waiting rooms"
                            name="waiting_room_id"
                            onChange={this.handleChangeWaitingRoom}
                            options={this.getWRsToSelectOptions()}
                        />
                    </Column>
                    <Column grow padding={0} >
                    {filter.waiting_room_id && 
                        <SelectUser
                            isClearable
                            placeholder="Sender"
                            name="sender_id"
                            byWaitingRoomId={filter.waiting_room_id}
                            onChange={this.handleChangeProvider}
                        />
                    }
                    </Column>
                </Styled.ActionBar>
                <InviteLogList 
                    provider_id={this.props.provider_id} 
                    filter={this.state.filter} 
                />
            </Column>
        );
    }
}
export default InviteLogs;