import React from 'react';
import { Icon } from 'icon';
import * as Styled from './styles';

const ProviderUnstableConToast = () => {
    return (
        <Styled.ProviderUnstableConToast>
            <Icon name="wireless_off" size={1.25} />&nbsp;Call connection unstable. This could be yours or the patient’s.
        </Styled.ProviderUnstableConToast>
    );
};
export default ProviderUnstableConToast;