import VmdAvatar from 'shared/Avatar';
import styled from 'styled-components';

import { Card } from '@village/ui';

export const PatientCard = styled(Card)`
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(5, minmax(min-content, 1fr));
    grid-template-columns: subgrid;
    align-items: center;
    padding: 12px 20px;
`;

export const PatientNameCell = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
`;

export const PatientActions = styled.div`
    display: flex;
    gap: 16px;
    justify-self: end;
    button {
        white-space: nowrap
    }
`;

export const Avatar = styled(VmdAvatar)`
    flex-shrink: 0;
`;

export const ActionButton = styled.div`
    @media screen and (max-width: 1023px) {
        display: none !important;
    }
`