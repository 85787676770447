import React from 'react';
import { Validation } from "shared/types";
import * as Styled from './styles';

interface Props {
    validation: Validation
}
const ValidationMessage = ({ validation }: Props) => {
    return (
        validation ?
            <Styled.ValidationMessage type={validation.type} >
                {validation.message}
            </Styled.ValidationMessage>
        : null
    );
}
export default ValidationMessage;