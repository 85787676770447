import { VideoQuality } from "pages/CallScreen/CallSession/types";

export const VIDEO_QUALITY_720p: VideoQuality = {
    label: "High",
    constraints: { 
        width: { ideal: 1280 },
        height: { ideal: 720 },
        frameRate: 30 
    }
};

export const VIDEO_QUALITY_480p: VideoQuality = {
    label: "Normal",
    constraints: { 
        width: { ideal: 640 },
        height: { ideal: 480 },
        frameRate: 30 
    }
};

export const VIDEO_QUALITY_240p: VideoQuality = {
    label: "Low", 
    constraints: { 
        width: { ideal: 320 },
        height: { ideal: 240 },
        frameRate: 15 
    }
};

export const VIDEO_QUALITY_Auto: VideoQuality = { 
    label: "Auto", 
    constraints: null 
};

export const VIDEO_QUALITIES: VideoQuality[] = [
    VIDEO_QUALITY_Auto,
    VIDEO_QUALITY_240p,
    VIDEO_QUALITY_480p,
    VIDEO_QUALITY_720p,
];