import React from 'react';
import './checkbox.scss';
import type { Validation } from '../types';


interface CheckboxProps {
    validation: Validation,
    value: string | number | readonly string[] | undefined,
    checked: boolean,
    className?: string,
    name: string,
    label: JSXElement | string,
    onChange: (name: string, checked: boolean, value: CheckboxProps['value']) => void,
}

class Checkbox extends React.Component<CheckboxProps> {
    constructor(props: CheckboxProps) {
        super(props);
        this.state = {};
    }
    classNameValidation(): string {
        if (!this.props.validation) return "";

        switch (this.props.validation.type) {
            case "success":
                return "is-success ";
            case "error":
            default:
                return "is-danger";
        }
    }
    handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.props.onChange(this.props.name, !this.props.checked, this.props.value);
    }
    render() {
        return (
            <div>
                <label className={"checkbox " + this.props.className}>
                    <input
                        type="checkbox"
                        className={this.props.className}
                        onChange={this.handleChange}
                        checked={this.props.checked}
                        value={this.props.value}
                        name={this.props.name}
                    />
                    <span className="checkmark"></span>
                    &nbsp;{this.props.label}&nbsp;
                </label>

                {
                    this.props.validation &&
                    <p className={"help " + this.classNameValidation()}>
                        {this.props.validation.message}
                    </p>
                }
            </div>
        );
    }
}
export default Checkbox;
