import React from 'react';


const TroubleshootDeviceInUse = () => {
    return (
        <div>
            <h4 className="gray-90 text-h5-to-h4-medium mb-4">
                Your camera and microphone is being used by another application
            </h4>
            <p className="is-size-7 has-text-weight-medium">
                Follow these steps to fix the issue:
            </p>
            <p>
                1. End any active phone call or virtual meeting (Facetime, WhatsApp, etc.)
            </p>
            <p>
                2. Close all applications in the background, including Village Medical app.
            </p>
            <p>
                3. Open the virtual visit link your provider sent you.
            </p>
        </div>
    );
};
export default TroubleshootDeviceInUse;