import React from 'react';
import type { Message, Toast } from './types';


type MessagesContextState = {
    messages: Message[],
    toasts: Toast[],
    showToast: (msg: Toast) => void,
    showMessage: (msg: Message) => void,
    dismissMessage: (uuid: string) => void,
    dismissToast: (uuid: string) => void,
    dismissByTag: (tag: string) => void,
}

const MessagesContext = React.createContext<MessagesContextState | {}>({});

export default MessagesContext;