import React from 'react';
import images from 'assets/images';


const TroubleshootChromeIOS = () => {
    return (
        <div>
            <h4 className="gray-90 text-h5-to-h4-medium mb-4">
                Chrome Mobile on iOS
            </h4>
            <p className="is-size-7 has-text-weight-medium">
                Follow these steps to fix the issue:
            </p>
            <div>
                <p>1. Open the iOS Settings Application.</p>
                <img src={images.settings_icon_ios} alt="Settings Icon iOS" /> 
            </div>
            <hr/>
            <div>
                <p>2. In the iOS Settings Application search bar, search for Chrome.</p>
                <img src={images.ios_settings} alt="iOS Settings" /> 
            </div>
            <hr/>
            <div>
                <p>3. Tap on Chrome logo to open the Chrome Settings Page.</p>
                <img src={images.ios_chrome_search} alt="iOS Chrome Search" /> 
            </div>
            <hr/>
            <div>
                <p>4. Tap the toggle icons to allow Chrome access to the Camera and Microphone.</p>
                <img src={images.ios_chrome_settings} alt="iOS Chrome Settings" /> 
            </div>
            <hr/>
            <div>
                <p>5. Return to the link your provider emailed or texted to you and follow the instructions to join the virtual visit. </p>
                <img src={images.text_message} alt="Text Message" /> 
            </div>
            <hr/>
            <div>
                <p>6. When prompted to allow access to the camera and microphone, tap Allow.</p>
                <img src={images.ios_chrome_prompt} alt="iOS/Chrome Prompt" /> 
            </div>
        </div>
    );
};
export default TroubleshootChromeIOS;