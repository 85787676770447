import React from 'react';
import type { Validation } from '../types';
import { Input as VmdInput, InputProps } from '@village/ui';


type Props = InputProps & {
    validation?: Validation,
}

const Input = ({id, name, validation, ...props}: Props) => {
    return (
        <VmdInput 
            id={id ? id : name}
            name={name}
            error={validation?.message}
            {...props}
        />
    );
}
Input.defaultProps = {
    type: "text",
    value: "",
}
export default Input;
