import React from 'react';
import ControlBtn from './ControlBtn';
import { TRYING_TO_CONNECT } from 'utils/Network/constants';
import { Icon } from 'icon';

type PipBtnProps = {
    videoElement: HTMLVideoElement,
    callSession: { networkQuality: number }
}
class PipBtn extends React.Component<PipBtnProps, {}> {
    constructor(props: PipBtnProps) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        this.setPipOnWindowBlur();
    }
    componentWillUnmount() {
        this.closePip();
    }
    setPipOnWindowBlur = () => {
        window.addEventListener('blur', () => {
            if (!document.pictureInPictureElement) {
                this.handlePiP();
            }
        });
    }
    isPiPEnabled = () => {
        return document.pictureInPictureEnabled && this.props.videoElement;
    }
    closePip = () => {
        if (document.pictureInPictureElement) {
            document.exitPictureInPicture();
            window.removeEventListener('click', this.closePip);
        }
    }
    handlePiP = () => {
        if (this.isPiPEnabled()) {
            try {
                const mediaStream = this.props.videoElement.srcObject as MediaStream;
                const videoTrack = mediaStream.getVideoTracks()[0];

                if (!document.pictureInPictureElement && videoTrack) {

                    this.props.videoElement.requestPictureInPicture()
                        .catch((msg) => console.warn(msg));

                    window.addEventListener('click', this.closePip.bind(this));
                } else {
                    document.exitPictureInPicture();
                }
            } catch (err) {
                // Video failed to enter/leave Picture-in-Picture mode.
                console.error("Video failed to enter/leave Picture-in-Picture mode.");
            }
        }
    }
    render() {
        return (
            this.isPiPEnabled() ?
                <ControlBtn
                    id="ctrl-btn-pip"
                    active={this.props.callSession.networkQuality > TRYING_TO_CONNECT}
                    onClick={this.handlePiP}
                    className="disable-blur"
                    label="PiP"
                >
                    <Icon name="pip" size={2} />
                </ControlBtn>
                : null
        );
    }
}
export default PipBtn;
