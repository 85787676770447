import styled from 'styled-components/macro';

export const PausedButton = styled.div`
    display: flex;
    align-items: center;
`;
export const TestSpeakerButton = styled.button`
    background-color: #f0f2f3;
    border-color: transparent;
    color: #0080a3;
    width: 100%;

    border-radius: 8px;
    padding: 10px 17px;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;
    border-width: 1px;

    cursor: pointer;
    justify-content: center;
    text-align: center;
    white-space: nowrap;

    -webkit-appearance: none;
    align-items: center;
    border: 1px solid transparent;
    box-shadow: none;
    display: inline-flex;
    height: 2.5em;
    vertical-align: top;

    &:focus {
        background-color: #f0f2f3;
        color: #0080a3;
    }

    &:hover {
        background-color: rgba(0, 168, 198, 0.15);
        color: #0080a3;
    }

    &:active {
        background-color: rgba(0, 168, 198, 0.3);
        color: #0080a3;
    }
`;
export const TooltipArrowUp = styled.div`
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 20px solid #17335b;
    position: absolute;
    top: -20px;
    left: calc(50% - 14px);
`;
export const Tooltip = styled.div`
    padding: 16px;
    background-color: #17335b;
    color: #fff;
    border-radius: 8px;
    position: relative;
    top: 24px;
`;

export const SpeakerTooltip = styled.div`
    display: flex;

    .tooltip-icon-container {
        padding: 6px 9px;
    }

    .flex-grow-1 {
        flex-grow: 1;
    }
`;
