import React from 'react';
import { NavLink, Prompt, RouteComponentProps } from 'react-router-dom';
import ProviderCallContainer from './ProviderCallContainer';
import HttpClient from 'utils/HttpClient';
import Loading from "shared/Loading";
import withMessages from 'shared/Messages/withMessages';
import ConfigFactory from "ConfigFactory";
import withClinicalRoom from 'providers/Room/withClinicalRoom';
import WSMessage from 'utils/Websocket/WSMessage';
import CallSession, { CallSessionState } from '../CallScreen/CallSession/CallSession';
import { AUDIO_QUALITY_BANDWIDTH_TOO_LOW } from 'utils/Network/constants';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import VISIT_COMPLETED_REASON from 'shared/constants/VISIT_COMPLETED_REASON';
import { RoomContextState } from 'providers/Room/RoomContext';
import { IUser } from 'shared/types';
import { IMessage, MessageType } from 'shared/Messages/types';
import { VisitSession, VisitSessionStatus } from '../CallScreen/CallSession/types';
import Feature from 'shared/Feature';
import FF from 'shared/constants/FF';

type Props = RouteComponentProps & RoomContextState & {
    match: RouteComponentProps['match'] & {
        params: { session_id: string }
    },
    authUser: IUser,
    showMessage: (message: IMessage) => void,
}
type State = {
    loading: boolean,
    error: boolean,
    shouldWarn: boolean,
    visitSession?: VisitSession
}
class ProviderRoomPage extends React.Component<Props, State> {
    opentok_api_key: string
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            shouldWarn: true,
        };
        this.opentok_api_key = ConfigFactory.getOpentokApiKey()
        this.warnOnReload = this.warnOnReload.bind(this);
    }
    componentDidMount() {
        window.addEventListener('beforeunload', this.warnOnReload);
        let msg = new WSMessage({ action: "clinician_busy" });
        this.props.websocket?.send(msg);
        this.getOTSession();
        this.props.setProviderStatus('busy');
    }
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.warnOnReload);
        let msg = new WSMessage({ action: "clinician_online" });
        this.props.websocket?.send(msg);
        this.props.setProviderStatus('online');
    }
    warnOnReload(e: any): void {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = "Leaving this page will reset the wizard";
    }
    getVideoState(): number {
        const { location } = this.props;
        const query = new URLSearchParams(location.search);
        const videoParam = query.get('video')
        return videoParam === null ? 1 : parseInt(videoParam);
    }
    setCallMode(patientId: number): void {
        let msg = new WSMessage({
            action: this.getVideoState() ? "call_video_on" : "call_video_off",
            recipients: [{type: "user", value: patientId}],
        });
        this.props.websocket?.send(msg);
    }
    getOTSession(): void {
        const session_id = this.props.match.params.session_id
        let apiName = 'telehealthApi';
        let path = '/sessions/' + session_id + '/join';
        this.setState({
            loading: true,
        });
        HttpClient().post(apiName, path)
            .then((data) => {
                if (data.status === VisitSessionStatus.inactive) this.handleSessionUnavailable();

                this.setState({
                    visitSession: data,
                    loading: false,
                });

                this.setCallMode(data.patient_id);

            }).catch((error) => {
                this.handleSessionUnavailable();
                this.setState({ loading: false });
            });
    }
    handleSessionUnavailable = (): void => {
        this.setState({ error: true });
        this.props.showMessage({
            type: MessageType.danger,
            message: (
                <p>
                    Patient has left the call.&nbsp;
                    <NavLink to="/dashboard" className="has-text-white is-underlined">
                        Click here to go back to dashboard
                    </NavLink>
                </p>
            )
        })
    }
    handleLeave = (): void => {
        this.setState({shouldWarn: false} , () => {
            if (Feature.has(FF.providerSurvey)) {
                this.props.history.push(`/session/${this.props.match.params.session_id}/provider/feedback`);
            } else {
                this.props.history.push('/dashboard');
            }
        });
    }

    handlePause = (): void => {
        this.setState({shouldWarn: false}, () => {
            this.props.history.push('/dashboard');
        });
    }

    handleNoParticipants = (callSessionState: CallSessionState): void => {
        // Don't leave the call if the Provider is disconnected. He might get back to the call again
        if(
            callSessionState.subscriberNetworkQuality
            && callSessionState.subscriberNetworkQuality < AUDIO_QUALITY_BANDWIDTH_TOO_LOW
        ) return;

        countlyAddEvent(countlyEvents.visitCompleted, {reason: VISIT_COMPLETED_REASON.endedByPatient});
        this.handleLeave();
    }
    render() {
        const videoState = this.getVideoState();
        const { visitSession, loading, error, shouldWarn } = this.state;
        return (
            <React.Fragment>
                <Prompt when={shouldWarn} message="Are you sure you want to leave?" />
                {error ?
                    <div className="container is-fluid fullheight d-flex align-items-center justify-content-center" />
                : loading ?
                    <div className="container is-fluid fullheight d-flex align-items-center justify-content-center" >
                        <Loading />
                    </div>
                : visitSession ?
                    <CallSession
                        apiKey={this.opentok_api_key}
                        externalSessionId={visitSession.external_session_id}
                        token={visitSession.external_token}
                        onNoParticipants={this.handleNoParticipants}
                        initialVideoState={!!videoState && !this.props.cameraUnavailable}
                    >
                        <ProviderCallContainer
                            sessionId={this.props.match.params.session_id}
                            publisherName={this.props.authUser.fullName}
                            patientId={visitSession.patient_id}
                            onLeave={this.handleLeave}
                            onPause={this.handlePause}
                            cameraUnavailable={this.props.cameraUnavailable}
                        />
                    </CallSession>
                    : null}
            </React.Fragment>
        );
    }
}
export default withMessages(withClinicalRoom(ProviderRoomPage));
