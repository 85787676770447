import styled from 'styled-components/macro';
import { rgba } from 'polished';

export const FeedbackButton = styled.div<{checked: boolean}>`
    background: #FFFFFF;
    color: #0080A3;    
    border: 1px solid #C6CBD1;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 10px;
    cursor: pointer;
    height: 48px;
    flex-grow: 1;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
        background: ${rgba('#00A8C6', 0.15)};
        color: #0080A3;
        border-color: #06668A;
    }
    &:active {
        background: ${rgba('#00A8C6', 0.3)};
        color: #0080A3;
        border-color: #06668A;
    }
    ${({ checked }) => checked && `
        background: #0080A3 !important;
        color: #FFFFFF !important;
        border-color: #0080A3 !important;
    `}
`;