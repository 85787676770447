import styled from 'styled-components/macro';

export const AzureMessage = styled.div`
    background-color: #f5f5f5;
    border-radius: 4px;
    font-size: 1rem;
    max-width: 800px;
`;

export const AzureMessageHeader = styled.div`
    background-color: #1c1f2a;
    border-radius: 4px 4px 0 0;
    color: #fff;
    padding: .75em 1em;
`;

export const AzureMessageBody = styled.div`
    border: 1px solid #dbdbdb;
    border-radius: 0 0 4px 4px;
    padding: 1.25em 1.5em;
    color: #1c1f2a;
    border-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
`;

export const FallbackLoginButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
`;