import React from 'react';
import ControlBtn from './ControlBtn';
import countlyEvents from 'countly/events';
import { TRYING_TO_CONNECT } from 'utils/Network/constants';
import { Icon } from 'icon';

type FlipCameraBtnProps = {
    callSession: {
        networkQuality: number,
        handleCycleVideo: () => void,
    }
}
const FlipCameraBtn = ({ callSession }: FlipCameraBtnProps) => {
    return (
        <ControlBtn
            active={callSession.networkQuality > TRYING_TO_CONNECT}
            onClick={callSession.handleCycleVideo}
            className='disable-blur'
            label='Flip'
            eventCountly={countlyEvents.clickFlipCameraButton}
        >
            <Icon name="flip_camera" size={2} />
        </ControlBtn>
    );
}
export default FlipCameraBtn;