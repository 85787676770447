import React from 'react';
import { hasPermission } from 'utils/Authorizer';
import HttpClient from 'utils/HttpClient';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events'
import Select from 'shared/inputs/VmdSelect';
import type { Props as SelectProps, SelectOption } from 'shared/inputs/VmdSelect';
import type { Role } from 'shared/types';


type SelectRoleProps = Omit<SelectProps, 'options'>;

interface SelectRoleState {
    roles: Role[]
}


class SelectRole extends React.Component<SelectRoleProps, SelectRoleState> {
    constructor(props: SelectRoleProps){
        super(props);
        this.state = {
            roles: []
        }
    }
    componentDidMount(){
        this.loadRoles();
    }
    filterRoles = (roles: Role[]): Role[] => {
        return roles.filter( role => hasPermission('WRITE', `assign_role.${role.code}`));
    }
    loadRoles = () => {
        let apiName = 'telehealthApi';
        let path = '/roles';
        HttpClient().get(apiName, path)
            .then((data: any) => {
                this.setState({roles: this.filterRoles(data) });
            }).catch((error) => {
                countlyAddEvent(countlyEvents.error, {
                    errorCode: error.name,
                    errorDescription: error.message,
                    comment: "Error on loading roles on selectRole", 
                });
            });
    }
    getRolesToSelectOptions = (): SelectOption[] => {
        return this.state.roles.map((role) => {
            return {
                value: role.id,
                label: role.name,
                item: role,
            };
        })
        // sort options by label
        .sort((a, b) => (a.label > b.label) ? 1 : -1);
    }
    render(){
        return (
            <Select
                className={this.props.className}
                placeholder={this.props.placeholder}
                label={this.props.label}
                id={this.props.id ? this.props.id : this.props.name}
                name={this.props.name}
                value={this.props.value}
                onChange={this.props.onChange}
                validation={this.props.validation}
                options={this.getRolesToSelectOptions()}
                showEmptyOption={true}
            />
        );
    }
}
export default SelectRole;