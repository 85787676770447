import StateRouter from './StateRouter';

class StateRouterHistory {
    history: string[] = []
    pointer: number = -1
    stateRouter: StateRouter

    constructor(stateRouter: StateRouter){
        this.stateRouter = stateRouter;
    }
    push = (route: string): void => {
        this.history.push(route);
        this.pointer++;
        this.stateRouter.setState({view: route});
    }
    goBack = (): void => {
        this.pointer--;
        this.pointer = this.pointer > -1 ? this.pointer : 0;
        const route = this.history[this.pointer];
        this.stateRouter.setState({view: route});
    }
    goForward = (): void => {
        this.pointer++;
        this.pointer = this.pointer < this.history.length ? this.pointer : (this.history.length - 1);
        const route = this.history[this.pointer];
        this.stateRouter.setState({view: route});
    }
}
export default StateRouterHistory;