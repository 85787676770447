import SSOUser from './User/SSOUser';
import Authorizer from '../Authorizer';
import UserStorage from '../UserStorage';
import type { IUser, UserOrigin, WaitingRoom, Clinic, Role, SSOUserData } from 'shared/types';


class User implements IUser {

    public id?: number
    public first_name?: string
    public last_name?: string
    public external_id?: string
    public login_time?: string
    public private_waiting_room?: WaitingRoom
    public title?: string
    public credential?: string
    public npi?: string
    public clinics?: Clinic[]
    public roles?: Role[]
    public waiting_rooms?: WaitingRoom[]

    //SSO props:
    public originalUser: SSOUser
    public issuer?: UserOrigin
    public attributes?: any
    public fullName?: string
    public challengeName?: string
    public sub?: string
    public username?: string
    public email?: string
    [key: string]: any

    constructor(data: SSOUserData) {
        this.issuer = data.issuer;
        let user: SSOUser = new SSOUser(data);
        this.originalUser = user;
        this.email = user.email;
        this.attributes = user.attributes
        this.fullName = user.fullName
        this.sub = user.sub
        this.username = user.username

    }
    public setAttribute = (key: string, value: any): void => {
        if (key in this) {
            this[key] = value;
        }
    }
    get preferences() {
        return (new UserStorage(localStorage, this.id));
    }
    set policies(newPermissions: Permission[]) {
        Authorizer.setPermissions(newPermissions);
    }

}
export default User;