import { defaultVillageUiTheme as theme } from '@village/ui';

type ModalColorProperties = 'background' | 'shadow' | 'border' 
| 'title' | 'subtitle' 
| 'close' | 'closeHover' | 'closePressed';

type WidthSizes = 'sm' | 'md' | 'lg' | 'xl';
type HeightSizes = 'sm' | 'lg';

export type ModalTheme = {
    readonly color: Record<ModalColorProperties, number | string>,
    readonly width: Record<WidthSizes, number>,
    readonly height: Record<HeightSizes, number>,
};

export const ModalTheme: ModalTheme = {
    color: {
        background: theme.vui.colors.general.white,
        shadow: theme.vui.colors.primary.navy,
        border: theme.vui.colors.neutral.gray10,
        title: theme.vui.colors.neutral.gray90,
        subtitle: theme.vui.colors.neutral.gray60,
        close: theme.vui.colors.neutral.gray40,
        closeHover: theme.vui.colors.neutral.gray25,
        closePressed: theme.vui.colors.neutral.gray5,
    },
    width: {
        sm: 296, 
        md: 320, 
        lg: 480, 
        xl: 640
    },
    height: {
        sm: 480,
        lg: 640,
    }
};