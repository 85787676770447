import React from 'react';
import { Text } from '@village/ui';
import * as Styled from './styles';


type CallParticipantListProps = {
    usersList: string[]
}

const CallParticipantList = ({ usersList }: CallParticipantListProps) => {
    return (
        <div>
            <Text type="h6">All call participants({usersList.length})</Text>
            <Styled.List>
                {usersList.map((participant, k) =>
                    <li key={k}>{participant}</li>
                )}
            </Styled.List>
        </div>
    );
}
export default CallParticipantList;