import React from 'react';
import HttpClient from 'utils/HttpClient';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events'
import Select from 'shared/inputs/VmdSelect';
import type { Props as SelectProps, SelectOption } from 'shared/inputs/VmdSelect';
import type { Clinic, AuthUser } from 'shared/types';

type SelectClinicProps = Omit<SelectProps, 'options'> & {
    authUser?: AuthUser,
}

interface SelectClinicState {
    clinics: Clinic[]
}

class SelectClinic extends React.Component<SelectClinicProps, SelectClinicState> {
    constructor(props: SelectClinicProps){
        super(props);
        this.state = {
            clinics: []
        };
    }
    componentDidMount(){
        this.loadClinics();
    }
    loadClinics = () => {
        let apiName = 'telehealthApi';
        let path = '/clinics';
        HttpClient().get(apiName, path)
            .then((data: any) => {
                this.setState({
                    clinics: data
                });
            }).catch((error) => {
                countlyAddEvent(countlyEvents.error, {
                    errorCode: error.name,
                    errorDescription: error.message,
                    comment: "Error on loading clinics on selectClinic", 
                });
            });
    }
    getClinics = (): SelectOption[] => {
        const clinics = this.state.clinics || this.props.authUser?.clinics || [];
        return clinics.map((clinic) => {
            return {
                value: clinic.id,
                label: clinic.name
            };
        });
    }
    handleChangeSelect = (name: string, value: string): void => {
        if(this.props.onChange) this.props.onChange(name, value)
    }
    render(){
        return (
            <Select
                className={this.props.className}
                placeholder={this.props.placeholder}
                label={this.props.label}
                id={this.props.id}
                name={this.props.name}
                value={this.props.value}
                onChange={this.handleChangeSelect}
                validation={this.props.validation}
                options={this.getClinics()}
                showEmptyOption
                fullWidth
            />
        );
    }
}
export default SelectClinic;