interface IEventManager {
    eventHandlers: any,
    on: (eventName: string, handler: Function) => void,
    handle: (eventName: string) => (...arg: any) => void
}
export type { IEventManager }

class EventManager implements IEventManager {
    eventHandlers: any = {}
    on = (eventName: string, handler: Function): void => {
        if (typeof handler === 'undefined') {
            throw new Error(`EventManager.on(${eventName}, ${handler}) handler is undefined`);
        }
        if (!this.eventHandlers[eventName]) this.eventHandlers[eventName] = [];
        this.eventHandlers[eventName].push(handler);
    }
    handle = (eventName: string) => (...arg: any): void => {
        if (this.eventHandlers[eventName]) {
            this.eventHandlers[eventName].forEach((handler: Function) => {
                handler(...arg);
            });
        }
    }
}
export default EventManager;