import { rem } from 'polished';
import styled from 'styled-components/macro';
import MuiNativeSelect from '@material-ui/core/NativeSelect';
import { Icon } from 'icon';

export const NativeSelect = styled(MuiNativeSelect)`
    &.MuiOutlinedInput-root .MuiOutlinedInput-input:not(.MuiOutlinedInput-inputMultiline) {
        padding-right: ${rem(32)};
    }
    .MuiNativeSelect-select:focus {
        background-color: #FFFFFF;
    }
`;

export const ChevronDownIcon = styled(Icon).attrs(() => ({name: "chevron_down", size: 1.5}))`
    top: calc(50% - 12px);
    color: rgba(0, 0, 0, 0.54);
    right: 4px;
    position: absolute;
    pointer-events: none;
`;