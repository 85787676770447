import React from 'react';
import images from 'assets/images';

const TroubleshootChromeAndroid = () => {
    return (
        <div>
            <h4 className="gray-90 text-h5-to-h4-medium mb-4">
                Chrome Mobile on Android
            </h4>
            <p className="is-size-7 has-text-weight-medium">
                Follow these steps to fix the issue:
            </p>
            <div>
                <p>1. Open Chrome on your Android device and tap the three dots in the top right corner.</p>
                <img src={images.screenshot_no_device_access} alt="Screenshot No Device Access" /> 
            </div>
            <hr/>
            <div>
                <p>2. Tap Settings.</p>
                <img src={images.screenshot_menu_settings} alt="Screenshot Menu Settings" /> 
            </div>
            <hr/>
            <div>
                <p>3. Tap Settings.</p>
                <img src={images.site_settings} alt="Site Settings" /> 
            </div>
            <hr/>
            <div>
                <p>4. Tap All Sites.</p>
                <img src={images.all_sites} alt="All Sites" /> 
            </div>
            <hr/>
            <div>
                <p>5. Tap https://care.villagemedical.com</p>
                <img src={images.care_villageplus} alt="Care VillageMedical" /> 
            </div>
            <hr/>
            <div>
                <p>6. Tap the Clear & Reset button.</p>
                <img src={images.clear_reset} alt="Clear Reset" /> 
            </div>
            <hr/>
            <div>
                <p>7. Return to the link your provider emailed or texted to you and follow the instructions to join the virtual visit. </p>
                <img src={images.text_message} alt="Text Message" /> 
            </div>
            <hr/>
            <div>
                <p>8. When prompted to allow access to the camera and microphone, tap Allow.</p>
                <img src={images.chrome_android_allow} alt="Chrome Android Allow" /> 
            </div>
            <hr/>
            <div>
                <p>9. If not prompted to allow access, you may need to reset Chrome's permissions in your device settings. Instructions below.</p>
            </div>
            <hr/>

            <h3>Reset Chrome's Permissions </h3>
            <div>
                <p>1. Exit Chrome and open the Settings application</p>
                <img src={images.settings_icon_android} alt="Settings Icon Android" /> 
            </div>
            <hr/>
            <div>
                <p>2. In Settings Tap on Apps. </p>
                <img src={images.apps_screen} alt="Apps list" />  
            </div>
            <hr/>
            <div>
                <p>3. Tap on Chrome. </p>
                <img src={images.apps_chrome} alt="App Chrome" />
            </div>
            <hr/>
            <div>
                <p>4. Tap on Permissions. </p>
                <img src={images.chrome_app_info} alt="Chrome App Info" /> 
            </div>
            <hr/>
            <div>
                <p>5. Ensure Camera and Microphone are listed under Allowed.</p>
                <img src={images.chrome_permissions} alt="Chrome Permissions" /> 
            </div>
            <hr/>
            <div>
                <p>6. If either Camera or Microphone is not listed under Allowed, tap it and select Allowed. </p>
                <img src={images.chrome_mic_permission} alt="Chrome Mic Permissions" /> 
            </div>
            <hr/>
            <div>
                <p>7. Return to the link your provider emailed or texted to you and follow the instructions to join the virtual visit. </p>
                <img src={images.text_message} alt="Text Message" /> 
            </div>
            <hr/>
            <div>
                <p>8. When prompted to allow access to the camera and microphone, tap Allow.</p>
                <img src={images.chrome_android_allow} alt="Chrome Android Allow" /> 
            </div>
        </div>
    );
};
export default TroubleshootChromeAndroid;