import { Config } from "../shared/types";

const config: Config = {
    "storage": sessionStorage,
    "aws_project_region": "us-east-1",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "telehealthApi",
            "endpoint": "https://thapi.prod.villagemd.com",
            "region": "us-east-1"
        },
        {
            "name": "teleHealthSocket",
            "endpoint": "wss://thmessaging.prod.villagemd.com/ws",
            "region": "us-east-1"
        }
    ],
    "patient_host": ["https://care.villagemedical.com"],
    "provider_host": "https://care.docos.com",

    // For Onelogin Resources
    "onelogin": {
        HOST: "https://villagemd.onelogin.com",
        OIDC_CLIENT_ID: "f2781df0-0ce4-0139-048f-0ae00689599d100286",
        REDIRECT_URI: "https://care.docos.com/oidc/callback"
    },
    // For Azure Resources
    "azure": {
        TENANT_ID: "e2cbf95a-41ca-4a3c-8268-fbfe6502b1a6",
        HOST: "https://login.microsoftonline.com",
        OIDC_CLIENT_ID: "0d542986-b370-446d-94b4-6d81dbc909c4",
        REDIRECT_URI: "https://care.docos.com/azure-oidc/callback"
    },
    // For Opentok resources
    "opentok": {
        API_KEY: "47121844"
    },
    // Countly
    "countly": {
        app_key: "52ab9b1b857b3dd3ab54f26d635ed1149b6bd8c3",
        url: "https://countly.docos.com"
    },
    "sentry_dsn": "https://3fbaf9492b134272af9e295b9b98d590@o47892.ingest.sentry.io/5958949",
    "POLLING_INTERVAL_MS": -1,
    "GCP_CAPTCHA_SITE_KEY": "6Lf1h0weAAAAAGFfLTF-x-Ca29-t74u3YlUIf1UP",
};

export default config;
