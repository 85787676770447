import React, { useRef, useState } from 'react';
import * as Styled from './styles';
import { MenuItem } from '@material-ui/core';

type EndBtnProps = {
    onClick: () => void,
}
const EndBtn = ({ onClick }: EndBtnProps) => {

    const [active, setActive] = useState(false);
    const anchorEl = useRef<HTMLButtonElement>(null);
    const handleOpen = () => setActive(true);
    const handleClose = () => setActive(false);
    return (
        <>
            <Styled.ActionEndBtn active={active} onClick={handleOpen} ref={anchorEl} id="btn-end">
                {active ? "Cancel" : "End"}
            </Styled.ActionEndBtn>
            <Styled.DropdownContent
                open={active}
                anchorEl={anchorEl.current}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handleClose}
            >
                <MenuItem>
                    <Styled.ConfirmEndBtn onClick={onClick} >
                        End Video Visit
                    </Styled.ConfirmEndBtn>
                </MenuItem>
            </Styled.DropdownContent>
        </>
    );
}
export default EndBtn;