import React from 'react';
import { useContext } from 'react';
import StateRouterContext from './StateRouterContext';


type StateRouteProps = {
    component: ReactComponent, 
    children?: JSXElement, 
    view: string,
    [key: string]: any,
}

const StateRoute = ({component, children, view, ...props}: StateRouteProps) => {
    const stateRouter = useContext(StateRouterContext);
    return (
        stateRouter.view === view ? (
            children ? 
                React.createElement(component, {...props, ...stateRouter}, children) 
                    : React.createElement(component, {...props, ...stateRouter})
        ): null);
}
export default StateRoute;