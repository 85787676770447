import styled from 'styled-components/macro';

export const RoomTimer = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(55, 61, 69, 0.8);
    border-radius: 8px;
    top: 13px;
    left: 85px;
    min-width: 90px;
    height: 50px;
    padding: 5px 17px;
    color: #f0f2f3;
    font-size: 18px;
`;