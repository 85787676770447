import React, { useEffect } from 'react';
import Modal from 'shared/Modal/Modal';
import { NO_CONNECTION } from 'utils/Network/constants';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import { CallSessionContextState } from '../../CallScreen/CallSession/CallSessionContext';


type Props = {
    callSession: CallSessionContextState,
}
const ProviderCallDisconnectedModal = ({ callSession }: Props) => {

    useEffect(() => {
        countlyAddEvent(countlyEvents.pageProviderCallDisconnected);
    }, []);

    const shouldShowModal = [
        callSession.networkQuality,
        callSession.subscriberNetworkQuality
    ].indexOf(NO_CONNECTION) > -1;
    
    const gotToDashboard = () => {
        window.location.href = "/dashboard";
    }

    return (
        <Modal isModalFullScreen={true} isOpen={shouldShowModal} white>
            <div className="d-flex flex-row align-items-center mt-2">
                <div className="column">
                    <figure className="image container post-call-icon">
                        <img
                            className="is-horizontal-center ic-band-aid"
                            src="/ic-band-aid.svg"
                            alt="Village medical oops band aid icon"
                        />
                    </figure>
                    <p className="has-text-centered post-call-title">Call disconnected</p>

                    {callSession.networkQuality === NO_CONNECTION ?
                        <p className="post-call-subtitle">
                            Call was disconnected due to poor internet connectivity on your end. 
                            Return to the dashboard and try starting the visit again. 
                        </p>
                        : callSession.subscriberNetworkQuality === NO_CONNECTION ?
                            <p className="post-call-subtitle">
                                Patient is experiencing technical issues. 
                                Consider restarting the call or call the patient over the phone. 
                            </p>
                            : null}

                    <div className="field has-addons has-text-centered">
                        <button className="button is-primary is-medium is-fullwidth mt-4" onClick={gotToDashboard} >
                            <span>Return to Dashboard</span>
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default ProviderCallDisconnectedModal;
