import React, { useState } from 'react';
import { Column } from 'shared/Flex';
import Input from 'shared/inputs/Input2';

import { Button, Checkbox, Radio, Text } from '@village/ui';

type Values = {
    is_completed: boolean,
    who_had_issue: "me" | "patient" | "both" | "not_sure",
    issue_speaker: boolean,
    issue_microphone: boolean,
    issue_camera: boolean,
    issue_video_quality: boolean,
    issue_network_connection: boolean,
    issue_other: boolean,
    other_feedback: string,
}
type Props = {
    onSubmit: (values: Values) => void
};

const PoorQuestionnaire = ({ onSubmit }: Props) => {
    const [values, setValues] = useState<Values>({
        is_completed: true,
        who_had_issue: "not_sure",
        issue_speaker: false,
        issue_microphone: false,
        issue_camera: false,
        issue_video_quality: false,
        issue_network_connection: false,
        issue_other: false,
        other_feedback: ""
    })
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValues((values) => ({
            ...values,
            [e.target.name]: e.target.value
        }));
    }
    const handleSwitch = (value: boolean) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValues((values) => ({
            ...values,
            [e.target.name]: value
        }));
    }
    const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues((values) => ({
            ...values,
            [event.target.name]: event.target.checked
        }));
    }
    const handleSubmit = () => onSubmit(values);

    return (
        <>
            <Column gap={12} padding={0}>
                <Text type='caption1'>Were you able to complete your Virtual Visit?</Text>
                <Column gap={8} padding={0}>
                    <Radio
                        name="is_completed"
                        value={true}
                        checked={values.is_completed}
                        onChange={handleSwitch(true)}
                        label="Yes"
                    />
                    <Radio
                        name="is_completed"
                        value={false}
                        checked={!values.is_completed}
                        onChange={handleSwitch(false)}
                        label="No"
                    />
                </Column>
            </Column>

            <Column gap={12} padding={0}>
                <Text type='caption1'>Who had an issue?</Text>
                <Column gap={8} padding={0}>
                    <Radio
                        name="who_had_issue"
                        value="me"
                        checked={"me" === values.who_had_issue}
                        onChange={handleChange}
                        label="Me"
                    />
                    <Radio
                        name="who_had_issue"
                        value="patient"
                        checked={"patient" === values.who_had_issue}
                        onChange={handleChange}
                        label="Patient"
                    />
                    <Radio
                        name="who_had_issue"
                        value="both"
                        checked={"both" === values.who_had_issue}
                        onChange={handleChange}
                        label="Both"
                    />
                    <Radio
                        name="who_had_issue"
                        value="not_sure"
                        checked={"not_sure" === values.who_had_issue}
                        onChange={handleChange}
                        label="I'm not sure"
                    />
                </Column>
            </Column>

            <Column gap={12} padding={0}>
                <Text type='caption1'>What issue’s did you or the patient experience?</Text>
                <Column gap={8} padding={0}>
                    <Checkbox
                        name="issue_speaker"
                        value={true}
                        checked={values.issue_speaker}
                        onChange={handleChangeCheck}
                        label="Speaker"
                    />
                    <Checkbox
                        name="issue_microphone"
                        value={true}
                        checked={values.issue_microphone}
                        onChange={handleChangeCheck}
                        label="Microphone"
                    />
                    <Checkbox
                        name="issue_camera"
                        value={true}
                        checked={values.issue_camera}
                        onChange={handleChangeCheck}
                        label="Camera"
                    />
                    <Checkbox
                        name="issue_video_quality"
                        value={true}
                        checked={values.issue_video_quality}
                        onChange={handleChangeCheck}
                        label="Video Quality"
                    />
                    <Checkbox
                        name="issue_network_connection"
                        value={true}
                        checked={values.issue_network_connection}
                        onChange={handleChangeCheck}
                        label="Network Connection"
                    />
                    <Checkbox
                        name="issue_other"
                        value={true}
                        checked={values.issue_other}
                        onChange={handleChangeCheck}
                        label="Other (please specify in the input below)"
                    />
                </Column>
            </Column>
            <Column gap={12} padding={0}>
                <Text type='caption1'>Any other feedback?</Text>
                <Input
                    label=''
                    type='textarea'
                    name='other_feedback'
                    data-testid='other_feedback'
                    fullWidth
                    value={values.other_feedback}
                    onChange={handleChange}
                    rows={10}
                />
            </Column>
            <Button size="medium" fullWidth onClick={handleSubmit} >
                Send feedback
            </Button>
        </>
    );
}
export default PoorQuestionnaire;