import JwtDecode from "jwt-decode";
import { countlyAddEvent } from "countly";
import countlyEvents from "countly/events";
import HttpClient from 'utils/HttpClient';
import ServerTime from 'utils/ServerTime';
import type { AuthData, UserOrigin } from 'shared/types';

class SSOAuth {
    
    storageKey: string = "VMD_Authorization";
    storage: Storage = sessionStorage;
    redirectLoginUrl?: string;
    refreshingToken: boolean = false;

    setRedirectLoginUrl(relative_url: string): void {
        this.redirectLoginUrl = relative_url;
    }
    setAuthData(authData: AuthData): void {
        this.storage.setItem(this.storageKey, JSON.stringify(authData));
    }
    getAuthData(): AuthData | never {
        let authData: any = this.storage.getItem(this.storageKey);
        if(!authData){
            throw Error("AuthData not found")
        }

        try {
            authData = JSON.parse(authData);
        } catch (error: any) {
            countlyAddEvent(countlyEvents.error, {
                errorCode: error.name,
                errorDescription: error.message,
                comment: "JSON parse failed on authData", 
            });
            throw Error("AuthData not found")
        }
        return authData;
    }
    getIdP(): UserOrigin | never {
        let authData = this.getAuthData();
        if(authData.userData.issuer) {
            return authData.userData.issuer
        }
        throw Error("Issuer is missing on authData")
    }
    getAccessToken(): string | undefined {
        try {
            let accessToken = this.getAuthData().accessToken;
            return !this.isAccessTokenExpired() ? accessToken : undefined;
        } catch {
            return undefined
        }
    }
    getIdToken(): string | undefined  {
        try {
            let idToken = this.getAuthData().idToken;
            return !this.isIdTokenExpired() ? idToken : undefined;
        } catch {
            return undefined
        }
    }
    isJwtTokenExpired(token: string): boolean {
        try {

            const payload: any = JwtDecode(token);
            let currentTimestamp = ServerTime.getServerTimeStampNow(); //(new Date()).getTime() / 1000;
            if ('exp' in payload && currentTimestamp > payload.exp) {
                return true
            }

            return false;

        } catch (error: any) {
            countlyAddEvent(countlyEvents.error, {
                errorCode: error.name,
                errorDescription: error.message,
                comment: "Can't decode JWT",
            });
            return true;
        }

    }
    isAccessTokenExpired(): boolean {
        try {
            let authData = this.getAuthData();
            let token = authData.accessToken;
            return this.isJwtTokenExpired(token);
        } catch {
            return true
        }
    }
    isRefreshTokenExpired(): boolean {
        try {
            let authData = this.getAuthData();
            let token = authData.refreshToken;
            return this.isJwtTokenExpired(token);
        } catch {
            return true
        }
    }
    isIdTokenExpired(): boolean {
        try {
            let authData = this.getAuthData();
            let token = authData.idToken;
            return this.isJwtTokenExpired(token);   
        } catch {
            return true
        }
    }
    isValidAuthData(): boolean {
        //Notes: this should be run at the initial execution otherwise user should be redirected into loginPage
        return !(this.isAccessTokenExpired() && this.isRefreshTokenExpired());
    }
    getRefreshToken(): string | undefined {
        try {
            let refreshToken = this.getAuthData().refreshToken;
            return !this.isRefreshTokenExpired() ? refreshToken : undefined;
        } catch {
            return undefined
        }
    }
    async refreshTokens(): Promise<void> {
        if (this.refreshingToken) return;
        let authData: AuthData;
        try {
            authData = this.getAuthData();
        } catch {
            return;
        }

        if (this.isRefreshTokenExpired()) {
            this.signOut();
            window.location.reload();
            return;
        }
        this.refreshingToken = true;

        let apiName = 'telehealthApi';
        let path = `/users/oidc/refresh_token?idp=${this.getIdP()}`;
        let params = {
            refresh_token: this.getRefreshToken(),
        };

        try {
            let response = await HttpClient().post(apiName, path, params);
            let newAuthData = {
                ...authData,
                accessToken: response.accessToken,
                idToken: response.idToken
            };
            this.setAuthData(newAuthData);

        } catch (error: any) {
            countlyAddEvent(countlyEvents.error, {
                errorCode: error.name,
                errorDescription: error.message,
                comment: "Failed to refresh token",
                response: JSON.stringify(error.response)
            });
            this.signOut();
            window.location.reload();
            return;
        }
        
        this.refreshingToken = false;
    }
    signOut(): void {
        this.storage.removeItem(this.storageKey);
    }
}
export default (new SSOAuth());