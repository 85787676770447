import styled from 'styled-components/macro';
import ReactDatePicker from 'react-date-picker';
import { Text } from "@village/ui";
import { rem } from "polished";

export interface LabelProps {
    readonly htmlFor?: string;
}

export const InputContainer = styled.div`
    position: relative;
`;

export const Label = styled(Text).attrs((props: LabelProps) => ({
    tag: 'label',
    htmlFor: props.htmlFor
})) <LabelProps>`
    display: block;
    margin-bottom: ${rem(6)};
    color: ${({ theme }) => theme.vui.colors.text.grayMuted};
`;

export const DatePicker = styled(ReactDatePicker)<{$status: "default" | "disabled" | "error"}>`
    border-width: ${({ theme, $status }) => theme.vui.component.input[$status].default.borderWidth}px;
    border-style: solid;
    border-color: ${({ theme, $status }) => theme.vui.component.input[$status].default.borderColor};

    border-radius: 4px;
    padding: 10px 12px;
    color: ${({ theme, $status }) => theme.vui.component.input[$status].default.textColor};
    background-color: ${({ theme, $status }) => theme.vui.component.input[$status].default.backgroundColor};

    &:hover {
        border-color: ${({ theme, $status }) => theme.vui.component.input[$status].hover.borderColor};
    }
    
    &.react-date-picker--open {
        border-color: ${({ theme, $status }) => theme.vui.component.input[$status].focus.borderColor};
        border-width: ${({ theme, $status }) => theme.vui.component.input[$status].focus.borderWidth}px;
        padding: 9px 11px;
    }

    .react-date-picker--disabled {
        background-color: ${({ theme, $status }) => theme.vui.component.input[$status].default.backgroundColor};
        border-color: ${({ theme, $status }) => theme.vui.component.input[$status].default.borderColor};
        color: ${({ theme, $status }) => theme.vui.component.input[$status].default.textColor};
    }

    .react-date-picker__wrapper {
        border: none;
    }
    .react-date-picker__button:enabled:hover .react-date-picker__button__icon, 
    .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
        stroke: ${({ theme, $status }) => theme.vui.component.input[$status].hover.borderColor};
    }
`;