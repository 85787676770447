import { SSOUserData, UserOrigin } from "shared/types";

class SSOUser {

    type: UserOrigin
    originalUser: SSOUserData

    constructor(user: any) {
        this.originalUser = user;
        this.type = user.issuer
    }
    get attributes() {
        return this.originalUser;
    }
    get sub() {
        return this.attributes.sub;
    }
    get email() {
        return this.attributes.email;
    }
    set email(value) {
        
    }
    get username() {
        return this.attributes.username;
    }
    set username(value) {

    }
    get fullName() {
        return this.attributes.full_name;
    }
}
export default SSOUser;