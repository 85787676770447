import React from 'react';
import type { ReactElement } from 'react';
import { components as baseComponents } from 'react-select';
import type { ClearIndicatorProps, NoticeProps, OptionProps } from 'react-select';

import * as Styled from './styles';
import type { OptionBase, OptionGroupBase } from './types';

// These are custom elements we are creating for the initial version of the autocomplete design.
// They can be overwritten by passing in an element to the <Autocomplete /> component with the corresponding name.

const ClearIndicator = <Option extends OptionBase, OptionGroup extends OptionGroupBase<Option>>({
    ...props
}: ClearIndicatorProps<Option, boolean, OptionGroup>): ReactElement => {
    const { innerProps } = props;

    return (
        <div {...innerProps}>
            <Styled.CancelButton size={0.75} />
        </div>
    );
};

const NoOptionsMessage = <Option extends OptionBase, OptionGroup extends OptionGroupBase<Option>>({
    ...props
}: NoticeProps<Option, boolean, OptionGroup>): ReactElement | null => (
    <baseComponents.NoOptionsMessage {...props}>
        <Styled.AutocompleteOptionDetails>
            {props.selectProps.noOptionsMessage({ inputValue: props.selectProps.inputValue }) ?? 'No items found.'}
        </Styled.AutocompleteOptionDetails>
    </baseComponents.NoOptionsMessage>
);

const Option = <Option extends OptionBase, OptionGroup extends OptionGroupBase<Option>>({
    ...props
}: OptionProps<Option, boolean, OptionGroup>): ReactElement => {
    const { children, innerRef, innerProps, isSelected } = props;

    return (
        <div ref={innerRef} {...innerProps}>
            <Styled.AutocompleteOption isSelected={isSelected}>
                <Styled.AutocompleteOptionDetails
                    icon={isSelected ? <Styled.SelectedIcon size={1.5} /> : null}
                    isSelected={isSelected}
                >
                    <div>{children}</div>
                </Styled.AutocompleteOptionDetails>
            </Styled.AutocompleteOption>
        </div>
    );
};

const OptionWithImage = <Option extends OptionBase, OptionGroup extends OptionGroupBase<Option>>({
    ...props
}: OptionProps<Option, boolean, OptionGroup>): ReactElement => {
    const { children, data, innerRef, innerProps, isSelected } = props;
    const { img } = data;
    const roundedProviderIcon = (
        <Styled.RoundProviderImageInListItemContainer>
            <Styled.RoundProviderImageInListItem src={img} />
        </Styled.RoundProviderImageInListItemContainer>
    );

    return (
        <div ref={innerRef} {...innerProps}>
            <Styled.AutocompleteOptionWithImage isSelected={isSelected}>
                <Styled.AutocompleteOptionDetailsWithImage
                    icon={props.isSelected ? <Styled.SelectedIcon size={1.5} /> : ''}
                    picture={roundedProviderIcon}
                    isSelected={isSelected}
                >
                    <div>{children}</div>
                </Styled.AutocompleteOptionDetailsWithImage>
            </Styled.AutocompleteOptionWithImage>
        </div>
    );
};

export const components = {
    ClearIndicator,
    NoOptionsMessage,
    Option,
    OptionWithImage,
};
