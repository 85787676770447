import React from 'react';
import ListWaitingRooms from './ListWaitingRooms';
import FormWaitingRooms from './FormWaitingRooms';
import MyRoute from 'core/router/MyRoute';
import HttpClient from 'utils/HttpClient';
import { hasPermission } from 'utils/Authorizer';
import MessageInline from 'shared/MessageInline';
import { debounce } from 'utils/Basics';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import { IUser, IWaitingRoom, WaitingRoom } from 'shared/types';
import { IMessage, MessageType } from 'shared/Messages/types';
import { Column } from 'shared/Flex';


type WaitingRoomsPageProps = {
    authUser: IUser
}

type WaitingRoomsPageState = {
    loading: boolean,
    page: number,
    perPage: number,
    search: string,
    message?: IMessage,
    waiting_rooms: WaitingRoom[],
}
class WaitingRoomsPage extends React.Component<WaitingRoomsPageProps, WaitingRoomsPageState> {
    
    noLoadMore?: boolean

    constructor(props: WaitingRoomsPageProps){
        super(props);
        this.state = {
            loading: false,
            page: 0,
            perPage: 30,
            search: "",
            waiting_rooms: []
        };
    }
    componentDidMount(){
        if( hasPermission('GET', '/waiting_rooms') ){
            this.loadWaitingRooms();        
        }
        document.getElementById('content')?.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        document.getElementById('content')?.removeEventListener('scroll', this.handleScroll);
    }
    getWaitingRooms(): WaitingRoom[] {
        if( hasPermission('GET', '/waiting_rooms') ){
            return this.state.waiting_rooms;
        }else{
            const authUser = this.props.authUser;
            return authUser.waiting_rooms ? authUser.waiting_rooms : [];
        }
    }
    loadWaitingRooms(page: number = 0): void {
        let apiName = 'telehealthApi';
        let path = '/waiting_rooms'; 
        let queryParams = {
            skip: page * this.state.perPage,
            limit: this.state.perPage,
            search: this.state.search
        };

        this.setState({
            loading: true,
            waiting_rooms: page ? this.state.waiting_rooms : []
        });
        HttpClient().get(apiName, path, queryParams)
        .then( (data) => {
            this.setState({
                loading: false,
                page: page,
                waiting_rooms: [...this.state.waiting_rooms, ...data]
            });
            this.noLoadMore = false;
            if (data.length < this.state.perPage) {
                this.noLoadMore = true;
            }
        }).catch((error) => {
            this.setState({
                loading: false
            });
        });
    }
    handleScroll = (e: Event): void => {
        debounce(() => {
            const element = e.target as Element
            if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                element.scrollTop--;
                if (!this.noLoadMore) {
                    this.loadWaitingRooms(this.state.page + 1);
                }
            }
        }, 200);
    }
    handleSearch = (event: any): void => {
        this.setState({ search: event?.target?.value });
        debounce(() => this.loadWaitingRooms(), 400);
    }
    newWaitingRoom = (waiting_room: WaitingRoom): void => {
        this.setState({
            waiting_rooms: [waiting_room, ...this.state.waiting_rooms]
        });
    }
    updateWaitingRoom = (waiting_room_id: number, data: IWaitingRoom) => {
        this.setState({
            waiting_rooms: this.state.waiting_rooms.map((item) => {
                return item.id === waiting_room_id ? { ...item, ...data} : item;
            })
        });
    }
    handleRemove = (waiting_room: WaitingRoom) => (): void => {
        let isConfirmed = window.confirm("Are you sure you want to remove?");
        if( isConfirmed ){
            let apiName = 'telehealthApi';
            let path = '/waiting_rooms/'+waiting_room.id; 
            this.setState({
                loading: true
            });
            HttpClient().delete(apiName, path)
            .then( (data) => {
                this.setState({
                    loading: false,
                    waiting_rooms: this.state.waiting_rooms.filter((item) => item.id !== waiting_room.id)
                });
            }).catch((error) => {
                this.setState({
                    loading: false,
                    message: {
                        type: MessageType.danger,
                        message: error.response?.data?.detail || "Failed to remove waiting room"
                    }
                });
                countlyAddEvent(countlyEvents.error, {
                    errorCode: error.name,
                    errorDescription: error.message,
                    comment: "Failed to remove waiting room", 
                    response: JSON.stringify(error.response)
                });
            });
        }
    }

    render(){

        const message = this.state.message;
        return (
            <Column grow scrollable>
                {message &&
                    <MessageInline type={message.type} message={message.message} />
                }
                <ListWaitingRooms 
                    waiting_rooms={this.getWaitingRooms()} 
                    loading={this.state.loading}
                    search={this.state.search}
                    onSearch={this.handleSearch}
                    onRemove={this.handleRemove}
                />
                <MyRoute 
                    exact 
                    path={"/waiting_rooms/new"} 
                    title={"New waiting room"} 
                    mode="new" 
                    onCreate={this.newWaitingRoom}
                    component={FormWaitingRooms} 
                />
                <MyRoute 
                    exact 
                    path={"/waiting_rooms/edit/:id"} 
                    title={"Edit waiting room"} 
                    mode="edit" 
                    onUpdate={this.updateWaitingRoom}
                    component={FormWaitingRooms} 
                />
            </Column>
        );
    }
}
export default WaitingRoomsPage;