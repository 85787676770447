import React, { useEffect, useRef } from 'react';
import Loading from 'shared/Loading';
import * as Styled from './styles';

type Props = {
    isVideoDisabled: boolean,
    isAudioDisabled: boolean,
    participantName?: string,
    onTimeout: () => void,
    onEnableAudioVideo: () => void
}

const WaitingParticipant = ({
    isVideoDisabled,
    isAudioDisabled,
    participantName,
    onTimeout,
    onEnableAudioVideo
}: Props) => {

    const mounted = useRef(false);

    const startTimer = () => {
        setTimeout(() => {
            if (mounted.current) onTimeout()
        }, 30_000);
    }

    useEffect(() => {
        mounted.current = true;
        startTimer();

        return () => { mounted.current = false; };
    });

    const isVisibleBtnEnableAudioVideo = () => {
        return isVideoDisabled || isAudioDisabled
    }

    const getTextBtnEnableAudioVideo = () => {
        let disabledStreams = [];
        if (isVideoDisabled) disabledStreams.push('Video');
        if (isAudioDisabled) disabledStreams.push('Sound');

        return disabledStreams.join(' and ');
    }

    return (
        <Styled.WaitingParticipant>
            <Styled.Content>
                <Loading type="BulmaSpinner" color="#FFFFFF" />
                <Styled.Title type='h5'>Please wait a few moments</Styled.Title>
                <Styled.Subtitle type='body1'>
                    Finalizing connection {participantName && `with ${participantName}`}
                </Styled.Subtitle>
                {isVisibleBtnEnableAudioVideo() &&
                    <Styled.ButtonEnableAudioVideo onClick={onEnableAudioVideo} >
                        Turn on {getTextBtnEnableAudioVideo()}
                    </Styled.ButtonEnableAudioVideo>
                }
            </Styled.Content>
        </Styled.WaitingParticipant>
    );
}
export default WaitingParticipant;
