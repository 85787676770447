import React from 'react';
import ProviderSidebar from './partials/ProviderSidebar';
import UpdateChecker from './partials/UpdateChecker';
import Messages from '../shared/Messages/Messages';
import EnvironmentBanner from '../shared/EnvironmentBanner';
import ConfigFactory from '../ConfigFactory';
import Feature from '../shared/Feature';
import FF from '../shared/constants/FF';
import SystemMessage from '../shared/SystemMessage';
import { Container, Row } from 'shared/Flex';

type Props = {
	children: JSXElement
}
const ProviderLayout = ({ children }: Props) => {
	return (
		<Container direction='column' className='App fullheight'>
			<Row grow alignItems="stretch" gap={0} className="fullheight">
				<ProviderSidebar />
				<Container grow direction='column' id="content" className='has-background-light'>
					<UpdateChecker env={ConfigFactory.getEnv()} />
					<EnvironmentBanner env={ConfigFactory.getEnv()} />
					<Feature item code={FF.messageProviderTop} >
						<SystemMessage keyName="message_provider_top" />
					</Feature>
					<Messages />
					{children}
				</Container>
			</Row>
		</Container>
	);
}
export default ProviderLayout;