import React, { ReactNode } from 'react';
import * as Styled from './styles';

type FeedbackButtonProps = {
    name?: string,
    value: any,
    onClick: (value: any) => void,
    checked: boolean,
    children: ReactNode
};
export const FeedbackButton = ({value, checked, onClick, children}: FeedbackButtonProps) => {
    return (
        <Styled.FeedbackButton onClick={() => onClick(!checked ? value : undefined)} checked={checked} >
            {children}
        </Styled.FeedbackButton>
    );
}
