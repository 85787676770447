import React from 'react';
import MessagesContext from './MessagesContext';


function withMessages(WrappedComponent: ReactComponent) {
    class withMessages extends React.Component<any, {}> {
        displayName: string = 'withMessages'
        static WrappedComponent: ReactComponent;

        constructor(props: any) {
            super(props);
            this.state = {};
        }
        render() {
            return (
                <MessagesContext.Consumer>
                    {(context) =>
                        <WrappedComponent {...context} {...this.props} />
                    }
                </MessagesContext.Consumer>
            );
        }
    };
    withMessages.WrappedComponent = WrappedComponent;
    return withMessages;
}
export default withMessages;

