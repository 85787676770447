import React, { ReactNode } from 'react';
import type { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import { theme } from 'theme';
import { GlobalStyles as VillageGlobalStyles } from '@village/ui';

interface Props {
    children: ReactNode
}

const VillageUIThemeProvider: FC<Props> = ({ children }) => {
    return (
        <StylesProvider injectFirst={true}>
            <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                    <VillageGlobalStyles />
                    {children}
                </ThemeProvider>
            </MuiThemeProvider>
        </StylesProvider>
    );
}
export default VillageUIThemeProvider;