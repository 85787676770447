import { rem } from 'polished';
import styled from 'styled-components/macro';
import { defaultVillageUiTheme as theme } from '@village/ui';
import { LeaveBtn } from '../LeaveBtn/styles';
import { Menu } from '@material-ui/core';

export const DropdownContent = styled(Menu)`
    .MuiPaper-root {
        margin: ${rem(4)} 0 0 ${rem(4)};
        background-color: ${theme.vui.colors.decorative.neutral.gray90};
        opacity: 0.96;
        border-radius: ${rem(16)};
        padding: ${rem(32)} ${rem(24)};
        @media screen and (max-width: 767px) {
            width: 100%;
            margin-left: 0;
        }
    }

    .MuiButtonBase-root {
        background-color: #4C535C;
        color: ${theme.vui.colors.text.emphasis}
    }

    .MuiListItem-root {
        padding: 0;
        background-color: transparent;
    }
`;

export const ActionEndBtn = styled(LeaveBtn) <{ active?: boolean }>`
    min-width: 117px;
    max-height: 48px;
    
    ${({ active }) => active && `
        background-color: #4C535C;
        
        &:hover {
            background-color: #626A74;
        }

        &:active {
            background-color: #373D45;
        }
    `}
`;

export const ConfirmEndBtn = styled(LeaveBtn)`
    min-width: 303px;
    @media screen and (max-width: 767px) {
        min-width: 100%;
    }
`;