import { TabProps } from '@material-ui/core';
import { createContext } from 'react';

type TabsContextState = {
    activeTab: TabProps['value'],
    setActive: (activeTab: TabProps['value']) => void,
}
const TabsContext = createContext<TabsContextState>({
    activeTab: 0,
    setActive: () => { },
});

export default TabsContext;