import React from 'react';
import { 
    BAD_BITRATE_THRESHOLD, 
    BAD_PACKETLOSS_THRESHOLD, 
    GOOD_BITRATE_THRESHOLD, 
    GOOD_PACKETLOSS_THRESHOLD 
} from 'utils/Network/constants';
import type { NetworkMetrics } from 'utils/Network/types';
import { Level } from './types';
import * as Styled from './styles';

type PeerMetricsProps = {
    name: string,
    metrics?: NetworkMetrics
}
const PeerMetrics = ({ name, metrics }: PeerMetricsProps) => {
    const { bitrate, packetLoss } = metrics || {};
    const bitrateLevel = (bitrate?: NetworkMetrics['bitrate']): Level => {
        if(!bitrate) return "neutral";

        const bitrateBps = bitrate?.combined;
        if(bitrateBps > GOOD_BITRATE_THRESHOLD){
            return "very_good";
        } else if(bitrateBps > BAD_BITRATE_THRESHOLD) {
            return "good";
        } else {
            return "bad";
        }
    }
    const packetLossLevel = (packetLoss?: NetworkMetrics['packetLoss']): Level => {
        if(!packetLoss) return "neutral";

        const packetLossRatio = Math.round(packetLoss?.combined * 100) / 100;
        if(packetLossRatio < GOOD_PACKETLOSS_THRESHOLD){
            return "very_good";
        } else if(packetLossRatio < BAD_PACKETLOSS_THRESHOLD) {
            return "good";
        } else {
            return "bad";
        }
    }
    return (
        <Styled.PeerMetrics>
            <Styled.Name>{name}</Styled.Name>
            <Styled.InfoItem level={bitrateLevel(bitrate)}>
                <Styled.ItemLabel>BITRATE</Styled.ItemLabel>
                {bitrate ? `${Math.round(bitrate?.combined / 1000)} Kbps` : "..."}
            </Styled.InfoItem>
            <Styled.InfoItem level={packetLossLevel(packetLoss)}>
                <Styled.ItemLabel>LOSSES</Styled.ItemLabel>
                {packetLoss ? `${Math.round(packetLoss?.combined * 100) / 100}%` : "..."}
            </Styled.InfoItem>
        </Styled.PeerMetrics>
    );
}
export default PeerMetrics;