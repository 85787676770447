import React from 'react';
import VillageUIThemeProvider from './VillageUIThemeProvider';
import { Switch } from 'react-router-dom';
import { MyRoute } from 'core/router';
import PatientRoomPage from 'pages/PatientRoom/PatientRoomPage';
import Patient404Page from 'pages/Patient404Page';

type Props = {
    no404: boolean
};
const PatientApp = ({ no404 }: Props) => {
    return (
        <VillageUIThemeProvider>
            <Switch>
                <MyRoute exact path='/:room' onlyPublic component={PatientRoomPage} />
                {!no404 && <MyRoute path="/" onlyPublic component={Patient404Page} />}
            </Switch>
        </VillageUIThemeProvider>
    );
}
export default PatientApp;