import React from 'react';
import Avatar from 'shared/Avatar';
import * as Styled from './styles';

type VideoAvatarProps = {
	text: string
}
const VideoAvatar = ({ text }: VideoAvatarProps) => {
	const part = text.split(' ');

	return (
		<Styled.VideoAvatar id="video-avatar">
			<Avatar first_name={part[0]} last_name={part[1]} avatarColor="rgba(122, 129, 139, 0.4)" />
		</Styled.VideoAvatar>
	);
}
export default VideoAvatar;