import React from 'react';
import consumeApp from 'providers/consumeApp';
import withClinicalRoom from 'providers/Room/withClinicalRoom';
import InviteCard from './components/InviteCard';
import InviteLogs from './components/InviteLogs';
import PatientQueue from './components/PatientQueue';
import { IUser, Session, WaitingRoom } from 'shared/types';
import SESSION_STATUS from 'shared/constants/SESSION_STATUS';
import { Text } from '@village/ui';
import { Column, Container } from 'shared/Flex';
import { TabsContainer, TabPanel } from 'village-ui/Tabs';
import * as Styled from './styles';
import Loading from 'shared/Loading';

type DashboardPageProps = {
    authUser: IUser,
    sessions: Session[],
    waiting_rooms: WaitingRoom[],
    isUserReady: boolean,
    handleUpdatePatientStatus: (
        sessionId: number,
        patientId: number,
        newPatientStatus: SESSION_STATUS,
        kickedOut: boolean
    ) => () => void,
}
const DashboardPage = ({
    authUser,
    sessions,
    waiting_rooms,
    isUserReady,
    handleUpdatePatientStatus
}: DashboardPageProps) => {

    const tabLabelPatientQueue = "Patient Queue";
    const tabLabelInviteLogs = "Invite Logs";

    return (
        isUserReady ?
            <Column grow scrollable>
                <Text type="h4">Welcome, {authUser.fullName}!</Text>
                <InviteCard />
                <TabsContainer default="patientQueue">
                    <Styled.TabList variant="standard" >
                        <Styled.Tab label={tabLabelPatientQueue} value="patientQueue" />
                        <Styled.Tab label={tabLabelInviteLogs} value="inviteLogs" />
                    </Styled.TabList>
                    <TabPanel value="patientQueue">
                        <PatientQueue
                            authUser={authUser}
                            sessions={sessions}
                            onUpdatePatientStatus={handleUpdatePatientStatus}
                        />
                    </TabPanel>
                    <TabPanel value="inviteLogs">
                        <InviteLogs
                            waiting_rooms={waiting_rooms}
                            provider_id={authUser.id}
                        />
                    </TabPanel>
                </TabsContainer>
            </Column>
        : <Container grow alignItems="center" justifyContent="center" >
            <Loading />
        </Container>
    );
}
export default consumeApp(withClinicalRoom(DashboardPage));
