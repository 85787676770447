import React from 'react';
import withAudioLevel from '../../../AudioLevel/withAudioLevel';
import { OpentokPublisher } from '../../../opentok/types';
import * as Styled from './styles';
import { Icon } from 'icon';

enum MicDataSource {
    props = "props",
    state = "state",
}
type TestMicrophoneProps = {
    audioLevel: number,
    audioSource: string,
    isAudioOn: boolean,
    settingsPublisher: OpentokPublisher
}
type TestMicrophoneState = {
    isReady: boolean,
    source: MicDataSource,
    audioLevel: number,
}
class TestMicrophone extends React.Component<TestMicrophoneProps, TestMicrophoneState> {
    
    _unmounted?: boolean
    
    constructor(props: TestMicrophoneProps) {
        super(props);
        this.state = {
            isReady: false,
            source: MicDataSource.state,
            audioLevel: 0,
        }
    }
    componentDidMount(){    
        this.listenAudioLevelUpdated();
        this.setAudioLevelSource();
    }
    componentDidUpdate(prevProps: TestMicrophoneProps) {
        if (prevProps.audioSource !== this.props.audioSource) {
            this.setAudioLevelSource();
        }
        if(prevProps.isAudioOn !== this.props.isAudioOn) {
            this.setAudioLevelSource();
        }
    }
    componentWillUnmount(){
        this._unmounted = true;
    }
    listenAudioLevelUpdated = (): void => {
        if(this.props.settingsPublisher) {
            this.props.settingsPublisher.on('audioLevelUpdated', (event: any) => {
                if(this._unmounted) return;

                const volume = event.audioLevel > 0 ? Math.round(event.audioLevel*100)+10 : 0
                this.setState({audioLevel: volume});
            });
        } else {
            setTimeout(() => this.listenAudioLevelUpdated(), 1000);
        }
    }
    setAudioLevelSource = (): void => {
        this.setState({isReady: false});
        if (this.props.isAudioOn) {
            this.setState({source: MicDataSource.props, isReady: true});
        } else {
            this.setState({source: MicDataSource.state, isReady: true});
        }
    }
    render(){
        const audioLevel = this.state.source === MicDataSource.state ? 
                                this.state.audioLevel 
                                    : this.props.audioLevel;
        return (
            this.state.isReady ?
                <TestMicrophoneUI audioLevel={audioLevel} isAudioOn={this.props.isAudioOn} /> 
            : null
        );
    }

}

type TestMicrophoneUIProps = {
    audioLevel: number, 
    isAudioOn: boolean,
}
const TestMicrophoneUI = ({ audioLevel, isAudioOn }: TestMicrophoneUIProps) => {
    const calculateVolume = (slotIndex: number): boolean => {
        return (audioLevel - 10)/100 > slotIndex/14;
    }
    return (
        <Styled.MicTestContainer>
            <Styled.MicTest>
                <Styled.AudioSlot className={calculateVolume(0) ? 'green' : ''} />
                <Styled.AudioSlot className={calculateVolume(1) ? 'green' : ''} />
                <Styled.AudioSlot className={calculateVolume(2) ? 'green' : ''} />
                <Styled.AudioSlot className={calculateVolume(3) ? 'green' : ''} />
                <Styled.AudioSlot className={calculateVolume(4) ? 'green' : ''} />
                <Styled.AudioSlot className={calculateVolume(5) ? 'green' : ''} />
                <Styled.AudioSlot className={calculateVolume(6) ? 'green' : ''} />
                <Styled.AudioSlot className={calculateVolume(7) ? 'green' : ''} />
                <Styled.AudioSlot className={calculateVolume(8) ? 'green' : ''} />
                <Styled.AudioSlot className={calculateVolume(9) ? 'green' : ''} />
                <Styled.AudioSlot className={calculateVolume(10) ? 'green' : ''} />
                <Styled.AudioSlot className={calculateVolume(11) ? 'green' : ''} />
                <Styled.AudioSlot className={calculateVolume(12) ? 'green' : ''} />
                <Styled.AudioSlot className={calculateVolume(13) ? 'green' : ''} />
            </Styled.MicTest>
            {!isAudioOn &&
                <Styled.MicMuted>
                    <Icon name="info_gray" size={1} />&nbsp;You’re currently muted
                </Styled.MicMuted>
            }
        </Styled.MicTestContainer>
    );
}
export default withAudioLevel(TestMicrophone);