import React, { useEffect } from 'react';
import qs from 'qs';
import Loading from 'shared/Loading';
import { CenteredContainer } from 'shared/PageLayout';
import ConfigFactory from 'ConfigFactory';
import { AzureConfig } from 'shared/types';

const OidcAzureLoginPage = () => {

    useEffect(() => {
        redirectToAuthURL();
    }, [])

    const redirectToAuthURL = (): void => {
        /*
            Generate the redirect_uri with response_type, client_id, scope, redirect_uri,
        */
        const AZURE_CONFIG = ConfigFactory.getSSOConfig("azure") as AzureConfig;

        let params = {
            'response_type': 'code',
            'scope': 'openid profile User.Read',
            'client_id': AZURE_CONFIG.OIDC_CLIENT_ID,
            'redirect_uri': AZURE_CONFIG.REDIRECT_URI
        }
        const authUrl = `${AZURE_CONFIG.HOST}/${AZURE_CONFIG.TENANT_ID}/oauth2/v2.0/authorize?${qs.stringify(params)}`
        window.location.assign(authUrl);
    }

    return (
        <CenteredContainer>
            <Loading />
        </CenteredContainer>
    );
}
export default OidcAzureLoginPage;