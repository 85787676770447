class NormalDecorator {
    response(data: any): any{
        return data;
    }
    postData(data: any): any{
        return data;
    }
    queryParams(data: any): any{
        return data;
    }
}
export default NormalDecorator;