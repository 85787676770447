import { OpentokPublisher, OpentokSubscriber } from "pages/CallScreen/opentok/types";

enum NetworkQualityState {
    AUDIO_VIDEO_SUPPORTED = 3,
    VIDEO_QUALITY_AUDIO_ONLY = 2,
    AUDIO_QUALITY_BANDWIDTH_TOO_LOW = 1,
    TRYING_TO_CONNECT = 0,
    NO_CONNECTION = -1,
}
export enum NetworkAssessment {
    bad = -1,
    neutral = 0,
    good = 1,
}
type OpentokPeeObject = OpentokSubscriber | OpentokPublisher
type OpentokPeerType = "publisher" | "subscriber"
type OpentokSubscriberStats = {
    audio: {
        packetsReceived: number,
        bytesReceived: number,
        packetsLost: number,
    },
    video: {
        bytesReceived: number,
        packetsReceived: number,
        packetsLost: number,
        frameRate: number,
    },
    timestamp: number
}
type OpentokPublisherStats = {
    audio: {
        packetsSent: number,
        bytesSent: number,
        packetsLost: number,
    },
    video: {
        bytesSent: number,
        packetsSent: number,
        packetsLost: number,
        frameRate: number,
    },
    timestamp: number
}
type PeerStats = {
    audio: {
        packetsTransfered: number,
        bytesTransfered: number,
        packetsLost: number,
    },
    video: {
        bytesTransfered: number,
        packetsTransfered: number,
        packetsLost: number,
        frameRate: number,
    },
    timestamp: number
}
type NetworkMetrics = {
    bitrate: {
        audio: number,
        video: number,
        combined: number,
    },
    packetLoss: {
        audio: number,
        video: number,
        combined: number,
    },
}
type NetworkQuality = {
    supported: {
        video: boolean,
        audio: boolean,
    },
    state: NetworkQualityState
}
type NetworkStats = {
    average: NetworkMetrics,
    lastState: NetworkMetrics,
    quality: NetworkQuality,
}
type NetworkStatsHandler = (networkStats: NetworkStats) => void
type OnlineStatusHandler = (quality: NetworkQualityState) => void

export type { 
    NetworkQualityState, 
    OpentokPeeObject,
    OpentokPeerType,
    OpentokSubscriberStats,
    OpentokPublisherStats,
    PeerStats,
    NetworkMetrics,
    NetworkQuality,
    NetworkStats,
    NetworkStatsHandler,
    OnlineStatusHandler,
}