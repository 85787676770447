import React from 'react';
import * as Styled from './styles';
import { Icon } from 'icon';

type MediaStatusProps = {
    hasAudio?: boolean
    hasVideo?: boolean
}

const MediaStatus = ({ hasAudio, hasVideo }: MediaStatusProps) => {
    return (
        <Styled.MediaStatus>
            <Styled.MediaStatusItem className={hasVideo ? "on" : "off"}>
                <Icon name={hasVideo ? "videocam" : "videocam_off"} size={1.5} />
            </Styled.MediaStatusItem>
            <Styled.MediaStatusItem className={hasAudio ? "on" : "off"}>
                <Icon name={hasAudio ? "mic" : "mic_off"} size={1.5} />
            </Styled.MediaStatusItem>
        </Styled.MediaStatus>
    );
}
export default MediaStatus;