import React from 'react';
import { Icon } from 'icon';
import * as Styled from './styles';

type Props = {
    muted: boolean
}
const YouAreMutedToast = ({ muted }: Props) => {
    return (
        <Styled.YouAreMutedToast>
            <Icon name={muted ? "mic_off_white" : "mic_on_white"} size={1.25} />&nbsp;
            {muted ? "You're now muted" : "Your audio is on"}
        </Styled.YouAreMutedToast>
    );
};
export default YouAreMutedToast;