import React from 'react';
import './ModalConfirm.scss';

export type ModalConfirmProps = {
    isOpen?: boolean,
    title: string,
    subtitle?: string,
    confirmText?: string,
    cancelText?: string,
    confirmBtnType?: "danger" | "primary" | "secondary",
    onConfirm?: () => void,
    onCancel?: () => void,
}

const ModalConfirm = (props: ModalConfirmProps) => {
    return (
        <div className={`modal modal-confirm ${props.isOpen && 'is-active'}`}>
            <div className="modal-background" ></div>
            <div className="modal-content">
                <div className="card">
                    <div className="card-content">
                        <h5 className="gray-90 text-h5-to-h4-medium">{props.title}</h5>
                        {props.subtitle && <p className="subtite">{props.subtitle}</p>}
                    </div>
                    <footer className="card-footer b-n">
                        {props.onConfirm &&
                            <button className={`button is-${props.confirmBtnType} confirm`} onClick={props.onConfirm}>
                                {props.confirmText}
                            </button>
                        }
                        {props.onCancel &&
                            <button className="button is-text is-inverted cancel" onClick={props.onCancel} >
                                {props.cancelText}
                            </button>
                        }
                    </footer>
                </div>
            </div>
        </div>
    );
}
ModalConfirm.defaultProps = {
    confirmBtnType: "danger"
}
export default ModalConfirm;
