import { rem } from 'polished';
import styled from 'styled-components';
import { CheckmarkIconDeprecated, CrossThinIconDeprecated } from '@village/icons';
import { Text, ListItem } from '@village/ui';

const ICON_SIZE = rem(48);
const ICON_PICTURE_MARGIN_RIGHT = rem(16);

export interface LabelProps {
    readonly htmlFor?: string;
}

export const CancelButton = styled(CrossThinIconDeprecated)`
    color: ${({ theme }) => theme.vui.colors.neutral.gray60};
`;

export const SelectedIcon = styled(CheckmarkIconDeprecated)`
    color: ${({ theme }) => theme.vui.colors.neutral.gray90};
    margin: ${rem(12)} 0 0 ${rem(12)};
`;

export const InputContainer = styled.div`
    position: relative;
`;

export const Label = styled(Text).attrs(() => ({ tag: 'label' }))<LabelProps>`
    display: block;
    margin-bottom: ${rem(6)};
    color: ${({ theme }) => theme.vui.colors.neutral.gray80};
`;

export const RoundProviderImageInListItem = styled.img`
    width: ${rem(40)};
    height: ${rem(40)};
`;

export const HelperText = styled(Text)<{ readonly $error: boolean }>`
    margin-top: ${rem(8)};
    color: ${({ theme, $error }) => ($error ? theme.vui.colors.feedback.redError : theme.vui.colors.neutral.gray60)};
`;

export const Container = styled.div<{ readonly $error: boolean }>`
    .vui-autocomplete-container {
        .vui-autocomplete--is-disabled {
            border: 1px solid ${({ theme }) => theme.vui.colors.neutral.gray10};
            background: ${({ theme }) => theme.vui.colors.neutral.gray25};
            color: ${({ theme }) => theme.vui.colors.neutral.gray40};
        }
        .vui-autocomplete__control {
            position: relative;
            border-radius: 4px;
            box-shadow: none;
            color: ${({ theme }) => theme.vui.colors.neutral.gray80};
            border-color: ${({ theme }) => theme.vui.colors.neutral.gray30};
            &:hover {
                border-color: ${({ theme }) => theme.vui.colors.neutral.gray60};
                color: ${({ theme }) => theme.vui.colors.neutral.gray80};
            }
            & .vui-autocomplete__indicators {
                color: ${({ theme }) => theme.vui.colors.neutral.gray60};
                margin-right: ${rem(14)};
                z-index: 1;
            }
            &.vui-autocomplete__control--menu-is-open,
            &.vui-autocomplete__control--is-focused {
                color: ${({ theme }) => theme.vui.colors.neutral.gray80};
                border-color: ${({ theme }) => theme.vui.colors.primary.primaryBlue};
                box-shadow: ${({ theme }) => theme.vui.colors.primary.primaryBlue} 0px 0px 0px 1px;
            }
            ${({ theme, $error }) => $error && `
                border-color: ${theme.vui.colors.feedback.redError} !important;
                box-shadow: ${theme.vui.colors.feedback.redError} 0px 0px 0px 1px !important;
            `}
        }
        .vui-autocomplete__value-container {
            border-color: ${({ theme }) => theme.vui.colors.neutral.gray30};
            padding: ${rem(13)} ${rem(16)};

            & .vui-autocomplete__placeholder,
            & .vui-autocomplete__input-container input {
                color: ${({ theme }) => theme.vui.colors.neutral.gray40};
            }
            &.vui-autocomplete__value-container--is-multi {
                padding: ${rem(13)} ${rem(12)};
            }
            .vui-autocomplete__multi-value {
                background-color: #D9ECF1;
                border-radius: 4px;
                color: #24282E;
            }
            .vui-autocomplete__multi-value__label {
                padding: 3px 3px 3px 8px;
            }
            .vui-autocomplete__input-container {
                padding-top: 0;
                padding-bottom: 0;
                line-height: 1.5;
            }
        }
        .vui-autocomplete__menu {
            background: ${({ theme }) => theme.vui.colors.general.white};
            box-shadow: 0 2px 4px 0 rgba(23, 51, 91, 0.2);
            border-radius: 4px;
            padding: ${rem(4)} 0;
            .vui-autocomplete__menu-list {
                padding: 0;
            }
            .vui-autocomplete__menu-notice {
                background: ${({ theme }) => theme.vui.colors.general.white};
                color: ${({ theme }) => theme.vui.colors.neutral.gray60};
                margin: 0;
                padding: ${rem(8)} ${rem(12)} ${rem(8)} ${rem(16)};
            }
            hr {
                display: none;
            }
            .vui-autocomplete__group {
                padding: 0;
                .vui-autocomplete__group-heading {
                    background: ${({ theme }) => theme.vui.colors.neutral.gray25};
                    color: ${({ theme }) => theme.vui.colors.neutral.gray80};
                    font-size: ${rem(14)};
                    padding: ${rem(8)} ${rem(16)};
                    line-height: 1.5;
                }
            }
        }
    }
`;

// Imported and extended styled-components
export const RoundProviderImageInListItemContainer = styled.div`
    /* This piece of style should be removed and used from Styles.List.ItemIcon */
    /* Styles.List.ItemIcon */
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${ICON_SIZE};
    height: ${ICON_SIZE};
    margin-right: ${ICON_PICTURE_MARGIN_RIGHT};
    border-radius: 50%;
    overflow: hidden;
    > * {
        flex: 1;
    }
    /* END: Styles.List.ItemIcon */

    margin-right: 0;
`;

export const AutocompleteOption = styled.div<{
    readonly isSelected: boolean;
}>`
    background: transparent;
    color: ${({ theme }) => theme.vui.colors.neutral.gray90};
    padding: ${(props) => (props.isSelected ? `0 0 0 ${rem(6)}` : `${rem(8)} ${rem(54)}`)};
    margin: 0;
    /* Add hover styles only when hover effect is supported */
    @media (hover: hover) {
        &:hover {
            background: ${({ theme }) => theme.vui.colors.neutral.gray5};
        }
    }
    &:active {
        background: ${({ theme }) => theme.vui.colors.neutral.gray10};
    }
`;

export const AutocompleteOptionDetails = styled(ListItem)<{
    readonly isSelected?: boolean;
}>`
    padding: 0;
    & > div:first-child {
        margin-right: ${(props) => (props.isSelected ? rem(8) : 0)};
    }
`;

export const AutocompleteOptionWithImage = styled(AutocompleteOption)<{
    readonly isSelected?: boolean;
}>`
    padding: ${(props) => (props.isSelected ? `${rem(8)} 0` : `${rem(8)} 0 ${rem(8)} ${rem(48)}`)};
`;

export const AutocompleteOptionDetailsWithImage = styled(AutocompleteOptionDetails)<{
    readonly isSelected?: boolean;
}>`
    & > div:first-child {
        width: ${ICON_SIZE};
        margin-right: ${(props) => (props.isSelected ? 0 : `${rem(12)}`)};
        height: auto;
    }
    & > div:nth-child(2) {
        width: ${ICON_SIZE};
        margin-right: ${rem(12)};
        height: auto;
    }
`;