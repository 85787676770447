import styled from 'styled-components/macro';

export const TestVideoPreview = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TestVideoContainer = styled.div`
    height: 100%;

    .OT_publisher {
        height: 100%;
    }

    .OT_video-element {
        border-radius: 4px;
    }
`;

export const VideoDisabled = styled.div`
    bottom: 0;
    z-index: 10000;
    position: absolute;
    width: 100%;
    background-color: #24282e;
    opacity: 0.96;
    padding: 8px;
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    align-items: start;
`;