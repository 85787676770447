import styled from 'styled-components/macro';
import { defaultVillageUiTheme as theme } from '@village/ui';

export const Section = styled.div`
    display: flex;
    gap: 16px;

    &:first-child {
        margin-bottom: 20px;
    }
    &:last-child {
        margin-top: 20px;
    }
`;

export const SectionContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    flex-grow: 1;
`;

export const SectionContentList = styled.ul`
    color: ${theme.vui.colors.text.graySubtle};
    list-style: inside;
`;