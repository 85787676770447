import React from 'react';
import { NavLink } from 'react-router-dom';
import MyRoute from 'core/router/MyRoute';
import dayjs from 'dayjs';
import HttpClient from 'utils/HttpClient';
import { debounce, cleanQueryParams } from 'utils/Basics';
import Loading from 'shared/Loading';
import DatePicker from 'shared/inputs/DatePicker';
import Select from 'shared/inputs/VmdSelect';
import SelectMarket from 'shared/SelectMarket';
import SelectClinic from 'shared/SelectClinic';
import SelectUser from 'shared/SelectUser';
import SelectWaitingRoom from 'shared/SelectWaitingRoom';
import { Log, Provider, SelectOption } from 'shared/types';
import { LOG_ACTION_BASE } from 'shared/constants/LOG_ACTION';
import { ButtonIcon, Text, Table, TableBody, TableHead, TableRow, TableCell, TableContainer } from '@village/ui';
import { CircleInfoOutlinedIcon, BadgeIcon } from '@village/icons';
import { Column } from 'shared/Flex';
import * as Styled from 'shared/PageLayout';
import LogDetails from './LogDetails';

type LogsProps = {}
type LogsState = {
    loading: boolean
    page: number
    perPage: number
    logs: Log[],
    providers: Provider[],
    filter: {
        start_date: any,
        end_date: any,
        provider_id?: number,
        entity_type?: string,
        entity_id?: number | null,
    },
}

class Logs extends React.Component<LogsProps, LogsState> {

    noLoadMore?: boolean

    constructor(props: LogsProps) {
        super(props);
        let today = dayjs(new Date());
        let before7Days = today.subtract(7, 'day')

        this.state = {
            loading: true,
            page: 0,
            perPage: 30,
            logs: [],
            providers: [],
            filter: {
                start_date: before7Days,
                end_date: today,
            },
        };
        this.handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        this.getLogs();
        document.getElementById('content')?.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        document.getElementById('content')?.removeEventListener('scroll', this.handleScroll);
    }
    getLogs(page: number = 0): void {
        const filter = this.state.filter;
        let apiName = 'telehealthApi';
        let path = `/logs`;
        let queryParams = cleanQueryParams({
            skip: page * this.state.perPage,
            limit: this.state.perPage,
            ...filter,
            start_date: filter.start_date?.format(),
            end_date: filter.end_date?.format(),
        });

        this.setState({
            loading: true,
            logs: page ? this.state.logs : []
        });

        HttpClient().get(apiName, path, queryParams)
            .then((data) => {
                this.setState({
                    loading: false,
                    page: page,
                    logs: [...this.state.logs, ...data]
                });
                this.noLoadMore = (data.length < this.state.perPage);
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            });
    }
    handleScroll = (e: Event): void => {
        debounce(() => {
            let element = e.target as Element
            if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                element.scrollTop--;
                if (!this.noLoadMore) {
                    this.getLogs(this.state.page + 1);
                    this.noLoadMore = false;
                }
            }
        }, 200);
    }
    handleChangeDate = (name: string) => (value: Date): void => {
        this.handleChangeFilter(name)(value ? dayjs(value) : null);
    }
    handleChangeFilter = (name: string) => (value?: any): void => {
        this.setState({
            filter: { ...this.state.filter, [name]: value }
        }, () => {
            debounce(() => this.getLogs(), 500);
        });
    }
    getHumanAction = (log: Log): string => {
        let label: string = "";
        if (log.action in LOG_ACTION_BASE) {
            label = `${log.action} ${log.entity_type}`;
        } else {
            label = log.action;
        }

        return label;
    }
    handleChangeSelect2 = (name: string, option?: SelectOption): void => {
        this.handleChangeFilter(name)(option?.value);
    }
    handleChangeSelect = (name: string, value: string): void => {
        this.handleChangeFilter(name)(value);
    }
    handleChangeEntityType = (name: string, value: string): void => {
        this.setState({
            filter: { ...this.state.filter, entity_id: null, [name]: value }
        }, () => {
            debounce(() => this.getLogs(), 500);
        });
    }
    render() {
        const filter = this.state.filter;

        const entityTypeOptions = [
            { label: "Market", value: "market" },
            { label: "Clinic", value: "clinic" },
            { label: "Provider", value: "provider" },
            { label: "Waiting room", value: "waiting_room" },
        ];

        return (
            <Column grow scrollable>
                <Text type="h4">Audit logs</Text>
                <Styled.ActionBar>
                    <DatePicker
                        onChange={this.handleChangeDate('start_date')}
                        value={filter.start_date?.toDate()}
                        clearIcon={null}
                    />
                    <DatePicker
                        onChange={this.handleChangeDate('end_date')}
                        value={filter.end_date?.toDate()}
                    />
                    <Styled.SearchContainer>
                        <SelectUser
                            isClearable
                            placeholder="Choose author"
                            name="provider_id"
                            onChange={this.handleChangeSelect2}
                        />
                    </Styled.SearchContainer>
                    <Select
                        placeholder="All entity"
                        name="entity_type"
                        value={filter.entity_type}
                        onChange={this.handleChangeEntityType}
                        options={entityTypeOptions}
                        showEmptyOption
                    />
                    {filter.entity_type === "market" ?
                        <SelectMarket
                            placeholder="All markets"
                            name="entity_id"
                            value={filter?.entity_id}
                            onChange={this.handleChangeSelect}
                        />
                        : filter.entity_type === "clinic" ?
                            <SelectClinic
                                placeholder="All clinics"
                                name="entity_id"
                                value={filter?.entity_id}
                                onChange={this.handleChangeSelect}
                            />
                            : filter.entity_type === "provider" ?
                                <Styled.SearchContainer>
                                    <SelectUser
                                        isClearable
                                        placeholder="All providers"
                                        name="entity_id"
                                        onChange={this.handleChangeSelect2}
                                    />
                                </Styled.SearchContainer>
                                : filter.entity_type === "waiting_room" ?
                                    <Styled.SearchContainer>
                                        <SelectWaitingRoom
                                            isClearable
                                            placeholder="All  waiting rooms"
                                            name="entity_id"
                                            onChange={this.handleChangeSelect2}
                                        />
                                    </Styled.SearchContainer>
                                    : <Styled.SearchContainer></Styled.SearchContainer>}
                </Styled.ActionBar>
                {this.state.loading ?
                    <div id="loading-row" >
                        <Loading />
                    </div>
                    : this.state.logs.length === 0 ?
                        <div className="has-text-centered">No logs found</div>
                        : <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date & time</TableCell>
                                        <TableCell>Author</TableCell>
                                        <TableCell>Action</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.logs.map((log) =>
                                        <TableRow key={log.id}>
                                            <TableCell>{dayjs(log.created_at).format("MMM D, YYYY h:mm A")}</TableCell>
                                            <TableCell>{log?.provider?.first_name} {log?.provider?.last_name}</TableCell>
                                            <TableCell>{this.getHumanAction(log)}</TableCell>
                                            <TableCell $action>
                                                {log.action in LOG_ACTION_BASE &&
                                                    <NavLink to={`/logs/view/${log.id}`}>
                                                        <ButtonIcon size="small" title="Details">
                                                            <CircleInfoOutlinedIcon />
                                                        </ButtonIcon>
                                                    </NavLink>
                                                }

                                                {log.action !== "delete" &&
                                                    <NavLink to={`/${log.entity_type}s/edit/${log.entity_id}`}>
                                                        <ButtonIcon size="small" title="Go to item">
                                                            <BadgeIcon />
                                                        </ButtonIcon>
                                                    </NavLink>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                <MyRoute
                    exact
                    path={"/logs/view/:id"}
                    title={"Log details"}
                    logs={this.state.logs}
                    component={LogDetails}
                />
            </Column>
        );
    }
}
export default Logs;