import type { Variant } from './types';
import { defaultVillageUiTheme as theme } from '@village/ui';

type ColorProperties = 'background' | 'hoverBackground' | 'activeBackground';

export type ControlBtnTheme = {
    readonly color: Record<Variant, Record<ColorProperties, string>>,
};

export const ControlBtnTheme: ControlBtnTheme = {
    color: {
        light: {
            background: "#4c535c",
            hoverBackground: theme.vui.colors.decorative.neutral.gray60,
            activeBackground: theme.vui.colors.decorative.neutral.gray80,
        },
        dark: {
            background: theme.vui.colors.decorative.neutral.gray80,
            hoverBackground: "#4c535c",
            activeBackground: theme.vui.colors.decorative.neutral.gray90,
        },
    }
};