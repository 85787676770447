import { Config } from "../shared/types";

const config: Config = {
    "storage": sessionStorage,
    "aws_project_region": "us-east-2",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "telehealthApi",
            "endpoint": "https://thapi.alpha.villagemd.com",
            "region": "us-east-2"
        },
        {
            "name": "teleHealthSocket",
            "endpoint": "wss://thmessaging.alpha.villagemd.com/ws",
            "region": "us-east-2"
        }
    ],
    "patient_host": ["https://telehealth.alpha.docos.me"],
    "provider_host": "https://telehealth.alpha.docos.me",

    // For Onelogin Resources
    "onelogin": {
        HOST: "https://villagemd.onelogin.com",
        OIDC_CLIENT_ID: "2840b330-f526-0138-8ba5-067c03dc70d5100286",
        REDIRECT_URI: "https://telehealth.alpha.docos.me/oidc/callback"
    },
    // For Azure Resources
    "azure": {
        TENANT_ID: "e2cbf95a-41ca-4a3c-8268-fbfe6502b1a6",
        HOST: "https://login.microsoftonline.com",
        OIDC_CLIENT_ID: "6a1494c8-458f-4e88-8d54-6f80c19c99d6",
        REDIRECT_URI: "https://telehealth.alpha.docos.me/azure-oidc/callback"
    },
    // For Opentok resources
    "opentok": {
        API_KEY: "47121834"
    },
    // Countly
    "countly": {
        app_key: "7e6629a24e7d3ff0946791de0bc5678c2db8d228",
        url: "https://countly.docos.me"
    },
    "sentry_dsn": "https://3fbaf9492b134272af9e295b9b98d590@o47892.ingest.sentry.io/5958949",
    "POLLING_INTERVAL_MS": -1,
    "GCP_CAPTCHA_SITE_KEY": "6LeZz0weAAAAAO9Cu9XW3ZKQZBdMNsbBTPN9Jx1E",
};

export default config;
