import NormalDecorator from "./NormalDecorator";

class ODataDecorator extends NormalDecorator{
    response(data: any): any{
        if(!data){
            return null;
        }
        
        return data.d.results;
    }
    postData(data: any): any{
        let postData = { 
            d: {
                results: data
            }
        };

        return postData;
    }
}
export default ODataDecorator;