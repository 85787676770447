import styled from 'styled-components/macro';

export const MainScreen = styled.div`
    flex: 1 1 auto !important;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
`;

export const MainScreenShadow = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgb(47, 59, 77);
    background: radial-gradient(circle, rgba(47, 59, 77, 0) 0%, rgba(47, 59, 77, 0.48921575466123945) 100%);
    z-index: 1;
`;