import { Config } from "../shared/types";

const config: Config = {
    "storage": sessionStorage,
    "aws_project_region": "us-east-2",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "telehealthApi",
            "endpoint": "https://thapi.test.villagemd.com",
            "region": "us-east-2"
        },
        {
            "name": "teleHealthSocket",
            "endpoint": "wss://thmessaging.test.villagemd.com/ws",
            "region": "us-east-2"
        }
    ],
    "patient_host": ["https://telehealth.test.docos.me"],
    "provider_host": "https://telehealth.test.docos.me",

    // For Onelogin Resources
    "onelogin": {
        HOST: "https://villagemd-dev.onelogin.com",
        OIDC_CLIENT_ID: "fa1f4830-fc51-0138-51dc-0213ed617bb3156252",
        REDIRECT_URI: "https://telehealth.test.docos.me/oidc/callback"
    },
    // For Azure Resources
    "azure": {
        TENANT_ID: "e2cbf95a-41ca-4a3c-8268-fbfe6502b1a6",
        HOST: "https://login.microsoftonline.com",
        OIDC_CLIENT_ID: "9879f016-adb7-48e7-958c-ca687bb7c9bd",
        REDIRECT_URI: "https://telehealth.test.docos.me/azure-oidc/callback"
    },
    // For Opentok resources
    "opentok": {
        API_KEY: "47121824"
    },
    // Countly
    "countly": {
        app_key: "2d2608099d02a6e2051d44bce91c00f113701fc0",
        url: "https://countly.docos.me"
    },
    "sentry_dsn": "https://3fbaf9492b134272af9e295b9b98d590@o47892.ingest.sentry.io/5958949",
    "POLLING_INTERVAL_MS": -1,
    "GCP_CAPTCHA_SITE_KEY": "6LeZz0weAAAAAO9Cu9XW3ZKQZBdMNsbBTPN9Jx1E",
};

export default config;
