import React from 'react';
import Messages from '../../shared/Messages/Messages';
import withMessages from '../../shared/Messages/withMessages';
import images from '../../assets/images';
import { IToast } from '../../shared/Messages/types';

type Props = {
    showToast: (toast: IToast) => void
}
class OopsChromeiOSUnsupported extends React.Component<Props, {}> {
    constructor(props: Props){
        super(props);
        this.state = {};
    }
    copyLinkToClipboard = () => {
        navigator.clipboard.writeText(window.location.href);
        this.props.showToast({message: "Link copied", position: "top center"});
    }
    render(){
        return(
            <div>
                <Messages />
                <p className="has-text-centered mb-4">Please, use Safari browser for the visit.</p>
                <div>
                    <div className="mb-1">
                        <div className="d-flex justify-content-between align-items-center" >
                            <span>1. Copy the link for the visit:<br/>
                                <span style={{wordBreak: "break-word"}} className="has-text-primary has-text-weight-bold">
                                    {window.location.href}
                                </span>
                            </span>
                            {navigator.clipboard &&
                                <button className="button is-light has-text-primary" onClick={this.copyLinkToClipboard} >
                                    Copy
                                </button>
                            }
                        </div>
                    </div>
                    <div className="mb-1">
                        <div className="d-flex justify-content-between align-items-center" >
                            <span>2. Open Safari browser app on your phone.</span>
                            <img src={images.ic_safari} alt="Safari logo" /> 
                        </div>
                    </div>
                    <div className="mb-1">3. Paste the link to your Safari browser.</div>
                </div>
            </div>
        );
    }
}
export default withMessages(OopsChromeiOSUnsupported);