import React, { ChangeEvent, PropsWithChildren, useContext } from 'react';
import { Tabs } from './tabs';
import TabsContext from './tab-context';
import { TabsProps } from '@village/ui';


type Props = Omit<TabsProps, 'onChange'> & {
    onChange?: TabsProps['onChange'],
};

export const TabList = ({ children, onChange, ...tabsProps }: PropsWithChildren<Props>) => {
    const { activeTab, setActive } = useContext(TabsContext);
    const handleChange = (event: ChangeEvent<unknown>, newValue: string | number) => {
        setActive(newValue);
        if(onChange) onChange(event, newValue);
    };

    return (
        <Tabs value={activeTab} onChange={handleChange} {...tabsProps}>
            {children}
        </Tabs>
    );
};