const ENDPOINTS_PATHS = {
    "/time": "/time",
    "/reports/visits": "/api/v1/reports/visits",
    "/logs": "/api/v1/logs/",
    "/roles": "/api/v1/roles/",

    "/markets/:id": "/api/v1/markets/:id",
    "/markets": "/api/v1/markets/",

    "/clinics/:id": "/api/v1/clinics/:id",
    "/clinics": "/api/v1/clinics/",

    "/providers/:provider_id/waiting_rooms": "/api/v1/providers/:provider_id/waiting_rooms",
    "/providers/:provider_id/sessions": "/api/v1/providers/:provider_id/sessions",
    "/providers/:provider_id": "/api/v1/providers/:provider_id",
    "/providers/:provider_id/invite_logs": "/api/v1/providers/:provider_id/invite_logs",
    "/providers": "/api/v1/providers/",

    "/waiting_rooms/:id": "/api/v1/waiting_rooms/:id",
    "/waiting_rooms/:id/sessions": "/api/v1/waiting_rooms/:id/sessions",
    "/waiting_rooms/:room": "/api/v1/waiting_rooms/:room",
    "/waiting_rooms": "/api/v1/waiting_rooms/",

    "/sessions/:session_id/provider/feedback": "/api/v1/sessions/:session_id/provider/feedback",
    "/sessions/:session_id/join": "/api/v1/sessions/:session_id/join",
    "/sessions/:session_id/hold": "/api/v1/sessions/:session_id/hold",
    "/sessions/:session_id/users/:user_id": "/api/v1/sessions/:session_id/users/:user_id",
    "/sessions/broadcast": "/api/v1/sessions/broadcast",
    "/sessions": "/api/v1/sessions/",
    
    "/patients/:patient_id/sessions/:session_id/status": "/api/v1/patients/:patient_id/sessions/:session_id/status",
    "/patients/:patient_id/sessions/:session_id": "/api/v1/patients/:patient_id/sessions/:session_id",
    "/patients/checkin": "/api/v1/patients/checkin",

    "/users/oidc/callback": "/api/v1/users/oidc/callback",
    "/users/oidc/refresh_token": "/api/v1/users/oidc/refresh_token"
};
export default ENDPOINTS_PATHS;