import { Countly as CountlyTools } from '@village/tools';
import type { CountlyKey, CountlySegmentation, CountlyUserAttributes } from './types/countly';
import ConfigFactory from '../ConfigFactory';

const countlyProductKey = 'telehealth';

const Countly = CountlyTools.setup<CountlyKey, CountlySegmentation, Record<string, unknown>>({
    beforeSend: (event) => ({
        ...event,
        key: `${event.segmentation?.source ?? countlyProductKey}.${event.key}`,
        segmentation: {
            ...event.segmentation,
            environment: ConfigFactory.getEnv(),
            origin: window.location.origin,
            pathname: window.location.pathname,
        }
    })
});

export const initCountly = (): void => {
    const countlyConfig = ConfigFactory.getCountlyConfig()
    Countly.init({
        ...countlyConfig,
        namespace: countlyProductKey,
        remote_config: true,
    })
}

export const countlyAddEvent = (key: CountlyKey, segmentation?: object ) => {
    const segmentationBase = {
        platform: 'web',
        site: window.location.href,
    };
    
    try {
        Countly.addEvent({
            key: key,
            segmentation: segmentation ? {...segmentationBase, ...segmentation } : segmentationBase
        })
    } catch (error: any){
        localStorage.clear();
        console.warn("error: ", error);
    }
};

export const countlyReportFunction = (func: Function, countlyEvent: CountlyKey) => (...args: any) => {
    countlyAddEvent(countlyEvent);
    return func(...args)
}

export const countlyUserDetails = (userData: CountlyUserAttributes) => {
    try {
        return Countly.setUserDetails(userData);
    } catch (error: any){
        localStorage.clear();
        console.warn("error: ", error);
    }
}

export const countlyRemoteConfig = (key: string) => {
    const config = Countly.getRemoteConfig(key);
    return config;
}
