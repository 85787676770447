import React from 'react';
import { adaptVersionsToSemver } from 'utils/Basics';
import COMPATIBLE_BROWSERS from 'shared/constants/COMPATIBLE_BROWSERS';
import BROWSER_MIN_VERSION from 'shared/constants/BROWSER_MIN_VERSION';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import Oops from './Oops';
import OopsChromeiOSUnsupported from './OopsChromeiOSUnsupported';
const { detect } = require('detect-browser');
const platform = require('platform');
const semverLt = require('semver/functions/lt');

type BrowserInfo = {
    browserName?: string,
    browserVersion?: string,
    browserOS?: string,
}
type BrowserInfoState = BrowserInfo & {
    continue?: boolean,
    oopsTitle: string | null,
    oopsMessageComponent: React.ReactElement | null,
    oopsMessage: string | null,
    showRefreshButton: boolean,
    noDownloadApp: boolean,
}
function withBrowserInfo(WrappedComponent: ReactComponent) {

    class withBrowserInfo extends React.Component<any, BrowserInfoState> {

        displayName: string = 'withBrowserInfo';
        static WrappedComponent: ReactComponent;
        platformInfo: any

        constructor(props: any) {
            super(props);
            this.state = {
                oopsTitle: null,
                oopsMessageComponent: null,
                oopsMessage: null,

                showRefreshButton: true,
                noDownloadApp: false,
            };

            // Keep it for test
            // this.userAgent = "Mozilla/5.0 (iPhone; CPU iPhone OS 12_1_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.0 Mobile/15E148 Safari/604.1";
            this.platformInfo = platform.parse(window.navigator.userAgent);
        }
        componentDidMount() {
            if (this.checkSystemRequirements()) {
                this.getBrowserInfo();
            }
        }
        showOops = (oopsMessage: string | null, showRefreshButton: boolean = false): void => {
            this.setState({
                continue: false,
                oopsTitle: null,
                oopsMessageComponent: null,
                oopsMessage: oopsMessage,
                showRefreshButton: showRefreshButton
            });
        }
        checkBrowserType = (): void => {
            const { browserName } = this.state;
            if (browserName && browserName in COMPATIBLE_BROWSERS) {
                this.checkBrowserVersion();
            } else {
                countlyAddEvent(countlyEvents.incompatibleBrowser, {
                    userAgent: window.navigator.userAgent,
                });
                console.error("Oops - incompatible browser type: " + browserName);
                let oopsMessage = "Please use Chrome, Safari, or Samsung browsers. Your current browser: " + browserName;
                this.showOops(oopsMessage);
            }
        }
        checkBrowserVersion = (): void => {
            let oopsMessage = "";
            let isBelowMinimumVersion = false;

            if (this.state.browserVersion === null || this.state.browserVersion === undefined || this.state.browserVersion === "") {
                countlyAddEvent(countlyEvents.oopsUnableToGetBrowserVersion);
                oopsMessage = "Not able to detect your browser version."
                return this.showOops(oopsMessage);
            }

            switch (this.state.browserName) {
                case "chrome":
                case "crios":
                    if (semverLt(this.state.browserVersion, BROWSER_MIN_VERSION.chrome)) {
                        isBelowMinimumVersion = true;
                    }
                    break;
                case "ios":
                case "safari":
                    if (semverLt(this.state.browserVersion, BROWSER_MIN_VERSION.ios)) {
                        isBelowMinimumVersion = true;
                    }
                    break;
                case "samsung":
                    if (semverLt(this.state.browserVersion, BROWSER_MIN_VERSION.samsung)) {
                        isBelowMinimumVersion = true;
                    }
                    break;
                default:
                    countlyAddEvent(countlyEvents.oopsUserIsOnUnsupportedBrowser);
                    oopsMessage = "Your browser is not supported: " + this.state.browserName;
                    return this.showOops(oopsMessage);
            }

            if (isBelowMinimumVersion) {
                countlyAddEvent(countlyEvents.oopsIsBelowMinimumVersion);
                oopsMessage = "It looks like you are on an outdated version of " + this.state.browserName + " " + this.state.browserVersion + ". Please update your browser and check-in again."
                return this.showOops(oopsMessage);
            }

            this.setState({
                continue: true
            });
        }
        getBrowserInfo = (): void => {
            const browser = detect();

            if (browser) {
                this.setState({
                    browserName: browser.name.toLowerCase(),
                    browserVersion: browser.version,
                    browserOS: this.platformInfo.os,
                }, () => {
                    this.checkBrowserType();
                });
            } else {
                countlyAddEvent(countlyEvents.oopsUnableToGetBrowserInfo);
                let oopsMessage = "Not able to detect browser.";
                return this.showOops(oopsMessage, true);
            }
        }
        checkSystemRequirements = (): boolean => {
            const browser = detect();

            if (!window.OT?.checkSystemRequirements()) {
                countlyAddEvent(countlyEvents.opentokRequirementsFailed, {
                    userAgent: window.navigator.userAgent,
                    platformOSVersion: this.platformInfo.os.version,
                    platformOSFamily: this.platformInfo.os.family,
                    browserName: browser.name,
                    browserVersion: browser.version,
                });
            }
            // Let's keep disable for now
            // if(window.OT.checkSystemRequirements()) return true;

            // In case is needed to test:
            // const info = platform.parse("Mozilla/5.0 (iPad; CPU OS 14_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/94.0.4606.76 Mobile/15E148 Safari/604.1");

            // Fix Version number as supported format for semver
            const osVersion = adaptVersionsToSemver(this.platformInfo.os.version);
            if (!osVersion) return true;

            if (['safari', 'ios'].indexOf(browser.name.toLowerCase()) < 0
                && this.platformInfo.os.family.toLowerCase() === 'ios'
                && semverLt(osVersion, BROWSER_MIN_VERSION.nonNativeBrowseriOS)
            ) {
                countlyAddEvent(countlyEvents.unsupportedChromeiOS);
                this.setState({
                    continue: false,
                    oopsTitle: "Your browser is not supported",
                    oopsMessageComponent: <OopsChromeiOSUnsupported />,
                    showRefreshButton: false,
                    noDownloadApp: true,
                });

                return false;
            }

            return true;
        }
        render() {
            return (
                this.state.continue ?
                    <WrappedComponent
                        {...this.props}
                        browserName={this.state.browserName}
                        browserVersion={this.state.browserVersion}
                        browserOS={this.state.browserOS}
                    />
                    : this.state.continue === false ?
                        <Oops
                            oopsTitle={this.state.oopsTitle}
                            oopsMessageComponent={this.state.oopsMessageComponent}
                            oopsMessage={this.state.oopsMessage}
                            showRefreshButton={this.state.showRefreshButton}
                            noDownloadApp={this.state.noDownloadApp}
                        />
                        : null
            );
        }
    }
    withBrowserInfo.WrappedComponent = WrappedComponent;
    return withBrowserInfo;
}
export default withBrowserInfo;