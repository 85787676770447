import first_time_check_in from './img/first-time-check-in.svg';
import ic_no_access_large from './img/ic-no-access-large.svg';
import ic_provider from './img/ic-provider.svg';
import ic_safari from './img/ic_safari.svg';
import ic_chrome from './img/ic_chrome.svg';
import ic_villagemd_v from './img/ic-villagemd-v.svg';
import logo_village_medical from './img/logo-village-medical.svg';
import waiting_room_bg from './img/waiting-room-bg.svg';
import ios_download_app from './img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';

import text_message from './img/text-message.jpeg';
import ios_chrome_prompt from './img/iOS-chrome-prompt.png';
import chrome_lock from './img/chrome-lock.png';
import chrome_allow from './img/chrome-allow.png';
import chrome_allow_prompt from './img/chrome-allow-prompt.png';
import settings_icon_android from './img/settings-icon-android.jpeg';
import settings_icon_ios from './img/settings-icon-ios.png';
import ios_settings from './img/ios-settings.jpeg';
import ios_chrome_search from './img/ios-chrome-search.jpeg';
import ios_chrome_settings from './img/ios-chrome-settings.jpeg';
import screenshot_no_device_access from './img/screenshot-no-device-access.jpeg';
import screenshot_menu_settings from './img/screenshot-menu-settings.jpeg';
import site_settings from './img/site-settings.jpeg';
import all_sites from './img/all-sites.jpeg';
import care_villageplus from './img/care-villageplus.jpeg';
import clear_reset from './img/clear-reset.jpeg';
import chrome_android_allow from './img/chrome-android-allow.jpeg';
import chrome_app_info from './img/chrome-app-info.jpeg';
import chrome_permissions from './img/chrome-permissions.jpeg';
import chrome_mic_permission from './img/chrome-mic-permission.jpeg';
import apps_screen from './img/apps-screen.webp';
import apps_chrome from './img/apps-chrome.jpeg';



export default {
	first_time_check_in,
	ic_no_access_large,
	ic_provider,
	ic_safari,
	ic_chrome,
	ic_villagemd_v,
	logo_village_medical,
	waiting_room_bg,
	ios_download_app,
	text_message,
	ios_chrome_prompt,
	chrome_lock,
	chrome_allow,
	chrome_allow_prompt,
	settings_icon_android,
	settings_icon_ios,
	ios_settings,
	ios_chrome_search,
	ios_chrome_settings,
	screenshot_no_device_access,
	screenshot_menu_settings,
	site_settings,
	all_sites,
	care_villageplus,
	clear_reset,
	chrome_android_allow,
	chrome_app_info,
	chrome_permissions,
	chrome_mic_permission,
	apps_screen,
	apps_chrome,
};