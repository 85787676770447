import React from 'react';
import ButtonConfirm from 'shared/buttons/ButtonConfirm';
import Feature from 'shared/Feature';
import SESSION_STATUS from 'shared/constants/SESSION_STATUS';
import FF from 'shared/constants/FF';
import { Patient } from 'shared/types';
import { ButtonIcon, DropdownMenu, DropdownMenuItem } from "@village/ui";
import { MoreHorizontalIcon, CameraFilledIcon, MicrophoneIcon, CloseIcon } from "@village/icons"
import { NavLink } from 'react-router-dom';


type Props = {
    isInCall: boolean,
    session_id: number,
    patient: Patient,
    onUpdatePatientStatus: (
        sessionId: number,
        patientId: number,
        newPatientStatus: SESSION_STATUS,
        kickedOut: boolean
    ) => () => void,
}
type State = {
    ellipsisActive: boolean
}

class PatientDropdownActions extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            ellipsisActive: false
        };
    }
    handleEllipsisOpen = (): void => this.setState({ ellipsisActive: true });
    handleEllipsisClose = (): void => this.setState({ ellipsisActive: false });

    handleRemovePatient = (): void => {
        this.props.onUpdatePatientStatus(
            this.props.session_id,
            this.props.patient.id,
            SESSION_STATUS.off,
            true
        )()
    }
    render() {

        const { first_name, last_name } = this.props.patient;

        return (
            <DropdownMenu
                open={this.state.ellipsisActive}
                onClose={this.handleEllipsisClose}
                anchorComponent={
                    <ButtonIcon
                        size="medium"
                        aria-haspopup="true"
                        aria-controls="patient-queue-card-more-features"
                        onClick={this.handleEllipsisOpen}
                        disabled={this.props.isInCall}
                    >
                        <MoreHorizontalIcon />
                    </ButtonIcon>
                }
            >
                <NavLink to={`/session/${this.props.session_id}?video=1`} className="is-hidden-desktop">
                    <DropdownMenuItem icon={<CameraFilledIcon />}>
                        Start video call
                    </DropdownMenuItem>
                </NavLink>
                <Feature item code={FF.audioOnlyCall} >
                    <NavLink to={`/session/${this.props.session_id}?video=0`}>
                        <DropdownMenuItem icon={<MicrophoneIcon />}>
                            Start audio call
                        </DropdownMenuItem>
                    </NavLink>
                </Feature>
                <Feature item code={FF.removePatient} >
                    <ButtonConfirm
                        title={`Remove patient ${first_name} ${last_name} from waiting room?`}
                        confirmText="Remove"
                        cancelText="Cancel"
                    >
                        <DropdownMenuItem variant='destructive' icon={<CloseIcon />} onClick={this.handleRemovePatient}>
                            Remove from waiting room
                        </DropdownMenuItem>
                    </ButtonConfirm>
                </Feature>
            </DropdownMenu >
        );
    }
}
export default PatientDropdownActions;