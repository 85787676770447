import React from 'react';
import ErrorScreen from "../../shared/ErrorScreen";
import { countlyAddEvent } from '../../countly';
import countlyEvents from '../../countly/events';

type Props = {
    oopsTitle: string | null,
    oopsMessageComponent: React.ReactElement | null,
    oopsMessage: string | null,
    showRefreshButton: boolean,
    noDownloadApp: boolean,
}
class Oops extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        countlyAddEvent(countlyEvents.pageOops);
    }
    refreshPage = () => {
        countlyAddEvent(countlyEvents.refreshingPage);
        window.location.reload();
    }
    render() {
        return (
            <ErrorScreen
                oopsTitle={this.props.oopsTitle}
                oopsMessageComponent={this.props.oopsMessageComponent}
                oopsMessage={this.props.oopsMessage}
                showRefreshButton={this.props.showRefreshButton}
                noDownloadApp={this.props.noDownloadApp}
                onRefreshPage={this.refreshPage}
            />
        )
    }
}
export default Oops;
