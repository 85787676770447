import React from 'react';
import images from 'assets/images';
import { DeniedPermissionViews } from '../types';
import { Icon } from 'icon';

type Props = {
    onClick: (view: DeniedPermissionViews) => () => void
}
const TroubleshootBrowserList = ({ onClick }: Props) => {

    return (
        <div>
            <h4 className="gray-90 text-h5-to-h4-medium mb-4">
                Access to your camera and microphone is disabled
            </h4>
            <p className="is-size-7 has-text-weight-medium">
                For instructions on how to manually allow access, please select your web browser and operating system from the list below: 
            </p>
            <div className="list">
                <div className="list-item" role="button" onClick={onClick(DeniedPermissionViews.safari)} >
                    <div className="list-item-content">
                        <img className="ic" src={images.ic_safari} alt="Safari logo" /> 
                        <div>Safari on MacOS or iOS</div>
                    </div>
                    <div className="list-arrow">
                        <Icon name="chevron_right" size={2} />
                    </div>
                </div>
                <div className="list-item" role="button" onClick={onClick(DeniedPermissionViews.chrome_desktop)} >
                    <div className="list-item-content">
                        <img className="ic" src={images.ic_chrome} alt="Safari logo" /> 
                        <div>Chrome Desktop on Windows or MacOS</div>
                    </div>
                    <div className="list-arrow">
                        <Icon name="chevron_right" size={2} />
                    </div>
                </div>
                <div className="list-item" role="button" onClick={onClick(DeniedPermissionViews.chrome_ios)} >
                    <div className="list-item-content">
                        <img className="ic" src={images.ic_chrome} alt="Safari logo" /> 
                        <div>Chrome Mobile on iOS</div>
                    </div>
                    <div className="list-arrow">
                        <Icon name="chevron_right" size={2} />
                    </div>
                </div>
                <div className="list-item" role="button" onClick={onClick(DeniedPermissionViews.chrome_android)} >
                    <div className="list-item-content">
                        <img className="ic" src={images.ic_chrome} alt="Safari logo" /> 
                        <div>Chrome Mobile on Android</div>
                    </div>
                    <div className="list-arrow">
                        <Icon name="chevron_right" size={2} />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TroubleshootBrowserList;