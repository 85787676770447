import React from 'react';
import AudioLevelContext from './AudioLevelContext';


function withAudioLevel(WrappedComponent: ReactComponent) {
    class withAudioLevel extends React.Component<any, {}> {
        
        displayName: string = 'withAudioLevel';
        static WrappedComponent: ReactComponent;

        constructor(props: any) {
            super(props);
            this.state = {};
        }
        render() {

            return (
                <AudioLevelContext.Consumer>
                    {(context) =>
                        <WrappedComponent {...context} {...this.props} />
                    }
                </AudioLevelContext.Consumer>
            );
        }
    };
    withAudioLevel.WrappedComponent = WrappedComponent;
    return withAudioLevel;
}
export default withAudioLevel;