import React from 'react';
import EnvironmentBanner from '../shared/EnvironmentBanner';
import ConfigFactory from '../ConfigFactory';


type Props = {
	children: JSXElement
}
const RoomLayout = ({ children }: Props) => {
	return (
		<div className='App fullheight is-fluid d-flex flex-column'>
			<EnvironmentBanner env={ConfigFactory.getEnv()} />
			<div className="d-flex flex-grow-1 flex-row align-items-stretch">
				<div className='flex-grow-1 has-background-light'>
					{children}
				</div>
			</div>
		</div>
	);
}
export default RoomLayout;