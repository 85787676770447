import React from 'react';
import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import './Tooltip.scss';

type TooltipVmdProps = any

const TooltipVmd = (props: TooltipVmdProps) => {
    return (
        <Tooltip 
            position="top"
            arrow={false}
            size='small'
            theme="transparent"
            followCursor={false}
            {...props} 
        />
    );
}
export default TooltipVmd;