import { Text } from "@village/ui";
import styled from "styled-components";
import { rem } from "polished";

export interface LabelProps {
    readonly htmlFor?: string;
}

export const InputContainer = styled.div`
    position: relative;
`;

export const Label = styled(Text).attrs((props: LabelProps) => ({
    tag: 'label',
    htmlFor: props.htmlFor
})) <LabelProps>`
    display: block;
    margin-bottom: ${rem(6)};
    color: ${({ theme }) => theme.vui.colors.text.grayMuted};
`;
