import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Pill from 'shared/Pill';
import ServerTime from 'utils/ServerTime';
import Feature from 'shared/Feature';
import SESSION_STATUS from 'shared/constants/SESSION_STATUS';
import FF from 'shared/constants/FF';
import ParticipantList from './ParticipantList';
import { SessionUser, UserBase } from 'shared/types';
dayjs.extend(utc);

enum PillColor {
    orange = "orange",
    neutral = "neutral",
    red = "red"
}
type PatientPillProps = {
    status?: SESSION_STATUS,
    patient?: SessionUser,
    session_users: SessionUser[]
}
type PatientPillState = {
    elapsedWaitTime: number,
    elapsedTotalTime?: number,
}
class PatientPill extends React.Component<PatientPillProps, PatientPillState> {

    constructor(props: PatientPillProps){
        super(props);
        this.state = {
            elapsedWaitTime: 0
        };
    }
    componentDidMount() {
        this.startCountingPatientWaitTime();
    }

    startCountingPatientWaitTime = (): void => {
        // tick up on every 1 minute interval
        this.countWaitTimeUp();
        setInterval(this.countWaitTimeUp, 60000);
    }

    countWaitTimeUp = (): void => {
        const { patient } = this.props;

        let patientEnteredWRTime: string | number = 0;
        let patientCheckinTime: string | number = 0;
        if (patient) patientEnteredWRTime = patient.updated_at ? patient.updated_at : patient.created_at;
        const patientEnteredWRTimestamp = dayjs.utc(patientEnteredWRTime).unix();

        if (patient) patientCheckinTime = patient.created_at;
        const patientCheckinTimestamp = dayjs.utc(patientCheckinTime).unix();

        this.setState({
            elapsedWaitTime: Math.floor((ServerTime.getServerTimeStampNow() - patientEnteredWRTimestamp) / 60),
            elapsedTotalTime: Math.floor((ServerTime.getServerTimeStampNow() - patientCheckinTimestamp) / 60),
        });
    }

    getPillColor = (elapsedWaitTime: number): PillColor => {
        let color = PillColor.neutral;
        if ( 6 <= elapsedWaitTime && elapsedWaitTime <= 10) {
            color = PillColor.orange;
        } else if (elapsedWaitTime > 10) {
            color = PillColor.red;
        }

        return color;
    }

    getUsersInCall = (): UserBase[] => {
        if(SESSION_STATUS.in_call !== this.props.status) return [];

        return this.props.session_users.filter((session_user) => {
            return session_user.status !== SESSION_STATUS.off 
                && session_user.user_id !== this.props.patient?.user_id
                && session_user.user;
            }
        ).map((session_user) => { return session_user.user; } );
    }

    render(){
        
        const { elapsedWaitTime, elapsedTotalTime } = this.state;
        const colorWaitTime = this.getPillColor(elapsedWaitTime);

        return (
            Feature.has(FF.waitingTime) ? (
                this.props.status === SESSION_STATUS.on ?
                    <div>
                        <Pill text={`Waiting: ${elapsedWaitTime}m`} color={colorWaitTime} />
                        <Pill text={`Total: ${elapsedTotalTime}m`} color={"in-call"} />
                    </div>
                : this.props.status === SESSION_STATUS.on_hold ?
                    <div>
                        <Pill text={`On hold: ${elapsedWaitTime}m`} color={colorWaitTime} />
                        <Pill text={`Total: ${elapsedTotalTime}m`} color={"in-call"} />
                    </div>
                : this.props.status === SESSION_STATUS.in_call ?
                    <React.Fragment>
                        <Pill text="Call in progress" color="in-call" />
                        <ParticipantList users={this.getUsersInCall()} />
                    </React.Fragment>
                : null
            ): null
        );
    }
}
export default PatientPill;