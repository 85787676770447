import React from 'react';
import ListProviders from './ListProviders';
import FormProviders from './FormProviders';
import LogsProviders from './LogsProviders';
import MyRoute from 'core/router/MyRoute';
import HttpClient from 'utils/HttpClient';
import { debounce, cleanQueryParams } from 'utils/Basics';
import { IProvider, Provider } from 'shared/types';
import { Column } from 'shared/Flex';


type FilterValues = {
    clinic_id?: number,
    role_id?: number,
}

type ProvidersPageProps = {}

type ProvidersPageState = {
    loading: boolean,
    page: number,
    perPage: number,
    search: string,
    providers: Provider[],
    filter: FilterValues
}

class ProvidersPage extends React.Component<ProvidersPageProps, ProvidersPageState> {
    
    noLoadMore?: boolean
    
    constructor(props: ProvidersPageProps) {
        super(props);
        this.state = {
            loading: true,
            page: 0,
            perPage: 30,
            search: "",
            filter: {},
            providers: []
        };
        this.handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        this.getProviders();
        document.getElementById('content')?.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        document.getElementById('content')?.removeEventListener('scroll', this.handleScroll);
    }
    getProviders(page: number = 0): void {
        
        let apiName = 'telehealthApi';
        let path = '/providers';
        let queryParams = cleanQueryParams({
            skip: page * this.state.perPage,
            limit: this.state.perPage,
            search: this.state.search,
            ...this.state.filter
        });

        this.setState({ 
            loading: true,
            providers: page ? this.state.providers : []
        });

        HttpClient().get(apiName, path, queryParams)
            .then((data) => {
                this.setState({
                    loading: false,
                    page: page,
                    providers: [...this.state.providers, ...data]
                });
                this.noLoadMore = false;
                if (data.length < this.state.perPage) {
                    this.noLoadMore = true;
                }
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            });
    }
    handleScroll = (e: Event): void => {
        debounce(() => {
            const element = e.target as Element
            if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                element.scrollTop--;
                if (!this.noLoadMore) {
                    this.getProviders(this.state.page + 1);
                }
            }
        }, 200);
    }
    handleFilter = (nextFilter: FilterValues) => {
        this.setState({filter: nextFilter}, () => {
            debounce(() => this.getProviders(), 400);
        });
    }
    handleSearch = (event: any): void => {
        this.setState({ search: event?.target?.value });
        debounce(() => this.getProviders(), 400);
    }
    newProvider = (provider: Provider): void => {
        this.setState({
            providers: [provider, ...this.state.providers]
        });
    }
    updateProvider = (provider_id: number, data: IProvider): void => {
        this.setState({
            providers: this.state.providers.map((item) => {
                return item.id === provider_id ? { ...item, ...data } : item;
            })
        });
    }
    handleRemove = (provider: Provider) => (): void => {
        let apiName = 'telehealthApi';
        let path = '/providers/' + provider.id;
        this.setState({
            loading: true
        });
        HttpClient().delete(apiName, path)
            .then((data) => {
                this.setState({
                    loading: false,
                    providers: this.state.providers.filter((item) => item.id !== provider.id)
                });
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            });
    }
    render() {

        return (
            <Column grow scrollable>
                <ListProviders
                    providers={this.state.providers}
                    loading={this.state.loading}
                    search={this.state.search}
                    filter={this.state.filter}
                    onSearch={this.handleSearch}
                    onFilter={this.handleFilter}
                    onRemove={this.handleRemove}
                />
                <MyRoute
                    exact
                    path={"/providers/new"}
                    title={"New provider"}
                    mode="new"
                    onCreate={this.newProvider}
                    component={FormProviders}
                />
                <MyRoute
                    exact
                    path={"/providers/edit/:id"}
                    title={"Edit provider"}
                    mode="edit"
                    onUpdate={this.updateProvider}
                    component={FormProviders}
                />
                <MyRoute
                    exact
                    path={"/providers/:id/logs"}
                    title={"Change logs"}
                    component={LogsProviders}
                />
            </Column>
        );
    }
}
export default ProvidersPage;