export enum Fields {
    first_name = "First Name",
    last_name = "Last Name",
    email = "E-mail",
    title = "Title",
    credential = "Credential",
    npi = "NPI",
}

export type TimelineItemDataUpdated = {
    what: Fields,
    prev: any,
    next: any,
}
export type TimelineItem = {
    date_time: string,
    author: string,
    action: string,
    data: TimelineItemDataUpdated | any
}