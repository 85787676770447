import React, { ReactNode } from 'react';
import { Text } from '@village/ui';
import { TimelineItem as TimelineItemType, Fields } from './types';
import { LogAction } from 'shared/types';
import LOG_ACTION from "shared/constants/LOG_ACTION";
import { IconName } from "icon"
import * as Styled from './styles';
import { Icon } from 'icon';


type Props = TimelineItemType;
const TimelineItem = ({ date_time, author, action, data }: Props) => {

    const getActionIcon = (action: LogAction): IconName => {
        let iconName: IconName = "edit";
        switch (action) {
            case LOG_ACTION.create:
            case LOG_ACTION.clinic__assign_provider:
            case LOG_ACTION.provider__assign_role:
            case LOG_ACTION.waiting_room__assign_provider:
                iconName = "plus";
                break;

            case LOG_ACTION.delete:
            case LOG_ACTION.clinic__unassign_provider:
            case LOG_ACTION.provider__unassign_role:
            case LOG_ACTION.waiting_room__unassign_provider:
                iconName = "cancel";
                break;

            case LOG_ACTION.update:
            default:
                iconName = "edit";
                break;
        }

        return iconName;
    }

    type TimelineInfoType = {
        author: string,
        action: string,
        data: TimelineItemType['data']
    }
    const compileTimelineInfo = ({ author, action, data }: TimelineInfoType): ReactNode => {
        let info: ReactNode = null;
        switch (action) {
            case LOG_ACTION.create:
                info = <div>Created by {author}</div>;
                break;
            case LOG_ACTION.delete:
                info = <div>Deleted by {author}</div>;
                break;
            case LOG_ACTION.update:
                const what = data.what as keyof typeof Fields;
                info = (
                    <>
                        <div>{author} changed {Fields[what]} from</div>
                        <div>{data.prev} to {data.next}</div>
                    </>
                );
                break;
            case LOG_ACTION.waiting_room__assign_provider:
                info = (
                    <>
                        <div>{author} assigned waiting room</div>
                        <div>{data.name}</div>
                    </>
                );
                break;
            case LOG_ACTION.waiting_room__unassign_provider:
                info = (
                    <>
                        <div>{author} unassigned waiting room</div>
                        <div>{data.name}</div>
                    </>
                );
                break;
            case LOG_ACTION.provider__assign_role:
                info = (
                    <>
                        <div>{author} added role</div>
                        <div>{data.name}</div>
                    </>
                );
                break;
            case LOG_ACTION.provider__unassign_role:
                info = (
                    <>
                        <div>{author} removed role</div>
                        <div>{data.name}</div>
                    </>
                );
                break;

            case LOG_ACTION.clinic__assign_provider:
                info = (
                    <>
                        <div>{author} added clinic</div>
                        <div>{data.name}</div>
                    </>
                );
                break;
            case LOG_ACTION.clinic__unassign_provider:
                info = (
                    <>
                        <div>{author} removed clinic</div>
                        <div>{data.name}</div>
                    </>
                );
                break;
        }

        return info;
    }

    return (
        <Styled.TimelineItem>
            <Styled.TimelineMarker>
                <Styled.TimelineItemVline />
                <Styled.TimelineItemIcon>
                    <Icon name={getActionIcon(action)} size={1} />
                </Styled.TimelineItemIcon>
                <Styled.TimelineItemVline />
            </Styled.TimelineMarker>
            <Styled.TimelineItemContent>
                <Text type="caption2">{date_time}</Text>
                <Styled.TimelineItemInfo type="body2">
                    {compileTimelineInfo({ author, action, data })}
                </Styled.TimelineItemInfo>
            </Styled.TimelineItemContent>
        </Styled.TimelineItem>
    );
}
export default TimelineItem;