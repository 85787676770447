import React from 'react';
import PatientRoomContext from './PatientRoomContext';


function withPatientRoom(WrappedComponent: ReactComponent) {
    class withPatientRoom extends React.Component<any, {}>  {

        displayName: string = 'withPatientRoom';
        static WrappedComponent: ReactComponent;

        constructor(props: any) {
            super(props);
            this.state = {};
        }
        render() {

            return (
                <PatientRoomContext.Consumer>
                    {(context) =>
                        <WrappedComponent {...context} {...this.props} />
                    }
                </PatientRoomContext.Consumer>
            );
        }
    };
    withPatientRoom.WrappedComponent = WrappedComponent;
    return withPatientRoom;
}
export default withPatientRoom;