import React from 'react';
import ButtonConfirm from 'shared/buttons/ButtonConfirm';
import { CheckPermission } from 'utils/Authorizer';
import { ButtonIcon, DropdownMenu, DropdownMenuItem } from '@village/ui'
import { MoreHorizontalIcon, PencilIcon, FileIcon, TrashIcon } from '@village/icons'
import { Provider } from 'shared/types';
import { NavLink } from 'react-router-dom';
import { useToggle } from '@village/tools';

type Props = {
    provider: Provider,
    onRemove: React.MouseEventHandler<HTMLSpanElement>
}
const ProviderOptions = ({ provider, onRemove }: Props) => {
    const { switchOn, switchOff, value } = useToggle(false);
    return (
        <DropdownMenu open={value} onClose={switchOff} id="provider-options" anchorComponent={<ButtonIcon size='small' onClick={switchOn} title="more provider options"><MoreHorizontalIcon /></ButtonIcon>}>
            <CheckPermission act='GET' obj={`/providers/${provider.id}`} >
                <NavLink to={`/providers/edit/${provider.id}`}>
                    <DropdownMenuItem icon={<PencilIcon />}> Edit provider </DropdownMenuItem>
                </NavLink>
            </CheckPermission>
            <CheckPermission act='GET' obj={`/providers/${provider.id}/logs`} >
                <NavLink to={`/providers/${provider.id}/logs`}>
                    <DropdownMenuItem icon={<FileIcon />}> Open change logs </DropdownMenuItem>
                </NavLink>
            </CheckPermission>
            <CheckPermission act='DELETE' obj={`/providers/${provider.id}`} >
                <ButtonConfirm
                    title={`Remove provider ${provider.first_name} ${provider.last_name}?`}
                    confirmText="Remove"
                    cancelText="Cancel"
                >
                    <DropdownMenuItem icon={<TrashIcon />} onClick={onRemove} variant="destructive"> Remove provider </DropdownMenuItem>
                </ButtonConfirm>
            </CheckPermission>
        </DropdownMenu>
    );
}
export default ProviderOptions;