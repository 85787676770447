import React, { useEffect } from 'react';
import { Button, Text } from '@village/ui';
import { RouteComponentProps } from 'react-router-dom';
import * as Styled from './styles';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';


export type IAzureError = {
    error: string,
    error_description: string,
    history: RouteComponentProps['history']
}

const AzureError = ({ error, error_description, history }: IAzureError) => {
    useEffect(() => {
        countlyAddEvent(countlyEvents.pageAzureError);
    }, [])

    const loginWithOnelogin = () => {
        history.push('/oidc/login');
    }

    return (
        <Styled.AzureMessage>
            <Styled.AzureMessageHeader>
                <Text type="body1" >{error === 'access_denied' ? "Access denied" : "Authentication error"}</Text>
            </Styled.AzureMessageHeader>
            <Styled.AzureMessageBody>
                {error_description}
                <Styled.FallbackLoginButton>
                    <div>
                        <Text type="body1" >
                            Having issues to login with Azure?
                        </Text>
                        <Button variant="secondary" size="medium" onClick={loginWithOnelogin}>
                            Login with Onelogin
                        </Button>
                    </div>
                </Styled.FallbackLoginButton>
            </Styled.AzureMessageBody>
        </Styled.AzureMessage>
    );
};
export default AzureError;