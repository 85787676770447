import { Tabs } from '@material-ui/core';
import styled from 'styled-components';

const INDICATOR_SIDE_PADDING_PX = 8;
const INDICATOR_HEIGHT_PX = 3;

export const MeterialTabs = styled(Tabs)`
    background-color: ${({ theme }) => theme.vui.component.tab.background};
    /* Removing the width so we can calculate the real width of the tab label */
    .MuiTab-wrapper {
        width: unset;
    }
    .MuiTabs-indicator {
        height: ${INDICATOR_HEIGHT_PX}px;
        background: transparent;
    }
`;

export const Indicator = styled.span<{ readonly width: number }>`
    display: block;
    position: relative;
    width: ${({ width }) => width + INDICATOR_SIDE_PADDING_PX}px;
    height: ${INDICATOR_HEIGHT_PX}px;
    background-color: ${({ theme }) => theme.vui.component.tab.background};
    margin: 0 auto;
    transition: 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${({ theme }) => theme.vui.component.tab.indicator.color};
        border-radius: 2px 2px 0px 0px;
    }
`;