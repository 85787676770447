import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ConfigFactory from 'ConfigFactory';
import Loading from 'shared/Loading';
import withMessages from 'shared/Messages/withMessages';
import HttpClientPlugins from 'utils/HttpClient/HttpClientPlugins';
import BulmaLoader from 'shared/VendorLoaders/BulmaLoader';
import { IToast } from 'shared/Messages/types';
import ProviderApp from 'apps/ProviderApp';
import PatientApp from 'apps/PatientApp';
import consumeApp from 'providers/consumeApp';
import CountlyTracker from 'countly/CountlyTracker';
import './App.scss';


const history = createBrowserHistory();

type Props = {
    checkCurrentUser: () => void,
    showToast: (toast: IToast) => void,
    checkingAuthentication: boolean,
}
type State = {
    use_provider_routes: boolean,
    use_patient_routes: boolean,
}
class App extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        ConfigFactory.init();
        this.state = {
            use_provider_routes: ConfigFactory.isProviderHost(window.location.origin),
            use_patient_routes: ConfigFactory.isPatientHost(window.location.origin),
        };
    }
    componentDidMount() {
        this.props.checkCurrentUser();

        HttpClientPlugins.push((client) => {
            client.on('HTTP_STATUS_401', (event: any) => {
                this.props.showToast({
                    message: `Unauthorized: ${event?.response?.data?.detail}`
                })
                client.stopRetry();
            });

            return client;
        });
    }
    render() {

        const LoadingScreen = () => (
            <div className="container is-fluid fullheight has-background-light d-flex align-items-center justify-content-center" >
                <Loading />
            </div>
        );
        const { use_provider_routes, use_patient_routes } = this.state;
        return (
            <Router history={history} >
                <CountlyTracker>
                    {this.props.checkingAuthentication ?
                        <LoadingScreen />
                        :
                        <BulmaLoader>
                            <React.Fragment>
                                {use_provider_routes && <ProviderApp />}
                                {use_patient_routes && <PatientApp no404={use_provider_routes} />}
                            </React.Fragment>
                        </BulmaLoader>
                    }
                </CountlyTracker>
            </Router>
        );
    }
}
export default consumeApp(withMessages(App));
