import React from 'react';
import Loader from './Loader';

type BulmaLoaderProps = {
    children: React.ReactElement
}

const BulmaLoader = ({ children }: BulmaLoaderProps) => {
    return (
        <Loader
            id="bulma"
            tagName="link"
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/bulma/0.9.1/css/bulma.min.css"
            crossorigin="anonymous"
        >
            {children}
        </Loader>
    );
}
export default BulmaLoader;