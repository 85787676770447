import React from 'react';
import { countlyAddEvent, countlyReportFunction } from 'countly';
import countlyEvents from 'countly/events';
import ButtonConfirm from 'shared/buttons/ButtonConfirm';
import * as Styled from './styles';

type LeaveBtnProps = {
    className?: string,
    onClick: () => void,
}
const LeaveBtn = ({ onClick, className }: LeaveBtnProps) => {

    const handleLeaveConfirm = countlyReportFunction(onClick, countlyEvents.clickLeaveConfirm);
    const handleClick = () => {
        countlyAddEvent(countlyEvents.clickLeave);
    }
    const handleCancel = () => {
        countlyAddEvent(countlyEvents.clickLeaveCancel);
    }

    return (
        <ButtonConfirm
            title="Leave Waiting Room?"
            subtitle="If you leave the Waiting Room, your provider will not be able to start your Video Visit"
            confirmText="Leave Waiting Room"
            cancelText="Cancel"
            onClick={handleClick}
            onCancel={handleCancel}
        >
            <Styled.LeaveBtn id="btn-leave" className={className} onClick={handleLeaveConfirm} >
                Leave
            </Styled.LeaveBtn>
        </ButtonConfirm>
    );
}
export default LeaveBtn;
