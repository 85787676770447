
enum DisconnectReason {
    clientDisconnected = "clientDisconnected",
    forceDisconnected = "forceDisconnected",
    networkDisconnected = "networkDisconnected"
}
enum VideoSourceError {
    OT_INVALID_VIDEO_SOURCE = "OT_INVALID_VIDEO_SOURCE",
    OT_SET_VIDEO_SOURCE_FAILURE = "OT_SET_VIDEO_SOURCE_FAILURE",
}

enum SessionConnectError {
    OT_AUTHENTICATION_ERROR = "OT_AUTHENTICATION_ERROR",
    OT_BADLY_FORMED_RESPONSE = "OT_BADLY_FORMED_RESPONSE",
    OT_CONNECT_FAILED = "OT_CONNECT_FAILED",
    OT_CONNECTION_LIMIT_EXCEEDED = "OT_CONNECTION_LIMIT_EXCEEDED",
    OT_EMPTY_RESPONSE_BODY = "OT_EMPTY_RESPONSE_BODY",
    OT_INVALID_SESSION_ID = "OT_INVALID_SESSION_ID",
    OT_INVALID_PARAMETER = "OT_INVALID_PARAMETER",
    OT_NOT_CONNECTED = "OT_NOT_CONNECTED",
    OT_TERMS_OF_SERVICE_FAILURE = "OT_TERMS_OF_SERVICE_FAILURE",
    OT_INVALID_HTTP_STATUS = "OT_INVALID_HTTP_STATUS",
    OT_XDOMAIN_OR_PARSING_ERROR = "OT_XDOMAIN_OR_PARSING_ERROR",
    OT_SOCKET_CLOSE_TIMEOUT = "OT_SOCKET_CLOSE_TIMEOUT",
}

enum PublisherError {
    OT_CHROME_MICROPHONE_ACQUISITION_ERROR = "OT_CHROME_MICROPHONE_ACQUISITION_ERROR",
    OT_CONSTRAINTS_NOT_SATISFIED = "OT_CONSTRAINTS_NOT_SATISFIED",
    OT_CREATE_PEER_CONNECTION_FAILED = "OT_CREATE_PEER_CONNECTION_FAILED",
    OT_HARDWARE_UNAVAILABLE = "OT_HARDWARE_UNAVAILABLE",
    OT_ICE_WORKFLOW_FAILED = "OT_ICE_WORKFLOW_FAILED",
    OT_INVALID_PARAMETER = "OT_INVALID_PARAMETER",
    OT_MEDIA_ERR_ABORTED = "OT_MEDIA_ERR_ABORTED",
    OT_MEDIA_ERR_DECODE = "OT_MEDIA_ERR_DECODE",
    OT_MEDIA_ERR_NETWORK = "OT_MEDIA_ERR_NETWORK",
    OT_MEDIA_ERR_SRC_NOT_SUPPORTED = "OT_MEDIA_ERR_SRC_NOT_SUPPORTED",
    OT_NO_DEVICES_FOUND = "OT_NO_DEVICES_FOUND",
    OT_NO_VALID_CONSTRAINTS = "OT_NO_VALID_CONSTRAINTS",
    OT_NOT_CONNECTED = "OT_NOT_CONNECTED",
    OT_NOT_SUPPORTED = "OT_NOT_SUPPORTED",
    OT_PERMISSION_DENIED = "OT_PERMISSION_DENIED",
    OT_SCREEN_SHARING_NOT_SUPPORTED = "OT_SCREEN_SHARING_NOT_SUPPORTED",
    OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED = "OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED",
    OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED = "OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED",
    OT_SET_REMOTE_DESCRIPTION_FAILED = "OT_SET_REMOTE_DESCRIPTION_FAILED",
    OT_STREAM_CREATE_FAILED = "OT_STREAM_CREATE_FAILED",
    OT_TIMEOUT = "OT_TIMEOUT",
    OT_USER_MEDIA_ACCESS_DENIED = "OT_USER_MEDIA_ACCESS_DENIED",
    OT_UNEXPECTED_SERVER_RESPONSE = "OT_UNEXPECTED_SERVER_RESPONSE",
}

enum SubscriberError {
    OT_CREATE_PEER_CONNECTION_FAILED = "OT_CREATE_PEER_CONNECTION_FAILED",
    OT_DISCONNECTED = "OT_DISCONNECTED",
    OT_INVALID_PARAMETER = "OT_INVALID_PARAMETER",
    OT_ICE_WORKFLOW_FAILED = "OT_ICE_WORKFLOW_FAILED",
    OT_MEDIA_ERR_ABORTED = "OT_MEDIA_ERR_ABORTED",
    OT_MEDIA_ERR_DECODE = "OT_MEDIA_ERR_DECODE",
    OT_MEDIA_ERR_NETWORK = "OT_MEDIA_ERR_NETWORK",
    OT_MEDIA_ERR_SRC_NOT_SUPPORTED = "OT_MEDIA_ERR_SRC_NOT_SUPPORTED",
    OT_NOT_CONNECTED = "OT_NOT_CONNECTED",
    OT_SET_REMOTE_DESCRIPTION_FAILED = "OT_SET_REMOTE_DESCRIPTION_FAILED",
    OT_STREAM_DESTROYED = "OT_STREAM_DESTROYED",
    OT_STREAM_LIMIT_EXCEEDED = "OT_STREAM_LIMIT_EXCEEDED",
    OT_STREAM_NOT_FOUND = "OT_STREAM_NOT_FOUND",
}

export {
    DisconnectReason,
    VideoSourceError, 
    SessionConnectError, 
    PublisherError, 
    SubscriberError
}