import React from 'react';
import type { PatientCheckIn, Provider, WaitingRoom } from 'shared/types';
import type { IToast } from 'shared/Messages/types';
import type { IWebsocket } from 'utils/Websocket/types';
import ConfigFactory from 'ConfigFactory';
import { countlyAddEvent, countlyRemoteConfig } from 'countly';
import countlyEvents from 'countly/events'
import HttpClient from 'utils/HttpClient';
import Websocket from 'utils/Websocket/Websocket';
import { AUDIO_QUALITY_BANDWIDTH_TOO_LOW } from 'utils/Network/constants';
import withStateRouter from 'shared/StateRouter/withStateRouter';
import withMessages from 'shared/Messages/withMessages';
import PRESENCE from 'shared/constants/PRESENCE';
import Loading from 'shared/Loading';
import PatientRoomContext from './PatientRoomContext';
import { CallSessionState } from 'pages/CallScreen/CallSession/CallSession';
import { StreamState, VisitSession } from 'pages/CallScreen/CallSession/types';
import SESSION_STATUS from 'shared/constants/SESSION_STATUS';
import VISIT_COMPLETED_REASON from 'shared/constants/VISIT_COMPLETED_REASON';
import { OpentokMediaSource } from 'pages/CallScreen/opentok/types';
;


type PatientRoomProviderProps = {
    history: any,
    view: string,
    params: any,
    showToast: (msg: IToast) => void,
    children: JSXElement
}

type PatientRoomProviderState = {
    loading: boolean
    isOnHold: boolean
    visitSession?: VisitSession
    
    initialAudioSource?: OpentokMediaSource,
    initialVideoSource?: OpentokMediaSource,

    audio_only_call: boolean
    session_id?: number
    patient_room?: {
        waiting_room: WaitingRoom
        provider: Provider
        providers: Provider[]
    }
    first_name?: string
    last_name?: string
}

class PatientRoomProvider extends React.Component<PatientRoomProviderProps, PatientRoomProviderState> {

    opentok_api_key: string
    socket?: IWebsocket
    _isUnmounted?: boolean
    recaptcha_loading?: boolean

    constructor(props: PatientRoomProviderProps) {
        super(props);
        this.state = {
            loading: false,
            isOnHold: false,
            audio_only_call: false,
        }

        this.opentok_api_key = ConfigFactory.getOpentokApiKey();
        this._loadReCaptchaEnterprise();

        if (this._isReturningPatient()) {
            props.history.push("welcome_back");
        } else {
            props.history.push("check_in");
        }
    }
    componentDidMount() {
        this._getWaitingRoom();

    }
    componentWillUnmount() {
        this._isUnmounted = true;
        this._closeSocket()
    }
    _loadReCaptchaEnterprise() {
        let GCP_CAPTCHA_STATE = countlyRemoteConfig('gcp_captcha_state') as number;
        if (GCP_CAPTCHA_STATE === undefined) GCP_CAPTCHA_STATE = 0;

        if (GCP_CAPTCHA_STATE > -1 && !this.recaptcha_loading) {
            this.recaptcha_loading = true;
            const GCP_CAPTCHA_SITE_KEY = ConfigFactory.getConfig().GCP_CAPTCHA_SITE_KEY
            const script = document.createElement("script");
            script.src = `https://www.google.com/recaptcha/enterprise.js?render=${GCP_CAPTCHA_SITE_KEY}`;
            script.async = true;
            document.body.appendChild(script);
        }
    }
    _getReCaptchaEnterpriseToken() {
        return (new Promise((resolve, reject) => {
            const grecaptcha = window.grecaptcha;
            const GCP_CAPTCHA_SITE_KEY = ConfigFactory.getConfig().GCP_CAPTCHA_SITE_KEY
            grecaptcha.enterprise.ready(function () {
                grecaptcha.enterprise.execute(GCP_CAPTCHA_SITE_KEY, { action: 'CHECKIN_SUBMIT' })
                    .then((token: string) => resolve(token))
                    .catch((error: any) => reject(error));
            });
        }));
    }
    _getWaitingRoom = () => {
        let apiName = 'telehealthApi';
        let path = '/waiting_rooms/by_slug/' + window.location.pathname.slice(1);

        this.setState({ loading: true });

        HttpClient()
            .get(apiName, path)
            .then((data) => {
                const { providers, waiting_room } = data;
                let provider = providers ? providers[0] : this.state.patient_room?.provider;

                this.setState({
                    patient_room: {
                        waiting_room: waiting_room,
                        provider: provider,
                        providers: providers,
                    },
                    loading: false,
                });

            }).catch((error) => {
                this.props.history.push("waiting_room_not_found");
                this.setState({
                    loading: false,
                });
            });
    }
    _handleSocket = () => {
        if (this._isUnmounted) return;
        const { visitSession } = this.state;
        this.socket = new Websocket();
        this.socket.connect(() => {
            if (visitSession?.patient_id) {
                this.socket?.iniSocket({ authorization: 'user', key: visitSession?.patient_id });
            }
        });
        this.socket.runHeartbeat();

        this.socket.listen('clinician_online', (message) => {
            const providerID = message.user_id;
            if (providerID) this._updateClinicianStatus(providerID, PRESENCE.available);
        });

        this.socket.listen('clinician_offline', (message) => {
            const providerID = message.user_id;
            if (providerID) this._updateClinicianStatus(providerID, PRESENCE.offline);
        });

        this.socket.listen('clinician_busy', (message) => {
            const providerID = message.user_id;
            if (providerID) this._updateClinicianStatus(providerID, PRESENCE.in_a_call);
        });

        this.socket.listen('call_video_on', (message) => {
            this.setState({
                audio_only_call: false,
            })
        });

        this.socket.listen('call_video_off', (message) => {
            this.setState({
                audio_only_call: true,
            })
        });

        this.socket.listen('patient_kicked_out', (message) => {
            this.handleKickedOut();
        });

        this.socket.listen('patient_exited', (message) => {
            if (message.user_id === visitSession?.patient_id) {
                this.handleLeave();
            }
        });
    }
    _updateClinicianStatus = (providerId: number, status: PRESENCE) => {
        if (!this.state.patient_room) return;

        let provider = { ...this.state.patient_room.provider };
        if (provider.id === providerId) {
            if ([PRESENCE.available, PRESENCE.offline].includes(status)) {
                countlyAddEvent(countlyEvents.patientSawProviderLeaveMeeting);
            }
            provider = { ...provider, presence: status };
        }

        this.setState({
            patient_room: {
                ...this.state.patient_room,
                provider: provider,
                providers: this.state.patient_room?.providers?.map((item) => {
                    if (item.id === providerId) {
                        return {
                            ...item,
                            presence: status
                        };
                    }

                    return item;
                })
            }
        });
    }
    _closeSocket = () => {
        if (this.socket) {
            this.socket.close();
        }
    }
    _isReturningPatient() {
        const stg = window.localStorage;
        if (stg?.latestFirstName && stg?.latestLastName && stg?.latestCheckedInDate) {
            const hoursDiff = Math.abs(new Date().getTime() - new Date(stg.latestCheckedInDate).getTime()) / 3_600_000; // Convert milliseconds to hours
            if (hoursDiff <= 12.0) { // Time window of 12 hours
                return true;
            }
        }
        return false;
    }
    handlePublisherDestroyed = () => {
        this._closeSocket()
        if (this.props.view === "call_session_started") {
            this.props.history.push("call_disconnected");
        }
    }
    handleLeave = () => {
        this._closeSocket();
        this.props.history.push("end_call");
    }
    handleKickedOut = () => {
        this._closeSocket();
        this.props.history.push("kicked_out");
    }
    handleCheckin = async (patient: PatientCheckIn) => {
        this.setState({ ...patient });
        this.props.history.push("setup_permissions");
    }
    handleStreamStateUpdated = async (updatedStream: StreamState): Promise<void> => {
        const { visitSession, session_id } = this.state;
        if (!visitSession) return;

        try {
            const apiName = 'telehealthApi';
            const path = `/sessions/${session_id}/users/${visitSession.patient_id}`;
            await HttpClient().post(apiName, path, updatedStream)
        } catch (e) {
            console.warn("handleStreamStateUpdated.error: ", e);
        }
    }
    handlePatientEnterQueue = async () => {
        this.setState({
            loading: true,
        });

        const apiName = 'telehealthApi';
        const path = '/patients/checkin';

        const postData: any = {
            slug: this.props.params.room,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
        };

        let recaptchaToken = null;
        let GCP_CAPTCHA_STATE = countlyRemoteConfig('gcp_captcha_state') as number;
        if (GCP_CAPTCHA_STATE === undefined) GCP_CAPTCHA_STATE = 0;

        if (GCP_CAPTCHA_STATE > -1 || ConfigFactory.getEnv() === "drone") {
            try {
                recaptchaToken = await this._getReCaptchaEnterpriseToken();
            } catch (error: any) {
                countlyAddEvent(countlyEvents.errorCaptcha, {
                    errorCode: error.name,
                    errorDescription: error.message,
                    comment: "Captcha error",
                });
            }
            postData.recaptcha_token = recaptchaToken;
        }

        HttpClient().post(apiName, path, postData)
            .then((data) => {
                let { providers, waiting_room } = data;
                providers = providers ? providers : this.state.patient_room?.providers;
                let provider = providers ? providers[0] : this.state.patient_room?.provider;

                this.setState({
                    session_id: data.session_id,
                    visitSession: {
                        external_session_id: data.external_session_id,
                        external_token: data.external_token,
                        patient_id: data.patient_id,
                    },
                    patient_room: {
                        waiting_room: waiting_room,
                        provider: provider,
                        providers: providers,
                    },
                });
                this._handleSocket();
                this.props.history.push("call_session_started");
                this.setState({ loading: false });
            }).catch((error) => {
                this.setState({
                    loading: false
                });
                this.props.history.push("check_in");
                countlyAddEvent(countlyEvents.errorCheckin, {
                    errorCode: error.name,
                    errorDescription: error.message,
                    comment: "Failed to checkin",
                });
                this.props.showToast({
                    message: <div className="has-text-centered" >We were not able to check in right now.<br />Please try again later.</div>,
                    position: "top center"
                });
            });
    }
    handleNoParticipants = (callSessionState: CallSessionState): void => {
        // Don't leave the call if the Provider is disconnected. He might get back to the call again
        if (
            callSessionState.subscriberNetworkQuality
            && callSessionState.subscriberNetworkQuality < AUDIO_QUALITY_BANDWIDTH_TOO_LOW
        ) return;

        const { visitSession, session_id } = this.state;
        if (!visitSession) return;

        let apiName = 'telehealthApi';
        let path = `/patients/${visitSession.patient_id}/sessions/${session_id}/status`;
        HttpClient().get(apiName, path)
            .then((data) => {
                switch (data.status) {
                    case SESSION_STATUS.on_hold:
                        this.setState({ isOnHold: true });
                        break;
                    case SESSION_STATUS.on:
                    case SESSION_STATUS.off:
                    default:
                        countlyAddEvent(countlyEvents.visitCompleted, {
                            reason: VISIT_COMPLETED_REASON.endedByProvider
                        });
                        this.handleLeave();
                        break;

                }
            })
            .catch((error) => {
                countlyAddEvent(countlyEvents.errorNoParticipants, {
                    errorCode: error.name,
                    errorDescription: error.message,
                    comment: `Failed to update session status on NoParticipants.
                    Call pause or a participant leaving call for different reason`,
                });
            });
    }
    setInitialAudioSource = (audioSource: OpentokMediaSource) => {
        this.setState({initialAudioSource: audioSource});
    }
    setInitialVideoSource = (videoSource: OpentokMediaSource) => {
        this.setState({initialVideoSource: videoSource});
    }
    render() {

        const { visitSession } = this.state;

        return (
            <PatientRoomContext.Provider value={{
                ...this.state.patient_room,
                loading: this.state.loading,
                isOnHold: this.state.isOnHold,
                visitSession: visitSession,
                session_id: this.state.session_id,
                audio_only_call: this.state.audio_only_call,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                initialAudioSource: this.state.initialAudioSource,
                initialVideoSource: this.state.initialVideoSource,

                //methods
                setInitialAudioSource: this.setInitialAudioSource,
                setInitialVideoSource: this.setInitialVideoSource,
                handlePublisherDestroyed: this.handlePublisherDestroyed,
                handleLeave: this.handleLeave,
                handleKickedOut: this.handleKickedOut,
                handleCheckin: this.handleCheckin,
                handleStreamStateUpdated: this.handleStreamStateUpdated,
                handlePatientEnterQueue: this.handlePatientEnterQueue,
                handleNoParticipants: this.handleNoParticipants,
            }} >
                {this.state.loading ?
                    <div className="container is-fluid fullheight d-flex align-items-center justify-content-center" >
                        <Loading />
                    </div>
                    : this.props.children}
            </PatientRoomContext.Provider>
        );
    }
}
export default withStateRouter(withMessages(PatientRoomProvider));