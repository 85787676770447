import React from 'react';
import HttpClient from 'utils/HttpClient';
import { Provider } from 'shared/types';
import SelectMultiple from 'shared/inputs/SelectMultiple';
import type { 
    SelectMultipleValue, 
    SelectMultipleOption, 
    ActionMetaOption 
} from 'shared/inputs/SelectMultiple';

type Props = {
    waiting_room_id: number
}
type State = {
    loading: boolean,
    assigned_providers: Provider[],
    options: SelectMultipleOption,
}
class ProvidersWaitingRoom extends React.Component<Props, State> {
    constructor(props: Props){
        super(props);
        this.state = {
            loading: false,
            assigned_providers: [],
            options: []
        };
    }
    componentDidMount(){
        this.loadOptions();
        this.getWaitingRoomProviders(this.props.waiting_room_id);
    }
    getWaitingRoomProviders(waiting_room_id: number): void{
        let apiName = 'telehealthApi';
        let path = `/waiting_rooms/${waiting_room_id}/providers`; 
        this.setState({
            loading: true
        });
        HttpClient().get(apiName, path)
        .then( (data) => {
            this.setState({
                loading: false,
                assigned_providers: data
            });
        }).catch((error) => {
            this.setState({
                loading: false
            });
        });
    }
    loadOptions = (search: string = "", callback?: (options: any) => void): void => {
        let apiName = 'telehealthApi';
        let path = '/providers'; 
        let queryParams = {
            skip: 0,
            limit: 30,
            search: search
        };
        this.setState({loading: true});
        HttpClient().get(apiName, path, queryParams)
        .then((data: Provider[]) => {
            this.setState({loading: false});
            this.setState({options: this.providersToOptions(data)});
            if(callback) callback(this.providersToOptions(data));
        }).catch((error) => {
            this.setState({loading: false});
        });
    }
    handleChange = (name: string, options: SelectMultipleValue, action: ActionMetaOption) => {
        if(action.action === "select-option" && action.option) { // Waiting room added
            this.assignProvider(this.props.waiting_room_id, action.option.value, () => {
                this.setState({
                    assigned_providers: options.map((option) => option.item),
                });
            });
        }
        if(["remove-value", "pop-value"].includes(action.action) && action.removedValue) { // Waiting room removed
            this.unassignProvider(this.props.waiting_room_id, action.removedValue.value, () => {
                this.setState({
                    assigned_providers: options.map((option) => option.item),
                });
            })
        }
        if(action.action === "clear" && action.removedValues) { // All Waiting room removed(cleared)
            action.removedValues.forEach((option) => {
                this.unassignProvider(this.props.waiting_room_id, option.value)
            });
            this.setState({assigned_providers: []});
        }
    }
    providersToOptions = (providers: Provider[]): SelectMultipleOption => {
        return providers.map((provider) => {
            return {
                label: provider.first_name + " " + provider.last_name,
                value: provider.id,
                item: provider,
            }
        });
    }
    assignProvider(waiting_room_id: number, provider_id: number | string, onSuccess?: Function): void{
        let apiName = 'telehealthApi';
        let path = `/waiting_rooms/${waiting_room_id}/assign_provider`; 
        this.setState({
            loading: true
        });
        let postData = {
            id: provider_id
        }
        HttpClient().post(apiName, path, postData)
        .then( (data) => {
            this.setState({
                loading: false
            });
            if(onSuccess) onSuccess();
        }).catch((error) => {
            this.setState({
                loading: false
            });
        });
    }
    unassignProvider(waiting_room_id: number, provider_id: number | string, onSuccess?: Function): void {
        let apiName = 'telehealthApi';
        let path = `/waiting_rooms/${waiting_room_id}/unassign_provider`;
        this.setState({
            loading: true
        });
        let postData = {
            id: provider_id
        }
        HttpClient().post(apiName, path, postData)
            .then((data) => {
                this.setState({
                    loading: false
                });
                if (onSuccess) onSuccess();
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            });
    }
    render() {
        return (
            <SelectMultiple
                isLoading={this.state.loading}
                label="Assign providers"
                placeholder={"Select providers to assign"}
                name="assign_provider"
                value={this.providersToOptions(this.state.assigned_providers)}
                defaultOptions={this.state.options}
                loadOptions={this.loadOptions}
                onChange={this.handleChange}
            />
        );
    }
}
export default ProvidersWaitingRoom;