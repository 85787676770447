import React from 'react';
import qs from "qs";
import { RouteComponentProps } from 'react-router-dom';
import HttpClient from 'utils/HttpClient';
import WaitingRoomsProvider from './components/WaitingRoomsProvider';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import { hasPermission } from 'utils/Authorizer';
import { IMessage, MessageType } from 'shared/Messages/types';
import Loading from "shared/Loading";
import Input from 'shared/inputs/Input2';
import SelectMultiple from 'shared/inputs/SelectMultiple';
import type { SelectMultipleValue, SelectMultipleOption } from 'shared/inputs/SelectMultiple';
import MessageInline from 'shared/MessageInline';
import { Clinic, IProvider, IUser, Provider, Role, Validation } from 'shared/types';
import withValidation from 'validations/withValidation';
import ProvidersFormRules from 'validations/FormRules/ProvidersFormRules';
import { IValidation } from 'validations/types';
import { Button, Text, Drawer, DrawerFooter, DrawerHeader, DrawerContent } from '@village/ui';
import { Box } from '@material-ui/core';

// TODO move to shared/types and set the code type to Role
enum UserRoleCode {
    provider = "provider",
    admin = "admin",
    clinical_supervisor = "clinical_supervisor",
    clinical_supporter = "clinical_supporter",
}
type FormProvidersProps = RouteComponentProps & IValidation & {
    match: RouteComponentProps['match'] & {
        params: { id: string }
    }
    mode: "new" | "edit",
    authUser: IUser,
    onCreate: (newProviders: Provider) => void,
    onUpdate: (providerId: number, postData: IProvider) => void,
}
type FormProvidersState = {
    loading: boolean,
    open: boolean,
    message: IMessage | null,
    clinics?: Clinic[],
    provider: IProvider,
    roles: Role[]
}


class FormProviders extends React.Component<FormProvidersProps, FormProvidersState> {
    constructor(props: FormProvidersProps) {
        super(props);
        this.state = {
            loading: false,
            open: false,
            message: null,
            provider: {},
            roles: []
        };
    }
    componentDidMount() {
        this.loadRoles();
        this.loadClinics();
        if (this.props.mode === 'edit') {
            this.getProvider(parseInt(this.props.match.params.id));
        }
        this.setState({ open: true });
    }
    isUserType = (provider: IProvider, user_type: UserRoleCode) => {
        if (!provider.roles) return false;

        const userTypeRole = this.state.roles.find((role) => role.code === user_type);
        if (userTypeRole) {
            let found = provider.roles.find((role) => role.id === userTypeRole.id)
            return !!found;
        }

        return false;
    }
    getProvider(provider_id: number): void {
        let apiName = 'telehealthApi';
        let path = '/providers/' + provider_id;

        this.setState({
            loading: true
        });
        HttpClient().get(apiName, path)
            .then((data) => {
                this.setState({
                    loading: false,
                    provider: {
                        ...data,
                    }
                });
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            });
    }
    getProviderRoleIds = (provider: IProvider): Role['id'][] => {
        return provider.roles ? provider.roles?.map((role) => role.id) : [];
    }
    loadRoles = (): void => {
        let apiName = 'telehealthApi';
        let path = '/roles';
        HttpClient().get(apiName, path)
            .then((data) => {
                this.setState({ roles: this.filterRoles(data) });
            }).catch((error) => {
                console.log("Loading roles error: ", error)
            });
    }
    filterRoles = (roles: Role[]): Role[] => {
        return roles.filter(role => hasPermission('WRITE', `assign_role.${role.code}`));
    }
    getRoles = (): SelectMultipleOption => {
        return this.rolesToOptions(this.state.roles);
    }
    rolesToOptions = (roles: Role[] = []): SelectMultipleOption => {
        return roles.map(role => {
            return {
                value: role.id,
                code: role.code,
                label: role.name,
                item: role,
            }
        });
    }
    getProviderClinicIds = (provider: IProvider): Clinic['id'][] => {
        const clinics = provider.clinics || [];
        return clinics.map((clinic) => clinic.id);
    }
    getClinics = (): SelectMultipleOption => {
        const clinics = this.state.clinics || this.props.authUser?.clinics || [];
        return this.clinicsToOptions(clinics);
    }
    clinicsToOptions = (clinics: Clinic[] = []): SelectMultipleOption => {
        return clinics.map(clinic => {
            return {
                value: clinic.id,
                code: clinic.code,
                label: clinic.name,
                item: clinic,
            }
        });
    }
    loadClinics = (): void => {
        const apiName = 'telehealthApi';
        const path = '/clinics';
        HttpClient().get(apiName, path)
            .then((data) => {
                this.setState({
                    clinics: data
                });
            }).catch((error) => {
                console.log("Loading clinics error: ", error)
            });
    }
    async createProvider(): Promise<void> {
        let apiName = 'telehealthApi';
        let path = '/providers';
        const { provider } = this.state;
        let postData = this.getProviderPostData(provider);

        if (!this.props.validate({
            ...provider,
            isProvider: this.isUserType(provider, UserRoleCode.provider),
        })) return;

        this.setState({
            loading: true
        });

        try {
            // create a new Provider
            const newProvider = await HttpClient().post(apiName, path, postData);

            this.setState({
                loading: false,
                provider: newProvider
            });
            this.props.onCreate(newProvider);
            this.redirectToEdit(newProvider.id);
        } catch (error: any) {
            this.setState({
                loading: false,
                message: {
                    type: MessageType.danger,
                    message: (error.response?.data?.detail || "Failed to create user")
                }
            });
            countlyAddEvent(countlyEvents.error, {
                errorCode: error.name,
                errorDescription: error.message,
                comment: "Failed to create user",
                response: JSON.stringify(error.response)
            });
        }
    }
    getProviderPostData = (provider: IProvider): IProvider => {
        return {
            first_name: provider.first_name ? provider.first_name.trim() : provider.first_name,
            last_name: provider.last_name ? provider.last_name.trim() : provider.last_name,
            email: provider.email ? provider.email.trim() : provider.email,
            title: provider.title ? provider.title.trim() : provider.title,
            credential: provider.credential ? provider.credential.trim() : provider.credential,
            npi: provider.npi ? provider.npi.trim() : provider.npi,
            role_ids: this.getProviderRoleIds(provider),
            clinic_ids: this.getProviderClinicIds(provider),
        };
    }
    updateProvider(provider_id: number): void {
        let apiName = 'telehealthApi';
        let path = '/providers/' + provider_id;
        const { provider } = this.state;
        let postData = this.getProviderPostData(provider);

        if (!this.props.validate({
            ...provider,
            isProvider: this.isUserType(provider, UserRoleCode.provider),
        }, 'update')) return;

        this.setState({
            loading: true
        });
        HttpClient().put(apiName, path, postData)
            .then((data) => {
                this.setState({
                    loading: false,
                    provider: {
                        ...provider,
                        ...postData
                    }
                });
                this.props.onUpdate(provider_id, postData);
                this.handleClose();
            }).catch((error) => {
                this.setState({
                    loading: false,
                    message: {
                        type: MessageType.danger,
                        message: (error.response?.data?.detail || "Failed to update user")
                    }
                });
                countlyAddEvent(countlyEvents.error, {
                    errorCode: error.name,
                    errorDescription: error.message,
                    comment: "Failed to update user",
                    response: JSON.stringify(error.response)
                });
            });
    }
    redirectToEdit = (providerId: number): void => {
        let queryParams = qs.parse(this.props.location?.search, { ignoreQueryPrefix: true });
        this.props.history.push({
            pathname: '/providers/edit/' + providerId,
            search: '?' + qs.stringify(queryParams),
        });
    }
    handleClose = (): void => {
        this.setState({ open: false });
        setTimeout(() => {
            let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            this.props.history.push({
                pathname: '/providers',
                search: '?' + qs.stringify(queryParams),
            });
        }, 300);
    }
    handleSubmit = (event: React.FormEvent): void => {
        event.preventDefault();

        if (this.props.mode === 'new') {
            this.createProvider();
        } else {
            this.updateProvider(parseInt(this.props.match.params.id));
        }
    }
    handleChangeSelect = (name: string, options: SelectMultipleValue): void => {
        const value = options.map((option) => option.item);
        this.setState({
            provider: {
                ...this.state.provider,
                [name]: value
            }
        });
        this.props.validateLive({
            ...this.state.provider,
            [name]: value
        }, this.props.mode === 'edit' ? 'update' : 'create');
    }
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            provider: {
                ...this.state.provider,
                [event.target.name]: event.target.value
            }
        });

        //Trigger validation for new updates:
        this.props.validateLive({
            ...this.state.provider,
            [`${event.target.name}`]: event.target.value
        }, this.props.mode === 'edit' ? 'update' : 'create');
    }
    isEditable = (): boolean => {
        const provider = this.state?.provider;
        if (!this.props.authUser?.roles || !provider.clinics) {
            return true;
        }

        const supervisorRole = this.props.authUser.roles.find((role) => role.code === 'clinical_supervisor');
        if (supervisorRole
            && provider?.clinics.length === 1
            && provider.clinics[0].code === 'default'
        ) {
            return false;
        }
        return true;
    }
    render() {

        const { provider, message } = this.state;
        const validations = this.props.validations === true ? {} : this.props.validations;

        return (
            <Drawer open={this.state.open} onClose={this.handleClose} anchor='right' data-testid='form-providers-drawer'>
                <DrawerHeader onClose={this.handleClose} >
                    <Text type="h6">{this.props.mode === 'new' ? 'Create' : 'Edit'} provider</Text>
                </DrawerHeader>
                <DrawerContent>
                    {this.state.loading ?
                        <Loading />
                        : <form id='provider-form' method='POST' >
                            {message && <MessageInline type={message.type} message={message.message} />}
                            <Box py={2}>
                                <Input
                                    label='First name'
                                    name='first_name'
                                    fullWidth
                                    value={provider.first_name}
                                    onChange={this.handleChange}
                                    validation={validations?.first_name as Validation}
                                    disabled={!this.isEditable()}
                                />
                            </Box>
                            <Box py={2}>
                                <Input
                                    label='Last name'
                                    name='last_name'
                                    fullWidth
                                    value={provider.last_name}
                                    onChange={this.handleChange}
                                    validation={validations?.last_name as Validation}
                                    disabled={!this.isEditable()}
                                />
                            </Box>
                            {this.isUserType(provider, UserRoleCode.provider) &&
                                <React.Fragment>
                                    <Box py={2}>
                                        <Input
                                            label='Title'
                                            name='title'
                                            fullWidth
                                            value={provider.title}
                                            onChange={this.handleChange}
                                            disabled={!this.isEditable()}
                                        />
                                    </Box>
                                    <Box py={2}>
                                        <Input
                                            label='Credential'
                                            name='credential'
                                            fullWidth
                                            value={provider.credential}
                                            onChange={this.handleChange}
                                            disabled={!this.isEditable()}
                                        />
                                    </Box>
                                    <Box py={2}>
                                        <Input
                                            label='NPI'
                                            name='npi'
                                            fullWidth
                                            value={provider.npi}
                                            validation={validations?.npi as Validation}
                                            onChange={this.handleChange}
                                            disabled={!this.isEditable()}
                                        />
                                    </Box>
                                </React.Fragment>
                            }
                            <Box py={2}>
                                <Input
                                    label='Email'
                                    name='email'
                                    fullWidth
                                    disabled={this.props.mode === 'edit' || !this.isEditable()}
                                    value={provider.email}
                                    onChange={this.handleChange}
                                    validation={validations?.email as Validation}
                                />
                            </Box>
                            <Box py={2}>
                                <SelectMultiple
                                    id="roles"
                                    placeholder={"Choose roles"}
                                    name="roles"
                                    label="Roles"
                                    options={this.getRoles()}
                                    value={this.rolesToOptions(provider.roles)}
                                    onChange={this.handleChangeSelect}
                                    disabled={!this.isEditable()}
                                    validation={validations?.roles as Validation}
                                />
                            </Box>
                            <Box py={2}>
                                <SelectMultiple
                                    id="clinics"
                                    placeholder={"Choose clinics"}
                                    name="clinics"
                                    label="Clinics"
                                    options={this.getClinics()}
                                    value={this.clinicsToOptions(provider.clinics)}
                                    onChange={this.handleChangeSelect}
                                    validation={validations?.clinics as Validation}
                                />
                            </Box>

                            {this.props.mode === 'edit' &&
                                <Box py={2}>
                                    <WaitingRoomsProvider
                                        provider_id={parseInt(this.props.match.params.id)}
                                        assigned_waiting_rooms={provider.waiting_rooms || []}
                                    />
                                </Box>
                            }

                        </form>}
                </DrawerContent>
                <DrawerFooter>
                    <Box display="flex" gridGap={16}>
                        <Button variant="secondary" size="medium" fullWidth onClick={this.handleClose} >
                            Cancel
                        </Button>
                        <Button size="medium" fullWidth type="submit" onClick={this.handleSubmit} >
                            Save
                        </Button>
                    </Box>
                </DrawerFooter>
            </Drawer>
        );
    }
}
export default withValidation(FormProviders, ProvidersFormRules);