import { Amplify } from 'aws-amplify'
import * as Sentry from "@sentry/react";
import SSOAuth from 'utils/SSO/SSOAuth';
import configDrone from "./config/drone";
import configDevelopment from "./config/development";
import configDevelopmentMobile from "./config/developmentMobile";
import configTest from "./config/test";
import configAlpha from "./config/alpha";
import configUat from "./config/uat";
import configProduction from "./config/production";
import type { UserOrigin, Env, Endpoint, Config, SSOConfig } from './shared/types';


class ConfigFactory {
    env?: Env;

    init = (env?: Env) => {
        var config = this.getConfig(env);
        return Amplify.configure(config);
    };
    getEnv = (): Env | string => {
        return this.env ? this.env :
            process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'development';
    };
    setEnv = (env: Env) => {
        this.env = env;
    }
    getConfig = (env?: Env): Config => {
        const envName = env ? env : this.getEnv();

        let config = configDevelopment;
        switch (envName) {
            case "drone":
                config = configDrone;
                break;
            case "developmentMobile":
                config = configDevelopmentMobile;
                break;
            case "test":
                config = configTest;
                break;
            case "alpha":
                config = configAlpha;
                break;
            case "uat":
                config = configUat;
                break;
            case "production":
                config = configProduction;
                break;
            default:
            case "development":
                config = configDevelopment;
                break;
        }
        config = this.setAccessTokenForEndpoints(config);

        return config;
    };
    getEndpoint = (name: string, env?: Env): Endpoint | undefined => {
        return this.getConfig(env).aws_cloud_logic_custom.find((endpoint) => endpoint.name === name);
    };
    setAccessTokenForEndpoints = (config: Config): Config => {
        return {
            ...config,
            aws_cloud_logic_custom: config.aws_cloud_logic_custom.map((endpoint: Endpoint) => ({
                ...endpoint,
                custom_header: async () => {
                    let customHeaders = {};

                    try {
                        let accessToken = (await this.getAccessToken());
                        if (accessToken) {
                            customHeaders = {
                                Authorization: `Bearer ${accessToken}`
                            };
                        }

                    } catch (error) {
                        customHeaders = {};
                    }

                    return customHeaders;
                }
            }))
        };
    };
    getAccessToken = async () => {
        return SSOAuth.getIdToken();
    };
    getSSOConfig = (idp: UserOrigin): SSOConfig => {
        return this.getConfig()[idp];
    }
    getOpentokApiKey = (): string => {
        return this.getConfig().opentok.API_KEY
    };
    getCountlyConfig = () => {
        return this.getConfig().countly
    };
    getPatientHost = (): string => {
        return this.getConfig().patient_host[0];
    };
    isPatientHost = (hostname: string): boolean => {
        return this.getConfig().patient_host.indexOf(hostname) > -1;
    };
    isProviderHost = (hostname: string): boolean => {
        const provider_host = this.getConfig().provider_host;
        if (Array.isArray(provider_host)) {
            return provider_host.indexOf(hostname) > -1;
        } else {
            return provider_host === hostname;
        }
    };
    initSentry = (): void => {
        const { sentry_dsn } = this.getConfig();
        if (sentry_dsn) {
            Sentry.init({
                dsn: sentry_dsn,
                environment: this.getEnv(),
                ignoreErrors: [
                    "AbortError: The operation was aborted.",
                    "TypeError: Cannot read properties of undefined (reading 'sample')",
                    "TypeError: Cannot read property 'socketId' of null",
                    "TypeError: Cannot read properties of null (reading '_')",
                    "TypeError: Cannot read properties of null (reading 'processMessage')",
                    "TypeError: Cannot read properties of null (reading 'getStats')",
                    "TypeError: undefined is not an object (evaluating 'this._audioLevelSampler.sample')",
                    "TypeError: null is not an object (evaluating 'oe.socketId')",
                    "TypeError: null is not an object (evaluating 'e.getStats')",
                    "TypeError: A server with the specified hostname could not be found.",
                    "TypeError: undefined is not an object (evaluating 'Se.promise.then')",
                    "TypeError: Failed to fetch",
                    "Error: Should not already be working.",
                    "Error: Network Error",
                    "Network Error",
                    "Failed to fetch",
                    "Request aborted",
                    "cancelled",
                    "WebKit encountered an internal error",
                    "The network connection was lost.",
                    "The Internet connection appears to be offline.",
                    "timeout of 0ms exceeded",
                    "null is not an object (evaluating 'n.session._')",
                    "SecurityError: The operation is insecure.",
                    "UnhandledRejection: Non-Error promise rejection captured with value",
                    "OT_NO_DEVICES_FOUND",
                    "OT_PEER_CONNECTION_NOT_CONNECTED",
                    "OT_USER_MEDIA_ACCESS_DENIED",
                    "OT_HARDWARE_UNAVAILABLE",
                    "OT_UNEXPECTED_SERVER_RESPONSE",
                    "OT_STREAM_CREATE_FAILED",
                ],
                integrations: [
                    new Sentry.Integrations.UserAgent(),
                    new Sentry.Integrations.FunctionToString(),
                    new Sentry.Integrations.GlobalHandlers(),
                    new Sentry.Integrations.Breadcrumbs(),
                ],
            });
        }
    }
}
export default (new ConfigFactory());
