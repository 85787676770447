import images from 'assets/images';
import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Feature from 'shared/Feature';
import FF from 'shared/constants/FF';
import { CheckPermission } from 'utils/Authorizer';
import { Box } from '@material-ui/core';
import {
    ArrowRightFromLineIcon,
    BuildingIcon,
    CircleQuestionIcon,
    DashboardIcon,
    DoorIcon,
    EarthIcon,
    GroupIcon,
    ReportsIcon,
    SquareListIcon
} from "@village/icons"
import {
    Sidebar,
    SidebarCollapseButton,
    SidebarContent,
    SidebarFooter,
    SidebarHeader,
    SidebarItem,
    SidebarLinkItem
} from '@village/ui'

import withClinicalRoom from '../../providers/Room/withClinicalRoom';
import consumeApp from '../../providers/consumeApp';
import FeedbackModal from './ProviderSidebar/FeedbackModal';

type Props = {
    handleClinicianGoOffline: () => void,
    logout: () => void,
}
type State = {
    isCollapsed: boolean,
    isModalOpen: boolean
}
class ProviderSidebar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isCollapsed: true,
            isModalOpen: false,
        };
    }
    componentDidMount() {
        window.addEventListener('beforeunload', this.props.handleClinicianGoOffline);
    }
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.props.handleClinicianGoOffline);
    }
    handleCollapse = () => this.setState({ isCollapsed: !this.state.isCollapsed });
    handleModalOpen = () => this.setState({ isModalOpen: true });
    handleModalClose = () => this.setState({ isModalOpen: false });

    handleLogout = () => {
        this.props.handleClinicianGoOffline();
        this.props.logout();
    }
    render() {
        return (
            <>
                <Sidebar open={this.state.isCollapsed} toggle={this.handleCollapse}>
                    <SidebarHeader>
                        {this.state.isCollapsed && <Box display="flex">
                            <img src={images.logo_village_medical} alt="Village medical V icon" />
                        </Box>}
                        <SidebarCollapseButton />
                    </SidebarHeader>
                    <SidebarContent>
                        <NavLink to="/dashboard" >
                            <SidebarItem title="dashboard" icon={<DashboardIcon />}>
                                Dashboard
                            </SidebarItem>
                        </NavLink>
                        <CheckPermission act='GET' obj='/markets' >
                            <NavLink to="/markets" >
                                <SidebarItem title="Markets" icon={<EarthIcon />}>
                                    Markets
                                </SidebarItem>
                            </NavLink>
                        </CheckPermission>
                        <CheckPermission act='GET' obj={'/clinics'} >
                            <NavLink to="/clinics" >
                                <SidebarItem title="Clinics" icon={<BuildingIcon />}>Clinics</SidebarItem>
                            </NavLink>
                        </CheckPermission>
                        <CheckPermission act='GET' obj={'/providers'} >
                            <NavLink to="/providers" >
                                <SidebarItem title="Providers" icon={<GroupIcon />}>Providers</SidebarItem>
                            </NavLink>
                        </CheckPermission>
                        <NavLink to="/waiting_rooms" >
                            <SidebarItem title="Waiting rooms" icon={<DoorIcon />}>Waiting rooms</SidebarItem>
                        </NavLink>
                        <Feature item code={FF.reports} >
                            <CheckPermission act='GET' obj={'/reports/visits'} >
                                <NavLink to="/reports" >
                                    <SidebarItem title="Reports" icon={<ReportsIcon />}>Reports</SidebarItem>
                                </NavLink>
                            </CheckPermission>
                        </Feature>
                        <CheckPermission act='GET' obj={'/logs'} >
                            <NavLink to="/logs">
                                <SidebarItem title='Logs' icon={<SquareListIcon />}>Logs</SidebarItem>
                            </NavLink>
                        </CheckPermission>
                    </SidebarContent>
                    <SidebarFooter>
                        <SidebarLinkItem icon={<CircleQuestionIcon />} title='Help & Feedback' onClick={this.handleModalOpen}>
                            Help & Feedback
                        </SidebarLinkItem>
                        <SidebarLinkItem icon={<ArrowRightFromLineIcon />} title='Logout' onClick={this.handleLogout}>
                            Logout
                        </SidebarLinkItem>
                    </SidebarFooter>
                </Sidebar>
                <FeedbackModal isOpen={this.state.isModalOpen} onClose={this.handleModalClose} />
            </>
        );
    }
}
export default withRouter(consumeApp(withClinicalRoom(ProviderSidebar)));
