import React from 'react';
import UpdateChecker from 'layouts/partials/UpdateChecker';
import ConfigFactory from 'ConfigFactory';
import Avatar from 'shared/Avatar';
import AVATAR_COLOR_STATUS from 'shared/constants/AVATAR_COLOR_STATUS';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import images from 'assets/images';
import BoxedLayout from 'layouts/BoxedLayout';
import withPatientRoom from 'providers/PatientRoom/withPatientRoom';
import { IWaitingRoom, PatientCheckIn, Provider } from 'shared/types';
import { StateRouterContextState } from 'shared/StateRouter/StateRouterContext';


type Props = StateRouterContextState & {
    loading: boolean,
    provider: Provider, 
    waiting_room: IWaitingRoom,
    handleCheckin: (patient: PatientCheckIn) => void,
}
type State = {
    first_name: string,
    last_name: string,
    patient_consent: boolean,
}
class WelcomeBack extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {...this.computePatientState()};
    }
    componentDidMount() {
        countlyAddEvent(countlyEvents.pageWelcomeBack);
    }
    computePatientState() {
        const stg = window.localStorage;
        return {
            first_name: stg.latestFirstName,
            last_name: stg.latestLastName,
            patient_consent: true,
        };
    }
    handleRejoin = (event: React.FormEvent): void => {
        if (event) event.preventDefault();
        countlyAddEvent(countlyEvents.clickWBRejoin);
        const { first_name, last_name } = this.state;
        this.props.handleCheckin({ first_name, last_name });
    }
    handlePatientReset = (event: React.MouseEvent): void => {
        if (event) event.preventDefault();
        const stg = window.localStorage;
        if (stg) {
            stg.removeItem('latestFirstName');
            stg.removeItem('latestLastName');
            stg.removeItem('latestCheckedInDate');
        }
        countlyAddEvent(countlyEvents.clickNotYou);
        this.props.history?.push("check_in");
    }
    render() {
        const { provider, waiting_room } = this.props;
        return (
            <BoxedLayout>
                <div className="checkin box d-flex flex-row align-items-center">
                    <div className="column">
                        <UpdateChecker env={ConfigFactory.getEnv()} />
                        <div className="media-left my-4 is-hidden-tablet">
                            <figure>
                                <img src={images.logo_village_medical} width="155" height="28" alt="village medical logo" />
                            </figure>
                        </div>
                        <form id="patient-form" method='POST' onSubmit={this.handleRejoin} >
                            <h4 className="has-text-centered gray-90 text-h5-to-h4-medium">Welcome back, {this.state.first_name} {this.state.last_name}!</h4>

                            <div className="media my-5 clinician-card">
                                <div className="media-left">
                                    <Avatar
                                        first_name={provider.first_name}
                                        last_name={provider.last_name}
                                        avatarColor={provider.presence && AVATAR_COLOR_STATUS[provider.presence]}
                                        img={images.ic_provider}
                                    />
                                </div>
                                <img className="v-logo-overlap" src="../../../../ic-villagemd-v.svg" alt="village medical v icon" />
                                <div className="media-content is-pulled-left">
                                    <div className="column is-vcentered">
                                        <p className="subtitle is-7">Appointment with</p>
                                        <p className="title has-text-weight-bold is-6">{waiting_room.name}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="has-text-centered">
                                <button className={`button is-primary is-fullwidth ${this.props.loading ? 'is-loading' : ''}`}>
                                    Join waiting room
                                </button>
                                <button className="button is-secondary mt-1 is-fullwidth" onClick={this.handlePatientReset}>
                                    Not you? Click here
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </BoxedLayout>
        )
    }
}
export default withPatientRoom(WelcomeBack);
