import React from 'react';
import { ButtonCard } from '@village/ui';
import { InputContainer, Label } from './styles';
import { Row, Column } from 'shared/Flex';
import { Icon } from 'icon';

type Props = {
    value: boolean,
    onChange: (willBlur: boolean) => void,
}
const SelectBackground = ({value, onChange}: Props) => {
    return (
        window?.OT?.hasMediaProcessorSupport() ?
            <InputContainer>
                <Label type="caption1">Background</Label>
                <Row gap={12}>
                    <Column grow padding={0}>
                        <ButtonCard
                            onClick={() => onChange(false)}
                            selected={value === false}
                            pictureSize="small"
                            picture={<Icon name="circle_slash" size={1.5} />}
                            primaryText="None"
                        />
                    </Column>
                    <Column grow padding={0}>
                        <ButtonCard
                            onClick={() => onChange(true)}
                            selected={value === true}
                            pictureSize="small"
                            picture={<Icon name="blur" size={1.5} />}
                            primaryText="Blur"
                        />
                    </Column>
                </Row>
            </InputContainer>
            : null
    );
}
export default SelectBackground;