import styled from 'styled-components';

export const VideoPreviewContainer = styled.div`
    width: 100%;
    height: 100%;

    ${({ hidden }) => hidden && `
        displau: none;
    `}
`;

export const VideoPreviewSize = styled.div`
    @media screen and (max-width: 767px) {
        height: 160px;
        width: 160px;
        position: absolute;
        z-index: -100;
    }

    @media screen and (min-width: 768px) {
        width: 234px;
        height: 234px;
        position: absolute;
        z-index: -100;
    }
`;

export const VideoPreview = styled.div`
    border-radius: 8px;
    overflow: hidden;

    @media screen and (max-width: 767px) {
        width: 88px;
        height: 106px;
    }

    @media screen and (min-width: 768px) {
        width: 194px;
        height: 234px;
    }

`;