import React from 'react';
import ControlBtn from './ControlBtn';
import MicrophoneVolume from './MicrophoneVolume';
import countlyEvents from 'countly/events';
import { TRYING_TO_CONNECT } from 'utils/Network/constants';

type AudioBtnProps = {
    callSession: {
        audio: boolean,
        networkQuality: number,
    },
    onClick: () => void
}
const AudioBtn = ({callSession, onClick}: AudioBtnProps) => {
    return (
        <ControlBtn
            id="ctrl-btn-audio"
            active={callSession.networkQuality > TRYING_TO_CONNECT}
            onClick={onClick}
            className="disable-blur"
            label={callSession.audio ? 'Mute' : 'Unmute'}
            eventCountly={countlyEvents.clickMuteButton}
        >
            <MicrophoneVolume isOn={callSession.audio} />
        </ControlBtn>
    );
}
export default AudioBtn;