import React from 'react';
import PeerMetrics from './PeerMetrics';
import type { NetworkStats } from 'utils/Network/types';
import * as Styled from './styles';

type Props = {
    publisher?: NetworkStats,
    subscriber?: NetworkStats,
}
const NetworkInfo = ({ publisher, subscriber }: Props) => {
    if(!publisher && !subscriber) return null;
    return (
        <Styled.NetworkInfo>
            <PeerMetrics name="You" metrics={publisher?.lastState} />
            <PeerMetrics name="Subscriber" metrics={subscriber?.lastState} />
        </Styled.NetworkInfo>
    );
}
export default NetworkInfo;