import React from 'react';
import PlaySound from 'utils/PlaySound';
import sound from 'assets/sound/small-success-4.mp3';
import playEqualizerSvg from 'assets/img/play-equalizer.svg';
import * as Styled from './styles';
import { Icon } from 'icon';

type TestSpeakerState = {
    isPlaying: boolean
}
class TestSpeaker extends React.Component<{}, TestSpeakerState> {
    
    playingSound?: HTMLAudioElement

    constructor(props: {}){
        super(props);
        this.state = {
            isPlaying: false
        };
    }
    tooglePlay = () => {
        if(!this.state.isPlaying) {
            this.play();
        }else{
            this.stop();
        }
    }
    play = () => {
        this.playingSound = PlaySound(sound);
        this.playingSound.onended = (event) => {
            this.stop();
        };
        this.setState({isPlaying: true});
    }
    stop = () => {
        this.playingSound?.pause();
        this.setState({isPlaying: false});
    }
    render(){
        return (
            <div id="speaker-test">
                <Styled.TestSpeakerButton onClick={this.tooglePlay} >
                    {this.state.isPlaying ? <PlayingButton /> : <PausedButton />}
                </Styled.TestSpeakerButton>
                {this.state.isPlaying && <SpeakerPlayingTooltip />}
            </div>
        );
    }
}
const PausedButton = () => {
    return (
        <Styled.PausedButton>
            <Icon name="speaker_green" size={1.125} />
            <span>&nbsp;Test speaker</span>
        </Styled.PausedButton>
    );
}
const PlayingButton = () => {
    return <span><object type="image/svg+xml" data={playEqualizerSvg}>Eq</object> Stop</span>;
}
const SpeakerPlayingTooltip = () => {
    return (
        <Styled.Tooltip>
            <Styled.TooltipArrowUp />
            <Styled.SpeakerTooltip>
                <div className="tooltip-icon-container"><Icon name="bulb" size={1.375} /></div>
                <div className="flex-grow-1">You should hear a sound playing. If you can’t hear the sound, try selecting a different speaker or increase volume.</div>
            </Styled.SpeakerTooltip>
        </Styled.Tooltip>
    );
}
export default TestSpeaker;