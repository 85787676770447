import { Config } from "../shared/types";

const config: Config = {
    "storage": sessionStorage,
    // For Amplify Resources
    "aws_project_region": "us-east-2",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "telehealthApi",
            "endpoint": "http://backend",
            "region": "us-east-2"
        },
        {
            "name": "teleHealthSocket",
            "endpoint": "ws://backend/ws",
            "region": "us-east-2"
        }
    ],

    "patient_host": ["http://localhost:3001"],
    "provider_host": "http://localhost:3001",

    // For Onelogin Resources
    "onelogin": {
        HOST: "https://villagemd-dev.onelogin.com",
        OIDC_CLIENT_ID: "fa1f4830-fc51-0138-51dc-0213ed617bb3156252",
        REDIRECT_URI: "http://localhost:3001/oidc/callback"
    },
    // For Azure Resources
    "azure": {
        TENANT_ID: "e2cbf95a-41ca-4a3c-8268-fbfe6502b1a6",
        HOST: "https://login.microsoftonline.com",
        OIDC_CLIENT_ID: "9879f016-adb7-48e7-958c-ca687bb7c9bd",
        REDIRECT_URI: "http://localhost:3001/azure-oidc/callback"
    },
    // For Opentok resources
    "opentok": {
        API_KEY: "47045724"
    },
    // Countly
    "countly": {
        app_key: "fdae69ec060c71eb7db7cbd4dc9a0febf144045e",
        url: "https://countly.docos.me"
    },
    "sentry_dsn": "",
    "POLLING_INTERVAL_MS": -1,
    "GCP_CAPTCHA_SITE_KEY": "6LeZz0weAAAAAO9Cu9XW3ZKQZBdMNsbBTPN9Jx1E",
};

export default config;
