import { API } from 'aws-amplify';
import HttpClientDecorator, { HttpClientDecoratorFormat } from './HttpClient/HttpClientDecorator';
import Endpoints from './HttpClient/Endpoints';
import HttpClientPlugins from './HttpClient/HttpClientPlugins';
import SSOAuth from 'utils/SSO/SSOAuth';
import { HttpEvent, HttpEventHandler, HttpEventRegistry } from './HttpClient/types';


class HttpClient {

    decorator = HttpClientDecorator();
    max_retries: number = 0;
    attempts_counter: number = 0;
    stopRetries: boolean = false;
    eventRegistry: HttpEventRegistry = {};

    useFormat = (format: HttpClientDecoratorFormat) => {
        this.decorator = HttpClientDecorator(format);
        return this;
    };
    retries = (max_retries: number = 0) => {
        this.max_retries = max_retries;
        return this;
    };
    stopRetry = (): void => {
        this.stopRetries = true;
    }
    shouldRetry = (): boolean => {
        if (this.stopRetries) {
            this.stopRetries = false
            return false;
        }

        if (this.max_retries > this.attempts_counter) {
            this.attempts_counter++;
            return true;
        }
        return false;
    };
    on = (eventType: string, handler: HttpEventHandler) => {
        this.eventRegistry[eventType] = handler;
    };
    dispatch = (eventType: string, data: HttpEvent) => {
        if (this.eventRegistry[eventType]) {
            this.eventRegistry[eventType](data);
        }
    };
    get = async (apiName: string, path: string, queryStringParameters?: any, options: any = {}): Promise<any> => {
        if(SSOAuth.isIdTokenExpired()){
            await SSOAuth.refreshTokens();
        }
        
        path = Endpoints.buildPath(path);
        if (queryStringParameters) {
            options.queryStringParameters = this.decorator.queryParams(queryStringParameters);
        }
        return (new Promise((resolve, reject) => {
            API.get(apiName, path, options)
                .then((response) => {
                    resolve(this.decorator.response(response));
                })
                .catch(async (error) => {
                    if (error.response) {
                        this.dispatch(`HTTP_STATUS_${error.response.status}`, {
                            method: "GET",
                            apiName,
                            path,
                            options,
                            response: error.response
                        });
                    }
                    let shouldRetry = this.shouldRetry()
                    if (shouldRetry) {
                        this.get(apiName, path, queryStringParameters, options)
                        .then((response) => resolve(response))
                        .catch((e) => reject(e));
                    } else {
                        reject(error);
                    }

                });
        }));
    };
    post = async (apiName: string, path: string, data?: any, options: any = {}): Promise<any> => {
        if(SSOAuth.isIdTokenExpired()){
            await SSOAuth.refreshTokens();
        }

        path = Endpoints.buildPath(path);
        if (data) {
            options.body = this.decorator.postData(data);
        }
        return (new Promise((resolve, reject) => {
            API.post(apiName, path, options)
                .then((response) => {
                    resolve(this.decorator.response(response));
                })
                .catch(async (error) => {
                    if (error.response) {
                        this.dispatch(`HTTP_STATUS_${error.response.status}`, {
                            method: "POST",
                            apiName, path,
                            options,
                            response: error.response
                        });
                    }

                    if (this.shouldRetry()) {
                        try {
                            await this.post(apiName, path, data, options);
                        } catch (e) {
                            error = e;
                        }
                    }

                    reject(error);
                });
        }));

    };
    patch = async (apiName: string, path: string, data?: any, options: any = {}): Promise<any> => {
        if(SSOAuth.isIdTokenExpired()){
            await SSOAuth.refreshTokens();
        }

        path = Endpoints.buildPath(path);
        if (data) {
            options.body = this.decorator.postData(data);
        }
        return (new Promise((resolve, reject) => {
            API.patch(apiName, path, options)
                .then((response) => {
                    resolve(this.decorator.response(response));
                })
                .catch(async (error) => {
                    if (error.response) {
                        this.dispatch(`HTTP_STATUS_${error.response.status}`, {
                            method: "PATCH",
                            apiName,
                            path,
                            options,
                            response: error.response
                        });
                    }

                    if (this.shouldRetry()) {
                        try {
                            await this.patch(apiName, path, data, options);
                        } catch (e) {
                            error = e;
                        }
                    }
                    reject(error);
                });
        }));
    };
    put = async (apiName: string, path: string, data?: any, options: any = {}): Promise<any> => {
        if(SSOAuth.isIdTokenExpired()){
            await SSOAuth.refreshTokens();
        }

        path = Endpoints.buildPath(path);
        if (data) {
            options.body = this.decorator.postData(data);
        }
        return (new Promise((resolve, reject) => {
            API.put(apiName, path, options)
                .then((response) => {
                    resolve(this.decorator.response(response));
                })
                .catch(async (error) => {
                    if (error.response) {
                        this.dispatch(`HTTP_STATUS_${error.response.status}`, {
                            method: "PUT",
                            apiName,
                            path,
                            options,
                            response: error.response
                        });
                    }

                    if (this.shouldRetry()) {
                        try {
                            await this.put(apiName, path, data, options);
                        } catch (e) {
                            error = e;
                        }
                    }
                    reject(error);
                });
        }));
    };
    delete = async (apiName: string, path: string, options?: any): Promise<any> => {
        if(SSOAuth.isIdTokenExpired()){
            await SSOAuth.refreshTokens();
        }

        path = Endpoints.buildPath(path);

        return (new Promise((resolve, reject) => {
            API.del(apiName, path, options)
                .then((response) => {
                    resolve(this.decorator.response(response));
                })
                .catch(async (error) => {
                    if (error.response) {
                        this.dispatch(`HTTP_STATUS_${error.response.status}`, {
                            method: "DELETE",
                            apiName,
                            path, options,
                            response: error.response
                        });
                    }

                    if (this.shouldRetry()) {
                        try {
                            await this.put(apiName, path, options);
                        } catch (e) {
                            error = e;
                        }
                    }
                    reject(error);
                });
        }));
    };
}

function HttpClientFactory() {
    let client = new HttpClient();

    HttpClientPlugins.forEach((plugin) => {
        client = plugin(client);
    });

    return client
}
export default HttpClientFactory;