import React from 'react';
import InCallScreen from '../../CallScreen/InCallScreen';
import CallContainer from '../../CallScreen/CallContainer';
import CallControls from '../../CallScreen/CallControls';
import PauseBtn from '../../CallScreen/controls/PauseBtn';
import ShareScreenBtn from '../../CallScreen/controls/ShareScreenBtn';
import PipBtn from '../../CallScreen/controls/PipBtn';
import RoomTimer from '../../CallScreen/controls/RoomTimer';
import withCallSession from '../../CallScreen/CallSession/withCallSession';
import Feature from 'shared/Feature';
import FF from 'shared/constants/FF';
import SESSION_STATUS from 'shared/constants/SESSION_STATUS';
import HttpClient from 'utils/HttpClient';
import ProviderCallDisconnectedModal from '../ProviderCallDisconnectedModal';
import { CallSessionContextState } from '../../CallScreen/CallSession/CallSessionContext';

type Props = {
    patientId: number,
    sessionId: number,
    publisherName: string,
    cameraUnavailable: boolean,
    callSession: CallSessionContextState,
    onLeave: () => void,
    onPause: () => void,
}
type State = {}
class ProviderCallContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    handleSubscriberReady = (): void => {
        let apiName = 'telehealthApi';
        let path = `/patients/${this.props.patientId}/sessions/${this.props.sessionId}/status`;
        let data = { "status": SESSION_STATUS.in_call };
        HttpClient().put(apiName, path, data)
            .then((data) => {
            })
            .catch((error) => {
                console.warn(`Updating Patient Status call failed: ${error}`);
            });
    }
    handlePause = (): void => {
        let apiName = 'telehealthApi';
        let path = `/patients/${this.props.patientId}/sessions/${this.props.sessionId}/status`;
        let data = { "status": SESSION_STATUS.on_hold };
        HttpClient().put(apiName, path, data)
            .then((data) => {
                this.props.callSession.handlePause();
                this.props.onPause();
            })
            .catch((error) => {
                console.warn(`Pausing call failed: ${error}`);
            });
    }
    render() {

        const { callSession } = this.props;

        return (
            <CallContainer
                userType='provider'
                callSession={callSession}
                publisherName={this.props.publisherName}
                cameraUnavailable={this.props.cameraUnavailable}
                onLeave={this.props.onLeave}
            >

                <InCallScreen 
                    userType='provider'
                    callSession={callSession}
                    onSubscriberReady={this.handleSubscriberReady}
                />
                
                <RoomTimer />

                <CallControls callSession={callSession} cameraUnavailable={this.props.cameraUnavailable}>
                    <Feature item code={FF.pauseButton} >
                        <PauseBtn callSession={callSession} onClick={this.handlePause} />
                    </Feature>
                    <Feature item code={FF.shareScreen} onlyDesktop >
                        <ShareScreenBtn callSession={callSession} />
                    </Feature>
                    <PipBtn videoElement={callSession.focusedVideoElement} callSession={callSession} />
                </CallControls>
                
                <ProviderCallDisconnectedModal callSession={callSession} />

            </CallContainer>
        );
    }
}
export default withCallSession(ProviderCallContainer);
