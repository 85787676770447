import type { FormRule } from '../types';

const FormRules: FormRule[] =  [
	{
		code: 'code',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Code is required',
				rule: ['required'],
			}
		}
	},
	{
		code: 'name',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Name is required',
				rule: ['required'],
			},
		}
	},
	{
		code: 'market_id',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Select a market',
				rule: ['required'],
			}
		}
	}
];

export default FormRules;