// Based on: https://support.tokbox.com/hc/en-us/articles/360029732311-What-is-the-minimum-bandwidth-requirement-to-use-OpenTok-
export const RUN_INTERVAL_TIMEOUT = 5000; // Run test every X ms
export const CHECK_CONNECTION_INTERVAL_TIMEOUT = 6000; // Check network connection every X ms
export const AUDIO_BW_THRESHOLD = 25_000; // Acceptable bandwidth limit for audio // 25kbps acceptable audio
export const AUDIO_PL = 5;
export const VIDEO_BW_THRESHOLD = 250_000; // Acceptable bandwidth limit for video // 250kbps acceptable audio
export const VIDEO_PL = 3;
export const GOOD_BITRATE_THRESHOLD = 350_000; // bps
export const BAD_BITRATE_THRESHOLD = 250_000; // bps
export const GOOD_PACKETLOSS_THRESHOLD = 0.5; // Percentage
export const BAD_PACKETLOSS_THRESHOLD = 5; // Percentage


// Network quality states:
export const AUDIO_VIDEO_SUPPORTED = 3;
export const VIDEO_QUALITY_AUDIO_ONLY = 2;
export const AUDIO_QUALITY_BANDWIDTH_TOO_LOW = 1;
export const TRYING_TO_CONNECT = 0;
export const NO_CONNECTION = -1;


export const MAX_SAMPLES = 6; // Since each sample is computed with their previous, there will be 5 computed samples
export const NORMALIZATION_FACTOR = 1.0 / 1.45; // Used to make samples weights sum 1.0
export const SAMPLE_WEIGHTS = Array(MAX_SAMPLES - 1).fill(0).map((_, i) => 1.0 / (MAX_SAMPLES - i) * NORMALIZATION_FACTOR); // Must sum 1.0

