import { rem } from 'polished';
import styled from 'styled-components';

export const ActionBar = styled.div`
    display: flex;
    gap: ${rem(20)};
    
    .MuiOutlinedInput-root .MuiOutlinedInput-input:not(.MuiOutlinedInput-inputMultiline) {
        height: ${rem(48)} !important;
    }
    .vui-autocomplete__value-container {
        padding: ${rem(9)} ${rem(16)} !important;
    }
    button {
        white-space: nowrap;
    }
`;

export const SearchContainer = styled.div`
    flex-grow: 1;
`;

export const CenteredContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F8F8F9;
`;