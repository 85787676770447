import React, { ReactNode } from 'react';
import { countlyReportFunction } from 'countly';
import * as Styled from './styles';
import type { Variant } from './types';
import { CountlyKey } from 'countly/types/countly';

type ControlBtnProps = {
    active: boolean,
    sm: boolean,
    onClick: () => void,
    eventCountly?: CountlyKey,
    children: ReactNode,
    className?: string,
    label?: string,
    id?: string,
    variant?: Variant
}
const ControlBtn = ({
    active,
    sm,
    onClick,
    eventCountly,
    children,
    className,
    label,
    id,
    variant = "light"
}: ControlBtnProps ) => {
    const handleClick = eventCountly ? countlyReportFunction(onClick, eventCountly) : onClick;

    return (
        <Styled.CallControlBtn id={id} active={active} sm={sm} className={className} onClick={handleClick} >
            <Styled.CtrBtn variant={variant} sm={sm}>{children}</Styled.CtrBtn>
            {label && <Styled.CtrLabel>{label}</Styled.CtrLabel>}
        </Styled.CallControlBtn>
    );
}
ControlBtn.defaultProps = {
    active: true,
    sm: false,
};
export default ControlBtn;
