import type { FormRule } from '../types';

const FormRules: FormRule[] =  [
	{
		code: 'email',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'This field is required',
				rule: ['required'],
			},
			email: {
				type: 'error',
				error_type: 'hard',
				message: 'This field should be a valid email',
				rule: ['email'],
			}
		}
	},
	{
		code: 'oldPassword',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'Current password is required',
				rule: ['required'],
			}
		}
	},
	{
		code: 'newPassword',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'New password is required',
				rule: ['required'],
			},
			password:{
				type: 'error',
				error_type: 'hard',
				message: 'New password does not meet requirements',
				rule: ['password']
			},
		}
	},
	{
		code: 'confirmNewPassword',
		element_type: 'input',
		rules: {
			required: {
				type: 'error',
				error_type: 'hard',
				message: 'New password confirmation is required',
				rule: ['required'],
			},
			equalTo: {
				type: 'error',
				error_type: 'hard',
				message: 'New passwords do not match',
				rule: ['equalTo', 'newPassword']
			},
		}
	},
];

export default FormRules;