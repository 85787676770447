import React from 'react';
import RoomContext from './RoomContext';


function withClinicalRoom(WrappedComponent: ReactComponent) {
    class withClinicalRoom extends React.Component<any, {}> {
        
        displayName: string = 'withClinicalRoom';
        static WrappedComponent: ReactComponent;

        constructor(props: any) {
            super(props);
            this.state = {};
        }
        render() {

            return (
                <RoomContext.Consumer>
                    {(context) =>
                        <WrappedComponent {...context} {...this.props} />
                    }
                </RoomContext.Consumer>
            );
        }
    };
    withClinicalRoom.WrappedComponent = WrappedComponent;
    return withClinicalRoom;
}
export default withClinicalRoom;