import styled from 'styled-components/macro';
import { rgba } from 'polished';
import { defaultVillageUiTheme as theme } from '@village/ui';

export const Quality = styled.div`
    position: absolute;
    top: 13px;
    left: calc(50% - 77px);
    z-index: 1;
`;
export const QualityValue = styled.div`
    border-radius: 8px;
    background-color: ${rgba(theme.vui.colors.decorative.neutral.gray80, 0.96)};
    padding: 5px 10px;
    color: ${theme.vui.colors.text.emphasis};
    width: 154px;
    cursor: pointer;
    text-align: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    
    &:hover {
        background-color: ${theme.vui.colors.decorative.neutral.gray75};
    }
`;
export const QualityList = styled.div`
    background-color: ${rgba(theme.vui.colors.decorative.neutral.gray80, 0.96)};
    color: ${theme.vui.colors.text.emphasis};
    position: absolute;
    top: 0px;
    z-index: -1;
    padding: 40px 0px 5px 0px;
    width: 100%;
    border-radius: 5px 5px 8px 8px;
    opacity: 1;
    transition: opacity 1s linear;
`;
export const QualityItem = styled.div`
    cursor: pointer;
    padding: 5px 15px;
    text-align: right;
    &:hover {
        background-color: ${theme.vui.colors.decorative.neutral.gray75};
    }

`;