import React from 'react';
import qs from "qs";
import { RouteComponentProps } from 'react-router-dom';
import { countlyAddEvent } from 'countly';
import countlyEvents from 'countly/events';
import HttpClient from 'utils/HttpClient';
import Loading from "shared/Loading";
import Input from 'shared/inputs/Input2';
import Select from 'shared/inputs/VmdSelect';
import MessageInline from 'shared/MessageInline';
import { Clinic, IClinic, Market, SelectOption, Validation } from 'shared/types';
import { IMessage, MessageType } from 'shared/Messages/types';
import withValidation from 'validations/withValidation';
import ClinicsFormRules from 'validations/FormRules/ClinicsFormRules';
import { IValidation } from 'validations/types';
import { Button, Text, Drawer, DrawerFooter, DrawerHeader, DrawerContent } from '@village/ui';
import { Box } from '@material-ui/core';


type FormClinicsProps = RouteComponentProps & IValidation & {
    match: RouteComponentProps['match'] & {
        params: { id: string }
    }
    mode: "new" | "edit",
    onCreate: (newClinics: Clinic) => void,
    onUpdate: (clinicId: number, postData: IClinic) => void,
}

type FormClinicsState = {
    loadingCounter: number
    open: boolean,
    message: IMessage | null,
    clinic: IClinic,
    marketOptions: SelectOption[]
}

class FormClinics extends React.Component<FormClinicsProps, FormClinicsState> {
    constructor(props: FormClinicsProps) {
        super(props);
        this.state = {
            loadingCounter: 0,
            open: false,
            message: null,
            clinic: {},
            marketOptions: []
        };
    }
    componentDidMount() {
        this.loadMarkets();
        if (this.props.mode === 'edit') {
            this.getClinic(parseInt(this.props.match.params.id));
        }
        this.setState({ open: true });
    }
    getClinic(clinicId: number) {
        let apiName = 'telehealthApi';
        let path = '/clinics/' + clinicId;
        this.setState({
            loadingCounter: this.state.loadingCounter + 1
        });
        HttpClient().get(apiName, path)
            .then((data: Clinic) => {
                this.setState({
                    loadingCounter: this.state.loadingCounter - 1,
                    clinic: data
                });
            }).catch((error) => {
                this.setState({
                    loadingCounter: this.state.loadingCounter - 1
                });
            });
    }
    async createClinic() {
        let apiName = 'telehealthApi';
        let path = '/clinics';
        const { clinic } = this.state;
        let postData = clinic;
        if (!this.props.validate(postData)) return;
        this.setState({
            loadingCounter: this.state.loadingCounter + 1
        });
        try {
            // create a new Clinic
            const newClinic = await HttpClient().post(apiName, path, postData);

            this.setState({
                loadingCounter: this.state.loadingCounter - 1,
                clinic: newClinic
            });
            this.props.onCreate(newClinic);
            this.handleClose();
        } catch (error: any) {
            this.setState({
                loadingCounter: this.state.loadingCounter - 1,
                message: {
                    type: MessageType.danger,
                    message: (error.response?.data?.detail || "Failed to create clinic")
                }
            });
            countlyAddEvent(countlyEvents.error, {
                errorCode: error.name,
                errorDescription: error.message,
                comment: "Failed to create clinic",
                response: JSON.stringify(error.response)
            });
        }
    }
    updateClinic(clinicId: number) {
        let apiName = 'telehealthApi';
        let path = '/clinics/' + clinicId;
        const { clinic } = this.state;
        let postData = clinic;
        if (!this.props.validate(postData, 'update')) return;
        this.setState({
            loadingCounter: this.state.loadingCounter + 1
        });
        HttpClient().put(apiName, path, postData)
            .then((data) => {
                this.setState({
                    loadingCounter: this.state.loadingCounter - 1,
                });
                if (postData) this.props.onUpdate(clinicId, postData);
                this.handleClose();
            }).catch((error: any) => {
                this.setState({
                    loadingCounter: this.state.loadingCounter - 1,
                    message: {
                        type: MessageType.danger,
                        message: (error.response?.data?.detail || "Failed to update clinic")
                    }
                });
                countlyAddEvent(countlyEvents.error, {
                    errorCode: error.name,
                    errorDescription: error.message,
                    comment: "Failed to update clinic",
                    response: JSON.stringify(error.response)
                });
            });
    }
    loadMarkets = (): void => {
        const apiName = 'telehealthApi';
        const path = '/markets';
        this.setState({
            loadingCounter: this.state.loadingCounter + 1,
        });
        HttpClient().get(apiName, path)
            .then(markets => {
                this.setState({
                    loadingCounter: this.state.loadingCounter - 1,
                    marketOptions: markets.map((m: Market) => ({ value: m.id, label: m.name }))
                });
            }).catch(error => {
                console.log("Loading markets error: ", error)
                this.setState({
                    loadingCounter: this.state.loadingCounter - 1
                });
            })
    }
    handleClose = (): void => {
        this.setState({ open: false });
        setTimeout(() => {
            let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            this.props.history.push({
                pathname: '/clinics',
                search: '?' + qs.stringify(queryParams),
            });
        }, 300);
    }
    handleSubmit = (event: React.FormEvent): void => {
        event.preventDefault();

        if (this.props.mode === 'new') {
            this.createClinic();
        } else {
            this.updateClinic(parseInt(this.props.match.params.id));
        }
    }
    handleChangeSelect = (name: string, value: string): void => {
        this.setState({
            clinic: {
                ...this.state.clinic,
                [name]: value
            }
        });
    }
    handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;
        this.setState({
            clinic: {
                ...this.state.clinic,
                [name]: value
            }
        });

        //Trigger validation for new updates:
        this.props.validateLive({
            ...this.state.clinic,
            [name]: value
        }, this.props.mode === 'edit' ? 'update' : 'create');
    }
    render() {

        const { clinic, message } = this.state;
        const validations = this.props.validations === true ? {} : this.props.validations;

        return (
            <Drawer open={this.state.open} onClose={this.handleClose} anchor='right' data-testid='form-clinics-drawer'>
                <DrawerHeader onClose={this.handleClose} >
                    <Text type='h6'>{this.props.mode === 'new' ? 'Create' : 'Edit'} clinic</Text>
                </DrawerHeader>
                <DrawerContent>
                    {this.state.loadingCounter > 0
                        ? <Loading />
                        : <form id='clinic-form' method='POST' >
                            {message && <MessageInline type={message.type} message={message.message} />}
                            <Box py={2}>
                                <Input
                                    label='Code'
                                    name='code'
                                    fullWidth
                                    value={clinic.code}
                                    onChange={this.handleChange}
                                    disabled={this.props.mode === 'edit'}
                                    validation={validations?.code as Validation}
                                />
                            </Box>
                            <Box py={2}>
                                <Input
                                    label='Name'
                                    name='name'
                                    fullWidth
                                    value={clinic.name}
                                    onChange={this.handleChange}
                                    validation={validations?.name as Validation}
                                />
                            </Box>
                            <Select
                                label="Market"
                                id="market_id"
                                name="market_id"
                                fullWidth
                                value={clinic.market_id}
                                onChange={this.handleChangeSelect}
                                validation={validations?.market_id as Validation}
                                options={this.state.marketOptions}
                                showEmptyOption
                            />
                        </form>
                    }
                </DrawerContent>
                <DrawerFooter>
                    <Box display="flex" gridGap={16} >
                        <Button variant="secondary" size="medium" fullWidth onClick={this.handleClose} >
                            Cancel
                        </Button>
                        <Button size="medium" fullWidth type="submit" onClick={this.handleSubmit} >
                            Save
                        </Button>
                    </Box>
                </DrawerFooter>
            </Drawer>
        );
    }
}
export default withValidation(FormClinics, ClinicsFormRules);